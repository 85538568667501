import React from "react";
import { Form, InputGroup, Col, Button, Tooltip, Overlay, OverlayTrigger, Spinner } from "react-bootstrap";
import { useState, useRef, useEffect, useCallback } from "react";
import Multiselect from "multiselect-react-dropdown";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import ImageUploading from "react-images-uploading";
import { useDropzone } from "react-dropzone";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import { toasterMessage } from "../../utils/toast-util";
import CropEasy from "../../crop/CropEasy";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  supplierListById,
  UpdateSupplier,
  createSupplier,
} from "../../Redux/action/supplierDetail";
import { getSupplierType } from "../../Redux/action/categoriesManagement";
import { countryDeatils } from "../../assets/country";
import ArtistDemo from "../../assets/images/artist.png";

function AddSupplier(props) {
  const [openCrop, setOpenCrop] = useState(false);
  const [openIdCrop, setOpenIdCrop] = useState(false);
  const [openPhotoCrop, setOpenPhotoCrop] = useState(false);
  const [openCopyCrop, setOpenCopyCrop] = useState(false);


  // New Data
  const [removeCompanyCredsImage, setRemoveCompanyCredsImage] = useState(true);
  const [removeCompanyCatalogueImage, setRemoveCompanyCatalogueImage] = useState(true);
  const [removeCompanyTradeLicenseImage, setRemoveCompanyTradeLicenseImage] = useState(true);
  const [removeCompanyVATCertificate, setRemoveCompanyVATCertificate] = useState(true);
  const [removeOtherDocuments, setRemoveOtherDocuments] = useState(true);


  const [companyCredsImage, setCompanyCredsImage] = useState([]);
  const [companyCatalogueImage, setCompanyCatalogueImage] = useState([]);
  const [companyTradeLicense, setCompanyTradeLicense] = useState([]);
  const [companyVatCertificate, SetCompanyVATCertificate] = useState([]);
  const [otherDocumentsImage, setOtherDocumentsImage] = useState([]);
  // End

  const [validated, setValidated] = useState(false);
  const [country, setCountry] = useState(null);
  const [mobileNumber1, setmobileNumber1] = useState(null);
  const [landlineNumber, setLandlineNumber] = useState(null);
  const [imageFile1, setImageFile] = useState(null);
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { editFlag } = location && location.state;
  const supplierRef = useRef(null);
  const locationCompanyHeadquarterRef = useRef(null);
  const companyServiceAreaRef = useRef(null);

  const [imagePreview, setImagePreview] = useState(ArtistDemo);


  const supplierList = useSelector(
    (state) => state.CategoriesManagementReducer.supplierTypeList
  );

  const countryOptions = countryDeatils.map(country => ({
    label: country.country,
    value: country.country,
  }));

  const supplier_detail = useSelector(
    (state) => state.SupplierManagementReducer.supplier_detail
  );
  const params = useParams();
  useEffect(() => {
    dispatch(getSupplierType());
    if (params.id) {
      dispatch(supplierListById(params.id));
    }
  }, []);
  useEffect(() => {
    if (supplier_detail.companyTradeLicense) {
      setRemoveCompanyTradeLicenseImage(false);
    }
    if (supplier_detail.companyCredentials) {
      setRemoveCompanyCredsImage(false);
    }
    if (supplier_detail.companyVatCertificate) {
      setRemoveCompanyVATCertificate(false);
    }
    if (supplier_detail.otherDocuments) {
      setRemoveOtherDocuments(false);
    }

    if (supplier_detail.companyCatalogue) {
      setRemoveCompanyCatalogueImage(false);
    }
    if (supplier_detail?.mobileNumber) {
      setmobileNumber1(supplier_detail?.mobileNumber)
    }
    if (supplier_detail?.companyLandline) {
      setLandlineNumber(supplier_detail?.companyLandline)
    }
    if (supplier_detail?.profile_pic) {
      setImageFile(supplier_detail?.profile_pic);
    }
    if (supplier_detail?.countryHeadquarters) {
      setCountry(supplier_detail?.countryHeadquarters)
    }
    if (supplier_detail?.profile_pic) {
      // setImagePreview( supplier_detail.profile_pic);
      setImagePreview(supplier_detail.profile_pic.includes('https') ? supplier_detail.profile_pic : ArtistDemo);
    }
  }, [supplier_detail]);
  const handleLocationSelection = (selectedItems) => {
    setCountry(selectedItems[0].value); // Assuming you only want to store one selected item
  };
  const handleSubmit = (event) => {
    if (mobileNumber1 === null || mobileNumber1.length < 11) {
      // event.preventDefault();
      console.log("");
      toasterMessage("error", "Please enter mobile number");
    }

    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(event.target.email.value)) {
      toasterMessage("error", "Please add valid email");
    }
    const form = event.currentTarget;

    var docE;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    event.preventDefault();
    setValidated(true);

    var localSupplier = [];
    supplierRef.current.getSelectedItems().forEach((item) => {
      localSupplier.push(item.id);
    });

    var localCompanyServiceAreas = [];
    companyServiceAreaRef.current.getSelectedItems().forEach((item) => {
      localCompanyServiceAreas.push(item.value);
    });
    var hasError = false;
    hasError = false;

    if (supplierList.length > 0) {
      if (localSupplier.length <= 0) {
        hasError = true;
        toasterMessage("error", "Please select Supplier");
      }
    }

    if (countryDeatils.length > 0) {
      if (localCompanyServiceAreas.length <= 0) {
        hasError = true;
        toasterMessage("error", "Please select Company Service Areas");
      }
    }
    if (
      form.checkValidity() === true &&
      mobileNumber1 &&
      !hasError
    ) {
      setLoader(true);
      let formData = new FormData();
      formData.append("companyName", event.target.companyName?.value);
      formData.append("supplierTypes", localSupplier);
      formData.append("legalTradeName", event.target.legalTradeName?.value);
      formData.append("tradeLicenseNumber", event.target.tradeLicenseNumber?.value);
      formData.append("vatNumber", event.target.vatNumber?.value);
      formData.append("countryHeadquarters", country);
      formData.append("cityHeadquarters", event.target.city?.value);
      formData.append("companyServiceArea", localCompanyServiceAreas);
      formData.append("companyDescription", event.target.companyDescription?.value);
      formData.append("website", event.target.website?.value);
      formData.append("firstName", event.target.firstName?.value);
      formData.append("lastName", event.target.lastName?.value);
      formData.append("email", event.target.email?.value);
      formData.append("mobileNumber", mobileNumber1);
      formData.append("companyLandline", landlineNumber);

      if (event.target.facebook?.value) {
        formData.append("fbURL", event.target.facebook?.value);
      }
      if (event.target.youtube?.value) {
        formData.append("ytURL", event.target.youtube?.value);
      }
      if (event.target.instagram?.value) {
        formData.append("instaURL", event.target.instagram?.value);
      }
      if (event.target.soundCloud?.value) {
        formData.append("soundcloudURL", event.target.soundCloud?.value);
      }
      if (event.target.twitter?.value) {
        formData.append("twitterURL", event.target.twitter?.value);
      }
      if (event.target.title?.value) {
        formData.append("title", event.target.title?.value);
      }
      if (imageFile1) {
        formData.append("supplier_profile_pic", imageFile1);
      }
      if (event.target.accountName.value) {
        formData.append("accountName", event.target.accountName?.value);
      }
      if (event.target.accountNumber.value) {
        formData.append("accountNumber", event.target.accountNumber?.value);
      }
      if (event.target.ibanNumber.value) {
        formData.append("ibanNumber", event.target.ibanNumber?.value);
      }
      if (event.target.swiftCode.value) {
        formData.append("swiftCode", event.target.swiftCode?.value);
      }
      if (event.target.sortCode.value) {
        formData.append("sortCode", event.target.sortCode?.value);
      }
      if (event.target.routeCode.value) {
        formData.append("routeCode", event.target.routeCode?.value);
      }
      if (event.target.bankName.value) {
        formData.append("bankName", event.target.bankName?.value);
      }
      if (event.target.bankAddress.value) {
        formData.append("bankAddress", event.target.bankAddress?.value);
      }
      if (event.target.recipientAddress.value) {
        formData.append("recipientAddress", event.target.recipientAddress?.value);
      }
      formData.append(
        "tradeLicense",
        removeCompanyTradeLicenseImage
          ? companyTradeLicense[0]
            ? companyTradeLicense[0]
            : "null"
          : supplier_detail.companyTradeLicense
      );
      formData.append(
        "vatCertificate",
        removeCompanyVATCertificate
          ? companyVatCertificate[0]
            ? companyVatCertificate[0]
            : "null"
          : supplier_detail.companyVatCertificate
      );
      formData.append(
        "other",
        removeOtherDocuments
          ? otherDocumentsImage[0]
            ? otherDocumentsImage[0]
            : "null"
          : supplier_detail.otherDocuments
      );
      formData.append(
        "catalogue",
        removeCompanyCatalogueImage
          ? companyCatalogueImage[0]
            ? companyCatalogueImage[0]
            : "null"
          : supplier_detail.companyCatalogue
      );
      formData.append(
        "credentials",
        removeCompanyCredsImage
          ? companyCredsImage[0]
            ? companyCredsImage[0]
            : "null"
          : supplier_detail.companyCredentials
      );
      if (params.id) {
        dispatch(UpdateSupplier(formData, params.id, history, loader, setLoader));
      } else {
        dispatch(createSupplier(formData, history, loader, setLoader));
      }
    }
  };

  function valuesToUseSupplier() {
    const arryused = [];

    if (supplier_detail && supplier_detail?.supplierTypes) {
      supplier_detail.supplierTypes.forEach((id) => {
        const foundSupplier = supplierList.find((x) => x.id === id);
        if (foundSupplier) {
          arryused.push(foundSupplier);
        }
      });
      if (arryused.length > 0) {
        return arryused;
      }
    } else {
      return null;
    }
  }

  function valuesToUseLocationOfCompanyHeadquarter() {
    const arryused = [];

    if (supplier_detail?.countryHeadquarters) {
      const selectedCountryValue = supplier_detail.countryHeadquarters;

      // Find the corresponding country object in countryOptions
      const foundCountry = countryOptions.find((option) => option.value === selectedCountryValue);

      if (foundCountry) {
        arryused.push(foundCountry);
      }

      if (arryused.length > 0) {
        return arryused;
      }
    } else {
      return null;
    }
  }

  function valuesToUseComapnyServiceArea() {
    const arryused = [];

    if (typeof supplier_detail?.companyServiceArea === 'string') {
      const typeIds = supplier_detail.companyServiceArea.split(',').map((idStr) => idStr.trim());

      typeIds.forEach((id) => {
        const foundSupplier = countryOptions.find((x) => x.value === id);
        if (foundSupplier) {
          arryused.push(foundSupplier);
        }
      });

      if (arryused.length > 0) {
        return arryused;
      }
    } else {
      return null;
    }
  }
  function OtherDocumentsUpload(props) {
    const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
      // Disable click and keydown behavior
      noClick: true,
      noKeyboard: true,
      accept: ".doc,.docx",
      onDrop: useCallback(
        (acceptedFiles) => {
          setOtherDocumentsImage([...otherDocumentsImage, ...acceptedFiles]);
        },
        [otherDocumentsImage]
      ),
    });

    const remove = (file) => {
      console.log("works");
      setOtherDocumentsImage([]);
    };
    const files = otherDocumentsImage.map((file) => (
      <li key={file.path}>
        {file.path} - {file.size} bytes
      </li>
    ));

    return (
      <div>
        {otherDocumentsImage.length > 0 ? null : (
          <div
            {...getRootProps({ className: "dropzone" })}
            style={{
              minHeight: "200px",
              border: "1px solid #ebedf2",
              padding: "15px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <input {...getInputProps()} />
            <p>Drag 'n' drop some files here</p>
            <button
              type="button"
              onClick={open}
              className="btn yellow-btn mt-auto"
              disabled={editFlag ? false : true}
            >
              Upload
            </button>
          </div>
        )}
        <aside>
          {files.length ? <h5 className="my-2"></h5> : null}
          <ul className="list-ticked">{files}</ul>
          {otherDocumentsImage.length > 0 ? (
            <Button
              type="button"
              className="yellow-btn"
              onClick={(i) => remove()}
              disabled={editFlag ? false : true}
            >
              {" "}
              Remove
            </Button>
          ) : null}
        </aside>
      </div>
    );
  }
  function CompanyCredsImageUpload(props) {
    const { getRootProps, getInputProps, open } = useDropzone({
      // Disable click and keydown behavior
      noClick: true,
      noKeyboard: true,
      onDrop: useCallback(
        (acceptedFiles) => {
          setCompanyCredsImage([...companyCredsImage, ...acceptedFiles]);
        },
        [companyCredsImage]
      ),
      accept: ".png,.jpg,.pdf",
    });

    const remove = (file) => {
      console.log("works");
      setCompanyCredsImage([]);
    };
    const files = companyCredsImage.map((file) => (
      <li key={file.path}>
        {file.path} - {file.size} bytes
      </li>
    ));

    return (
      <div>
        {companyCredsImage.length > 0 ? null : (
          <div
            {...getRootProps({ className: "dropzone" })}
            style={{
              minHeight: "200px",
              border: "1px solid #ebedf2",
              padding: "15px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <input {...getInputProps()} />
            <button
              type="button"
              onClick={open}
              className="btn yellow-btn mt-auto"
              disabled={editFlag ? false : true}
            >
              Upload
            </button>
          </div>
        )}
        <aside>
          {files.length ? <h5 className="my-2"></h5> : null}
          <ul className="list-ticked">{files}</ul>
          {companyCredsImage.length > 0 ? (
            <Button
              type="button"
              className="yellow-btn"
              onClick={(i) => remove()}
              disabled={editFlag ? false : true}
            >
              {" "}
              Remove
            </Button>
          ) : null}
        </aside>
      </div>
    );
  }
  function CompanyCatalogueImageUpload(props) {
    const { getRootProps, getInputProps, open } = useDropzone({
      // Disable click and keydown behavior
      noClick: true,
      noKeyboard: true,
      onDrop: useCallback(
        (acceptedFiles) => {
          setCompanyCatalogueImage([...companyCatalogueImage, ...acceptedFiles]);
        },
        [companyCatalogueImage]
      ),
      accept: ".png,.jpg,.pdf",
    });

    const remove = (file) => {
      console.log("works");
      setCompanyCatalogueImage([]);
    };
    const files = companyCatalogueImage.map((file) => (
      <li key={file.path}>
        {file.path} - {file.size} bytes
      </li>
    ));

    return (
      <div>
        {companyCatalogueImage.length > 0 ? null : (
          <div
            {...getRootProps({ className: "dropzone" })}
            style={{
              minHeight: "200px",
              border: "1px solid #ebedf2",
              padding: "15px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <input {...getInputProps()} />
            <button
              type="button"
              onClick={open}
              className="btn yellow-btn mt-auto"
              disabled={editFlag ? false : true}
            >
              Upload
            </button>
          </div>
        )}
        <aside>
          {files.length ? <h5 className="my-2"></h5> : null}
          <ul className="list-ticked">{files}</ul>
          {companyCatalogueImage.length > 0 ? (
            <Button
              type="button"
              className="yellow-btn"
              onClick={(i) => remove()}
              disabled={editFlag ? false : true}
            >
              {" "}
              Remove
            </Button>
          ) : null}
        </aside>
      </div>
    );
  }
  function CompanyTradeLicenseUpload(props) {
    const { getRootProps, getInputProps, open } = useDropzone({
      // Disable click and keydown behavior
      noClick: true,
      noKeyboard: true,
      onDrop: useCallback(
        (acceptedFiles) => {
          setCompanyTradeLicense([...companyTradeLicense, ...acceptedFiles]);
        },
        [companyTradeLicense]
      ),
      accept: ".png,.jpg,.pdf",
    });

    const remove = (file) => {
      console.log("works");
      setCompanyTradeLicense([]);
    };
    const files = companyTradeLicense.map((file) => (
      <li key={file.path}>
        {file.path} - {file.size} bytes
      </li>
    ));

    return (
      <div>
        {companyTradeLicense.length > 0 ? null : (
          <div
            {...getRootProps({ className: "dropzone" })}
            style={{
              minHeight: "200px",
              border: "1px solid #ebedf2",
              padding: "15px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <input {...getInputProps()} />
            <button
              type="button"
              onClick={open}
              className="btn yellow-btn mt-auto"
              disabled={editFlag ? false : true}
            >
              Upload
            </button>
          </div>
        )}
        <aside>
          {files.length ? <h5 className="my-2"></h5> : null}
          <ul className="list-ticked">{files}</ul>
          {companyTradeLicense.length > 0 ? (
            <Button
              type="button"
              className="yellow-btn"
              onClick={(i) => remove()}
              disabled={editFlag ? false : true}
            >
              {" "}
              Remove
            </Button>
          ) : null}
        </aside>
      </div>
    );
  }
  function CompanyVATCertificateUpload(props) {
    const { getRootProps, getInputProps, open } = useDropzone({
      // Disable click and keydown behavior
      noClick: true,
      noKeyboard: true,
      onDrop: useCallback(
        (acceptedFiles) => {
          SetCompanyVATCertificate([...companyVatCertificate, ...acceptedFiles]);
        },
        [companyVatCertificate]
      ),
      accept: ".png,.jpg,.pdf",
    });

    const remove = (file) => {
      console.log("works");
      SetCompanyVATCertificate([]);
    };
    const files = companyVatCertificate.map((file) => (
      <li key={file.path}>
        {file.path} - {file.size} bytes
      </li>
    ));

    return (
      <div>
        {companyVatCertificate.length > 0 ? null : (
          <div
            {...getRootProps({ className: "dropzone" })}
            style={{
              minHeight: "200px",
              border: "1px solid #ebedf2",
              padding: "15px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <input {...getInputProps()} />
            <button
              type="button"
              onClick={open}
              className="btn yellow-btn mt-auto"
              disabled={editFlag ? false : true}
            >
              Upload
            </button>
          </div>
        )}
        <aside>
          {files.length ? <h5 className="my-2"></h5> : null}
          <ul className="list-ticked">{files}</ul>
          {companyVatCertificate.length > 0 ? (
            <Button
              type="button"
              className="yellow-btn"
              onClick={(i) => remove()}
              disabled={editFlag ? false : true}
            >
              {" "}
              Remove
            </Button>
          ) : null}
        </aside>
      </div>
    );
  }

  const commonStyles = {
    searchBox: { // To change search box element look
      background: 'white',
      height: "46px",
      overflow: 'auto',
      width: 'auto'
    },
    chips: {
      background: '#F6F4ED',
      color: 'black',
      maxWidth: 'calc(100% - 32px)', // Reduce the max width of chips to fit within the search box
      overflow: 'hidden', // Hide the overflow content
      whiteSpace: 'nowrap', // Prevent wrapping of long names
      textOverflow: 'ellipsis', // Show ellipsis for long names
    },
    options: {
      "fontSize": "0.875rem",
    },
    "fontSize": "0.875rem",
  }

  const nonCommonStyle = {
    chips: {
      background: "#F6F4ED",
      color: "black",
    },
    "fontSize": "0.875rem",
  }

  return (
    <>
      <div className="row">
        <h4 className="card-title col-8 d-flex align-items-center">
          {!params.id ? "Create Supplier" : (params.id && editFlag) ? "Update Supplier" : "View Supplier"}
        </h4>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/supplier-management">Supplier Management</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {!params.id ? "Create Supplier" : (params.id && editFlag) ? "Update Supplier" : "View Supplier"}
            </li>
          </ol>
        </nav>
      </div>
      <div className="row">
        <div className="col-md-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              {params.id ?
                <div className="artist-register-image mb-2 mt-1">
                  <img
                    src={imagePreview}
                    alt=""
                    width="100"
                    className="rounded-circle artist-image"
                  />
                  <input
                    className="d-none"
                    type="file"
                  />
                </div> : null}
              <div className="row">
                <h4 className="card-title col-9 d-flex align-items-center">
                  General Information
                </h4>
              </div>

              <Form
                className="forms-sample"
                noValidate
                validated={validated}
                onSubmit={(event) => handleSubmit(event)}
              >
                <>
                  {params.id ? (
                    <Form.Row>
                      <Form.Group as={Col} md="6" className="row">
                        <Form.Label
                          htmlFor="exampleInputUsername2"
                          className=" col-sm-4  col-form-label"
                        >
                          Company Id
                        </Form.Label>
                        <div className="col-sm-8">
                          <Form.Control
                            type="text"
                            disabled
                            className="form-control"
                            placeholder="Company Code"
                            defaultValue={supplier_detail.id}
                          />
                        </div>
                      </Form.Group>
                    </Form.Row>
                  ) : null}
                  <Form.Row>
                    <Form.Group as={Col} md="6" className="row">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className="col-sm-4   col-form-label"
                      >
                        Company Name*
                      </Form.Label>
                      <div className="col-sm-8">
                        <Form.Control
                          type="text"
                          className="form-control"
                          required
                          placeholder="Enter your Company name"
                          name="companyName"
                          defaultValue={supplier_detail?.companyName}
                          disabled={editFlag ? false : true}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide Your Company Name.
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>

                    <Form.Group as={Col} md="6" className="row">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className="col-sm-4   col-form-label"
                      >
                        Supplier Type*
                      </Form.Label>
                      <div className="col-sm-8">
                        <Multiselect
                          options={supplierList}
                          selectedValues={valuesToUseSupplier()}
                          showCheckbox={true}
                          customCloseIcon={
                            <i className="typcn typcn-delete ml-1"></i>
                          }
                          displayValue={"name" || null} // Property name to display in the dropdown options
                          hidePlaceholder={true}
                          showArrow
                          selectionLimit={
                            editFlag ? -1 : `${valuesToUseSupplier()}.length`
                          }
                          style={editFlag ? commonStyles : nonCommonStyle}
                          ref={supplierRef}
                          placeholder="Select Supplier Type"
                          disable={editFlag ? false : true}
                        />
                      </div>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md="6" className="row">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className="col-sm-4   col-form-label"
                      >
                        Legal Trade Name*
                      </Form.Label>
                      <div className="col-sm-8">
                        <Form.Control
                          type="text"
                          className="form-control"
                          required
                          placeholder="Enter your Legal Trade Name"
                          name="legalTradeName"
                          defaultValue={supplier_detail?.legalTradeName}
                          disabled={editFlag ? false : true}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide Your Legal Trade Name.
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>
                    <Form.Group as={Col} md="6" className="row">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className="col-sm-4   col-form-label"
                      >
                        Trade License Number*
                      </Form.Label>
                      <div className="col-sm-8">
                        <Form.Control
                          type="text"
                          className="form-control"
                          required
                          placeholder="Enter your Trade License Number"
                          name="tradeLicenseNumber"
                          defaultValue={supplier_detail?.tradeLicenseNumber}
                          disabled={editFlag ? false : true}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide Your Trade License Number.
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md="6" className="row">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className="col-sm-4   col-form-label"
                      >
                        VAT Number*
                      </Form.Label>
                      <div className="col-sm-8">
                        <Form.Control
                          type="text"
                          className="form-control"
                          required
                          placeholder="Enter your VAT Number"
                          name="vatNumber"
                          defaultValue={supplier_detail?.vatNumber}
                          disabled={editFlag ? false : true}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide Your VAT Number.
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>
                    <Form.Group as={Col} md="6" className="row  mr-1">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className="col-sm-4   col-form-label"
                      >
                        Location of Company Headquarter*
                      </Form.Label>
                      <div className="col-sm-8">
                        <Multiselect
                          options={countryOptions}
                          // getOptionLabel={(option) => option.label}
                          // getOptionValue={(option) => option.value}
                          singleSelect={true}
                          selectedValues={valuesToUseLocationOfCompanyHeadquarter()}
                          customCloseIcon={
                            <i className="typcn typcn-delete ml-1"></i>
                          }
                          displayValue={"value" || null} // Property name to display in the dropdown options
                          hidePlaceholder={true}
                          showArrow
                          onSelect={handleLocationSelection}
                          style={{
                            searchBox: { // To change search box element look
                              background: 'white',
                              height: "46px",
                              overflow: 'auto',
                              width: 'auto'
                            },
                            chips: {
                              background: '#F6F4ED',
                              color: 'black',
                              maxWidth: 'calc(100% - 32px)', // Reduce the max width of chips to fit within the search box
                              overflow: 'hidden', // Hide the overflow content
                              whiteSpace: 'nowrap', // Prevent wrapping of long names
                              textOverflow: 'ellipsis', // Show ellipsis for long names
                            },
                            options: {
                              "fontSize": "0.875rem",
                            },
                            "fontSize": "0.875rem",
                          }}
                          ref={locationCompanyHeadquarterRef}
                          placeholder="Company Headquarter"
                          disable={editFlag ? false : true}
                        />
                        <span
                          style={{
                            width: "100%",
                            fontSize: "0.875em",
                            color: "#dc3545",
                          }}
                        ></span>
                      </div>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md="6" className="row">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className="col-sm-4   col-form-label"
                      >
                        City*
                      </Form.Label>
                      <div className="col-sm-8">
                        <Form.Control
                          type="text"
                          className="form-control"
                          required
                          placeholder="Enter your City Name"
                          name="city"
                          defaultValue={supplier_detail?.cityHeadquarters}
                          disabled={editFlag ? false : true}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide Your City Name.
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>
                    <Form.Group as={Col} md="6" className="row mr-1">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className="col-sm-4   col-form-label"
                      >
                        Company's Service Areas*
                      </Form.Label>
                      <div className="col-sm-8">
                        <Multiselect
                          options={countryOptions}
                          // getOptionLabel={(option) => option.label}
                          // getOptionValue={(option) => option.value}
                          showCheckbox={true}
                          selectedValues={valuesToUseComapnyServiceArea()}
                          customCloseIcon={
                            <i className="typcn typcn-delete ml-1"></i>
                          }
                          displayValue={"value" || null} // Property name to display in the dropdown options
                          hidePlaceholder={true}
                          showArrow
                          style={editFlag ? commonStyles : nonCommonStyle}
                          ref={companyServiceAreaRef}
                          placeholder="Company Service Areas"
                          disable={editFlag ? false : true}
                        />
                      </div>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md="6" className="row">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className="col-sm-4   col-form-label"
                      >
                        Company Description*
                      </Form.Label>
                      <div className="col-sm-8">
                        <Form.Control
                          type="text"
                          className="form-control"
                          required
                          placeholder="Enter Company description"
                          name="companyDescription"
                          defaultValue={supplier_detail?.companyDescription}
                          disabled={editFlag ? false : true}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide Company description.
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>
                    <Form.Group as={Col} md="6" className="row">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className="col-sm-4   col-form-label"
                      >
                        Website*
                      </Form.Label>
                      <div className="col-sm-8">
                        <Form.Control
                          type="text"
                          className="form-control"
                          required
                          placeholder="Enter your Website"
                          name="website"
                          defaultValue={supplier_detail?.website}
                          disabled={editFlag ? false : true}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide Website Name.
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>
                  </Form.Row>
                </>
                <div className="row">
                  <h4 className="card-title col-9 d-flex align-items-center">
                    Social Media Links
                  </h4>
                </div>
                <>
                  <Form.Row>
                    <Form.Group as={Col} md="6" className="row mr-1">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className=" col-sm-4  col-form-label"
                      >
                        Facebook
                      </Form.Label>
                      <div className="col-sm-8">
                        <Form.Control
                          type="text"
                          className="form-control"
                          placeholder="Facebook"
                          name="facebook"
                          defaultValue={supplier_detail.fbURL}
                          disabled={editFlag ? false : true}
                        />
                      </div>
                    </Form.Group>
                    <Form.Group as={Col} md="6" className="row ">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className="col-sm-4  col-form-label"
                      >
                        YouTube
                      </Form.Label>
                      <div className="col-sm-8">
                        <Form.Control
                          type="text"
                          className="form-control"
                          placeholder="YouTube"
                          name="youtube"
                          defaultValue={supplier_detail.ytURL}
                          disabled={editFlag ? false : true}
                        />
                      </div>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md="6" className="row mr-1">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className=" col-sm-4  col-form-label"
                      >
                        Instagram
                      </Form.Label>
                      <div className="col-sm-8">
                        <Form.Control
                          type="text"
                          className="form-control"
                          placeholder="Instagram"
                          name="instagram"
                          defaultValue={supplier_detail.instaURL}
                          disabled={editFlag ? false : true}
                        />
                      </div>
                    </Form.Group>
                    <Form.Group as={Col} md="6" className="row ">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className="col-sm-4  col-form-label"
                      >
                        Sound Cloud
                      </Form.Label>
                      <div className="col-sm-8">
                        <Form.Control
                          type="text"
                          className="form-control"
                          placeholder="Sound Cloud"
                          name="soundCloud"
                          defaultValue={supplier_detail.soundcloudURL}
                          disabled={editFlag ? false : true}
                        />
                      </div>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md="6" className="row ">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className=" col-sm-4  col-form-label"
                      >
                        Twitter
                      </Form.Label>
                      <div className="col-sm-8">
                        <Form.Control
                          type="text"
                          className="form-control"
                          placeholder="Twitter"
                          name="twitter"
                          defaultValue={supplier_detail.twitterURL}
                          disabled={editFlag ? false : true}
                        />
                      </div>
                    </Form.Group>
                  </Form.Row>
                </>
                <div className="row">
                  <h4 className="card-title col-9 d-flex align-items-center">
                    Contact Details
                  </h4>
                </div>
                <>
                  <Form.Row>
                    <Form.Group as={Col} md="6" className="row mr-1">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className=" col-sm-4  col-form-label"
                      >
                        First Name*
                      </Form.Label>
                      <div className="col-sm-8">
                        <Form.Control
                          type="text"
                          className="form-control"
                          required
                          placeholder="First Name"
                          name="firstName"
                          defaultValue={supplier_detail.firstName}
                          disabled={editFlag ? false : true}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide a first Name.
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>
                    <Form.Group as={Col} md="6" className="row">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className="col-sm-4  col-form-label"
                      >
                        Last Name*
                      </Form.Label>
                      <div className="col-sm-8">
                        <Form.Control
                          type="text"
                          className="form-control"
                          required
                          placeholder="Last Name"
                          name="lastName"
                          defaultValue={supplier_detail.lastName}
                          disabled={editFlag ? false : true}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide a last name.
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md="6" className="row mr-1">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className=" col-sm-4  col-form-label"
                      >
                        Email*
                      </Form.Label>
                      <div className="col-sm-8">
                        <Form.Control
                          type="email"
                          className="form-control"
                          placeholder="Email"
                          name="email"
                          required
                          defaultValue={supplier_detail.email}
                          disabled={editFlag ? false : true}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide Email.
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>
                    <Form.Group as={Col} md="6" className="row">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className="col-sm-4  col-form-label"
                      >
                        Mobile Number*
                      </Form.Label>
                      <div className="col-sm-8">
                        <PhoneInput
                          id="phoneId"
                          inputProps={{
                            name: "phone",
                            required: true,
                          }}
                          country={"ae"}
                          minLength={12}
                          className="custom-input-fields"
                          value={mobileNumber1}
                          onChange={(phone) => setmobileNumber1(phone)}
                          disabled={editFlag ? false : true}
                        />
                        <Form.Control.Feedback type="invalid" id="#phoneId">
                          Please provide a mobile number.
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md="6" className="row mr-1">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className=" col-sm-4  col-form-label"
                      >
                        Company LandLine Number
                      </Form.Label>
                      <div className="col-sm-8">
                        <PhoneInput
                          country={"ae"}
                          className="custom-input-fields"
                          value={landlineNumber}
                          onChange={(phone) => setLandlineNumber(phone)}
                          disabled={editFlag ? false : true}
                        />
                      </div>
                    </Form.Group>
                  </Form.Row>
                </>
                <div className="row">
                  <h4 className="card-title col-9 d-flex align-items-center">
                    Bank Details
                  </h4>
                </div>
                <>
                  <Form.Row>
                    <Form.Group as={Col} md="6" className="row mr-1">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className=" col-sm-4  col-form-label"
                      >
                        Account Name
                      </Form.Label>
                      <div className="col-sm-8">
                        <Form.Control
                          type="text"
                          className="form-control"
                          placeholder="Enter your Account Name"
                          name="accountName"
                          defaultValue={supplier_detail?.accountName}
                          disabled={editFlag ? false : true}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide your Account Name.
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>
                    <Form.Group as={Col} md="6" className="row mr-1">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className=" col-sm-4  col-form-label"
                      >
                        Account Number
                      </Form.Label>
                      <div className="col-sm-8">
                        <Form.Control
                          type="text"
                          className="form-control"
                          placeholder="Enter your Account Number"
                          name="accountNumber"
                          defaultValue={supplier_detail?.accountNumber}
                          disabled={editFlag ? false : true}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide your Account Number.
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md="6" className="row mr-1">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className=" col-sm-4  col-form-label"
                      >
                        IBAN Number
                      </Form.Label>
                      <div className="col-sm-8">
                        <Form.Control
                          type="text"
                          className="form-control"
                          placeholder="Enter your IBAN Number"
                          name="ibanNumber"
                          defaultValue={supplier_detail?.ibanNumber}
                          disabled={editFlag ? false : true}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide your IBAN Number.
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>

                    <Form.Group as={Col} md="6" className="row mr-1">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className=" col-sm-4  col-form-label"
                      >
                        SwiftCode/ BIC Code
                      </Form.Label>
                      <div className="col-sm-8">
                        <Form.Control
                          type="text"
                          className="form-control"
                          placeholder="Enter your Swift Code"
                          name="swiftCode"
                          defaultValue={supplier_detail?.swiftCode}
                          disabled={editFlag ? false : true}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide your Swift Code.
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>

                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md="6" className="row mr-1">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className=" col-sm-4  col-form-label"
                      >
                        <OverlayTrigger
                          key="top"
                          placement="top"
                          overlay={<Tooltip id="tooltip">Applicable for United Kingdom</Tooltip>}
                        >
                          <span className="tooltip-trigger">Sort Code</span>
                        </OverlayTrigger>
                      </Form.Label>
                      <div className="col-sm-8">
                        <Form.Control
                          type="text"
                          className="form-control"
                          placeholder="Enter your Sort Code"
                          name="sortCode"
                          defaultValue={supplier_detail?.sortCode}
                          disabled={editFlag ? false : true}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide your Sort Code.
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>
                    <Form.Group as={Col} md="6" className="row mr-1">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className=" col-sm-4  col-form-label"
                      >
                        <OverlayTrigger
                          key="top"
                          placement="top"
                          overlay={<Tooltip id="tooltip">
                            Applicable for USA - ABA/FedWire number; India - IFSC Code; Australia & New Zealand - BSB Code
                          </Tooltip>}
                        >
                          <span className="tooltip-trigger">Route Code</span>
                        </OverlayTrigger>
                      </Form.Label>
                      <div className="col-sm-8">
                        <Form.Control
                          type="text"
                          className="form-control"
                          placeholder="Enter your Route Code"
                          name="routeCode"
                          defaultValue={supplier_detail?.routeCode}
                          disabled={editFlag ? false : true}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide your Route Code.
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md="6" className="row mr-1">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className=" col-sm-4  col-form-label"
                      >
                        Bank Name
                      </Form.Label>
                      <div className="col-sm-8">
                        <Form.Control
                          type="text"
                          className="form-control"
                          placeholder="Enter your Bank Name"
                          name="bankName"
                          defaultValue={supplier_detail?.bankName}
                          disabled={editFlag ? false : true}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide your Bank Name.
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>
                    <Form.Group as={Col} md="6" className="row mr-1">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className=" col-sm-4  col-form-label"
                      >
                        Bank Address
                      </Form.Label>
                      <div className="col-sm-8">
                        <Form.Control
                          type="text"
                          className="form-control"
                          placeholder="Enter your Bank Address"
                          name="bankAddress"
                          defaultValue={supplier_detail?.bankAddress}
                          disabled={editFlag ? false : true}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide your Bank Address.
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md="6" className="row">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className=" col-sm-4  col-form-label"
                      >
                        Recipient Address
                      </Form.Label>
                      <div className="col-sm-8">
                        <Form.Control
                          type="text"
                          className="form-control"
                          placeholder="Enter your Recipient Address"
                          name="recipientAddress"
                          defaultValue={supplier_detail?.recipientAddress}
                          disabled={editFlag ? false : true}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide your Recipient Address.
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>
                  </Form.Row>
                </>
                <div className="row">
                  <h4 className="card-title col-9 d-flex align-items-center">
                    Document Upload
                  </h4>
                </div>
                <>
                  <Form.Row>
                    <Form.Group as={Col} md="6" className="row">
                      {openCopyCrop ? (
                        <CropEasy
                          photoURL={companyCredsImage[0].image_profile}
                          setOpenCrop={setOpenCopyCrop}
                          setPhotoURL={setCompanyCredsImage}
                        />
                      ) : (
                        <>
                          <Form.Label
                            htmlFor="exampleInputUsername2"
                            className=" col-sm-4  col-form-label"
                          >
                            Company Credentials
                          </Form.Label>
                          <div className="col-sm-8">
                            <div className="upload__image-wrapper col-sm-12">
                              {supplier_detail.companyCredentials &&
                                supplier_detail.companyCredentials !== "undefined" &&
                                !removeCompanyCredsImage ? (
                                <aside>
                                  <ul className="list-ticked">
                                    <li>
                                      <a
                                        href={

                                          supplier_detail.companyCredentials
                                        }
                                        target="_blank"
                                      >
                                        {(supplier_detail.companyCredentials).split("?")[0]}
                                      </a>
                                    </li>
                                  </ul>
                                  <Button
                                    type="button"
                                    className="yellow-btn"
                                    onClick={(i) =>
                                      setRemoveCompanyCredsImage(true)
                                    }
                                    disabled={editFlag ? false : true}
                                  >
                                    Remove
                                  </Button>
                                </aside>
                              ) : (
                                <CompanyCredsImageUpload />
                              )}
                            </div>
                          </div>
                        </>
                      )}
                    </Form.Group>
                    <Form.Group as={Col} md="6" className="row">
                      {openPhotoCrop ? (
                        <CropEasy
                          photoURL={companyCatalogueImage[0].image_profile}
                          setOpenCrop={setOpenPhotoCrop}
                          setPhotoURL={setCompanyCatalogueImage}
                        />
                      ) : (
                        <>
                          <Form.Label
                            htmlFor="exampleInputUsername2"
                            className="col-sm-4  col-form-label"
                          >
                            Company Catalogue
                          </Form.Label>
                          <div className="col-sm-8">
                            <div className="upload__image-wrapper col-sm-12">
                              {supplier_detail.companyCatalogue &&
                                supplier_detail.companyCatalogue !== "undefined" &&
                                !removeCompanyCatalogueImage ? (
                                <aside>
                                  <ul className="list-ticked">
                                    <li>
                                      <a
                                        href={

                                          supplier_detail.companyCatalogue
                                        }
                                        target="_blank"
                                      >
                                        {(supplier_detail.companyCatalogue).split("?")[0]}
                                      </a>
                                    </li>
                                  </ul>
                                  <Button
                                    type="button"
                                    className="yellow-btn"
                                    onClick={(i) =>
                                      setRemoveCompanyCatalogueImage(true)
                                    }
                                    disabled={editFlag ? false : true}
                                  >
                                    Remove
                                  </Button>
                                </aside>
                              ) : (
                                <CompanyCatalogueImageUpload />
                              )}
                            </div>
                          </div>
                        </>
                      )}
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md="6" className="row">
                      {openIdCrop ? (
                        <CropEasy
                          photoURL={companyTradeLicense[0].image_profile}
                          setOpenCrop={setOpenIdCrop}
                          setPhotoURL={setCompanyTradeLicense}
                        />
                      ) : (
                        <>
                          <Form.Label
                            htmlFor="exampleInputUsername2"
                            className=" col-sm-4  col-form-label"
                          >
                            Company Trade License
                          </Form.Label>
                          <div className="col-sm-8">
                            <div className="upload__image-wrapper col-sm-12">
                              {supplier_detail.companyTradeLicense &&
                                supplier_detail.companyTradeLicense !== "undefined" &&
                                !removeCompanyTradeLicenseImage ? (
                                <aside>
                                  <ul className="list-ticked">
                                    <li>
                                      <a
                                        href={

                                          supplier_detail.companyTradeLicense
                                        }
                                        target="_blank"
                                      >
                                        {(supplier_detail.companyTradeLicense).split("?")[0]}
                                      </a>
                                    </li>
                                  </ul>
                                  <Button
                                    type="button"
                                    className="yellow-btn"
                                    onClick={(i) =>
                                      setRemoveCompanyTradeLicenseImage(true)
                                    }
                                    disabled={editFlag ? false : true}
                                  >
                                    Remove
                                  </Button>
                                </aside>
                              ) : (
                                <CompanyTradeLicenseUpload />
                              )}
                            </div>
                          </div>
                        </>
                      )}
                    </Form.Group>

                    <Form.Group as={Col} md="6" className="row">
                      {openCrop ? (
                        (console.log("works"),
                          (
                            <CropEasy
                              photoURL={companyVatCertificate[0].image_profile}
                              setOpenCrop={setOpenCrop}
                              setPhotoURL={SetCompanyVATCertificate}
                            />
                          ))
                      ) : (
                        <>
                          <Form.Label
                            htmlFor="exampleInputUsername2"
                            className="col-sm-4  col-form-label"
                          >
                            Company VAT Certificate
                          </Form.Label>
                          <div className="col-sm-8">
                            <div className="upload__image-wrapper col-sm-12">
                              {supplier_detail.companyVatCertificate &&
                                supplier_detail.companyVatCertificate !== "undefined" &&
                                !removeCompanyVATCertificate ? (
                                <aside>
                                  <ul className="list-ticked">
                                    <li>
                                      <a
                                        href={

                                          supplier_detail.companyVatCertificate
                                        }
                                        target="_blank"
                                      >
                                        {(supplier_detail.companyVatCertificate).split("?")[0]}
                                      </a>
                                    </li>
                                  </ul>
                                  <Button
                                    type="button"
                                    className="yellow-btn"
                                    onClick={(i) =>
                                      setRemoveCompanyVATCertificate(true)
                                    }
                                    disabled={editFlag ? false : true}
                                  >
                                    Remove
                                  </Button>
                                </aside>
                              ) : (
                                <CompanyVATCertificateUpload />
                              )}
                            </div>
                          </div>
                        </>
                      )}
                    </Form.Group>
                  </Form.Row>
                </>
                <>
                  <Form.Row>
                    <Form.Group as={Col} md="6" className="row">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className=" col-sm-4  col-form-label"
                      >
                        Other
                      </Form.Label>
                      <div className="col-sm-8">
                        {removeOtherDocuments ? (
                          <OtherDocumentsUpload />
                        ) : (
                          <aside>
                            <ul className="list-ticked">
                              <li>
                                <a
                                  href={

                                    supplier_detail.otherDocuments
                                  }
                                  target="_blank"
                                >
                                  {(supplier_detail.otherDocuments).split("?")[0]}
                                </a>
                              </li>
                            </ul>
                            <Button
                              type="button"
                              className="yellow-btn"
                              onClick={(i) => setRemoveOtherDocuments(true)}
                              disabled={editFlag ? false : true}
                            >
                              Remove
                            </Button>
                          </aside>
                        )}
                      </div>
                    </Form.Group>
                  </Form.Row>
                </>
                <div className="row">
                  <div className="col-sm-12">
                    {!params.id ? <Button type="submit" className="btn yellow-btn mr-2">
                      Create
                    </Button> : null}
                    {params.id && editFlag ?
                      <Button type="submit" className="btn yellow-btn mr-2">
                        {loader ? <Spinner animation="border" variant="light" />
                          : 'Update'}
                      </Button> : null}

                    {/* <Button type="submit" className="btn yellow-btn mr-2">
                      {params.id ? null : "Create"}
                    </Button> */}
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div >
    </>
  );
}

export default AddSupplier;
