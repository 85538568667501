import React from "react";
import { Form, InputGroup, Col, Button, FormControl } from "react-bootstrap";
import { useState, useRef, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Drag from "../../assets/images/drag.png"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Select from "react-select";
import {
  menuById,
  createMenu,
  UpdateMenu,
  subMenuList,
  deleatSubMenu,
  ReoderSubMenu,
} from "../../Redux/action/cms";

function CreateMenu() {
  const [validated, setValidated] = useState(false);

  const [mobileNumber1, setmobileNumber1] = useState(null);
  const [mdShow, setMdShow] = useState(false);

  const [menu, setMenu] = useState([]);
  const [deleteId, setdeleteId] = useState();
  const dispatch = useDispatch();
  const history = useHistory();
  const roleRef = useRef(null);

  const menu_detail = useSelector(
    (state) => state.CmsReducer.menu_detail.data
  );
  console.log('printing menu_details ---->>>', menu_detail);
  const params = useParams();
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    event.preventDefault();
    setValidated(true);
    if (form.checkValidity() === true) {
      let obj = {
        name: event.target.menuName?.value,
        name_arabic: event.target.menuNameArabic?.value,
      };
      if (params.id) {
        dispatch(UpdateMenu(obj, params.id, history));
      } else {
        dispatch(createMenu(obj, history));
      }
    }
  };
  useEffect(() => {
    if (params.id) {
      dispatch(menuById(params.id));
      dispatch(subMenuList(params.id));
    }
  }, []);
  const handleDragEnd = (e) => {
    if (!e.destination) return;
    let tempData = Array.from(menu);
    let [source_data] = tempData.splice(e.source.index, 1);
    tempData.splice(e.destination.index, 0, source_data);
    setMenu(tempData);
    var obj = {
      data: tempData,
    }
    dispatch(ReoderSubMenu(obj, params.id))
    console.log(tempData, "tempData");
  };
  const subMenuListOption = useSelector(
    (state) => state.CmsReducer.sub_menu_list
  );
  useEffect(() => {
    setMenu(subMenuListOption)
  }, [subMenuListOption]);
  const styles = {
    menu: (base) => ({
      ...base,
      marginTop: "4px",
      fontSize: "0.875rem",

      passive: "true",
    }),
    control: (base) => ({
      ...base,
      fontSize: "0.875rem",
    }),
  };

  return (
    <>
      <div className="row">
        <h4 className="card-title col-8 d-flex align-items-center">
          {!params.id ? "Create Menu" : "Update Menu"}
        </h4>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/website-management/menu-management">
                Menu Management
              </Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {!params.id ? "Create Menu" : "Update Menu"}
            </li>
          </ol>
        </nav>
      </div>
      <div className="row">
        <div className="col-md-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <h4 className="card-title col-9 d-flex align-items-center">
                  Menu Information
                </h4>
              </div>

              <Form
                className="forms-sample"
                noValidate
                id="form"
                validated={validated}
                onSubmit={(event) => handleSubmit(event)}
              >
                <>
                  <Form.Row>
                    <Form.Group as={Col} md="6" className="row  mr-1">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className=" col-sm-4  col-form-label"
                      >
                        Menu Name (English)*
                      </Form.Label>
                      <div className="col-sm-8">
                        <Form.Control
                          type="text"
                          className="form-control rounded"
                          required
                          minLength={3}
                          id="exampleInputUsername2"
                          placeholder="Menu Name"
                          name="menuName"
                          defaultValue={menu_detail?.name}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide Menu Name in English.
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>
                    <Form.Group as={Col} md="6" className="row mr-1">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className=" col-sm-4  col-form-label"
                      >
                        Menu Name (Arabic)*
                      </Form.Label>
                      <div className="col-sm-8">
                        <Form.Control
                          type="text"
                          className="form-control rounded"
                          required
                          minLength={3}
                          dir={"rtl"}
                          id="exampleInputUsername2"
                          placeholder="Menu Name"
                          name="menuNameArabic"
                          defaultValue={menu_detail?.name_arabic}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide Menu Name in Arabic.
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>
                  </Form.Row>
                </>

                {/* <Form.Row> */}
                {/* <Form.Group as={Col} md="10"></Form.Group> */}
                {/* <Form.Group as={Col} md="12" className="row ml-2">
                    <Form.Check
                      className=""
                      name="status"
                      defaultChecked={subadmin_detail?.active_status}
                      onClick={() => {}}
                    /> */}
                {/* <Form.Label className="col-form-label ml-1">
                      Active This Account
                    </Form.Label>
                  </Form.Group> */}
                {/* </Form.Row> */}
                <div className="row">
                  <div className="col-sm-4">
                    <Button type="submit" className="btn yellow-btn mr-2">
                      {params.id ? "Update" : "Create"}
                    </Button>
                  </div>
                </div>
              </Form>
              {params.id ? (<>
                <div className="row mt-4">
                  <h4 className="card-title col-10 d-flex align-items-center">
                    Sub Menu Information
                  </h4>
                  <div className="col-2">
                    <Link
                      className="btn btn-block yellow-btn"
                      to={`/website-management/menu-management/${params.id}/create-sub-menu`}
                    >
                      Create
                    </Link>
                  </div>

                </div>
                <div className="row ">
                  <div className="col-12 ">
                    {menu.length > 0 ?
                      <DragDropContext onDragEnd={handleDragEnd}>
                        <table className="table borderd">
                          <thead>
                            <tr>
                              <th style={{ fontWeight: "bold" }}>Change Order</th>
                              <th style={{ fontWeight: "bold" }}>Sub Menu(English)</th>
                              <th style={{ fontWeight: "bold" }}>Sub Menu(Arabic)</th>
                              <th style={{ "text-align": "center", fontWeight: "bold" }}>Order</th>
                              <th style={{ "text-align": "center", fontWeight: "bold" }}>Action</th>
                            </tr>
                          </thead>
                          <Droppable droppableId="droppable-1">
                            {(provider) => (
                              <tbody
                                className="text-capitalize"
                                ref={provider.innerRef}
                                {...provider.droppableProps}
                              >
                                {menu?.map((user, index) => (
                                  <Draggable
                                    key={user.id}
                                    draggableId={user.name}
                                    index={index}
                                  >
                                    {(provider) => (
                                      <tr
                                        {...provider.draggableProps}
                                        ref={provider.innerRef}
                                      >
                                        <td style={{ "width": "180px", }} {...provider.dragHandleProps}><img src={Drag} alt="=" /></td>
                                        <td>{user.name}</td>
                                        <td>{user.name_arabic}</td>
                                        <td style={{ "text-align": "center" }}>{user.order}</td>

                                        <td style={{ "width": "180px" }}>
                                          <div style={{ display: "flex", "justify-content": "center" }}>
                                            <div>
                                              <Link
                                                className="btn edit-icons mr-2"
                                                to={`/website-management/menu-management/${params?.id}/update-sub-menu/${user?.id}`}
                                              >
                                                <i class="ti-pencil"></i>
                                              </Link>
                                            </div>
                                            <div>
                                              <button
                                                className="btn edit-icons"
                                                onClick={(e) => {
                                                  setdeleteId(user?.id);
                                                  setMdShow(true);
                                                }}
                                              >
                                                <i class="ti-trash"></i>
                                              </button>
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    )}
                                  </Draggable>
                                ))}
                                {/* {provider.placeholder} */}
                              </tbody>
                            )}
                          </Droppable>
                        </table>
                      </DragDropContext> : null}
                    <Modal
                      show={mdShow}
                      onHide={() => {
                        setMdShow(false);
                      }}
                      aria-labelledby="example-modal-sizes-title-md"
                    >
                      <Modal.Header closeButton>
                        <Modal.Title
                          style={{ width: "100%", "text-align": "center" }}
                        >
                          Are You Sure, You Want To Delete ?
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Footer
                        className="fleex-wrap"
                        style={{ justifyContent: "center" }}
                      >
                        <Button
                          variant="success m-2 yellow-btn"
                          onClick={(e) => {
                            setMdShow(false);
                            dispatch(deleatSubMenu(deleteId, params.id));
                          }}
                        >
                          Yes
                        </Button>
                        <Button
                          variant="light m-2 yellow-btn"
                          onClick={() => {
                            setMdShow(false);
                          }}
                        >
                          Cancel
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </div>
                </div>
              </>) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateMenu;
