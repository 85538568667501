import React from "react";
import { Form, InputGroup, Col, Button, FormControl } from "react-bootstrap";
import { useState, useRef, useEffect, useCallback } from "react";
import Multiselect from "multiselect-react-dropdown";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { toasterMessage } from "../../utils/toast-util";
import "react-quill/dist/quill.snow.css";
import { roleList } from "../../Redux/action/roleManagement";
import {
  createSubAdmin,
  UpdateSubAdmin,
  SubAdminById,
} from "../../Redux/action/subAdminManagement";

function CreateSubadmin() {
  const [validated, setValidated] = useState(false);

  const [mobileNumber1, setmobileNumber1] = useState(null);

  const dispatch = useDispatch();
  const history = useHistory();
  const roleRef = useRef(null);

  const roleListing = useSelector(
    (state) => state.RoleManagementReducer.role_list
  );

  const subadmin_detail = useSelector(
    (state) => state.SubAdminManagementReducer.subadmin_detail
  );

  const params = useParams();
  const roles = [
    { id: 1, label: "Admin" },
    { id: 2, label: "Sub Admin" },
  ];
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    event.preventDefault();
    if (mobileNumber1 == null || mobileNumber1.length < 11 || mobileNumber1.length === 11  ) {
      toasterMessage("error", "Please enter mobile number");
    } else {
      console.log(mobileNumber1?.length);
    }
    var localRole = [];
    roleRef.current.getSelectedItems().forEach((item) => {
      localRole.push(item.id);
    });
    var hasError = false;
    if (localRole?.length <= 0) {
      hasError = true;
      toasterMessage("error", "Please select role");
    }
    event.preventDefault();
    setValidated(true);
    if (
      form.checkValidity() === true &&
      mobileNumber1 &&
      mobileNumber1?.length > 11 &&
      !hasError
    ) {
      let obj = {
        firstName: event.target.firstName?.value,
        lastName: event.target.lastName?.value,
        email: event.target.email?.value,
        phone: mobileNumber1,
        roleId: localRole,
        // organization:event.target.organization?.value,
        // jobTitle:event.target.jobTitle?.value,
        active_status: event.target.status.checked ? 1 : 0,
      };
      if (params.id) {
        dispatch(UpdateSubAdmin(obj, params.id, history));
      } else {
        dispatch(createSubAdmin(obj, history));
        console.log(obj);
      }
    }
  };
  useEffect(() => {
    dispatch(roleList());
    if (params.id) {
      dispatch(SubAdminById(params.id));
    }
  }, []);

  useEffect(() => {
    setmobileNumber1(subadmin_detail?.phone);
  }, [subadmin_detail]);

  function valuesToUseRole() {
    const arryused = [];
    if (subadmin_detail?.roleId) {
      subadmin_detail.roleId.map((id) => {
        arryused.push(roleListing[roleListing.findIndex((x) => x.id === id)]);
      });
      if (arryused[0] !== undefined && arryused.length > 0) {
        console.log("roles", arryused);
        console.log("roles", arryused.length);
        return arryused;
      }
    } else {
      return null;
    }
  }
  return (
    <>
      <div className="row">
        <h4 className="card-title col-8 d-flex align-items-center">
          {!params.id ? "Create Sub Admin" : "Update Sub Admin"}
        </h4>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/sub-admin-management">Sub Admin Management</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {!params.id ? "Create Sub Admin" : "Update Sub Admin"}
            </li>
          </ol>
        </nav>
      </div>
      <div className="row">
        <div className="col-md-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <h4 className="card-title col-9 d-flex align-items-center">
                  Sub Admin Information
                </h4>
              </div>

              <Form
                className="forms-sample"
                noValidate
                id="form"
                validated={validated}
                onSubmit={(event) => handleSubmit(event)}
              >
                <>
                  {/* <div className="row">
                    <h4 className="card-title col-9 d-flex align-items-center">
                    General Information
                    </h4>
                  </div> */}
                  <Form.Row>
                    <Form.Group as={Col} md="6" className="row  mr-1">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className=" col-sm-4  col-form-label"
                      >
                        First Name*
                      </Form.Label>
                      <div className="col-sm-8">
                        <Form.Control
                          type="text"
                          className="form-control"
                          required
                          minLength={3}
                          id="exampleInputUsername2"
                          placeholder="First Name"
                          name="firstName"
                          defaultValue={subadmin_detail?.firstname}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide First Name.
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>
                    <Form.Group as={Col} md="6" className="row">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className=" col-sm-4  col-form-label"
                      >
                        Last Name*
                      </Form.Label>
                      <div className="col-sm-8">
                        <Form.Control
                          type="text"
                          className="form-control"
                          required
                          minLength={3}
                          id="exampleInputUsername2"
                          placeholder="Last Name"
                          name="lastName"
                          defaultValue={subadmin_detail?.lastname}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide Last Name.
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md="6" className="row mr-1">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className=" col-sm-4  col-form-label"
                      >
                        Email*
                      </Form.Label>
                      <div className="col-sm-8">
                        <Form.Control
                          type="email"
                          className="form-control"
                          placeholder="Email"
                          name="email"
                          required
                          defaultValue={subadmin_detail?.email}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide Email.
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>
                    <Form.Group as={Col} md="6" className="row">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className="col-sm-4  col-form-label"
                      >
                        Mobile Number*
                      </Form.Label>
                      <div className="col-sm-8">
                        <PhoneInput
                          id="phoneId"
                          inputProps={{
                            name: "phone",
                            required: true,
                          }}
                          isValid={(value, country) => {
                            if (value.match(/12345/)) {
                             console.log( "Invalid value: " + value + ", " + country.name)
                            } else if (value.match(/1234/)) {
                              return false;
                            } else {
                              return true;
                            }
                          }}
                          country={"ae"}
                          minLength={12}
                          maxLength={16}
                          className="custom-input-fields"
                          value={mobileNumber1}
                          onChange={(phone) => setmobileNumber1(phone)}
                        />
                        <Form.Control.Feedback type="invalid" id="#phoneId">
                          Please provide a mobile number.
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>
                  </Form.Row>
                  {/* <Form.Row>
                    <Form.Group as={Col} md="6" className="row  mr-1">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className=" col-sm-4  col-form-label"
                      >
                        Organization Name*
                      </Form.Label>
                      <div className="col-sm-8">
                        <Form.Control
                          type="text"
                          className="form-control"
                          required
                          id="exampleInputUsername2"
                          placeholder="Organization Name"
                          name="organization"
                          defaultValue={subadmin_detail?.organization}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide Organization Name.
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>
                    <Form.Group as={Col} md="6" className="row">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className=" col-sm-4  col-form-label"
                      >
                        Job Title*
                      </Form.Label>
                      <div className="col-sm-8">
                        <Form.Control
                          type="text"
                          className="form-control"
                          required
                          id="exampleInputUsername2"
                          placeholder="Job Title"
                          name="jobTitle"
                          defaultValue={subadmin_detail?.jobTitle}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide Job Title.
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>
                  </Form.Row> */}
                  <Form.Row>
                    <Form.Group as={Col} md="6" className="row">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className=" col-sm-4  col-form-label"
                      >
                        Role*
                      </Form.Label>
                      <div className="col-sm-8">
                        <Multiselect
                          options={roleListing}
                          selectedValues={valuesToUseRole()}
                          displayValue="role_desc" // Property name to display in the dropdown options
                          showArrow
                          customCloseIcon={
                            <i className="typcn typcn-delete ml-1"></i>
                          }
                          ref={roleRef}
                          style={{
                            chips: {
                              background: "#F6F4ED",
                              color: "black",
                            },
                            options: {
                              "font-size": "0.875rem",
                            },
                            "font-size": "0.875rem",
                          }}
                        />
                      </div>
                    </Form.Group>
                  </Form.Row>
                </>

                <Form.Row>
                  {/* <Form.Group as={Col} md="10"></Form.Group> */}
                  <Form.Group as={Col} md="12" className="row ml-2">
                    <Form.Check
                      className=""
                      name="status"
                      defaultChecked={subadmin_detail?.active_status}
                      onClick={() => {}}
                    />
                    <Form.Label className="col-form-label ml-1">
                      Active This Account
                    </Form.Label>
                  </Form.Group>
                </Form.Row>
                <div className="row">
                  <div className="col-sm-4">
                    <Button type="submit" className="btn yellow-btn mr-2">
                      {params.id ? "Update" : "Create"}
                    </Button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateSubadmin;
