import axios from "axios";
import { toasterMessage } from "../../utils/toast-util";

export const subAdminList = () => {
  return async (dispatch) => {
    try {
      const response = await axios({
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        url: process.env.REACT_APP_BACKEND_API + `admin/sub-admin`,
      });
      const { data } = response;
      if (data.status) {
        await dispatch({
          type: "SUB_ADMIN_LIST",
          payload: data.result.data,
        });
      } else {
        toasterMessage("error", data.result.message);
      }
    } catch (error) {
      if (error.reponse) {
        console.log("error");
        toasterMessage("error", error.response.data.result.message);
      }
    }
  };
};
export const subAdminFilter = (value) => {
    console.log(value,"value");

  return async (dispatch) => {
    try {
        var data_send = {};
        console.log(value,"value");
        if(value.status !=null){
           if (value.status) {
          data_send["status"] = 1;
      }else {
        data_send["status"] = 0;
      }  
        }
        console.log(data_send,"data_send");
      const response = await axios({
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        url: `${process.env.REACT_APP_BACKEND_API}admin/sub-admin`,
        params: data_send,

        //   ?myparam1=123&myparam2=abc&myparam2=xyz
      });
      const { data } = response;
      if (data.status) {
        await dispatch({
          type: "SUB_ADMIN_LIST",
          payload: data.result.data,
        });
      } else {
        toasterMessage("error", data.result.message);
      }
    } catch (error) {
      if (error.reponse) {
        console.log("error");
        toasterMessage("error", error.response.data.result.message);
      }
    }
  };
};

export const deleatSubAdmin= (id) => {
  return async (dispatch) => {
    try {
      const response = await axios({
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + (localStorage.getItem("token")),
        },
        url: process.env.REACT_APP_BACKEND_API+`admin/sub-admin/${id}`,
        
      });
      const { data } = response;
      if (data.status) {

        toasterMessage("success", data.result.message);
        await dispatch(subAdminList());
      }else {
        toasterMessage("error", data.result.message);
      }
    } catch (error) {
      if (error.response) {
        console.log("error");
       
      }
       toasterMessage("error", error.response.data.result.message);
    }
  };
};
export const createSubAdmin = (value,history) => {
  return async (dispatch) => {
    try {
      const response = await axios({
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + (localStorage.getItem("jwt")),
        },
        url: process.env.REACT_APP_BACKEND_API+`admin/sub-admin`,
        data: value,
        
      });
      const { data } = response;
      if (data.status) {
        toasterMessage("success",data.result.message);
        history.push('/sub-admin-management')
      }else {
        toasterMessage("error", data.result.message);
      }
    } catch (error) {
      if (error.response) {
        console.log("error");
        toasterMessage("error", error.response.data.result.message);
      }
    }
  };
};


export const UpdateSubAdmin = (value,id,history) => {
  return async (dispatch) => {
    try {
      const response = await axios({
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + (localStorage.getItem("jwt")),
        },
        url: process.env.REACT_APP_BACKEND_API+`admin/sub-admin/${id}`,
        data: value,
        
      });
      const { data } = response;
      if (data.status) {
        toasterMessage("success",data.result.message);
        history.push('/sub-admin-management')
      }else {
        toasterMessage("error", data.result.message);
      }
    } catch (error) {
      if (error.reponse) {
        console.log("error");
        toasterMessage("error", "Something went Wrong");
      }
    }
  };
};

export const SubAdminById = (id) => {
  return async (dispatch) => {
    try {
      const response = await axios({
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + localStorage.getItem("jwt"),
        },
        url: process.env.REACT_APP_BACKEND_API + `admin/sub-admin/${id}`,
      });
      const { data } = response;
      if (data.status) {
        await dispatch({
          type: "SUB_ADMIN_DETAIL",
          payload: data.result.data,
        });
      } else {
        toasterMessage("error", data.result.message);
      }
    } catch (error) {
      if (error.reponse) {
        console.log("error");
        toasterMessage("error", "Something went Wrong");
      }
    }
  };
};