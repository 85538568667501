export const LOGIN_DETAILS = "LOGIN_DETAILS";
export const USER_DETAILS = "USER_DETAILS";
export const FORGOT_EMAIL = "FORGOT_EMAIL";
export const USER_INFO = "USER_INFO";
export const ACCOUNT_DETAILS = "ACCOUNT_DETAILS";
export const USER_DETAILS_RESET = "USER_DETAILS_RESET";
export const ARTIST_TYPE_LISTS = "ARTIST_TYPE_LISTS";
export const ARTIST_TYPE_DETAILS = "ARTIST_TYPE_DETAILS";
export const SPECIALITY_LISTS = "SPECIALITY_LISTS";
export const SPECIALITY_DETAILS = "SPECIALITY_DETAILS";
export const GENRE_DETAILS = "GENRE_DETAILS";
export const GENRES_LISTS = "GENRES_LISTS";
export const METATAG_DETAILS = "METATAG_DETAILS";
export const METATAG_LISTS = "METATAG_LISTS";
export const TAG_LISTS = "TAG_LISTS";
export const TAG_DETAILS = "TAG_DETAILS";
export const OPTION_LISTS = "OPTION_LISTS";
export const ARTIST_LIST = "ARTIST_LIST";
export const ARTIST_DETAIL = "ARTIST_DETAIL";
export const PAGE_LIST = "PAGE_LIST";
export const PAGE_DETAIL = "PAGE_DETAIL";
export const CONTENT_LIST = "CONTENT_LIST";
export const CONTENT_DETAIL = "CONTENT_DETAIL";
export const ARTIST_POST = "ARTIST_POST";
export const SUB_ADMIN_LIST = "SUB_ADMIN_LIST";
export const SUB_ADMIN_DETAIL = "SUB_ADMIN_DETAIL";
export const ROLES_LIST = "ROLES_LIST";
export const ROLE_DETAIL = "ROLE_DETAIL";
export const PERMISSION_DETAILS = "PERMISSION_DETAILS";
export const MENU_DETAILS = "MENU_DETAILS";
export const MENU_LIST = "MENU_LIST";
export const SUB_MENU_LISTS = "SUB_MENU_LISTS";
export const SUB_MENU_DETAILS = "SUB_MENU_DETAILS";
export const PAGE_LAYOUT_LISTS = "PAGE_LAYOUT_LISTS";
export const PAGE_LAYOUT_MENUS_LISTS = "PAGE_LAYOUT_MENUS_LISTS";
export const PAGE_LAYOUT_DETAILS = "PAGE_LAYOUT_DETAILS";
export const DASHBOARD_DATA = "DASHBOARD_DATA";
export const NEWSLETTER_LIST = "NEWSLETTER_LIST";
export const ENQUIRY_LIST = "ENQUIRY_LIST";
export const ENQUIRY_DATA = "ENQUIRY_DATA";
export const ARTIST_PROFILE_DETAIL = "ARTIST_PROFILE_DETAIL";
export const ARTIST_POST_DETAILS = "ARTIST_POST_DETAILS";
export const ARTIST_OTHER_DETAILS = "ARTIST_OTHER_DETAILS";
export const ARTIST_NOTIFICATION = "ARTIST_NOTIFICATION";
export const ARTIST_DASHBOARD = "ARTIST_DASHBOARD";
export const SUPPLIER_TYPE_LISTS = "SUPPLIER_TYPE_LISTS";
export const SUPPLIER_PROFILE_DETAIL = "SUPPLIER_PROFILE_DETAIL";
export const SUPPLIER_LIST = "SUPPLIER_LIST";
export const SUPPLIER_DETAIL = "SUPPLIER_DETAIL";
export const SET_ARTIST_TYPE = "SET_ARTIST_TYPE";
export const SET_SPECIALITY_TYPE = "SET_SPECIALITY_TYPE";
export const SET_TAGS_TYPE = "SET_TAGS_TYPE";
export const SET_GENRE_TYPE = "SET_GENRE_TYPE";
export const SET_SUPPLIER_TYPE = "SET_SUPPLIER_TYPE";