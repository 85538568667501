import axios from "axios";
import { toasterMessage } from "../../utils/toast-util";

export const createSupplier = (value, history, loader, setLoader) => {
    return async (dispatch) => {
        setLoader(!loader);
        try {
            const response = await axios({
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "multipart/form-data",
                    "Access-Control-Allow-Origin": "*",
                    Authorization: "Bearer " + localStorage.getItem("jwt"),
                },
                url: process.env.REACT_APP_BACKEND_API + `admin/supplier`,
                data: value,
            });
            const { data } = response;
            if (data.status) {
                setLoader(!loader);
                toasterMessage("success", data.result.message);
                history.push("/supplier-management");
            } else {
                setLoader(!loader);
                toasterMessage("error", data.result.message);
            }
        } catch (error) {
            if (error.response) {
                setLoader(!loader);
                console.log("error");
                toasterMessage("error", error.response.data.result.message);
            }
        }
    };
};

export const UpdateSupplier = (value, id, history, loader, setLoader) => {
    return async (dispatch) => {
        try {
            setLoader(!loader);
            const response = await axios({
                method: "PUT",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "multipart/form-data",
                    "Access-Control-Allow-Origin": "*",
                    Authorization: "Bearer " + localStorage.getItem("jwt"),
                },
                url: process.env.REACT_APP_BACKEND_API + `admin/supplier/${id}`,
                data: value,
            });
            const { data } = response;
            if (data.status) {
                setLoader(!loader);
                toasterMessage("success", data.result.message);
                history.push("/supplier-management");
            } else {
                setLoader(!loader);
                toasterMessage("error", data.result.message);
            }
        } catch (error) {
            if (error.reponse) {
                console.log("error");
                setLoader(!loader);
                toasterMessage("error", error.response.data.result.message);
            }
        }
    };
};
export const supplierListById = (id) => {
    return async (dispatch) => {
        try {
            const response = await axios({
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
                url: process.env.REACT_APP_BACKEND_API + `admin/supplier/${id}`,
            });
            const { data } = response;
            if (data.status) {
                await dispatch({
                    type: "SUPPLIER_DETAIL",
                    payload: data.result.data,
                });
            } else {
                toasterMessage("error", data.result.message);
            }
        } catch (error) {
            if (error.reponse) {
                console.log("error");
                toasterMessage("error", error.response.data.result.message);
            }
        }
    };
};
