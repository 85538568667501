import axios from "axios";
import { toasterMessage } from "../../utils/toast-util";

export const supplierList = () => {
    return async (dispatch) => {
        try {
            const response = await axios({
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
                url: process.env.REACT_APP_BACKEND_API + `admin/supplier `,
            });
            const { data } = response;
            if (data.status) {
                await dispatch({
                    type: "SUPPLIER_LIST",
                    payload: data.result.data,
                });
            } else {
                toasterMessage("error", data.result.message);
            }
        } catch (error) {
            if (error.reponse) {
                console.log("error");
                toasterMessage("error", "Something went Wrong");
            }
        }
    };
};
export const supplierListFilter = (value) => {
    return async (dispatch) => {
        try {
            var data_send = {};
            if (value.supplierType) {
                data_send["supplierType"] = (value.supplierType).toString();
            }
            const response = await axios({
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    Authorization: "Bearer " + localStorage.getItem("jwt"),
                },
                url: `${process.env.REACT_APP_BACKEND_API}admin/supplier`,
                params: data_send,
            });
            const { data } = response;
            if (data.status) {
                await dispatch({
                    type: "SUPPLIER_LIST",
                    payload: data.result.data,
                });
            } else {
                toasterMessage("error", data.result.message);
            }
        } catch (error) {
            if (error.reponse) {
                console.log("error");
                toasterMessage("error", "Something went Wrong");
            }
        }
    };
};

export const deleatSupplier = (id) => {
    return async (dispatch) => {
        try {
            const response = await axios({
                method: "DELETE",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    Authorization: "Bearer " + localStorage.getItem("jwt"),
                },
                url: process.env.REACT_APP_BACKEND_API + `admin/supplier/${id}`,
            });
            const { data } = response;
            if (data.status) {
                toasterMessage("success", data.result.message);
                await dispatch(supplierList());
            } else {
                toasterMessage("error", data.result.message);
            }
        } catch (error) {
            if (error.response) {
                console.log("error");
            }
            toasterMessage("error", error.response.data.result.message);
        }
    };
};

//Action to set Supplier Type
export const actionSetSupplierType = (value) => {
    return async (dispatch) => {
        await dispatch({
            type: "SET_SUPPLIER_TYPE",
            payload: value
        });
    }
}