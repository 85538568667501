import { CONTENT_LIST , OPTION_LISTS,CONTENT_DETAIL } from "../action/actiontypes";
const initialState = {
    tagList: [],
    tagdetails:{},
    specialty_options:[],
    genres_options:[],
    content_list:[],
    content_detail:{},
  };
  const ContentReducer = (state = initialState, action) => {
    switch (action.type) {
        case OPTION_LISTS:
          return {
            ...state,
            specialty_options: action.payload.specialities,
            genres_options: action.payload.genres,            
          };
        case CONTENT_LIST:
          return {
            ...state,
            content_list: action.payload,
            content_detail: {},
        };
        case CONTENT_DETAIL:
          return {
            ...state,
            content_detail: action.payload,
        };
      default: return state
    }
  };
  export default ContentReducer 