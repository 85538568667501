import React from "react";
import { Form, InputGroup, Col, Button, FormControl } from "react-bootstrap";
import { useState, useRef, useEffect, useCallback } from "react";
import Multiselect from "multiselect-react-dropdown";
import { Editor } from "@tinymce/tinymce-react";
import ReactQuill from "react-quill";
import "../../../assets/styles/global.scss";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import ImageUploading from "react-images-uploading";
import { useDropzone } from "react-dropzone";
import { Link, useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { toasterMessage } from "../../../utils/toast-util";
import "react-quill/dist/quill.snow.css";
import CropEasy from "../../../crop/CropEasy";
import {
  createPage,
  UpdatePage,
  pageById,
} from "../../../Redux/action/pageDetail";
import { pageLayoutList } from "../../../Redux/action/cms";

function HeroWarehouse({
  page_detail,
  subpageSelected,
  languageSetected,
  pageSelected,
  pageNameValue
}) {
  const DEFAULT_ORDER = 0;
  const [openCrop, setOpenCrop] = useState(false);
  const [quillText, setQuillText] = useState();
  const [image, setImage] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [description, setDescription] = useState("");
  const [videodescription, setVideodescription] = useState("");
  const [openCopyCrop, setOpenCopyCrop] = useState(false);
  const [validated, setValidated] = useState(false);
  const [publish, setPublish] = useState(false);
  const [is_banner, setis_banner] = useState(false);
  const [publishVideo, setPublishvideo] = useState(false);
  const [postImageArray, setPostImageArray] = useState([]);
  const [postVideoArray, setPostVideoArray] = useState([]);
  const [videoLink, setVideoLink] = useState(null);
  const [deleatImage, setDeleatImage] = useState([]);
  const [deleatVideo, setDeleatVideo] = useState([]);
  const [priority, setPriority] = useState(0);
  const [video, setVideo] = useState(null);
  const [videoFile, setVideoFile] = useState(null);
  const [postImageDetails, setPostImageDetails] = useState({});
  const [typeError, setTypeError] = useState(null);
  const [isButtonPublish, setIsButtonPublish] = useState(page_detail.button_publish && true | false);
  const dispatch = useDispatch();
  const history = useHistory();

  const pageListOptions = useSelector(
    (state) => state.CmsReducer.page_layout_list
  );
  const params = useParams();
  const language = [
    { value: "ar", label: "Arabic" },
    { value: "en", label: "English" },
  ];
  const Layout1 = [
    { value: "hero_image", label: "Hero" },
    { value: "banner", label: "Banner" },
    {
      value: "what_we_can_do",
      label: "What We can do",
    },
    {
      value: "what_makes_us_different",
      label: "What Makes Us Different",
    },
    { value: "our_work", label: "Our Work" },
  ];
  const Layout2 = [
    { value: "hero_image", label: "Hero" },
    { value: "banner", label: "Banner" },
    {
      value: "what_we_can_do",
      label: "What We can do",
    },
    {
      value: "what_makes_us_different",
      label: "What Makes Us Different",
    },
    { value: "our_work", label: "Our Work" },
  ];
  const Layout3 = [
    { value: "banner", label: "Banner" },
    {
      value: "event_tile",
      label: "Event Tile",
    },
    { value: "carousel", label: "Carousel" },
  ];

  const Layout4 = [
    { value: "a_venu", label: "A Venu" },
    { value: "hero_image", label: "Hero" },
    {
      value: "changing_tiles",
      label: "Changing Tiles",
    },
    {
      value: "Banner",
      label: "Banner",
    },
    { value: "gallery", label: "Gallery" },
  ];
  const Layout5 = [
    { value: "carousel", label: "Carousel" },
    { value: "hero_image", label: "Hero" },
    {
      value: "changing_tiles",
      label: "Changing Tiles",
    },
    {
      value: "Banner",
      label: "Banner",
    },
    { value: "spotlight", label: "Spotlight" },
  ];
  const Layout6 = [
    {
      value: "tiles",
      label: "Tiles",
    },
  ];

  const addVideoPost = () => {
    if (videoLink) {
      setPostVideoArray([
        ...postVideoArray,
        {
          media_link: videoLink,
          active_status: publishVideo,
          isNew: true,
          media_desc: videodescription,
        },
      ]);
      setVideoLink();
      setPublishvideo(false);
      setVideodescription("");
    } else {
      toasterMessage("error", "Please add a video link before adding Post");
    }
  };
  const unPublishVideo = (index, value) => {
    const newState = postVideoArray.map((obj, i) => {
      // 👇️ if id equals 2, update country property
      if (i === index) {
        return { ...obj, active_status: !value };
      }

      // 👇️ otherwise return object as is
      return obj;
    });
    setPostVideoArray(newState);
  };
  const editVideoPosts = (index, valueRecived, change) => {
    // let newState = postImageArray;
    const newState = postVideoArray.map((obj, i) => {
      // 👇️ if id equals 2, update country property
      if (i === index) {
        if (change === 1) {
          return { ...obj, media_title: valueRecived };
        } else {
          return { ...obj, media_desc: valueRecived };
        }
      }

      // 👇️ otherwise return object as is
      return obj;
    });
    setPostVideoArray(newState);
  };
  const isDynamicButtonSectionShow = () => {
    return (
      (subpageSelected.value === "hero" &&
        pageSelected.slug ===
        "/live-entertainment-programming--creative-consultant") ||
      pageSelected.slug === "/event-design--production" ||
      pageSelected.slug === "/warehouse"
    );
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    let count = 0;

    postImageArray.length &&
      postImageArray.map((post) => {
        if (post.is_banner) count++;
      });

    if (pageSelected.name === "About Us") {
      count = 1;
    }

    if (count > 1) {
      // You can select only one banner
      toasterMessage("error", "You can select only one banner");
    } else if (count === 0) {
      // You need to select one banner
      toasterMessage("error", "You need to select one banner");
    } else if (count === 1) {
      const form = event.currentTarget;
      if (languageSetected) {
        // setTypeError(false);
      } else {
        toasterMessage("error", "Please select language");
      }
      if (pageSelected) {
      } else {
        toasterMessage("error", "Please select page");
      }
      if (subpageSelected) {
      } else {
        toasterMessage("error", "Please select Sub Section");
      }
      if (postImageArray.length <= 0) {
        toasterMessage("error", "Please Add Image");
      }
      // if (form.checkValidity() === false) {
      //   event.preventDefault();
      //   event.stopPropagation();
      // }
      // if (){

      // }
      event.preventDefault();
      setValidated(true);
      if (
        form.checkValidity() === true &&
        pageSelected &&
        languageSetected &&
        subpageSelected &&
        postImageArray.length > 0
      ) {
        let formData = new FormData();
        formData.append("lang", languageSetected?.value);
        formData.append("pageNameId", pageSelected.id);
        formData.append("order", event.target.order?.value);
        formData.append("subPage", subpageSelected?.value);
        formData.append("title", event.target.title?.value);
        formData.append("subTitle", event.target.subtitle?.value);
        formData.append("description", quillText ? quillText : "");
        formData.append("link", event.target.link?.value);
        formData.append("event_info", event.target.eventInfo?.value);
        formData.append("active_status", event.target.status.checked ? 1 : 0);
        if (pageSelected.name === "About Us") {
          formData.append("priority", event.target.priority?.value);
        }
        if (isDynamicButtonSectionShow()) {
          formData.append("button_title", event.target.buttonTitle?.value);
          formData.append("button_link", event.target.buttonLink?.value);
          formData.append("button_publish", isButtonPublish ? 1 : 0);
        }

        if (deleatImage.length > 0) {
          for (var x = 0; x < deleatImage.length; x++) {
            formData.append("delete_images_array", deleatImage[x]);
          }
        }
        if (deleatVideo.length > 0) {
          for (var x = 0; x < deleatVideo.length; x++) {
            formData.append("delete_videos_array", deleatVideo[x]);
          }
        }
        if (postImageArray.length > 0) {
          for (var x = 0; x < postImageArray.length; x++) {
            if (postImageArray[x]?.isNew) {
              formData.append("page_images", postImageArray[x].file);
              formData.append("new_images_desc", postImageArray[x].media_desc);
              formData.append(
                "new_images_status",
                postImageArray[x].active_status ? 1 : 0
              );
              formData.append(
                "is_new_banner",
                postImageArray[x].is_banner ? 1 : 0
              );
              formData.append("new_banner_order", DEFAULT_ORDER);
            } else {
              formData.append("prev_images_id", postImageArray[x].id);
              formData.append("prev_images_desc", postImageArray[x].media_desc);
              formData.append(
                "prev_images_status",
                postImageArray[x].active_status ? 1 : 0
              );
              formData.append(
                "is_prev_banner",
                postImageArray[x].is_banner ? 1 : 0
              );
              formData.append("prev_banner_order", DEFAULT_ORDER);
              // formData.append("page_images", null);
            }
          }
        } else {
          formData.append("page_images", null);
        }
        if (videoFile) {
          console.log('postVideoArray---->>>>', postVideoArray[0]);
          formData.append("page_videos", videoFile);
          formData.append("new_videos_desc", postVideoArray?.[0]?.media_desc);
          formData.append(
            "new_videos_status",
            postVideoArray[0]?.active_status ? 1 : 0
          );
          formData.append("new_video_order", DEFAULT_ORDER);
        }
        // if (postVideoArray.length > 0) {
        //   for (var x = 0; x < postVideoArray.length; x++) {
        //     if (postVideoArray[x]?.isNew) {
        //       formData.append("new_videos_link", postVideoArray[x].media_link);
        //       formData.append("new_videos_desc", postVideoArray[x].media_desc);
        //       formData.append(
        //         "new_videos_status",
        //         postVideoArray[x].active_status ? 1 : 0
        //       );
        //       formData.append("new_video_order", DEFAULT_ORDER);
        //     } else {
        //       formData.append("prev_videos_id", postVideoArray[x].id);
        //       formData.append(
        //         "new_videos_status",
        //         postVideoArray[x].active_status ? 1 : 0
        //       );
        //       formData.append(
        //         "prev_videos_status",
        //         postVideoArray[x].active_status ? 1 : 0
        //       );
        //       formData.append("prev_video_order", DEFAULT_ORDER);
        //       // formData.append("new_videos_link ", null);
        //     }
        //   }
        // } else {
        //   formData.append("new_videos_link ", null);
        // }
        if (params.id) {
          dispatch(UpdatePage(formData, params.id, history));
        } else {
          dispatch(createPage(formData, history));
        }
      }
    }
  };
  useEffect(() => {
    if (params.id) {
      dispatch(pageById(params.id));
    }
    dispatch(pageLayoutList());
  }, []);

  useEffect(() => {
    if (page_detail.images?.length > 0) {
      setPostImageArray(page_detail.images);
    }
    if (page_detail.videos?.length > 0) {
      setPostVideoArray(page_detail.videos);
    }

    setQuillText(page_detail.description);
  }, [page_detail]);

  function handleQuillChange(e) {
    setQuillText(e);
  }
  Editor.formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
  ];
  Editor.modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike"], // toggled buttons
      ["blockquote"],
      ["link"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "sub" }, { script: "super" }], // superscript/subscript
      [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
      [{ direction: "rtl" }], // text direction

      [{ size: ["small", false, "large", "huge"] }], // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ font: [] }],
      [{ align: [] }],

      ["clean"],
    ],
  };
  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImage(URL.createObjectURL(event.target.files[0]));
      setImageFile(event.target.files[0]);
    }
  };
  function onVideoChange(event) {
    const selectedVideo = event.target.files && event.target.files[0];
    setVideoLink(event.target.value)

    if (selectedVideo) {
      setVideo(URL.createObjectURL(selectedVideo))
      setVideoFile(selectedVideo);
      console.log('Selected video file:', selectedVideo.name);
    } else {
      console.log('No video selected.');
    }
  }
  const addPost = () => {
    if (image) {
      const newPost = {
        image: image,
        active_status: publish,
        file: imageFile,
        isNew: true,
        is_banner: is_banner,
        media_desc: description,
        priority: priority
      };

      setPostImageArray([...postImageArray, newPost]);

      setImageFile();
      setImage();
      setPublish(false);
      setDescription("");
      setis_banner(false);
      setPriority(0);
    } else {
      toasterMessage("error", "Please add an image before adding Post.");
    }

    setPostImageArray(prevArray => {
      const sortedArray = prevArray.slice().sort((a, b) => {
        if (!a.priority || a.priority === 0) {
          return 1;
        } else if (!b.priority || b.priority === 0) {
          return -1;
        } else {
          return a.priority - b.priority;
        }
      });

      return sortedArray;
    });
  };


  // const addPost = () => {
  //   if (image) {
  //     setPostImageArray([
  //       ...postImageArray,
  //       {
  //         image: image,
  //         active_status: publish,
  //         file: imageFile,
  //         isNew: true,
  //         is_banner: is_banner,
  //         media_desc: description,
  //         priority:parseInt(priority) 
  //       },
  //     ]);
  //     setImageFile();
  //     setImage();
  //     setPublish(false);
  //     setDescription("");
  //     setis_banner(false);
  //     setPriority(0);
  //   } else {
  //     toasterMessage("error", "Please add an image before adding Post.");
  //   }
  //   postImageArray.sort((a, b) => a.priority - b.priority);
  // };
  const editPosts = (index, valueRecived, change) => {
    // let newState = postImageArray;
    const newState = postImageArray.map((obj, i) => {
      // 👇️ if id equals 2, update country property
      if (i === index) {
        if (change === 1) {
          return { ...obj, media_title: valueRecived };
        } else {
          return { ...obj, media_desc: valueRecived };
        }
      }

      // 👇️ otherwise return object as is
      return obj;
    });
    setPostImageArray(newState);
  };

  const unPublish = (index, value) => {
    // let newState = postImageArray;
    const newState = postImageArray.map((obj, i) => {
      // 👇️ if id equals 2, update country property
      if (i === index) {
        return { ...obj, active_status: !value };
      }

      // 👇️ otherwise return object as is
      return obj;
    });
    setPostImageArray(newState);
  };
  const handleBanner = (index, value) => {
    // let newState = postImageArray;
    const newState = postImageArray.map((obj, i) => {
      // 👇️ if id equals 2, update country property
      if (i === index) {
        return { ...obj, is_banner: !value };
      }

      // 👇️ otherwise return object as is
      return obj;
    });
    setPostImageArray(newState);
  };

  const deleatPost = (index, id) => {
    if (postImageArray[index]?.isNew === false) {
      setDeleatImage([...deleatImage, postImageArray[index].id]);
    }
    let newPostArray = [...postImageArray];
    setPostImageArray(newPostArray.filter((item, i) => item.id !== id));
  };
  const deleatVideoPost = (index) => {
    if (postVideoArray[index]?.isNew === false) {
      setDeleatVideo([...deleatVideo, postVideoArray[index].id]);
    }
    setPostVideoArray(postVideoArray.filter((item, i) => i !== index));
  };
  const handleSection = (pageSelected) => {
    switch (pageSelected?.layout) {
      case "1":
        return Layout1;
      case "2":
        return Layout2;
      case "3":
        return Layout3;
      case "4":
        return Layout4;
      case "5":
        return Layout5;
      case "6":
        return Layout6;
      default:
        return [];
    }
  };
  const styles = {
    menu: (base) => ({
      ...base,
      marginTop: "4px",
      fontSize: "0.875rem",

      passive: "true",
    }),
    control: (base) => ({
      ...base,
      fontSize: "0.875rem",
    }),
  };
  return (
    <>
      <Form
        className="forms-sample"
        noValidate
        validated={validated}
        onSubmit={(event) => handleSubmit(event)}
      >
        <>
          <div className="row">
            <h4 className="card-title col-9 d-flex align-items-center">
              General Information
            </h4>
          </div>
          <Form.Row>
            <Form.Group as={Col} md="6" className="row  mr-1">
              <Form.Label
                htmlFor="exampleInputUsername2"
                className=" col-sm-4  col-form-label"
              >
                Title*
              </Form.Label>
              <div className="col-sm-8">
                <Form.Control
                  type="text"
                  dir={languageSetected?.value === "ar" ? "rtl" : "ltr"}
                  className="form-control"
                  required
                  id="exampleInputUsername2"
                  placeholder="Title"
                  name="title"
                  defaultValue={page_detail.title}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide Title.
                </Form.Control.Feedback>
              </div>
            </Form.Group>
            <Form.Group as={Col} md="6" className="row">
              <Form.Label
                htmlFor="exampleInputUsername2"
                className="col-sm-4  col-form-label"
              >
                Sub Title
              </Form.Label>
              <div className="col-sm-8 ">
                <Form.Control
                  type="text"
                  className="form-control"
                  id="exampleInputUsername2"
                  placeholder="Sub Title"
                  name="subtitle"
                  dir={languageSetected?.value === "ar" ? "rtl" : "ltr"}
                  defaultValue={page_detail.subTitle}
                />
              </div>
            </Form.Group>
            {pageSelected.name === "About Us" && (
              <Form.Group as={Col} md="6" className="row  mr-1">

                <Form.Label
                  htmlFor="priority"
                  className=" col-sm-4  col-form-label"
                >
                  Priority Order*
                </Form.Label>
                <div className="col-sm-8">
                  <Form.Control
                    type="number"
                    dir={languageSetected?.value === "ar" ? "rtl" : "ltr"}
                    className="form-control"
                    required
                    id="priority"
                    placeholder="Priority Order"
                    name="priority"
                    defaultValue={page_detail.priority}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide priority.
                  </Form.Control.Feedback>
                </div>
              </Form.Group>)}
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md="12" className="row">
              <Form.Label
                htmlFor="exampleInputUsername2"
                className=" col-sm-2  col-form-label"
              >
                Description
              </Form.Label>
              <div className="col-sm-8">
                <ReactQuill
                  value={quillText || ""}
                  modules={Editor.modules}
                  formats={Editor.formats}
                  onChange={(e) => handleQuillChange(e)}
                />
              </div>
            </Form.Group>
          </Form.Row>
        </>

        {/* Dynamic Button Section End */}
        {isDynamicButtonSectionShow() && (
          <>
            <div className="row">
              <h4 className="card-title col-9 d-flex align-items-center">
                Banner Button
              </h4>
            </div>
            <Form.Row>
              <Form.Group as={Col} md="6" className="row  mr-1">
                <Form.Label
                  htmlFor="buttonTitle"
                  className=" col-sm-4  col-form-label"
                >
                  Button Title
                </Form.Label>
                <div className="col-sm-8">
                  <Form.Control
                    type="text"
                    className="form-control"
                    id="buttonTitle"
                    placeholder="Button Title"
                    name="buttonTitle"
                    defaultValue={page_detail.button_title}
                  />
                </div>
              </Form.Group>
              <Form.Group as={Col} md="6" className="row">
                <Form.Label
                  htmlFor="buttonLink"
                  className="col-sm-4  col-form-label"
                >
                  Button Link
                </Form.Label>
                <div className="col-sm-8 ">
                  <Form.Control
                    type="text"
                    className="form-control"
                    id="buttonLink"
                    placeholder="Button Link"
                    name="buttonLink"
                    defaultValue={page_detail.button_link}
                  />
                </div>
              </Form.Group>
            </Form.Row>
            <div className="row mb-5">
              <div className="col-sm-4">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-check form-check-success">
                      <label className="form-check-label">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          checked={isButtonPublish}
                          onChange={() =>
                            setIsButtonPublish((prevState) => !prevState)
                          }
                        />
                        Is Button Publish
                        <i className="input-helper"></i>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {/* Dynamic Button Section End */}

        <div className="row">
          <h4 className="card-title col-9 d-flex align-items-center">
            Add Images
          </h4>
        </div>
        <>
          <div className="row">
            <div className="col-sm-8">
              <div className="row">
                <label className=" col-sm-3  col-form-label">Image :</label>
                {image ? (
                  <>
                    <div className="image-item col-sm-9">
                      <img
                        src={image}
                        alt=""
                        width="100"
                        className="img-lg mb-3"
                      />
                      <div className="upload__image-wrapper ">
                        {/* <div className="row"> */}
                        <Button
                          onClick={(e) => {
                            e.preventDefault();
                            setImage();
                          }}
                          className="btn yellow-btn"
                        >
                          Remove
                        </Button>
                      </div>
                    </div>
                  </>
                ) : (
                  <input
                    type="file"
                    accept="image/png, image/jpeg"
                    onChange={onImageChange}
                    className="filetype col-sm-9"
                  />
                )}
              </div>
            </div>
            <div className="col-sm-4">
              <div className="row">
                <div className="col-sm-12">
                  <div className="form-check form-check-success">
                    <label className="form-check-label">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        checked={publish}
                        onChange={() => setPublish(!publish)}
                      />{" "}
                      Publish
                      <i className="input-helper"></i>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-sm-4">
              <div className="row">
                <div className="col-sm-12">
                  <div className="form-check form-check-success">
                    {pageSelected.name !== "About Us" && (
                      <label className="form-check-label">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          checked={is_banner}
                          onChange={() => setis_banner(!is_banner)}
                        />{" "}
                        Is Banner
                        <i className="input-helper"></i>
                      </label>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <label className=" col-sm-2  col-form-label">Description</label>
            <div className="image-item col-sm-8">
              <textarea
                id="w3review"
                dir={languageSetected?.value === "ar" ? "rtl" : "ltr"}
                name="w3review"
                rows="6"
                value={description}
                // cols={70}
                style={{ width: "100%" }}
                onChange={(e) => setDescription(e.target.value)}
              />
              {/* {description}
                          </textarea> */}
            </div>
          </div>
          <Button onClick={() => addPost()} className="mt-3 mb-3 yellow-btn">
            {" "}
            Add Post{" "}
          </Button>
        </>
        <>
          {postImageArray?.length > 0 ? (
            <h4 className="card-title col-9 d-flex align-items-center mt-3">
              Already Added Posts
            </h4>
          ) : null}
          {postImageArray.map((i, index) => (
            <>
              <div
                key={i.id}
                className="card"
                style={{
                  padding: "10px",
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
              >
                <div className="row">
                  <div className="col-sm-5">
                    <div className="row">
                      <label className=" col-sm-5  col-form-label">
                        Image :
                      </label>
                      <>
                        <div className="image-item col-sm-7">
                          {i?.media_link ?
                            <img
                              src={
                                i.media_link?.includes('https')
                                  ? i.media_link : process.env.REACT_APP_IMAGE_LINK + i.media_link
                              }
                              alt=""
                              width="100"
                              className="img-lg mb-3"
                            /> :
                            <img
                              src={i?.image}
                              alt=""
                              width="100"
                              className="img-lg mb-3"
                            />}
                        </div>
                      </>
                    </div>
                  </div>
                  <div className="col-sm-7">
                    <div className="row">
                      <div className="col-sm-4">
                        <div className="form-check form-check-success">
                          <label className="form-check-label">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              checked={i.active_status}
                              onChange={() => unPublish(index, i.active_status)}
                            />{" "}
                            Publish
                            <i className="input-helper"></i>
                          </label>
                        </div>
                      </div>
                      <div className="col-sm-4 mt-2">
                        <h5>Image No : {index + 1}</h5>
                      </div>
                      <div className="col-sm-4">
                        <Button
                          className="btn yellow-btn"
                          onClick={() => {
                            deleatPost(index, i.id);
                          }}
                        >
                          Remove
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-5">
                  <div className="col-sm-4">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="form-check form-check-success">
                          {pageSelected.name !== "About Us" && (
                            <label className="form-check-label">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                checked={i.is_banner}
                                onChange={() =>
                                  handleBanner(index, i.is_banner)
                                }
                              />{" "}
                              Is Banner
                              <i className="input-helper"></i>
                            </label>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-5">
                  <label className=" col-sm-2  col-form-label">
                    Description
                  </label>
                  <div className="image-item col-sm-8">
                    <textarea
                      dir={languageSetected?.value === "ar" ? "rtl" : "ltr"}
                      id={`desc${i.id}`}
                      name="w3review"
                      rows="6"
                      defaultValue={i.media_desc}
                      style={{ width: "100%" }}
                      onChange={(e) => {
                        editPosts(index, e.target.value, 2);
                      }}
                    ></textarea>
                  </div>
                </div>
              </div>
            </>
          ))}
        </>
        {pageNameValue && pageNameValue?.name == 'Home' ?
          <>
            <div className="row">
              <h4 className="card-title col-9 d-flex align-items-center">
                Add Video Posts
              </h4>
            </div>
            <>
              <div className="row">
                <div className="col-sm-8">
                  <Form.Row>
                    <Form.Label
                      htmlFor="exampleInputUsername2"
                      className=" col-sm-3  col-form-label"
                    >
                      Link
                    </Form.Label>
                    <div className="col-sm-8">
                      <input
                        type="file"
                        accept="video/mp4"
                        onChange={(e) => onVideoChange(e)}
                        className="filetype col-sm-9"
                      />
                    </div>
                  </Form.Row>
                </div>
                <div className="col-sm-4">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="form-check form-check-success">
                        <label className="form-check-label">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            checked={publishVideo}
                            onChange={() => setPublishvideo(!publishVideo)}
                          />{" "}
                          Publish
                          <i className="input-helper"></i>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-5">
                <label className=" col-sm-2  col-form-label">Description</label>
                <div className="image-item col-sm-8">
                  <textarea
                    id="w3review"
                    dir={languageSetected?.value === "ar" ? "rtl" : "ltr"}
                    name="w3review"
                    rows="6"
                    value={videodescription}
                    style={{ width: "100%" }}
                    onChange={(e) => setVideodescription(e.target.value)}
                  />
                  {/* {description}
                        </textarea> */}
                </div>
              </div>

              <Button
                onClick={() => addVideoPost()}
                className="mt-3 mb-3 yellow-btn"
              >
                {" "}
                Add Video{" "}
              </Button>
            </>
            <>
              {postVideoArray.length > 0 ? (
                <h4 className="card-title col-9 d-flex align-items-center mt-3">
                  Already Added Video Posts
                </h4>
              ) : null}
              {postVideoArray.map((i, index) => (
                <>
                  <div
                    key={i.id}
                    className="card"
                    style={{
                      padding: "10px",
                      marginTop: "20px",
                      marginBottom: "20px",
                    }}
                  >
                    <div className="row">
                      <div className="col-sm-5">
                        <Form.Row>
                          <Form.Group as={Col} md="12" className="row">
                            <Form.Label
                              htmlFor="exampleInputUsername2"
                              className=" col-sm-5  col-form-label"
                            >
                              Link
                            </Form.Label>
                            <div className="col-sm-7 mt-2">
                              <a href={
                                i.media_link?.includes('https') ? i.media_link : process.env.REACT_APP_IMAGE_LINK + i.media_link
                              } target="_blank">
                                Click here{" "}
                              </a>
                            </div>
                          </Form.Group>
                        </Form.Row>
                      </div>
                      <div className="col-sm-6">
                        <div className="row">
                          <div className="col-sm-4">
                            <div className="form-check form-check-success">
                              <label className="form-check-label">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  checked={i.active_status}
                                  onChange={() =>
                                    unPublishVideo(index, i.active_status)
                                  }
                                />{" "}
                                Publish
                                <i className="input-helper"></i>
                              </label>
                            </div>
                          </div>
                          <div className="col-sm-4 mt-2 ">
                            <h5>Video No : {index + 1}</h5>
                          </div>
                          <div className="col-sm-4">
                            <Button
                              className="btn yellow-btn"
                              onClick={() => {
                                deleatVideoPost(index);
                              }}
                            >
                              Remove
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-5">
                      <label className=" col-sm-2  col-form-label">
                        Description
                      </label>
                      <div className="image-item col-sm-8">
                        <textarea
                          dir={languageSetected?.value === "ar" ? "rtl" : "ltr"}
                          id="w3review"
                          name="w3review"
                          rows="6"
                          style={{ width: "100%" }}
                          defaultValue={i.media_desc}
                          onChange={(e) => editVideoPosts(index, e.target.value, 2)}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </>
          </> : null
        }
        <Form.Row>
          {/* <Form.Group as={Col} md="10"></Form.Group> */}
          <Form.Group as={Col} md="12" className="row ml-2">
            <Form.Check
              className=""
              name="status"
              defaultChecked={page_detail.active_status}
              onClick={() => { }}
            />
            <Form.Label className="col-form-label ml-1">
              Publish This Section
            </Form.Label>
          </Form.Group>
        </Form.Row>
        <div className="row">
          <div className="col-sm-4">
            <Button type="submit" className="btn yellow-btn mr-2">
              {params.id ? "Update" : "Create"}
            </Button>
          </div>
        </div>
      </Form>
    </>
  );
}

export default HeroWarehouse;
