import React, { Component } from "react";
import { Form, InputGroup, Col, Button } from "react-bootstrap";
import ImageUploading from "react-images-uploading";
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import {
    tagDetail,
    tagIdUpdate,
    tagCreate,
} from "../../Redux/action/categoriesManagement";

export class AddTag extends Component {
  constructor(props) {
    super();
    this.state = {
      validated: false,
      passwordValidated: false,
      images: [],
      maxNumber: 1,
      tagId: "",
      disable: true,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount() {
    if (this.props.match.params.id) {
      this.props.tagDetail(this.props.match.params.id);
      this.setState({
        tagId: this.props.match.params.id,
      });
    }
  }

  handleSubmit(event) {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    event.preventDefault();
    this.setState({ validated: true });
    var data = {
      name: event.target.TagName.value,
    };
    if (form.checkValidity() === true) {
      if (this.state.tagId) {
        this.props.tagIdUpdate(this.state.tagId, data,  this.props.history);
      } else {
        this.props.tagCreate(data,  this.props.history);
      }
    }
    // history.push("/categories-management")
  }

  render() {
    return (
      <div className="row">
        <div className="col-md-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <h4 className="card-title col-9 d-flex align-items-center">Tag</h4>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/categories-management">Category Management</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                    Tag
                    </li>
                  </ol>
                </nav>
              </div>

              <Form
                className="forms-sample"
                noValidate
                validated={this.state.validated}
                onSubmit={this.handleSubmit}
              >
                <Form.Group className="row">
                  <label
                    htmlFor="exampleInputUsername2"
                    className="col-sm-2 col-form-label"
                  >
                    Tag
                  </label>
                  <div className="col-sm-4">
                    <Form.Control
                      type="text"
                      className="form-control"
                      required
                      maxLength="25"
                      id="exampleInputUsername2"
                      placeholder="Tag Name"
                      name="TagName"
                      pattern="^(?![0-9]+$)[a-zA-Z0-9 ]{2,}$"
                      defaultValue={this.props.tagDetails.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide a valid Tag name.
                    </Form.Control.Feedback>
                  </div>
                </Form.Group>
                <div className="row">
                  <div className="col-sm-4">
                  {this.state.tagId ? <button type="submit" className="btn yellow-btn mr-2">
                    Update
                  </button>
                  :<button type="submit" className="btn yellow-btn mr-2">
                    Create
                  </button>} 
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    tagDetails: state.CategoriesManagementReducer.tagDetails,
  };
}
export default connect(mapStateToProps, {
    tagDetail,
    tagIdUpdate,
    tagCreate,
})(AddTag);
