import {
  ARTIST_TYPE_LISTS,
  ARTIST_TYPE_DETAILS,
  SPECIALITY_LISTS,
  SPECIALITY_DETAILS,
  GENRE_DETAILS,
  GENRES_LISTS,
  TAG_LISTS,
  TAG_DETAILS,
  SUPPLIER_TYPE_LISTS
} from "../action/actiontypes";
const initialState = {
  artistTypeList: [],
  artistTypeDetails: {},
  specialityList: [],
  tagList: [],
  specialityDetails: {},
  tagDetails: {},
  genreList: [],
  genreDetails: {},
  supplierTypeList: [],
};
const CategoriesManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case ARTIST_TYPE_LISTS:
      return {
        ...state,
        artistTypeList: action.payload,
        artistTypeDetails: {},
      };
    case ARTIST_TYPE_DETAILS:
      return {
        ...state,
        artistTypeDetails: action.payload,
      };
    case SPECIALITY_LISTS:
      return {
        ...state,
        specialityList: action.payload,
        specialityDetails: {},
      };
    case SPECIALITY_DETAILS:
      return {
        ...state,
        specialityDetails: action.payload,
      };
    case GENRES_LISTS:
      return {
        ...state,
        genreList: action.payload,
        genreDetails: {},
      };
    case GENRE_DETAILS:
      return {
        ...state,
        genreDetails: action.payload,
      };
    case TAG_LISTS:
      return {
        ...state,
        tagList: action.payload,
        tagDetails: {},
      };
    case TAG_DETAILS:
      return {
        ...state,
        tagDetails: action.payload,
      };
    case SUPPLIER_TYPE_LISTS:
      return {
        ...state,
        supplierTypeList: action.payload,
      };
    default:
      return state;
  }
};
export default CategoriesManagementReducer;
