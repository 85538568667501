import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "../../assets/styles/global.scss";
import { useState, useRef, useEffect, useCallback } from "react";
import { useMemo } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Drag from "../../assets/images/drag.png"
// import MaterialReactTable from 'material-react-table';
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import {
  getArtistType,
  getSpecialities,
} from "../../Redux/action/categoriesManagement";
import {
  menuList,
  ReoderMenu,
  deleatMenu,
} from "../../Redux/action/cms";
import { Link } from "react-router-dom";
// import "bootstrap/dist/css/bootstrap.min.css";
import { Button } from "react-bootstrap";
const { SearchBar } = Search;
const styles = {
  menu: (base) => ({
    ...base,
    marginTop: "4px",
    "font-size": "0.875rem",

    passive: "true",
  }),
  control: (base) => ({
    ...base,
    "font-size": "0.875rem",
  }),
};

function MenuList() {
  const [mdShow, setMdShow] = useState(false);

  const [menu, setMenu] = useState();
  const [deleteId, setdeleteId] = useState();
  const dispatch = useDispatch();
  const menuListOption = useSelector(
    (state) => state.CmsReducer.menu_list
  );
  useEffect(() => {
    dispatch(menuList());
  }, []);
  useEffect(() => {
    setMenu(menuListOption)
  }, [menuListOption]);
  const handleDragEnd = (e) => {
    if (!e.destination) return;
    let tempData = Array.from(menu);
    let [source_data] = tempData.splice(e.source.index, 1);
    tempData.splice(e.destination.index, 0, source_data);
    setMenu(tempData);
    var obj = {
      data: tempData,
    }
    dispatch(ReoderMenu(obj))
    console.log(tempData, "tempData");
  };
  // const columns = useMemo(
  //   () => [
  //     {
  //       accessorKey: "menuName",
  //       header: "Main menu",
  //       sort: true,
  //       style: { padding: "0px 25px  0px 0.9375rem" },
  //     },
  //     {
  //       accessorKey: "subMenu",
  //       header: "Sub Menu",
  //       sort: true,
  //       style: { padding: "0px 25px  0px 0.9375rem" },
  //     },

  //     {
  //       accessorKey: "post_order",
  //       header: "Order",
  //       sort: true,
  //       style: { padding: "0px 25px  0px 0.9375rem" },
  //     },
  //     {
  //       accessorKey: "action",
  //       header: "Action",
  //       sort: false,
  //       style: { padding: "5px 25px 5px 0.9375rem", width: "16em" },
  //       formatter: (cellContent, row) => {
  //         return (
  //           <div style={{ display: "flex" }}>
  //             <div>
  //               <Link
  //                 className="btn edit-icons mr-2"
  //                 to={`/update-page/${row.id}`}
  //               >
  //                 <i class="ti-pencil"></i>
  //               </Link>
  //             </div>
  //             <div>
  //               <button
  //                 className="btn edit-icons"
  //                 onClick={(e) => {
  //                   setdeleteId(row.id);
  //                   setMdShow(true);
  //                 }}
  //               >
  //                 <i class="ti-trash"></i>
  //               </button>
  //             </div>
  //           </div>
  //         );
  //       },
  //     },
  //   ],
  //   []
  // );

  const status = [
    { value: 0, label: "Unpublish" },
    { value: 1, label: "Publish" },
  ];

  return (
    <div>
      <div className="page-header">
        <h3 className="page-title">Menu Management</h3>

        <div className="breadcrumb">
          <Link
            className="btn btn-block yellow-btn font-weight-medium auth-form-btn"
            to="/website-management/create-menu"
          >
            + NEW
          </Link>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body ">
              <div className="row ">
                <div className="col-12 ">
                  <DragDropContext onDragEnd={handleDragEnd}>
                    <table className="table borderd">
                      <thead>
                        <tr>
                          {/*                    
                          <th />
                          <th /> */}

                          <th style={{ fontWeight: "bold" }}>Change Order</th>
                          <th style={{ fontWeight: "bold" }}>Main Menu(English)</th>
                          <th style={{ fontWeight: "bold" }}>Main Menu(Arabic)</th>
                          <th style={{ "text-align": "center", fontWeight: "bold" }}>Order</th>
                          <th style={{ "text-align": "center", fontWeight: "bold" }}>Action</th>
                        </tr>
                      </thead>
                      <Droppable droppableId="droppable-1">
                        {(provider) => (
                          <tbody
                            className="text-capitalize"
                            ref={provider.innerRef}
                            {...provider.droppableProps}
                          >
                            {menu?.map((user, index) => (
                              <Draggable
                                key={user.id}
                                draggableId={user.name}
                                index={index}
                              >
                                {(provider) => (
                                  <tr
                                    {...provider.draggableProps}
                                    ref={provider.innerRef}
                                  >

                                    {/* <td/>
                                  <td/> */}

                                    <td style={{ "width": "180px", }} {...provider.dragHandleProps}> <div className="justify-content-center"><img src={Drag} alt="=" /></div> </td>
                                    <td>{user.name}</td>
                                    <td>{user.name_arabic ? user.name_arabic : 'N/A'}</td>
                                    <td style={{ "text-align": "center" }}>{user.order}</td>
                                    <td style={{ "width": "180px" }}>

                                      <><div style={{ display: "flex", "justify-content": "center" }}>
                                        <div>


                                          <Link
                                            className="btn edit-icons mr-2"
                                            to={`/website-management/update-menu/${user?.id}`}
                                          >
                                            <i class="ti-pencil"></i>
                                          </Link>
                                        </div>
                                        {user.id <= 5 ? null :
                                          <div>
                                            <button
                                              className="btn edit-icons"
                                              onClick={(e) => {
                                                setdeleteId(user?.id);
                                                setMdShow(true);
                                              }}
                                            >
                                              <i class="ti-trash"></i>
                                            </button>
                                          </div>}

                                      </div></>
                                    </td>
                                  </tr>
                                )}
                              </Draggable>
                            ))}
                            {/* {provider.placeholder} */}
                          </tbody>
                        )}
                      </Droppable>
                    </table>
                  </DragDropContext>
                  <Modal
                    show={mdShow}
                    onHide={() => {
                      setMdShow(false);
                    }}
                    aria-labelledby="example-modal-sizes-title-md"
                  >
                    <Modal.Header closeButton>
                      <Modal.Title
                        style={{ width: "100%", "text-align": "center" }}
                      >
                        Are You Sure, You Want To Delete ?
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Footer
                      className="fleex-wrap"
                      style={{ justifyContent: "center" }}
                    >
                      <Button
                        variant="success m-2 yellow-btn"
                        onClick={(e) => {
                          setMdShow(false);
                          dispatch(deleatMenu(deleteId));
                        }}
                      >
                        Yes
                      </Button>
                      <Button
                        variant="light m-2 yellow-btn"
                        onClick={() => {
                          setMdShow(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MenuList;
