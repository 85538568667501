import {
  MENU_LIST,
  SUB_MENU_DETAILS,
  MENU_DETAILS,
  SUB_MENU_LISTS,
  PAGE_LAYOUT_LISTS,
  PAGE_LAYOUT_MENUS_LISTS,
  PAGE_LAYOUT_DETAILS,
} from "../action/actiontypes";
const initialState = {
  sub_menu_detail: {},
  sub_menu_list: [],
  menu_list: [],
  menu_detail: {},
  page_layout_list: [],
  page_layout_details:{},
  page_layout_menus_list: [],
};
const CmsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SUB_MENU_DETAILS:
      return {
        ...state,
        sub_menu_detail: action.payload,
      };
    case MENU_LIST:
      return {
        ...state,
        menu_list: action.payload,
        menu_detail: {},
        sub_menu_detail: {},
        sub_menu_list: [],
      };
    case MENU_DETAILS:
      return {
        ...state,
        menu_detail: action.payload,
      };
    case SUB_MENU_LISTS:
      return {
        ...state,
        sub_menu_list: action.payload,
        sub_menu_detail: {},
      };
    case PAGE_LAYOUT_LISTS:
      return {
        ...state,
        page_layout_list: action.payload,
        page_layout_details: {},
      };
    case PAGE_LAYOUT_MENUS_LISTS:
      return {
        ...state,
        page_layout_menus_list: action.payload,
        // sub_menu_detail: {},
      };
      case PAGE_LAYOUT_DETAILS:
        return {
          ...state,
          page_layout_details: action.payload,
        };
    default:
      return state;
  }
};
export default CmsReducer;
