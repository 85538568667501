import {
    SUPPLIER_PROFILE_DETAIL
} from "../action/actiontypes";
const initialState = {
    supplierDetail: {},
};
const SupplierPortalReducer = (state = initialState, action) => {
    switch (action.type) {
        case SUPPLIER_PROFILE_DETAIL:
            return {
                ...state,
                supplierDetail: action.payload,
            };
        default:
            return state;
    }
};
export default SupplierPortalReducer;
