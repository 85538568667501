import { ROLE_DETAIL , ROLES_LIST} from "../action/actiontypes";
const initialState = {
    role_list:[],
    role_detail:{},
  };
  const RoleManagementReducer = (state = initialState, action) => {
    switch (action.type) {
        case ROLES_LIST:
          return {
            ...state,
            role_list: action.payload,
            role_detail: {},
        };
        case ROLE_DETAIL:
          return {
            ...state,
            role_detail: action.payload,
        };
      default: return state
    }
  };
  export default RoleManagementReducer 