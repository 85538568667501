import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Collapse } from "react-bootstrap";
import { Trans } from "react-i18next";
import Logo from "../../assets/images/logo.svg";
import { connect } from "react-redux";
class Sidebar extends Component {
  state = {};

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({ [menuState]: false });
    } else if (Object.keys(this.state).length === 0) {
      this.setState({ [menuState]: true });
    } else {
      Object.keys(this.state).forEach((i) => {
        this.setState({ [i]: false });
      });
      this.setState({ [menuState]: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    document.querySelector("#sidebar").classList.remove("active");
    Object.keys(this.state).forEach((i) => {
      this.setState({ [i]: false });
    });

    const dropdownPaths = [
      { path: "/apps", state: "appsMenuOpen" },
      { path: "/appsMarketing", state: "appsMarketingMenuOpen" },
      { path: "/appsManageDatabase", state: "appsManageDatabaseMenuOpen" },
      { path: "/appsSystemManagement", state: "appsSystemManagementMenuOpen" },
      { path: "/basic-ui", state: "basicUiMenuOpen" },
      { path: "/advanced-ui", state: "advancedUiMenuOpen" },
      { path: "/form-elements", state: "formElementsMenuOpen" },
      { path: "/tables", state: "tablesMenuOpen" },
      { path: "/maps", state: "mapsMenuOpen" },
      { path: "/icons", state: "iconsMenuOpen" },
      { path: "/charts", state: "chartsMenuOpen" },
      { path: "/user-pages", state: "userPagesMenuOpen" },
      { path: "/error-pages", state: "errorPagesMenuOpen" },
      { path: "/general-pages", state: "generalPagesMenuOpen" },
      { path: "/ecommerce", state: "ecommercePagesMenuOpen" },
    ];

    dropdownPaths.forEach((obj) => {
      if (this.isPathActive(obj.path)) {
        this.setState({ [obj.state]: true });
      }
    });
  }
  render() {
    let loginType = localStorage.loginType;
    return (
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <div className="text-center sidebar-brand-wrapper d-flex align-items-center">
          <a className="sidebar-brand brand-logo" href="index.html">
            <img src={Logo} alt="logo" />
          </a>
          <a
            className="sidebar-brand brand-logo-mini pl-4 pt-3"
            href="index.html"
          >
            <img src={Logo} alt="logo" />
          </a>
        </div>
        {loginType == 1 ? (
          <ul className="nav">
            <li className="nav-item nav-profile"></li>
            <li
              className={
                this.isPathActive("/dashboard-artist")
                  ? "nav-item active"
                  : "nav-item"
              }
            >
              <Link className="nav-link" to="/dashboard-artist">
                <i className="mdi mdi-home menu-icon"></i>
                <span className="menu-title">
                  <Trans>Dashboard</Trans>
                </span>
              </Link>
            </li>
            <li
              className={
                this.isPathActive("/my-notifications")
                  ? "nav-item active"
                  : "nav-item"
              }
            >
              <Link className="nav-link" to="/my-notifications">
                <i className="mdi mdi-home menu-icon"></i>
                <span className="menu-title">
                  <Trans>Notification</Trans>
                </span>
              </Link>
            </li>
            <li
              className={
                this.isPathActive("/my-profile")
                  ? "nav-item active"
                  : "nav-item"
              }
            >
              <Link className="nav-link" to="/my-profile">
                <i className="mdi mdi-home menu-icon"></i>
                <span className="menu-title">
                  <Trans>My Profile</Trans>
                </span>
              </Link>
            </li>
            {/* <li
              className={
                this.isPathActive("/logout") ? "nav-item active" : "nav-item"
              }
            >
              <Link className="nav-link" to="/dashboard">
                <i className="mdi mdi-home menu-icon"></i>
                <span className="menu-title">
                  <Trans>Logout</Trans>
                </span>
              </Link>
            </li> */}
          </ul>
        ) : loginType == 3 ? (
          <ul className="nav">
            <li className="nav-item nav-profile"></li>
            {/* <li
              className={
                this.isPathActive("/dashboard-artist")
                  ? "nav-item active"
                  : "nav-item"
              }
            >
              <Link className="nav-link" to="/dashboard-artist">
                <i className="mdi mdi-home menu-icon"></i>
                <span className="menu-title">
                  <Trans>Dashboard</Trans>
                </span>
              </Link>
            </li> */}
            {/* <li
              className={
                this.isPathActive("/my-notifications")
                  ? "nav-item active"
                  : "nav-item"
              }
            >
              <Link className="nav-link" to="/my-notifications">
                <i className="mdi mdi-home menu-icon"></i>
                <span className="menu-title">
                  <Trans>Notification</Trans>
                </span>
              </Link>
            </li> */}
            <li
              className={
                this.isPathActive("/supplier/my-profile")
                  ? "nav-item active"
                  : "nav-item"
              }
            >
              <Link className="nav-link" to="/supplier/my-profile">
                <i className="mdi mdi-account menu-icon"></i>
                <span className="menu-title">
                  <Trans>My Profile</Trans>
                </span>
              </Link>
            </li>
            {/* <li
              className={
                this.isPathActive("/logout") ? "nav-item active" : "nav-item"
              }
            >
              <Link className="nav-link" to="/dashboard">
                <i className="mdi mdi-home menu-icon"></i>
                <span className="menu-title">
                  <Trans>Logout</Trans>
                </span>
              </Link>
            </li> */}
          </ul>
        ) : (
          <ul className="nav">
            <li className="nav-item nav-profile"></li>
            <li
              className={
                this.isPathActive("/dashboard") ? "nav-item active" : "nav-item"
              }
            >
              <Link className="nav-link" to="/dashboard">
                <i className="mdi mdi-home menu-icon"></i>
                <span className="menu-title">
                  <Trans>Dashboard</Trans>
                </span>
              </Link>
            </li>
            {this.props.permissions.enquiryManagement?.view ? (
              <li
                className={
                  this.isPathActive("/enquiry-management")
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <Link className="nav-link" to="/enquiry-management">
                  <i className="mdi mdi-account-search menu-icon"></i>
                  <span className="menu-title">
                    <Trans>Enquiry Management</Trans>
                  </span>
                </Link>
              </li>
            ) : null}
            {this.props.permissions.metaTagManagement?.view || this.props.permissions.newsletter?.view ? (
              <li
                className={
                  this.isPathActive("/appsMarketing") ? "nav-item active" : "nav-item"
                }
              >
                <div
                  className={
                    this.state.appsMarketingMenuOpen
                      ? "nav-link menu-expanded"
                      : "nav-link"
                  }
                  onClick={() => this.toggleMenuState("appsMarketingMenuOpen")}
                  data-toggle="collapse"
                >
                  <i className="mdi mdi-newspaper menu-icon"></i>
                  <span className="menu-title">
                    <Trans>Marketing</Trans>
                  </span>
                  <i className="menu-arrow"></i>
                </div>
                <Collapse in={this.state.appsMarketingMenuOpen}>
                  <ul className="nav flex-column sub-menu">
                    {this.props.permissions.metaTagManagement?.view ?
                      <li
                        className={
                          this.isPathActive("/news-letter")
                            ? "nav-item active"
                            : "nav-item"
                        }
                      >
                        <Link className="nav-link" to="/news-letter">
                          {/* <i className="mdi mdi-newspaper menu-icon"></i> */}
                          {/* <span className="menu-title"> */}
                          <Trans>News Letter</Trans>
                          {/* </span> */}
                        </Link>
                      </li>
                      : null}
                    {this.props.permissions.newsletter?.view ?
                      <li
                        className={
                          this.isPathActive("/meta-tags")
                            ? "nav-item active"
                            : "nav-item"
                        }
                      >
                        <Link className="nav-link" to="/meta-tags">
                          {/* <i className="mdi mdi-tag menu-icon"></i> */}
                          {/* <span className="menu-title"> */}
                          <Trans>Meta Tag Management</Trans>
                          {/* </span> */}
                        </Link>
                      </li>
                      : null}
                  </ul>
                </Collapse>
              </li>
            ) : null}
            {this.props.permissions.artistManagement?.view || this.props.permissions.contentManagement?.view || this.props.permissions.supplierManagement?.view ? (
              <li
                className={
                  this.isPathActive("/appsManageDatabase") ? "nav-item active" : "nav-item"
                }
              >
                <div
                  className={
                    this.state.appsManageDatabaseMenuOpen
                      ? "nav-link menu-expanded"
                      : "nav-link"
                  }
                  onClick={() => this.toggleMenuState("appsManageDatabaseMenuOpen")}
                  data-toggle="collapse"
                >
                  {/* <i className="mdi mdi-newspaper menu-icon"></i> */}
                  <i class="mdi mdi-database menu-icon"></i>
                  <span className="menu-title">
                    <Trans>Manage Database</Trans>
                  </span>
                  <i className="menu-arrow"></i>
                </div>
                <Collapse in={this.state.appsManageDatabaseMenuOpen}>
                  <ul className="nav flex-column sub-menu">
                    {this.props.permissions.artistManagement?.view ?
                      <li
                        className={
                          this.isPathActive("/artist-management")
                            ? "nav-item active"
                            : "nav-item"
                        }
                      >
                        <Link className="nav-link" to="/artist-management">
                          {/* <i className="mdi mdi-microphone-variant menu-icon"></i> */}
                          {/* <span className="menu-title"> */}
                          <Trans>Artist Management</Trans>
                          {/* </span> */}
                        </Link>
                      </li>
                      : null}

                    {this.props.permissions.contentManagement?.view ?
                      <li
                        className={
                          this.isPathActive("/content-management")
                            ? "nav-item active"
                            : "nav-item"
                        }
                      >
                        <Link className="nav-link" to="/content-management">
                          {/* <i className="mdi mdi-content-paste menu-icon"></i> */}
                          {/* <span className="menu-title"> */}
                          <Trans>Content Management</Trans>
                          {/* </span> */}
                        </Link>
                      </li>
                      : null}
                    {this.props.permissions.supplierManagement?.view ?
                      <li
                        className={
                          this.isPathActive("/supplier-management")
                            ? "nav-item active"
                            : "nav-item"
                        }
                      >
                        <Link className="nav-link" to="/supplier-management">
                          {/* <i className="mdi mdi-microphone-variant menu-icon"></i> */}
                          {/* <span className="menu-title"> */}
                          <Trans>Supplier Management</Trans>
                          {/* </span> */}
                        </Link>
                      </li>
                      : null}
                  </ul>
                </Collapse>
              </li>
            ) : null}
            {this.props.permissions.userManagement?.view || this.props.permissions.roles?.view
              || this.props.permissions.subadmin?.view || this.props.permissions.categoryManagement?.view ? (
              <li
                className={
                  this.isPathActive("/appsSystemManagement") ? "nav-item active" : "nav-item"
                }
              >
                <div
                  className={
                    this.state.appsSystemManagementMenuOpen
                      ? "nav-link menu-expanded"
                      : "nav-link"
                  }
                  onClick={() => this.toggleMenuState("appsSystemManagementMenuOpen")}
                  data-toggle="collapse"
                >
                  {/* <i className="mdi mdi-newspaper menu-icon"></i> */}
                  <i class="mdi mdi-monitor-multiple menu-icon"></i>
                  <span className="menu-title">
                    <Trans>System Management</Trans>
                  </span>
                  <i className="menu-arrow"></i>
                </div>
                <Collapse in={this.state.appsSystemManagementMenuOpen}>
                  <ul className="nav flex-column sub-menu">
                    {/* {this.props.permissions.userManagement?.view ? (
                      <li
                        className={
                          this.isPathActive("/user-management")
                            ? "nav-item active"
                            : "nav-item"
                        }
                      >
                        <Link className="nav-link" to="/user-management">
                          <Trans>User Management</Trans>
                        </Link>
                      </li>
                    ) : null} */}
                    {this.props.permissions.roles?.view ? (
                      <li
                        className={
                          this.isPathActive("/roles-management")
                            ? "nav-item active"
                            : "nav-item"
                        }
                      >
                        <Link className="nav-link" to="/roles-management">
                          {/* <i className="mdi mdi-widgets menu-icon"></i> */}
                          {/* <span className="menu-title"> */}
                          <Trans>Roles Management</Trans>
                          {/* </span> */}
                        </Link>
                      </li>
                    ) : null}
                    {this.props.permissions.subadmin?.view ? (
                      <li
                        className={
                          this.isPathActive("/sub-admin-management")
                            ? "nav-item active"
                            : "nav-item"
                        }
                      >
                        <Link className="nav-link" to="/sub-admin-management">
                          {/* <i className="mdi mdi-account-key menu-icon"></i> */}
                          {/* <span className="menu-title"> */}
                          <Trans>Sub Admin Management</Trans>
                          {/* </span> */}
                        </Link>
                      </li>
                    ) : null}
                    {this.props.permissions.categoryManagement?.view ? (
                      <li
                        className={
                          this.isPathActive("/categories-management")
                            ? "nav-item active"
                            : "nav-item"
                        }
                      >
                        <Link className="nav-link" to="/categories-management">
                          {/* <i className="mdi mdi-server menu-icon"></i> */}
                          {/* <span className="menu-title"> */}
                          <Trans>Category Management</Trans>
                          {/* </span> */}
                        </Link>
                      </li>
                    ) : null}
                  </ul>
                </Collapse>
              </li>
            ) : null}
            {/* <li className={ this.isPathActive('/layout/RtlLayout') ? 'nav-item menu-items active' : 'nav-item menu-items' }>
            <Link className="nav-link" to="/layout/RtlLayout">
              <i className="mdi mdi-translate menu-icon"></i>
              <span className="menu-title">RTL</span>
            </Link>
          </li> */}
            {/* <li className={ this.isPathActive('/widgets') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/widgets">
              <i className="mdi mdi-widgets menu-icon"></i>
              <span className="menu-title">
                <Trans>Widgets</Trans>
              </span>
            </Link>
          </li> */}
            {/* {this.props.permissions.userManagement?.view ? (
              <li
                className={
                  this.isPathActive("/user-management")
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <Link className="nav-link" to="/user-management">
                  <i className="mdi mdi-account-multiple menu-icon"></i>
                  <span className="menu-title">
                    <Trans>User Management</Trans>
                  </span>
                </Link>
              </li>
            ) : null} */}
            {console.log(this.props.permissions)}
            {/* <li className={ this.isPathActive('/artist-management') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/artist-management">
              <i className="mdi mdi-contacts menu-icon"></i>
              <span className="menu-title">
                <Trans>Artist Management</Trans>
              </span>
            </Link>
          </li> */}
            {/* {this.props.permissions.categoryManagement?.view ? (
              <li
                className={
                  this.isPathActive("/categories-management")
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <Link className="nav-link" to="/categories-management">
                  <i className="mdi mdi-server menu-icon"></i>
                  <span className="menu-title">
                    <Trans>Category Management</Trans>
                  </span>
                </Link>
              </li>
            ) : null} */}
            {/* {this.props.permissions.metaTagManagement?.view ? (
              <li
                className={
                  this.isPathActive("/meta-tags")
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <Link className="nav-link" to="/meta-tags">
                  <i className="mdi mdi-tag menu-icon"></i>
                  <span className="menu-title">
                    <Trans>Meta Tag Management</Trans>
                  </span>
                </Link>
              </li>
            ) : null} */}
            {/* {this.props.permissions.artistManagement?.view ? (
              <li
                className={
                  this.isPathActive("/artist-management")
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <Link className="nav-link" to="/artist-management">
                  <i className="mdi mdi-microphone-variant menu-icon"></i>
                  <span className="menu-title">
                    <Trans>Artist Management</Trans>
                  </span>
                </Link>
              </li>
            ) : null} */}
            {/* {this.props.permissions.newsletter?.view ? (
              <li
                className={
                  this.isPathActive("/news-letter")
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <Link className="nav-link" to="/news-letter">
                  <i className="mdi mdi-newspaper menu-icon"></i>
                  <span className="menu-title">
                    <Trans>News Letter</Trans>
                  </span>
                </Link>
              </li>
            ) : null} */}
            {/* {this.props.permissions.websiteManagement?.view ? (
            <li
              className={
                this.isPathActive("/page-management")
                  ? "nav-item active"
                  : "nav-item"
              }
            >
              <Link className="nav-link" to="/page-management">
                <i className="mdi mdi-contacts menu-icon"></i>
                <span className="menu-title">
                  <Trans>Page Management</Trans>
                </span>
              </Link>
            </li>
          ) : null} */}
            {/* {this.props.permissions.contentManagement?.view ? (
              <li
                className={
                  this.isPathActive("/content-management")
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <Link className="nav-link" to="/content-management">
                  <i className="mdi mdi-content-paste menu-icon"></i>
                  <span className="menu-title">
                    <Trans>Content Management</Trans>
                  </span>
                </Link>
              </li>
            ) : null} */}
            {/* {this.props.permissions.subadmin?.view ? (
              <li
                className={
                  this.isPathActive("/sub-admin-management")
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <Link className="nav-link" to="/sub-admin-management">
                  <i className="mdi mdi-account-key menu-icon"></i>
                  <span className="menu-title">
                    <Trans>Sub Admin Management</Trans>
                  </span>
                </Link>
              </li>
            ) : null} */}
            {/* {this.props.permissions.roles?.view ? (
              <li
                className={
                  this.isPathActive("/roles-management")
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <Link className="nav-link" to="/roles-management">
                  <i className="mdi mdi-widgets menu-icon"></i>
                  <span className="menu-title">
                    <Trans>Roles Management</Trans>
                  </span>
                </Link>
              </li>
            ) : null} */}
            {this.props.permissions.websiteManagement?.view ? (
              <li
                className={
                  this.isPathActive("/apps") ? "nav-item active" : "nav-item"
                }
              >
                <div
                  className={
                    this.state.appsMenuOpen
                      ? "nav-link menu-expanded"
                      : "nav-link"
                  }
                  onClick={() => this.toggleMenuState("appsMenuOpen")}
                  data-toggle="collapse"
                >
                  <i className="mdi mdi-web menu-icon"></i>
                  <span className="menu-title">
                    <Trans>Web Site Management</Trans>
                  </span>
                  <i className="menu-arrow"></i>
                </div>
                <Collapse in={this.state.appsMenuOpen}>
                  <ul className="nav flex-column sub-menu">
                    {/* <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/apps/kanban-board")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/apps/kanban-board"
                  >
                    <Trans>Kanban Board</Trans>
                  </Link>
                </li> */}
                    {/* <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/apps/todo-list")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/apps/todo-list"
                  >
                    <Trans>Todo List</Trans>
                  </Link>
                </li> */}
                    {/* <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/apps/tickets")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/apps/tickets"
                  >
                    <Trans>Tickets</Trans>
                  </Link>
                </li> */}
                    {/* <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/apps/chats")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/apps/chats"
                  >
                    <Trans>Chats</Trans>
                  </Link>
                </li> */}
                    {/* <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/apps/email")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/apps/email"
                  >
                    <Trans>E-mail</Trans>
                  </Link>
                </li> */}
                    {/* <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/apps/calendar")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/apps/calendar"
                  >
                    <Trans>Calendar</Trans>
                  </Link>
                </li> */}
                    {/* <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/apps/gallery")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/apps/gallery"
                  >
                    <Trans>Gallery</Trans>
                  </Link>
                </li> */}
                    <li
                      className={
                        this.isPathActive("/website-management/menu-management")
                          ? "nav-item active"
                          : "nav-item"
                      }
                    >
                      <Link
                        className="nav-link"
                        to="/website-management/menu-management"
                      >
                        {/* <span className="menu-title"> */}
                        <Trans>Menu Management</Trans>
                        {/* </span> */}
                      </Link>
                    </li>
                    <li
                      className={
                        this.isPathActive(
                          "/website-management/page-layout-management"
                        )
                          ? "nav-item active"
                          : "nav-item"
                      }
                    >
                      <Link
                        className="nav-link"
                        to="/website-management/page-layout-management"
                      >
                        {/* <span className="menu-title"> */}
                        <Trans>Page Layout</Trans>
                        {/* </span> */}
                      </Link>
                    </li>
                    <li
                      className={
                        this.isPathActive(
                          "/website-management/section-management"
                        )
                          ? "nav-item active"
                          : "nav-item"
                      }
                    >
                      <Link
                        className="nav-link"
                        to="/website-management/section-management"
                      >
                        {/* <span className="menu-title"> */}
                        <Trans>Section Management</Trans>
                        {/* </span> */}
                      </Link>
                    </li>
                  </ul>
                </Collapse>
              </li>
            ) : null}
            <li
              className={
                this.isPathActive("/my-account")
                  ? "nav-item active"
                  : "nav-item"
              }
            >
              <Link className="nav-link" to="/my-account">
                <i className="mdi mdi-account-circle menu-icon"></i>
                <span className="menu-title">
                  <Trans>My Account</Trans>
                </span>
              </Link>
            </li>
            {/* <li className={ this.isPathActive('/basic-ui') ? 'nav-item active' : 'nav-item' }>
            <div className={ this.state.basicUiMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('basicUiMenuOpen') } data-toggle="collapse">
              <i className="mdi mdi-crosshairs-gps menu-icon"></i>
              <span className="menu-title">
                <Trans>Basic UI Elements</Trans>
              </span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={this.state.basicUiMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/basic-ui/accordions")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/basic-ui/accordions"
                  >
                    <Trans>Accordions</Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/basic-ui/buttons")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/basic-ui/buttons"
                  >
                    <Trans>Buttons</Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/basic-ui/badges")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/basic-ui/badges"
                  >
                    <Trans>Badges</Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/basic-ui/breadcrumbs")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/basic-ui/breadcrumbs"
                  >
                    <Trans>Breadcrumbs</Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/basic-ui/dropdowns")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/basic-ui/dropdowns"
                  >
                    <Trans>Dropdowns</Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/basic-ui/modals")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/basic-ui/modals"
                  >
                    <Trans>Modals</Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/basic-ui/progressbar")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/basic-ui/progressbar"
                  >
                    <Trans>Progress bar</Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/basic-ui/pagination")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/basic-ui/pagination"
                  >
                    <Trans>Pagination</Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/basic-ui/tabs")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/basic-ui/tabs"
                  >
                    <Trans>Tabs</Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/basic-ui/typography")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/basic-ui/typography"
                  >
                    <Trans>Typography</Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/basic-ui/tooltips")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/basic-ui/tooltips"
                  >
                    <Trans>Tooltips</Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/basic-ui/popups")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/basic-ui/popups"
                  >
                    <Trans>Popups</Trans>
                  </Link>
                </li>
              </ul>
            </Collapse>
          </li> */}
            {/* <li className={ this.isPathActive('/advanced-ui') ? 'nav-item active' : 'nav-item' }>
            <div className={ this.state.advancedUiMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('advancedUiMenuOpen') } data-toggle="collapse">
              <i className="mdi mdi-cards-variant menu-icon"></i>
              <span className="menu-title">
                <Trans>Advanced UI</Trans>
              </span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={this.state.advancedUiMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/advanced-ui/dragula")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/advanced-ui/dragula"
                  >
                    <Trans>Dragula</Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/advanced-ui/clipboard")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/advanced-ui/clipboard"
                  >
                    <Trans>Clipboard</Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/advanced-ui/context-menu")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/advanced-ui/context-menu"
                  >
                    <Trans>Context menu</Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/advanced-ui/sliders")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/advanced-ui/sliders"
                  >
                    <Trans>Sliders</Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/advanced-ui/carousel")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/advanced-ui/carousel"
                  >
                    <Trans>Carousel</Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/advanced-ui/loaders")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/advanced-ui/loaders"
                  >
                    <Trans>Loaders</Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/advanced-ui/tree-view")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/advanced-ui/tree-view"
                  >
                    <Trans>Tree View</Trans>
                  </Link>
                </li>
              </ul>
            </Collapse>
          </li> */}
            {/* <li className={ this.isPathActive('/form-elements') ? 'nav-item active' : 'nav-item' }>
            <div className={ this.state.formElementsMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('formElementsMenuOpen') } data-toggle="collapse">
              <i className="mdi mdi-format-list-bulleted menu-icon"></i>
              <span className="menu-title">
                <Trans>Form Elements</Trans>
              </span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={this.state.formElementsMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/form-elements/basic-elements")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/form-elements/basic-elements"
                  >
                    <Trans>Basic Elements</Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/form-elements/advanced-elements")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/form-elements/advanced-elements"
                  >
                    <Trans>Advanced Elements</Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/form-elements/validation")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/form-elements/validation"
                  >
                    <Trans>Validation</Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/form-elements/wizard")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/form-elements/wizard"
                  >
                    <Trans>Wizard</Trans>
                  </Link>
                </li>
              </ul>
            </Collapse>
          </li> */}
            {/* <li className={ this.isPathActive('/tables') ? 'nav-item active' : 'nav-item' }>
            <div className={ this.state.tablesMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('tablesMenuOpen') } data-toggle="collapse">
              <i className="mdi mdi-table-large menu-icon"></i>
              <span className="menu-title">
                <Trans>Tables</Trans>
              </span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={this.state.tablesMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/tables/basic-table")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/tables/basic-table"
                  >
                    <Trans>Basic Table</Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/tables/data-table")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/tables/data-table"
                  >
                    <Trans>Data Table</Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/tables/react-table")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/tables/react-table"
                  >
                    <Trans>React Table</Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/tables/sortable-table")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/tables/sortable-table"
                  >
                    <Trans>Sortable Table</Trans>
                  </Link>
                </li>
              </ul>
            </Collapse>
          </li> */}
            {/* <li className={ this.isPathActive('/maps') ? 'nav-item active' : 'nav-item' }>
            <div className={ this.state.mapsMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('mapsMenuOpen') } data-toggle="collapse">
            <i className="mdi mdi-map-marker-radius menu-icon"></i>
            <span className="menu-title"><Trans>Maps</Trans></span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={this.state.mapsMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/maps/vector-map")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/maps/vector-map"
                  >
                    Vector Maps
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/maps/simple-map")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/maps/simple-map"
                  >
                    Simple Maps
                  </Link>
                </li>
              </ul>
            </Collapse>
          </li> */}
            {/* <li className={ this.isPathActive('/notifications') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/notifications">
              <i className="mdi mdi-bell-ring menu-icon"></i>
              <span className="menu-title">
                <Trans>Notifications</Trans>
              </span>
            </Link>
          </li> */}
            {/* <li className={ this.isPathActive('/icons') ? 'nav-item active' : 'nav-item' }>
            <div className={ this.state.iconsMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('iconsMenuOpen') } data-toggle="collapse">
              <i className="mdi mdi-contacts menu-icon"></i>
              <span className="menu-title">
                <Trans>Icons</Trans>
              </span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={this.state.iconsMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/icons/mdi")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/icons/mdi"
                  >
                    Material
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/icons/flag-icons")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/icons/flag-icons"
                  >
                    Flag icons
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/icons/font-awesome")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/icons/font-awesome"
                  >
                    Font Awesome
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/icons/simple-line")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/icons/simple-line"
                  >
                    Simple Line Icons
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/icons/themify")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/icons/themify"
                  >
                    Themify
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/icons/typicons")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/icons/typicons"
                  >
                    Typicons
                  </Link>
                </li>
              </ul>
            </Collapse>
            
          </li> */}
            {/* <li className={ this.isPathActive('/text-editors') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/text-editors">
              <i className="mdi mdi-file-document menu-icon"></i>
              <span className="menu-title">
                <Trans>Text Editor</Trans>
              </span>
            </Link>
          </li> */}
            {/* <li className={ this.isPathActive('/code-editor') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/code-editor">
              <i className="mdi mdi-code-not-equal-variant menu-icon"></i>
              <span className="menu-title">
                <Trans>Code Editor</Trans>
              </span>
            </Link>
          </li> */}
            {/* <li className={ this.isPathActive('/charts') ? 'nav-item active' : 'nav-item' }>
            <div className={ this.state.chartsMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('chartsMenuOpen') } data-toggle="collapse">
              <i className="mdi mdi-chart-bar menu-icon"></i>
              <span className="menu-title">
                <Trans>Charts</Trans>
              </span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={this.state.chartsMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/charts/chart-js")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/charts/chart-js"
                  >
                    <Trans>Chart Js</Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/charts/c3-chart")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/charts/c3-chart"
                  >
                    <Trans>C3 Charts</Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/charts/chartist")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/charts/chartist"
                  >
                    <Trans>Chartist</Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/charts/google-charts")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/charts/google-charts"
                  >
                    <Trans>Google Charts</Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/charts/sparkline-charts")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/charts/sparkline-charts"
                  >
                    <Trans>Sparkline Charts</Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/charts/guage-chart")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/charts/guage-chart"
                  >
                    <Trans>Guage Chart</Trans>
                  </Link>
                </li>
              </ul>
            </Collapse>
          </li> */}
            {/* <li className={ this.isPathActive('/user-pages') ? 'nav-item active' : 'nav-item' }>
            <div className={ this.state.userPagesMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('userPagesMenuOpen') } data-toggle="collapse">
              <i className="mdi mdi-lock menu-icon"></i>
              <span className="menu-title">
                <Trans>User Pages</Trans>
              </span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={this.state.userPagesMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/user-pages/login-1")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/user-pages/login-1"
                  >
                    <Trans>Login</Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/user-pages/login-2")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/user-pages/login-2"
                  >
                    <Trans>Login 2</Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/user-pages/register-1")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/user-pages/register-1"
                  >
                    <Trans>Register</Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/user-pages/register-2")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/user-pages/register-2"
                  >
                    <Trans>Register 2</Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/user-pages/lockscreen")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/user-pages/lockscreen"
                  >
                    <Trans>Lockscreen</Trans>
                  </Link>
                </li>
              </ul>
            </Collapse>
          </li> */}
            {/* <li className={ this.isPathActive('/error-pages') ? 'nav-item active' : 'nav-item' }>
            <div className={ this.state.errorPagesMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('errorPagesMenuOpen') } data-toggle="collapse">
              <i className="mdi mdi-security menu-icon"></i>
              <span className="menu-title">
                <Trans>Error Pages</Trans>
              </span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={this.state.errorPagesMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/error-pages/error-404")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/error-pages/error-404"
                  >
                    404
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/error-pages/error-500")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/error-pages/error-500"
                  >
                    500
                  </Link>
                </li>
              </ul>
            </Collapse>
          </li> */}
            {/* <li className={ this.isPathActive('/general-pages') ? 'nav-item active' : 'nav-item' }>
            <div className={ this.state.generalPagesMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('generalPagesMenuOpen') } data-toggle="collapse">
              <i className="mdi mdi-medical-bag menu-icon"></i>
              <span className="menu-title">
                <Trans>General Pages</Trans>
              </span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={this.state.generalPagesMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/general-pages/blank-page")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/general-pages/blank-page"
                  >
                    <Trans>Blank Page</Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/general-pages/landing-page")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/general-pages/landing-page"
                  >
                    <Trans>Landing Page</Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/general-pages/profile")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/general-pages/profile"
                  >
                    <Trans>Profile</Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/general-pages/faq-1")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/general-pages/faq-1"
                  >
                    <Trans>FAQ</Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/general-pages/faq-2")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/general-pages/faq-2"
                  >
                    <Trans>FAQ 2</Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/general-pages/news-grid")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/general-pages/news-grid"
                  >
                    <Trans>News Grid</Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/general-pages/timeline")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/general-pages/timeline"
                  >
                    <Trans>Timeline</Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/general-pages/search-results")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/general-pages/search-results"
                  >
                    <Trans>Search Results</Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/general-pages/portfolio")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/general-pages/portfolio"
                  >
                    <Trans>Portfolio</Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/general-pages/user-listing")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/general-pages/user-listing"
                  >
                    <Trans>User Listing</Trans>
                  </Link>
                </li>
              </ul>
            </Collapse>
          </li> */}
            {/* <li className={ this.isPathActive('/ecommerce') ? 'nav-item active' : 'nav-item' }>
            <div className={ this.state.ecommercePagesMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('ecommercePagesMenuOpen') } data-toggle="collapse">
              <i className="mdi mdi-security menu-icon"></i>
              <span className="menu-title">
                <Trans>E-commerce</Trans>
              </span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={this.state.ecommercePagesMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/ecommerce/invoice")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/ecommerce/invoice"
                  >
                    <Trans>Invoice</Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/ecommerce/pricing")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/ecommerce/pricing"
                  >
                    <Trans>Pricing</Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/ecommerce/product-catalogue")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/ecommerce/product-catalogue"
                  >
                    <Trans>Product Catalogue</Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/ecommerce/project-list")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/ecommerce/project-list"
                  >
                    <Trans>Project List</Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/ecommerce/orders")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/ecommerce/orders"
                  >
                    <Trans>Orders</Trans>
                  </Link>
                </li>
              </ul>
            </Collapse>
          </li> */}
            {/* <li className="nav-item">
            <span className="nav-link" href="#">
              <span className="menu-title">
                <Trans>Docs</Trans>
              </span>
            </span>
          </li> */}
            {/* <li className="nav-item">
            <a className="nav-link" href="http://bootstrapdash.com/demo/breeze/react/documentation/documentation.html" rel="noopener noreferrer" target="_blank">
              <i className="mdi mdi-file-document-box menu-icon"></i>
              <span className="menu-title">
                <Trans>Documentation</Trans>
              </span>
            </a>
          </li> */}
            {/* <li className="nav-item sidebar-actions">
            <div className="nav-link">
              <div className="mt-4">
                <div className="border-none">
                  <p className="text-black">
                    <Trans>Notifications</Trans>
                  </p>
                </div>
                <ul className="mt-4 pl-0 pr-0">
                  <li>
                    <Trans>Sign Out</Trans>
                  </li>
                </ul>
              </div>
            </div>
          </li> */}
          </ul>
        )}
      </nav>
    );
  }


  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  componentDidMount() {
    this.onRouteChanged();
    // add className 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector("body");
    document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
      el.addEventListener("mouseover", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });
  }
}
function mapStateToProps(state) {
  return {
    permissions: state.PermissionReducer.permissionDetails,
  };
}
export default connect(mapStateToProps, {})(withRouter(Sidebar));
