import React, { Component } from "react";
import { Form, InputGroup, Col, Button } from "react-bootstrap";
import ImageUploading from "react-images-uploading";
import { Link , history  } from 'react-router-dom';
import { connect } from "react-redux";
import {
  typeDetail,
  getArtistType,
  artistTypeIdUpdate,
  artistTypeIdCreate,
} from "../../Redux/action/categoriesManagement";

export class AddArtistType extends Component {
  constructor(props) {
    super();
    this.state = {
      validated: false,
      passwordValidated: false,
      images: [],
      maxNumber: 1,
      artistTypeId: "",
      disable: true,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount() {
    if (this.props.match.params.id) {
      this.props.typeDetail(this.props.match.params.id);
      this.setState({
        artistTypeId: this.props.match.params.id,
      });
    }
  }

  handleSubmit(event) {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    event.preventDefault();
    this.setState({ validated: true });
  
    var data = {
      name: event.target.artistType.value,
    };
    if (form.checkValidity() === true) {
      if (this.state.artistTypeId) {
        this.props.artistTypeIdUpdate(this.state.artistTypeId, data ,  this.props.history);
      } else {
       if(this.props.artistTypeIdCreate(data , this.props.history)){
       }
      }
    }
  }

  render() {
    return (
      <div className="row">
        <div className="col-md-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <h4 className="card-title col-9 d-flex align-items-center">Artist Type</h4>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/categories-management">Category Management</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                    Artist Type
                    </li>
                  </ol>
                </nav>
              </div>

              <Form
                className="forms-sample"
                noValidate
                validated={this.state.validated}
                onSubmit={this.handleSubmit}
              >
                <Form.Group className="row">
                  <label
                    htmlFor="exampleInputUsername2"
                    className="col-sm-2 col-form-label"
                  >
                    Artist Type
                  </label>
                  <div className="col-sm-4">
                    <Form.Control
                      type="text"
                      className="form-control"
                      required
                      maxLength="25"
                      id="exampleInputUsername2"
                      placeholder="Artist Type"
                      name="artistType"
                      pattern="^(?![0-9]+$)[a-zA-Z0-9 ]{2,}$"
                      defaultValue={this.props.artistType.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide a valid Artist Type.
                    </Form.Control.Feedback>
                  </div>
                </Form.Group>
                <div className="row">
                  <div className="col-sm-4">
                  {this.state.artistTypeId ? <button type="submit" className="btn yellow-btn mr-2">
                    Update
                  </button>
                  :<button type="submit" className="btn yellow-btn mr-2">
                    Create
                  </button>} 
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    artistType: state.CategoriesManagementReducer.artistTypeDetails,
  };
}
export default connect(mapStateToProps, {
  typeDetail,
  getArtistType,
  artistTypeIdUpdate,
  artistTypeIdCreate,
})(AddArtistType);
