import React, { Component } from "react";
import { Form, InputGroup, Col, Button } from "react-bootstrap";
import Multiselect from "multiselect-react-dropdown";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import {
  specialtyDetail,
  getArtistType,
  specialtyUpdate,
  specialtyCreate,
} from "../../Redux/action/categoriesManagement";

const selectedIds = [2,5,7];
export class AddSpecialties extends Component {
  constructor(props) {
    super();
    this.state = {
      validated: false,
      passwordValidated: false,
      selectedValue: [],
      maxNumber: 1,
      specialtyId: "",
      disable: true,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.valuesToUse = this.valuesToUse.bind(this);
    this.multiselectRef = React.createRef();
  }
  async componentDidMount() {
    if (this.props.match.params.id) {
      await this.props.specialtyDetail(this.props.match.params.id)
      this.setState({
        specialtyId: this.props.match.params.id,
      });
    }
    this.props.getArtistType();
  }

  handleSubmit(event) {
    // event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    event.preventDefault();
    this.setState({ validated: true });
    var data = {
      name: event.target.Specialty.value,
      artistTypeId: this.multiselectRef.current.getSelectedItems(),
    };
    if (form.checkValidity() === true) {
      if (this.state.specialtyId) {
        this.props.specialtyUpdate(this.state.specialtyId, data ,  this.props.history);
      } else {
        this.props.specialtyCreate(data,  this.props.history);
      }

    }
  }
  valuesToUse(){
    if(this.state.specialtyId){
    const arryused = [];
    if(this.props.options.length >0 && selectedIds.length>0){
      this.props.specialityDetail.artistTypeId.map((id) => {
      arryused.push(this.props.options[this.props.options.findIndex((x) => x.id === id)]);
    });
    }
   if (arryused.length>0) {
      return (arryused)
    }}
  }

  render() {
    return (
      <div className="row">
        <div className="col-md-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <h4 className="card-title col-9 d-flex align-items-center">Speciality</h4>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/categories-management">Category Management</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                    Specialty Detail
                    </li>
                  </ol>
                </nav>
              </div>

              <Form
                className="forms-sample"
                noValidate
                validated={this.state.validated}
                onSubmit={this.handleSubmit}
              >
                <Form.Group className="row">
                  <label
                    htmlFor="exampleInputUsername2"
                    className="col-sm-2 col-form-label"
                  >
                    Name
                  </label>
                  <div className="col-sm-4">
                    <Form.Control
                      type="text"
                      className="form-control"
                      required
                      maxLength="25"
                      id="exampleInputUsername2"
                      placeholder="Speciality"
                      name="Specialty"
                      pattern="^(?![0-9]+$)[a-zA-Z0-9 ]{2,}$"
                      defaultValue={this.props.specialityDetail.name}
                    />
                    
                    <Form.Control.Feedback type="invalid">
                      Please provide a valid Speciality.
                    </Form.Control.Feedback>
                  </div>
                </Form.Group>
                <Form.Group  className="row" controlId="my_multiselect_field">
                <div className="col-sm-2 col-form-label">
                  <Form.Label>Artist Type</Form.Label>
                </div>
                <div className="col-4">
                  <Multiselect
                    options={this.props.options} // Options to display in the dropdown
                    selectedValues={
                      this.valuesToUse()
                    } // Preselected value to persist in dropdown
                    onSelect={this.onSelect} // Function will trigger on select event
                    displayValue="name" // Property name to display in the dropdown options
                    ref={this.multiselectRef}
                    placeholder="Select Artist Type"
                    customCloseIcon={<i class="typcn typcn-delete ml-1"></i>}
                    style={{
                            chips: {
                              background: "#F6F4ED",
    color: "black",
                            },
                            options: {
                              "font-size": "0.875rem",
                            },
                            "font-size": "0.875rem",
                          }}
                  />
                </div>
                </Form.Group>
                <div className="row">
                  <div className="col-sm-4">
                  {this.state.specialtyId ? (
                    <button type="submit" className="btn yellow-btn mr-2">
                      Update
                    </button>
                  ) : (
                    <button type="submit" className="btn yellow-btn mr-2">
                      Create
                    </button>
                  )}
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    specialityDetail: state.CategoriesManagementReducer.specialityDetails,
    options: state.CategoriesManagementReducer.artistTypeList,
  };
}
export default connect(mapStateToProps, {
  specialtyDetail,
  getArtistType,
  specialtyUpdate,
  specialtyCreate,
})(AddSpecialties);
