import React from "react";
import { Form, InputGroup, Col, Button, FormControl } from "react-bootstrap";
import { useState, useRef, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import Event from "../../assets/images/event.png";
import Live from "../../assets/images/live.png";
import Warehouse from "../../assets/images/Warehouse.png";
import Upcoming from "../../assets/images/upcoming.png";
import Select from "react-select";
import { Modal } from "react-bootstrap";
import { toasterMessage } from "../../utils/toast-util";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
  layoutDeatilById,
  createPageLayout,
  UpdatePageLayout,
  pageLayoutMenuList,
} from "../../Redux/action/cms";

function CreatePageLayout() {
  const [validated, setValidated] = useState(false);
  const [layout, setLayout] = useState(null);
  const [menuSelected, setMenuSelected] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();

  // const menu_detail = useSelector((state) => state.CmsReducer.menu_detail);
  const params = useParams();
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    event.preventDefault();
    setValidated(true);
    
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    if(params.id<6){
      if (form.checkValidity() === true) {
        let obj = {
          name: event.target.pageName?.value,
          menuId: menuSelected.id,
          menuType: menuSelected.menuType,
          active_status: event.target.status.checked ? 1 : 0,
        };
        if (params.id) {
          dispatch(UpdatePageLayout(obj, params.id, history));
        } else {
          dispatch(createPageLayout(obj, history));
          console.log(obj);
        }
      }
    }else {

    
    if (!event.target.ExampleRadio4?.value) {
      toasterMessage("error", "Please select Layout");
      event.preventDefault();
      event.stopPropagation();
    }
    if (!menuSelected) {
      toasterMessage("error", "Please select Menu");
      event.preventDefault();
      event.stopPropagation();
    }
    
   
    if (form.checkValidity() === true && menuSelected !== undefined && event.target.ExampleRadio4?.value) {
      console.log(menuSelected);
      let obj = {
        name: event.target.pageName?.value,
        menuId: menuSelected.id,
        menuType: menuSelected.menuType,
        layout: event.target.ExampleRadio4?.value,
        active_status: event.target.status.checked ? 1 : 0,
      };
      if (params.id) {
        dispatch(UpdatePageLayout(obj, params.id, history));
      } else {
        dispatch(createPageLayout(obj, history));
        console.log(obj);
      }
    }
  }
  };
  useEffect(() => {
    dispatch(pageLayoutMenuList());
    if (params.id) {
      dispatch(layoutDeatilById(params.id));
    }
  }, []);

  const pageLayoutDetails = useSelector(
    (state) => state.CmsReducer.page_layout_details
  );
  const menuOption = useSelector(
    (state) => state.CmsReducer.page_layout_menus_list
  );

  useEffect(() => {
    setMenuSelected(
      menuOption[menuOption.findIndex((x) => x.id === pageLayoutDetails.menuId && x.menuType === pageLayoutDetails.menuType)]
    );
  }, [pageLayoutDetails, menuOption]);

   useEffect(() => {
    console.log(pageLayoutDetails)
    setLayout(pageLayoutDetails.layout);
   },[pageLayoutDetails]);
  // const styles = {
  //   menu: (base) => ({
  //     ...base,
  //     marginTop: "4px",
  //     fontSize: "0.875rem",

  //     passive: "true",
  //   }),
  //   control: (base) => ({
  //     ...base,
  //     fontSize: "0.875rem",
  //   }),
  // };


  return (
    <>
      <div className="row">
        <h4 className="card-title col-8 d-flex align-items-center">
          {!params.id ? "Create Page" : "Update Page"}
        </h4>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/website-management/page-layout-management">
                Page Layout Management
              </Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {!params.id ? "Create Page" : "Update Page"}
            </li>
          </ol>
        </nav>
      </div>
      <div className="row">
        <div className="col-md-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <h4 className="card-title col-9 d-flex align-items-center">
                  Page Layout Information
                </h4>
              </div>

              <Form
                className="forms-sample"
                noValidate
                id="form"
                validated={validated}
                onSubmit={(event) => handleSubmit(event)}
              >
                <>
                  <Form.Row>
                    <Form.Group as={Col} md="6" className="row  mr-1">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className=" col-sm-4  col-form-label"
                      >
                        Page Name*
                      </Form.Label>
                      <div className="col-sm-8">
                        <Form.Control
                          type="text"
                          className="form-control"
                          required
                          minLength={3}
                          id="exampleInputUsername2"
                          placeholder="Page Name"
                          name="pageName"
                          defaultValue={pageLayoutDetails?.name}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide Page Name.
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md="6" className="row mr-1">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className=" col-sm-4  col-form-label"
                      >
                        Select Menu*
                      </Form.Label>
                      <div className="col-sm-8">
                        <Select
                          required
                          options={menuOption}
                          value={menuSelected}
                          // styles={styles}
                          isDisabled={params.id < 6 ? true : false}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          placeholder="Select Menu"
                          maxMenuHeight={100}
                          onChange={(e) => {
                            setMenuSelected(e);
                          }}
                        />
                      </div>
                    </Form.Group>
                  </Form.Row>
                  {params.id < 6 ?
                  null: <> <Form.Row>
                    <Form.Group as={Col} md="12" className="row  mr-1">
                      <Form.Label className=" col-sm-2  col-form-label">
                        Layout*
                      </Form.Label>
                      <div className="col mr-2">
                        <img
                          src={Live}
                          alt="Live Entertainment"
                          height={500}
                          width={130}
                        />
                        <div className="form-check ">
                          <Form.Label className="form-check-label">
                            <Form.Control
                              type="radio"
                              className="form-check-input"
                              name="ExampleRadio4"
                              id="membershipRadios1"
                              value="1"
                              checked={layout === "1"}
                              disabled = {params.id}
                              onChange={(e)=>{setLayout("1")}}
                            />{" "}
                           <i className="input-helper"></i>
                            Live Entertainment
                            <div> </div>
                          </Form.Label>
                        </div>
                      </div>
                      <div className="col mr-2">
                        <img
                          src={Event}
                          alt="Event_Design"
                          height={500}
                          width={130}
                        />
                        <div className="form-check">
                          <Form.Label className="form-check-label">
                            <input
                              type="radio"
                              className="form-check-input"
                              name="ExampleRadio4"
                              id="membershipRadios1"
                              value="2"
                              disabled = {params.id}
                              checked={layout === "2"}
                              onChange={(e)=>{setLayout("2")}}
                            />{" "}
                            <i className="input-helper"></i>
                            Event Design
                          </Form.Label>
                        </div>
                      </div>
                      <div className="col mr-2">
                        <img
                          src={Upcoming}
                          alt="Upcoming Events"
                          height={500}
                          width={130}
                        />
                        <div className="form-check">
                          <Form.Label className="form-check-label">
                            <input
                              type="radio"
                              className="form-check-input"
                              name="ExampleRadio4"
                              id="membershipRadios1"
                              value="3"
                              disabled = {params.id}
                              checked={layout === "3"}
                              onChange={(e)=>{setLayout("3")}}
                            />{" "}
                            <i className="input-helper"></i>
                            Upcoming Events
                          </Form.Label>
                        </div>
                      </div>
                      <div className="col">
                        <img
                          src={Warehouse}
                          alt="Live Entertainment"
                          height={500}
                          width={130}
                        />
                        <div className="form-check">
                          <Form.Label className="form-check-label">
                            <input
                              type="radio"
                              className="form-check-input"
                              name="ExampleRadio4"
                              id="membershipRadios2"
                              value="4"
                              checked={layout === "4"}
                              disabled = {params.id}
                              onChange={(e)=>{setLayout("4")}}
                              // defaultSelect={pageLayoutDetails?.layout === "4"}
                            />{" "}
                            <i className="input-helper"></i>
                           Warehouse
                          </Form.Label>
                        </div>
                      </div>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                 
                      (Note:- Page Layouts can't be changed later.
                       You have too delete the existing one and create a new Page Layout )
    
                  </Form.Row>
                  </> }
                  <Form.Row>
                    <Form.Group as={Col} md="12" className="row ml-2">
                      <Form.Check
                        className=""
                        name="status"
                        defaultChecked={pageLayoutDetails?.active_status}
                        onClick={() => {}}
                      />
                      <Form.Label className="col-form-label ml-1">
                        Active This Page
                      </Form.Label>
                    </Form.Group>
                  </Form.Row>
                
                </>
                <div className="row">
                  <div className="col-sm-4">
                    <Button type="submit" className="btn yellow-btn mr-2">
                      {params.id ? "Update" : "Create"}
                    </Button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreatePageLayout;
