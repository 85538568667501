import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useState, useRef, useEffect, useCallback } from "react";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { useDispatch, useSelector } from "react-redux";
import { artistList } from "../../Redux/action/artistList";
import { Modal } from "react-bootstrap";
import {
  contentList,
  contentListFilter,
  deleatContent,
} from "../../Redux/action/contentList";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import Select from "react-select";
import { Button } from "react-bootstrap";
const { SearchBar } = Search;

const defaultSorted = [
  {
    dataField: "id",
    order: "desc",
  },
];
const status = [
  { value: 0, label: "Inactive" },
  { value: 1, label: "Active" },
];
const approvedStatus = [
  { value: "Pending", label: "Pending" },
  { value: "Approved", label: "Approved" },
  { value: "Rejected", label: "Rejected" },
];

const styles = {
  menu: (base) => ({
    ...base,
    marginTop: "4px",
    "font-size": "0.875rem",

    passive: "true",
  }),
  control: (base) => ({
    ...base,
    "font-size": "0.875rem",
  }),
};
function ContentList() {
  const [leadArtist, setLeadArtist] = useState();
  const [associatedArtist, setAssociatedArtist] = useState();
  const [title, setTitle] = useState();
  const [mdShow, setMdShow] = useState(false);
  const [deleteId, setdeleteId] = useState();
  const [statusSelected, setStatusSelected] = useState();
  const [languageOption, setLanguageOption] = useState();
  const dispatch = useDispatch();
  const artistListOption = useSelector(
    (state) => state.ArtistManagementReducer.artist_list
  );
  const contentListOption = useSelector(
    (state) => state.ContentReducer.content_list
  );
  useEffect(() => {
    dispatch(artistList());
    dispatch(contentList());
  }, []);

  const columns = [
    {
      dataField: "title",
      text: "Content Title",
      sort: true,
      style: { padding: "0px 25px  0px 0.9375rem" },
    },
    {
      dataField: "content_artist",
      text: "Associated Artist",
      sort: true,
      style: { padding: "0px 25px  0px 0.9375rem" },
      formatter: (cellContent, row) => { 
        if (row.content_artist){
          return row.content_artist.split(",").join(", "); 
        }
      },
    },
    {
      dataField: "lead_artist",
      text: "Lead Artist",
      sort: true,
      style: { padding: "0px 25px  0px 0.9375rem" },
    },
    {
      dataField: "createdAt",
      text: "Created Date",
      sort: true,
      style: { padding: "0px 25px  0px 0.9375rem" },
      formatter: (cellContent) => {
        return <Moment format="DD/MM/YYYY">{cellContent}</Moment>;
      },
    },
    {
      dataField: "approval_status",
      text: "Approved Status",
      sort: true,
      style: { padding: "0px 25px  0px 0.9375rem" },
      formatter: (cellContent, row) => {
        if (cellContent === "Rejected") {
          return <label className="badge badge-danger">Rejected</label>;
        }
        if (cellContent === "Approved") {
          return <label className="badge badge-success">Approved</label>;
        } else {
          return <label className="badge badge-warning">Pending</label>;
        }
      },
      csvFormatter: (cellContent) => {
        if (cellContent === 1) {
          return "Active";
        } else {
          return "Inactive";
        }
      },
    },

    {
      dataField: "approved_by",
      text: "Approved By",
      sort: false,
      style: { padding: "0px 25px  0px 0.9375rem" },
    },
    {
      dataField: "approval_date",
      text: "Approved Date",
      sort: false,
      style: { padding: "0px 25px  0px 0.9375rem" },
      formatter: (cellContent) => {
        return <Moment format="DD/MM/YYYY">{cellContent}</Moment>;
      },
    },
    {
      dataField: "comment",
      text: "Comments",
      sort: false,
      style: { padding: "0px 25px  0px 0.9375rem" },
    },

    {
      dataField: "action",
      text: "Action",
      sort: false,
      style: { padding: "0px 25px 0px 0.9375rem", width: "16em" },
      formatter: (cellContent, row) => {
        return (
          <div style={{ display: "flex" }}>
            <div>
              <Link className="btn edit-icons" to={`/update-content/${row.id}`}>
                <i class="ti-pencil"></i>
              </Link>
            </div>
            <div>
              <button
                className="btn edit-icons"
                onClick={(e) => {
                  setdeleteId(row.id)
                  setMdShow(true);
                }}
              >
                <i class="ti-trash"></i>
              </button>
            </div>
          </div>
        );
      },
    },
  ];
  const handleFilter = () => {
    var value = {
      status: statusSelected?.value,
      language: languageOption?.value,
      associatedArtist: associatedArtist?.id,
      title: title?.title,
      leadArtist: leadArtist?.id,
    };
    dispatch(contentListFilter(value));
  };
  const resetFilter = () => {
    setLeadArtist("");
    setStatusSelected("");
    setLanguageOption("");
    setTitle("");
    setAssociatedArtist("");
    dispatch(contentList());
  };
  const language = [
    { value: "ar", label: "Arabic" },
    { value: "en", label: "English" },
  ];
  const page = [
    { value: "upcoming_events", label: "Upcoming Events" },
    {
      value: "live_entertainment",
      label: "Live Entertainment Programming & Creative",
    },
    { value: "talent_booking", label: "Talent Booking" },
    { value: "event_design", label: "Event Design & Production" },
    { value: "warehouse", label: "Warehouse" },
  ];
  const Subpage1 = [
    { value: "creative_consultancy", label: "Creative Consultancy" },
    {
      value: "live_entertainment",
      label: "Live Entertainment Programming",
    },
    { value: "our_work", label: "Our Work" },
  ];
  const Subpage2 = [
    { value: "technical", label: "Technical" },
    {
      value: "event_design",
      label: "Event Design",
    },
    { value: "costume_props", label: "Costume & Props" },
    { value: "production", label: "Production" },
  ];
  const Subpage3 = [
    { value: "live_room", label: "Live Room" },
    {
      value: "the_space",
      label: "The Space",
    },
    { value: "gallery", label: "Gallery" },
    { value: "virtual_space", label: "Virtual Space" },
  ];
  const status = [
    { value: 0, label: "Inactive" },
    { value: 1, label: "Active" },
  ];

  return (
    <div>
      <div className="page-header">
        <h3 className="page-title">Content Management</h3>

        <div className="breadcrumb">
          <Link
            className="btn btn-block yellow-btn font-weight-medium auth-form-btn"
            to="/create-content"
          >
            + NEW
          </Link>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
        <div className="row justify-content-end mb-2 mr-1">
            <Button
              className="yellow-btn"
              onClick={(e) => handleFilter()}
            >
              Apply
            </Button>
            <Button
              className="yellow-btn"
              onClick={(e) => resetFilter()}
            >
              Reset
            </Button>
          </div>
          <div className="row mb-2">
            
            <div className="col">
              <Select
                styles={styles}
                maxMenuHeight={100}
                options={language}
                value={languageOption}
                onChange={(e) => {
                  setLanguageOption(e);
                }}
                placeholder="Language"
                // ref={this.artistTypeRef}
              />
            </div>
            <div className="col">
              <Select
                styles={styles}
                maxMenuHeight={100}
                options={artistListOption}
                getOptionLabel={(option) => option.stageName}
                value={leadArtist}
                onChange={(e) => {
                  setLeadArtist(e);
                }}
                placeholder="Lead Artist"
              />
            </div>
            <div className="col">
              <Select
                styles={styles}
                maxMenuHeight={100}
                options={artistListOption}
                getOptionLabel={(option) => option.stageName}
                value={associatedArtist}
                onChange={(e) => {
                  setAssociatedArtist(e);
                }}
                placeholder="Associated Artist"
              />

              <span
                style={{
                  width: "100%",
                  fontSize: "0.875em",
                  color: "#dc3545",
                }}
              ></span>
            </div>
            <div className="col">
              <Select
                styles={styles}
                maxMenuHeight={100}
                options={contentListOption}
                getOptionLabel={(option) => option.title}
                value={title}
                onChange={(e) => {
                  setTitle(e);
                }}
                placeholder="Title"
              />
            </div>

            
          </div>
          
          <div className="card">
            <div className="card-body ">
              <div className="row ">
                <div className="col-12 ">
                  <ToolkitProvider
                    keyField="id"
                    bootstrap4
                    data={contentListOption}
                    columns={columns}
                    search
                    exportCSV={{
                      fileName: "User List.csv",
                    }}
                  >
                    {(props) => (
                      <div style={{ display: "grid" }}>
                        <div className="page-header ">
                          <div className="d-flex align-items-center">
                            <p className="mb-2 mr-2">Search:</p>
                            <SearchBar {...props.searchProps} />{" "}
                          </div>
                        </div>
                        {/* <div className="container"> */}
                        <BootstrapTable
                          defaultSorted={defaultSorted}
                          pagination={paginationFactory()}
                          noDataIndication={() => <h4> No Records Found</h4>}
                          {...props.baseProps}
                          wrapperClasses="table-responsive "
                        />
                        {/* </div> */}
                      </div>
                    )}
                  </ToolkitProvider>
                  <Modal
                    show={mdShow}
                    onHide={() => {
                      setMdShow(false)
                    }}
                    aria-labelledby="example-modal-sizes-title-md"
                  >
                    <Modal.Header closeButton>
                      <Modal.Title
                        style={{ width: "100%", "text-align": "center" }}
                      >
                        Are You Sure, You Want To Delete ?
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Footer
                      className="fleex-wrap"
                      style={{ justifyContent: "center" }}
                    >
                      <Button
                        variant="success m-2 yellow-btn"
                        onClick={(e) => {
                          setMdShow(false)
                          dispatch(deleatContent(deleteId));
                        }}
                      >
                        Yes
                      </Button>
                      <Button
                        variant="light m-2 yellow-btn"
                        onClick={() => {
                          setMdShow(false)
                        }}
                      >
                        Cancel
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContentList;
