import { USER_DETAILS,USER_INFO,USER_DETAILS_RESET } from "../action/actiontypes";
const initialState = {
    usersDetails: [],
    userInfo:{},
  };
  const UserManagementReducer = (state = initialState, action) => {
    switch (action.type) {
      case USER_DETAILS:
        return {
          ...state,
          usersDetails: action.payload,
          userInfo: {},
        };
        case USER_INFO:
        return {
          ...state,
          userInfo: action.payload,
        };
        case USER_DETAILS_RESET:
          return {
            userInfo: action.payload,
          };
      default: return state
    }
  };
  export default UserManagementReducer 