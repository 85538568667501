import React from "react";
import { Form, InputGroup, Col, Button, FormControl } from "react-bootstrap";
import { useState, useRef, useEffect, useCallback } from "react";
import Multiselect from "multiselect-react-dropdown";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { toasterMessage } from "../../utils/toast-util";
import "react-quill/dist/quill.snow.css";

import {
  createRole,
  UpdateRole,
  RoleById,
} from "../../Redux/action/roleManagement";

function CreateRole() {
  const [validated, setValidated] = useState(false);

  const [mobileNumber1, setmobileNumber1] = useState(null);
  const [permission, setPermission] = useState({
    userManagement: {
      name: "User Management",
      view: false,
      edit: false,

      delete: false,
      export: false,
    },
    artistManagement: {
      name: "Artist Management",
      view: false,
      edit: false,

      delete: false,
      add: false,
      export: false,
    },
    enquiryManagement: {
      name: "Enquiry Management",
      view: false,
    },
    contentManagement: {
      name: "Content Management",
      view: false,
      edit: false,

      delete: false,
      add: false,
    },
    websiteManagement: {
      name: "Website Management",
      view: false,
      edit: false,

      delete: false,
      add: false,
    },
    notifications: {
      name: "Notifications",
      view: false,
    },
    categoryManagement: {
      name: "Categories Management",
      view: false,
      edit: false,
      delete: false,
      add: false,
    },
    reportManagement: {
      name: "Report Management",
      view: false,
      export: false,
    },
    metaTagManagement: {
      name: "Meta Tag Management",
      view: false,
      edit: false,
      delete: false,
    },
    newsletter: {
      name: "Newsletter",
      view: false,
      edit: false,

      delete: false,
      add: false,
    },
    // helpandsupport: {
    //   name: "Help & Support",
    //   view: false,
    // },
    supplierManagement: {
      name: "Supplier Management",
      view: false,
      edit: false,

      delete: false,
      add: false,
      export: false,
    },
  });

  const dispatch = useDispatch();
  const history = useHistory();
  const roleRef = useRef(null);
  let keys;
  // const roles = useSelector(
  //   (state) => state.ArtistManagementReducer.specialty_options
  // );

  const role_detail = useSelector(
    (state) => state.RoleManagementReducer.role_detail
  );

  const params = useParams();
  const roles = [
    { id: 1, label: "Admin" },
    { id: 2, label: "Sub Admin" },
  ];
  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(permission);
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    // event.preventDefault();
    setValidated(true);
    if (form.checkValidity() === true) {
      let obj = {
        roleName: event.target.roleName?.value,
        permissions: permission,
      }
      if (params.id) {
        dispatch(UpdateRole(obj, params.id, history));
      } else {
        dispatch(createRole(obj, history));
        console.log(obj);
      }
    }
  };
  useEffect(() => {
    if (params.id) {
      dispatch(RoleById(params.id));
    }
  }, []);

  useEffect(() => {
    console.log("hieeeee", role_detail.permissions);
    // setPermission(role_detail?.permissions)
    if (role_detail.permissions) {
      setPermission(role_detail?.permissions)
    }
  }, [role_detail]);
  const GetPermission = () => {
    var data = Object.keys(permission);
    return data.map((index) => {
      let options = Object.keys(permission[`${index}`]);
      return (
        <div className="row">
          <Form.Label className="col-sm-3 col-form-label ">{permission[`${index}`].name}</Form.Label>
          {options.map((value) => {
            if (value != "name") {
              return (
                <Form.Group className="row  col-sm-2">
                  {console.log(permission[`${index}`].view)}
                  <Form.Check
                    className=""
                    name={value}
                    disabled={
                      value != "view"
                        ? permission[`${index}`].view === false
                          ? true
                          : false
                        : false
                    }
                    defaultChecked={permission[`${index}`][`${value}`]}
                    onClick={(e) => {
                      console.log("value", e.target.checked);
                      var changes = permission;
                      if (value === "view" && e.target.checked === false) {
                        let change = Object.keys(permission[`${index}`]);
                        change.map((e) => {
                          if (e != "name") {
                            setPermission(
                              { ...permission },
                              (permission[`${index}`][`${e}`] = false)
                            );
                          }

                        })
                      } else {
                        if (value === "view" && e.target.checked === true) {
                          let change = Object.keys(permission[`${index}`]);
                          change.map((e) => {
                            if (e != "name") {
                              setPermission(
                                { ...permission },
                                (permission[`${index}`][`${e}`] = true)
                              );
                            }

                          })
                        }
                      }
                      setPermission(
                        { ...permission },
                        (permission[`${index}`][`${value}`] = e.target.checked)
                      );
                    }}
                  />
                  <Form.Label className="col-form-label ml-1">
                    {value}
                  </Form.Label>
                </Form.Group>
              );
            }
          })}
        </div>
      );
    });
  };

  return (
    <>
      <div className="row">
        <h4 className="card-title col-8 d-flex align-items-center">
          {!params.id ? "Create Role" : "Update Role"}
        </h4>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/roles-management">Role Management</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {!params.id ? "Create Role" : "Update Role"}
            </li>
          </ol>
        </nav>
      </div>
      <div className="row">
        <div className="col-md-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <h4 className="card-title col-9 d-flex align-items-center">
                  Role Information
                </h4>
              </div>

              <Form
                className="forms-sample"
                noValidate
                id="form"
                validated={validated}
                onSubmit={(event) => handleSubmit(event)}
              >
                <>
                  <Form.Row>
                    <Form.Group as={Col} md="6" className="row  mr-1">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className=" col-sm-4  col-form-label"
                      >
                        Role Name*
                      </Form.Label>
                      <div className="col-sm-8">
                        <Form.Control
                          type="text"
                          className="form-control"
                          required
                          id="exampleInputUsername2"
                          placeholder="Role Name"
                          name="roleName"
                          defaultValue={role_detail?.roleName}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide Role Name.
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>
                  </Form.Row>
                  <div className="row">
                    <h4 className="card-title col-9 d-flex align-items-center">
                      Permissions Information
                    </h4>
                  </div>
                  <GetPermission />
                </>
                <div className="row">
                  <div className="col-sm-4">
                    <Button type="submit" className="btn yellow-btn mr-2">
                      {params.id ? "Update" : "Create"}
                    </Button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateRole;
