import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { connect } from "react-redux";
import { Modal, Tooltip, OverlayTrigger } from "react-bootstrap";
import {
  getSupplierType
} from "../../Redux/action/categoriesManagement";
import {
  supplierList,
  supplierListFilter,
  deleatSupplier,
  actionSetSupplierType
} from "../../Redux/action/supplierList";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import Select, { components } from "react-select";
import { Button } from "react-bootstrap";
import Multiselect from "multiselect-react-dropdown";
import dp from '../../assets/images/artist.png';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const { SearchBar } = Search;
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const defaultSorted = [
  {
    dataField: "id",
    order: "desc",
  },
];

const MyExportCSV = (props) => {
  const handleClick = () => {
    props.onExport();
  };
  return (
    <div>
      <button className="btn yellow-btn btn-icon-tex" onClick={handleClick}>
        <i className="fa fa-download "></i> Export
      </button>
    </div>
  );
};
const styles = {
  menu: (base) => ({
    ...base,
    marginTop: "4px",
    "font-size": "0.875rem",
    width: "100% ",
    // maxHeight: "100px",
    // overflowY: "initial",
    passive: "true",
  }),
  control: (base) => ({
    ...base,
    "font-size": "0.875rem",
  }),
};
export class SupplierList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: false,
      valid: true,
      supplierType: this.props && this.props.get_supplier_type,
      mdShow: false,
      deleateId: 0,
    };
    this.handleFilter = this.handleFilter.bind(this);
    this.resetFilter = this.resetFilter.bind(this);
    this.supplierRef = React.createRef();
    var show = false;
  }

  componentDidMount() {
    var value = {
      supplierType: this.state.supplierType,
    };
    this.props.getSupplierType();
    this.props.supplierListFilter(value);
  }
  columns = [
    {
      dataField: "id",
      text: "Company ID",
      sort: true,
      style: { padding: "0px 25px 0px 0.9375rem" },
    },
    {
      dataField: "firstName",
      text: "First Name",
      sort: true,
      style: { padding: "0px 25px 0px 0.9375rem" },
    },
    {
      dataField: "email",
      text: "Email Address",
      sort: true,
      style: { padding: "0px 25px 0px 0.9375rem" },
    },
    {
      dataField: "companyName",
      text: "Company Name",
      sort: true,
      style: { padding: "0px 25px 0px 0.9375rem" },
    },
    {
      dataField: "companyServiceArea",
      text: "Company Service Area",
      sort: true,
      style: { padding: "0px 25px 0px 0.9375rem" },
    },
    {
      dataField: "countryHeadquarters",
      text: "Company Headquarter",
      sort: true,
      style: { padding: "0px 25px 0px 0.9375rem" },
    },
    {
      dataField: "supplier_types",
      text: "Supplier Type",
      sort: true,
      style: { padding: "0px 25px 0px 0.9375rem" },
    },
    {
      dataField: "cityHeadquarters",
      text: "City",
      sort: true,
      style: { padding: "0px 25px 0px 0.9375rem" },
    },
    {
      dataField: "createdAt",
      text: "Created Date",
      sort: true,
      style: { padding: "0px 25px 0px 0.9375rem" },
      formatter: (cellContent) => {
        return <Moment format="DD/MM/YYYY">{cellContent}</Moment>;
      },
      csvFormatter: (cellContent) => {
        const today = new Date(cellContent);
        return new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }).format(today);
      },
    },
    {
      dataField: "updatedAt",
      text: "Updated Date",
      sort: true,
      style: { padding: "0px 25px 0px 0.9375rem" },
      formatter: (cellContent) => {
        return <Moment format="DD/MM/YYYY">{cellContent}</Moment>;
      },
      csvFormatter: (cellContent) => {
        const today = new Date(cellContent);
        return new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }).format(today);
      },
    },
  ];
  columns_action = [
    {
      dataField: "id",
      text: "Company ID",
      sort: true,
      style: { padding: "0px 25px 0px 0.9375rem" },
      hidden: true
    },
    {
      dataField: "profile_pic",
      text: "Profile pic",
      sort: true,
      csvExport: false,
      style: { padding: "0px 25px 0px 0.9375rem" },
      formatter: (cellContent) => {
        // let profilePic = process.env.REACT_APP_IMAGE_LINK + cellContent;
        if (cellContent) {
          return (
            <img
              src={cellContent.includes("https") ? cellContent : dp}
              alt="User Avatar"
              style={{ width: "50px", height: "50px" }}
            />
          );
        } else {
          return (
            <img
              src={dp}
              alt="Default Avatar"
              style={{ width: "50px", height: "50px" }}
            />
          );
        }
      },
    },
    {
      dataField: "firstName",
      text: "First Name",
      sort: true,
      style: { padding: "10px 50px 10px 0.9375rem" },
      formatter: (cellContent, row) => {
        return (
          <Link to={{ pathname: `/view-supplier/${row.id}`, state: { editFlag: false } }} style={{ color: 'black' }} >
            {cellContent}
          </Link>
        );
      },
    },
    {
      dataField: "email",
      text: "Email Address",
      sort: true,
      style: { padding: "10px 50px 10px 0.9375rem" },
    },
    {
      dataField: "companyName",
      text: "Company Name",
      sort: true,
      style: { padding: "10px 50px 10px 0.9375rem" },
    },
    {
      dataField: "companyServiceArea",
      text: "Company Service Area",
      sort: true,
      style: { padding: "10px 50px 10px 0.9375rem" },
    },
    {
      dataField: "countryHeadquarters",
      text: "Company Headquarter",
      sort: true,
      style: { padding: "10px 50px 10px 0.9375rem" },
    },
    {
      dataField: "cityHeadquarters",
      text: "City",
      sort: true,
      style: { padding: "10px 50px 10px 0.9375rem" },
    },
    {
      dataField: "supplier_types",
      text: "Supplier Type",
      sort: true,
      style: { padding: "10px 50px 10px 0.9375rem" },
    },
    {
      dataField: "createdAt",
      text: "Created Date",
      sort: true,
      hidden: true,
      style: { padding: "0px 25px 0px 0.9375rem" },
      formatter: (cellContent) => {
        return <Moment format="DD/MM/YYYY">{cellContent}</Moment>;
      },
      csvFormatter: (cellContent) => {
        const today = new Date(cellContent);
        return new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }).format(today);
      },
    },
    {
      dataField: "updatedAt",
      text: "Updated Date",
      sort: true,
      hidden: true,
      style: { padding: "0px 25px 0px 0.9375rem" },
      formatter: (cellContent) => {
        return <Moment format="DD/MM/YYYY">{cellContent}</Moment>;
      },
      csvFormatter: (cellContent) => {
        const today = new Date(cellContent);
        return new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }).format(today);
      },
    },
    {
      dataField: "action",
      text: "Action",
      sort: false,
      style: { padding: "0px 25px 0px 0.9375rem", width: "16em" },
      csvExport: false,
      formatter: (cellContent, row) => {
        return (
          <div style={{ display: "flex" }}>
            <div>
              {this.props.permissions.supplierManagement.view ? (
                <Link className="btn edit-icons" to={{ pathname: `/view-supplier/${row.id}`, state: { editFlag: false } }} >
                  <OverlayTrigger
                    key="top"
                    placement="top"
                    overlay={<Tooltip id="tooltip">View Details</Tooltip>}
                  >
                    <i class="ti-eye"></i>
                  </OverlayTrigger>
                </Link>
              ) : null}
            </div>
            <div>
              {this.props.permissions.supplierManagement.edit ? (
                <Link className="btn edit-icons" to={{ pathname: `/update-supplier/${row.id}`, state: { editFlag: true } }} >
                  <OverlayTrigger
                    key="top"
                    placement="top"
                    overlay={<Tooltip id="tooltip">Edit Details</Tooltip>}
                  >
                    <i class="ti-pencil"></i>
                  </OverlayTrigger>
                </Link>
              ) : null}
            </div>

            {
              this.props.permissions.supplierManagement.delete ? (
                <div>
                  <button
                    className="btn edit-icons"
                    onClick={(e) => {
                      this.show = true;
                      this.setState({ mdShow: true, deleateId: row.id });
                    }}
                  >
                    <OverlayTrigger
                      key="top"
                      placement="top"
                      overlay={<Tooltip id="tooltip">Delete Details</Tooltip>}
                    >
                      <i class="ti-trash"></i>
                    </OverlayTrigger>
                  </button>
                </div>
              ) : null
            }
          </div >
        );
      },
    },
  ];

  handleFilter() {
    var value = {
      supplierType: this.state.supplierType,
    };
    if (
      this.state.supplierType
    ) {
      this.props.supplierListFilter(value);
    }
  }
  resetFilter() {
    this.supplierRef && this.supplierRef.current && this.supplierRef.current.resetSelectedValues();
    this.props.supplierList();
    this.props.actionSetSupplierType('');
    this.setState({
      supplierType: [],
    });
  }

  render() {
    return (
      <div>
        <div className="page-header">
          <h3 className="page-title">Supplier Management</h3>

          <div className="breadcrumb">
            {this.props.permissions.supplierManagement?.add ?
              <Link
                className="btn btn-block yellow-btn font-weight-medium auth-form-btn"
                to={{ pathname: "/add-supplier", state: { editFlag: true } }}
              >
                + NEW
              </Link>
              : null}
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="row justify-content-end mb-2 mr-1">
              <div className="col-sm-6 col-md-4 col-lg-4">
                <Multiselect
                  options={this.props.supplierTypeList}
                  customCloseIcon={
                    <i class="typcn typcn-delete ml-1"></i>
                  }
                  className="centered-placeholder"
                  selectedValues={this.props.supplierTypeList.filter((option) => this.state.supplierType.includes(option.id))}
                  showCheckbox={true}
                  hidePlaceholder={true}
                  displayValue={"name" || null}
                  showArrow
                  style={{
                    searchBox: { // To change search box element look
                      background: 'white',
                      height: "40px",
                      overflow: 'auto'
                    },
                    chips: {
                      background: '#F6F4ED',
                      color: 'black',
                      maxWidth: 'calc(100% - 32px)', // Reduce the max width of chips to fit within the search box
                      overflow: 'hidden', // Hide the overflow content
                      whiteSpace: 'nowrap', // Prevent wrapping of long names
                      textOverflow: 'ellipsis', // Show ellipsis for long names
                    },
                    options: {
                      "fontSize": "0.875rem",
                    },
                    "fontSize": "0.875rem",
                  }}
                  ref={this.supplierRef}
                  onSelect={(s) => {
                    let arrSupplier = [];
                    s.map((t, i) => {
                      arrSupplier.push(t.id)
                    })
                    this.setState({ supplierType: arrSupplier })
                    this.props.actionSetSupplierType(arrSupplier)
                  }}
                  onRemove={(r) => {
                    let arrSupplier = [];
                    if (r) {
                      r.map((t) => {
                        arrSupplier.push(t.id)
                      })
                    }
                    this.setState({ supplierType: arrSupplier })
                    this.props.actionSetSupplierType(arrSupplier)
                  }}
                  placeholder="Supplier Type"
                />
              </div>
              <Button
                className=" yellow-btn "
                onClick={(e) => this.handleFilter()}
              >
                Apply
              </Button>
              <Button
                className=" yellow-btn"
                onClick={(e) => this.resetFilter()}
              >
                Reset
              </Button>
            </div>
            <div className="row mb-2">
            </div>
            <Modal
              show={this.show}
              onHide={() => {
                this.setState({ mdShow: false });
                this.show = false;
              }}
              aria-labelledby="example-modal-sizes-title-md"
            >
              <Modal.Header closeButton>
                <Modal.Title style={{ width: "100%", "text-align": "center" }}>
                  Are You Sure, You Want To Delete ?
                </Modal.Title>
              </Modal.Header>
              <Modal.Footer
                className="fleex-wrap"
                style={{ justifyContent: "center" }}
              >
                <Button
                  variant="success m-2 yellow-btn"
                  onClick={(e) => {
                    this.props.deleatSupplier(this.state.deleateId);
                    this.show = false;
                    this.setState({ mdShow: false });
                  }}
                >
                  Yes
                </Button>
                <Button
                  variant="light m-2 yellow-btn"
                  onClick={() => {
                    this.setState({ mdShow: false });
                    this.show = false;
                  }}
                >
                  Cancel
                </Button>
              </Modal.Footer>
            </Modal>
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-12">
                    <ToolkitProvider
                      keyField="id"
                      bootstrap4
                      data={this.props.supplier_list || []}
                      columns={
                        this.props.permissions.supplierManagement?.edit ||
                          this.props.permissions.supplierManagement?.delete
                          ? this.columns_action
                          : this.columns
                      }
                      search
                      exportCSV={{
                        fileName: "Supplier List.csv",
                      }}
                    >
                      {(props) => (
                        <div style={{ display: "grid" }}>
                          <div className="page-header">
                            <div className="d-flex align-items-center">
                              <p className="mb-2 mr-2">Search:</p>
                              <SearchBar {...props.searchProps} />{" "}
                            </div>
                            {this.props.permissions.supplierManagement.export ?
                              <MyExportCSV {...props.csvProps} />
                              : null}
                          </div>
                          {/* <div className="container"> */}
                          <BootstrapTable
                            defaultSorted={defaultSorted}
                            pagination={paginationFactory()}
                            noDataIndication={() => (
                              <div className="row">
                                <div className="col-sm-12 text-center">
                                  <h4 className="text-center">
                                    {" "}
                                    No Records Found
                                  </h4>
                                </div>
                              </div>
                            )}
                            {...props.baseProps}
                            wrapperClasses="table-responsive"
                          />
                          {/* </div> */}
                        </div>
                      )}
                    </ToolkitProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    );
  }
}

function mapStateToProps(state) {
  return {
    usersDetails: state.UserManagementReducer.usersDetails,
    specialityList: state.ArtistManagementReducer.specialty_options,
    artistTypeList: state.CategoriesManagementReducer.artistTypeList,
    supplierTypeList: state.CategoriesManagementReducer.supplierTypeList,
    artist_list: state.ArtistManagementReducer.artist_list,
    supplier_list: state.SupplierManagementReducer.supplier_list,
    genres: state.ArtistManagementReducer.genres_options,
    tagList: state.CategoriesManagementReducer.tagList,
    permissions: state.PermissionReducer.permissionDetails,
    get_supplier_type: state.SupplierManagementReducer.get_supplier_type,
  };
}

export default connect(mapStateToProps, {
  supplierListFilter,
  deleatSupplier,
  supplierList,
  getSupplierType,
  actionSetSupplierType
})(SupplierList);
