import { NEWSLETTER_LIST } from "../action/actiontypes";
const initialState = {
    list:[],
  data: {},
  };
  const NewsletterReducer = (state = initialState, action) => {
    switch (action.type) {
      case NEWSLETTER_LIST:
        return {
            list: action.payload,
        };
        
      default: return state
    }
  };
  export default NewsletterReducer 