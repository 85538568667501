import {
  ARTIST_PROFILE_DETAIL,
  OPTION_LISTS,
  ARTIST_OTHER_DETAILS,
  ARTIST_POST_DETAILS,
  ARTIST_NOTIFICATION,
  ARTIST_DASHBOARD,
} from "../action/actiontypes";
const initialState = {
  tagList: [],
  tagdetails: {},
  specialty_options: [],
  genres_options: [],
  artist_other_details: {},
  artist_detail: {},
  notifications: [],
  dashborad:{},
  artist_post: {},
};
const ArtistPortalReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPTION_LISTS:
      return {
        ...state,
        specialty_options: action.payload.specialities,
        genres_options: action.payload.genres,
      };

    case ARTIST_PROFILE_DETAIL:
      return {
        ...state,
        artist_detail: action.payload,
      };
    case ARTIST_POST_DETAILS:
      return {
        ...state,
        artist_post: action.payload,
      };
    case ARTIST_OTHER_DETAILS:
      return {
        ...state,
        artist_other_details: action.payload,
      };
    case ARTIST_NOTIFICATION:
      return {
        ...state,
        notifications: action.payload,
      };
      case ARTIST_DASHBOARD:
      return {
        ...state,
        dashborad: action.payload,
      };
    default:
      return state;
  }
};
export default ArtistPortalReducer;
