import React, { Component, useState } from "react";
import { Button } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { toasterMessage } from "../../utils/toast-util";
import { useDispatch, useSelector } from "react-redux";
import { supplierReset } from "../../Redux/action/supplierLogin";
import { useParams } from "react-router-dom";
function SupplierReset() {
  const history = useHistory();
  const [password, setPassword] = useState(null);
  const [password2, setPassword2] = useState(null);
  const [view1, setView1] = useState(false);
  const [view2, setView2] = useState(false);
  const params = useParams();
  const dispatch = useDispatch();
  const [mdShow, setMdShow] = useState(false);
  const handleChange = (e, type) => {
    if (type == 1) {
      setPassword(e.target.value);
    } else {
      setPassword2(e.target.value);
    }
  };
  const handleSubmit = () => {
    var validated = false;
    if (
      !/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(
        password
      )
    ) {
      toasterMessage(
        "error",
        "Please add minimum eight , at least one uppercase letter, one lowercase letter, one number and one special character"
      );
    } else {
      if (password === password2) {
        validated = true;
      } else {
        toasterMessage("error", "Password and Confirm Password doesn't match");
      }
    }

    if (validated) {
      console.log("works");
      var obj = {
        token: params.id,
        password: password,
      };
      dispatch(supplierReset(obj));
      history.push('/supplier-login');
      // setMdShow(true);
    }
  };
  return (
    <div>
      <div className="d-flex align-items-stretch auth auth-img-bg h-100">
        <div className="row flex-grow">
          <div className="col-lg-5 register-half-bg">

            <div className="auth-form-transparent text-left d-flex side_area ">
              <div className="">
                <div className="brand-logo-forget">
                  <img
                    src={require("../../assets/images/logo.svg")}
                    alt="logo"
                  />
                </div>
                {/* <h2>Lorem ipsum dolor sit amet consectetur</h2>
                <h5 className="font-weight-light">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
                  vulputate libero et velit interdum, ac aliquet odio mattis.
                </h5> */}
              </div>

            </div>
            <div className="bottom_text">
              <h6>Terms & Conditions Privacy Policy</h6>
              <h6>copyrigtht@ 2022. All rights reserved</h6>
            </div>
          </div>
          <div className="col-lg-6 d-flex align-items-center justify-content-center">
            <div className="auth-form-transparent text-left ">
              {/* <div className="brand-logo">
                <img src={require("../../assets/images/logo.svg")} alt="logo" />
              </div> */}
              <h2 className="font-weight-bold">Reset Password</h2>
              {/* <h5 className="font-weight-light">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </h5> */}
              <form className="pt-3">
                {/* <div className="form-group">
                    <label>Username</label>
                    <div className="input-group">
                      <div className="input-group-prepend bg-transparent">
                        <span className="input-group-text bg-transparent border-right-0">
                          <i className="mdi mdi-account-outline text-primary"></i>
                        </span>
                      </div>
                      <input type="text" className="form-control form-control-lg border-left-0" placeholder="Username" />
                    </div>
                  </div> */}
                <div className="form-group">
                  <h5>New Password*</h5>
                  <div className="input-group">
                    <input
                      type={view1 ? "text" : "password"}
                      className="form-control form-control-lg border-right-0"
                      id="exampleInputPassword"
                      placeholder="Enter new password*"
                      onChange={(e) => handleChange(e, 1)}
                      value={password}
                    />
                    <div
                      className="input-group-prepend bg-transparent"
                      onClick={() => setView1(!view1)}
                    >
                      <span className="input-group-text bg-transparent border-left-0">
                        <i
                          className={`fa ${view1 ? "fa-eye-slash" : "fa-eye"}`}
                        ></i>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <h5>Confirm Password*</h5>
                  <div className="input-group">
                    <input
                      type={view2 ? "text" : "password"}
                      className="form-control form-control-lg border-right-0"
                      id="exampleInputPassword"
                      placeholder="Re-enter new password *"
                      onChange={(e) => handleChange(e, 2)}
                      value={password2}
                    />
                    <div
                      className="input-group-prepend bg-transparent"
                      onClick={() => setView2(!view2)}
                    >
                      <span className="input-group-text bg-transparent border-left-0">
                        <i
                          className={`fa ${view2 ? "fa-eye-slash" : "fa-eye"}`}
                        ></i>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="mt-3">
                  <Button
                    className="btn btn-block btn-lg font-weight-medium yellow-btn btn-icon-tex"
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    Reset
                  </Button>
                </div>
              </form>
              <Modal
                size="md"
                show={mdShow}
                onHide={() => {
                  setMdShow(false);
                }}
                aria-labelledby="example-modal-sizes-title-md"
              >
                <Modal.Header closeButton>
                  <Modal.Title
                    style={{
                      width: "100%",
                      "text-align": "center",
                      "border-bottom": "1px solid  #86878880",
                    }}
                  >
                    <div className="brand-logo p-4">
                      <img
                        src={require("../../assets/images/mail.png")}
                        alt="logo"
                      />
                    </div>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body
                  className="fleex-wrap"
                  style={{ "text-align": "center" }}
                >
                  <p className="font-weight-bold">
                    A verification link will be sent to your registered email
                    address.
                  </p>
                </Modal.Body>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SupplierReset;
