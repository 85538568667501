import axios from "axios";
import { toasterMessage } from "../../utils/toast-util";

export const newsletterLists = () => {
    return async (dispatch) => {
      try {
        const response = await axios({
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          url: process.env.REACT_APP_BACKEND_API + `admin/newsletter`,
        });
        const { data } = response;
        if (data.status) {
          await dispatch({
            type: "NEWSLETTER_LIST",
            payload: data.result.data,
          });
        } else {
          toasterMessage("error", data.result.message);
        }
      } catch (error) {
        if (error.reponse) {
          console.log("error");
          toasterMessage("error", "Something went Wrong");
        }
      }
    };
  };
  export const newsletterDelete = (id) => {
    var dataSend ={
      active_status:0,
    }
    return async (dispatch) => {
      try {
        const response = await axios({
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          url: process.env.REACT_APP_BACKEND_API + `admin/newsletter/${id}`,
          data: dataSend,
        });
        const { data } = response;
        if (data.status) {
          await dispatch(newsletterLists());
        } else {
          toasterMessage("error", data.result.message);
        }
      } catch (error) {
        if (error.reponse) {
          console.log("error");
          toasterMessage("error", "Something went Wrong");
        }
      }
    };
  };