import axios from "axios";
import { toasterMessage } from "../../utils/toast-util"
export function typeDetail(id) {
  return async (dispatch) => {
    try {
      const response = await axios({
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + (localStorage.getItem("jwt")),
        },
        url: process.env.REACT_APP_BACKEND_API + `admin/artist-type/${id}`,

      });
      const { data } = response;
      if (data.status) {
        await dispatch({
          type: "ARTIST_TYPE_DETAILS",
          payload: data.result.data,
        });
      } else {
        toasterMessage("error", data.result.message);
      }
    } catch (error) {
      if (error.reponse) {
        console.log("error");
      }
    }
  };
};
export const getArtistType = (value) => {
  return async (dispatch) => {
    try {
      const response = await axios({
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + (localStorage.getItem("jwt")),
        },
        url: process.env.REACT_APP_BACKEND_API + `admin/artist-type `,

      });
      const { data } = response;
      if (data.status) {
        await dispatch({
          type: "ARTIST_TYPE_LISTS",
          payload: data.result.data,
        });
      }
    } catch (error) {
      if (error.reponse) {
        console.log("error");
      }
    }
  };
};
export const artistTypeIdUpdate = (id, value, history) => {
  return async (dispatch) => {
    try {
      const response = await axios({
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + (localStorage.getItem("jwt")),
        },
        url: process.env.REACT_APP_BACKEND_API + `admin/artist-type/${id}`,
        data: value,

      });
      const { data } = response;
      if (data.status) {
        toasterMessage("success", data.result.message);
        history.push("/categories-management");
      } else {
        toasterMessage("error", data.result.message);
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response);
        toasterMessage("error", error.response.data.result.message);
      }
    }
  };
};
export const artistTypeIdCreate = (value, history) => {
  return async (dispatch) => {
    try {
      const response = await axios({
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + (localStorage.getItem("jwt")),
        },
        url: process.env.REACT_APP_BACKEND_API + `admin/artist-type`,
        data: value,

      });
      const { data } = response;
      if (data.status) {
        toasterMessage("success", data.result.message);
        history.push("/categories-management");
      } else {
        toasterMessage("error", data.result.message);
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response);
        toasterMessage("error", error.response.data.result.message);
      }
    }
  };
};
export const deleatArtistType = (id) => {
  return async (dispatch) => {
    try {
      const response = await axios({
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + (localStorage.getItem("jwt")),
        },
        url: process.env.REACT_APP_BACKEND_API + `admin/artist-type/${id}`,

      });
      const { data } = response;
      if (data.status) {

        toasterMessage("success", data.result.message);
        await dispatch(getArtistType());
        // getAllUsers();
      } else {
        toasterMessage("error", data.result.message);
      }
    } catch (error) {
      if (error.reponse) {
        console.log("error");

      }
      toasterMessage("error", error.response.data.result.message);
    }
  };
};

//Speciality Management

export const getSpecialities = (value) => {
  return async (dispatch) => {
    try {
      const response = await axios({
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + (localStorage.getItem("jwt")),
        },
        url: process.env.REACT_APP_BACKEND_API + `admin/speciality `,

      });
      const { data } = response;
      if (data.status) {
        await dispatch({
          type: "SPECIALITY_LISTS",
          payload: data.result.data,
        });
      }
    } catch (error) {
      if (error.reponse) {
        console.log("error");
      }
    }
  };
};

export function specialtyDetail(id) {
  return async (dispatch) => {
    try {
      const response = await axios({
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + (localStorage.getItem("jwt")),
        },
        url: process.env.REACT_APP_BACKEND_API + `admin/speciality/${id}`,

      });
      const { data } = response;
      if (data.status) {
        await dispatch({
          type: "SPECIALITY_DETAILS",
          payload: data.result.data,
        });
      } else {
        toasterMessage("error", data.result.message);
      }
    } catch (error) {
      if (error.reponse) {
        console.log("error");
      }
    }
  };
};

export const specialtyCreate = (value, history) => {
  return async (dispatch) => {
    try {
      const response = await axios({
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + (localStorage.getItem("jwt")),
        },
        url: process.env.REACT_APP_BACKEND_API + `admin/speciality`,
        data: value,

      });
      const { data } = response;
      if (data.status) {
        toasterMessage("success", data.result.message);
        history.push("/categories-management");
      } else {
        toasterMessage("error", data.result.message);
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response);
        toasterMessage("error", error.response.data.result.message);
      }
    }
  };
};

export const specialtyUpdate = (id, value, history) => {
  return async (dispatch) => {
    try {
      const response = await axios({
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + (localStorage.getItem("jwt")),
        },
        url: process.env.REACT_APP_BACKEND_API + `admin/speciality/${id}`,
        data: value,

      });
      const { data } = response;
      if (data.status) {
        toasterMessage("success", data.result.message);
        history.push("/categories-management");
      } else {
        toasterMessage("error", data.result.message);
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response);
        toasterMessage("error", error.response.data.result.message);
      }
    }
  };
};

export const deleatSpecialty = (id) => {
  return async (dispatch) => {
    try {
      const response = await axios({
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + (localStorage.getItem("jwt")),
        },
        url: process.env.REACT_APP_BACKEND_API + `admin/speciality/${id}`,

      });
      const { data } = response;
      if (data.status) {

        toasterMessage("success", data.result.message);
        await dispatch(getSpecialities());
      } else {
        toasterMessage("error", data.result.message);
      }
    } catch (error) {
      if (error.reponse) {
        console.log("error");
      }
      toasterMessage("error", error.response.data.result.message);
    }
  };
};

//Genre Management

export const Getgenre = (value) => {
  return async (dispatch) => {
    try {
      const response = await axios({
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + (localStorage.getItem("jwt")),
        },
        url: process.env.REACT_APP_BACKEND_API + `admin/genre `,

      });
      const { data } = response;
      if (data.status) {

        await dispatch({
          type: "GENRES_LISTS",
          payload: data.result.data,
        });
      }
    } catch (error) {
      if (error.reponse) {
        console.log("error");
      }
    }
  };
};

export function genreDetail(id) {
  return async (dispatch) => {
    try {
      const response = await axios({
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + (localStorage.getItem("jwt")),
        },
        url: process.env.REACT_APP_BACKEND_API + `admin/genre/${id}`,

      });
      const { data } = response;
      if (data.status) {
        await dispatch({
          type: "GENRE_DETAILS",
          payload: data.result.data,
        });
      } else {
        toasterMessage("error", data.result.message);
      }
    } catch (error) {
      if (error.reponse) {
        console.log("error");
      }
    }
  };
};

export const genreCreate = (value, history) => {

  return async (dispatch) => {
    try {
      const response = await axios({
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + (localStorage.getItem("jwt")),
        },
        url: process.env.REACT_APP_BACKEND_API + `admin/genre`,
        data: value,

      });
      const { data } = response;
      if (data.status) {
        toasterMessage("success", data.result.message);
        history.push("/categories-management");
      } else {
        toasterMessage("error", data.result.message);
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response);
        toasterMessage("error", error.response.data.result.message);
      }
    }
  };
};

export const genreUpdate = (id, value, history) => {
  return async (dispatch) => {
    try {
      const response = await axios({
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + (localStorage.getItem("jwt")),
        },
        url: process.env.REACT_APP_BACKEND_API + `admin/genre/${id}`,
        data: value,

      });
      const { data } = response;
      if (data.status) {
        toasterMessage("success", data.result.message);
        history.push("/categories-management");
      } else {
        toasterMessage("error", data.result.message);
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response);
        toasterMessage("error", error.response.data.result.message);
      }
    }
  };
};

export const deleatGenre = (id) => {
  return async (dispatch) => {
    try {
      const response = await axios({
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + (localStorage.getItem("jwt")),
        },
        url: process.env.REACT_APP_BACKEND_API + `admin/genre/${id}`,

      });
      const { data } = response;
      if (data.status) {
        toasterMessage("success", data.result.message);
        await dispatch(Getgenre());

      } else {
        toasterMessage("error", data.result.message);
      }
    } catch (error) {
      if (error.reponse) {
        console.log("error");
      }
      toasterMessage("error", error.response.data.result.message);
    }
  };
};

// Tag Management

export const getTagList = (value) => {
  return async (dispatch) => {
    try {
      const response = await axios({
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + (localStorage.getItem("jwt")),
        },
        url: process.env.REACT_APP_BACKEND_API + `admin/tag `,

      });
      const { data } = response;
      if (data.status) {

        await dispatch({
          type: "TAG_LISTS",
          payload: data.result.data,
        });
      }
    } catch (error) {
      if (error.reponse) {
        console.log("error");
      }
    }
  };
};

export function tagDetail(id) {
  return async (dispatch) => {
    try {
      const response = await axios({
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + (localStorage.getItem("jwt")),
        },
        url: process.env.REACT_APP_BACKEND_API + `admin/tag/${id}`,

      });
      const { data } = response;
      if (data.status) {
        await dispatch({
          type: "TAG_DETAILS",
          payload: data.result.data,
        });
      } else {
        toasterMessage("error", data.result.message);
      }
    } catch (error) {
      if (error.reponse) {
        console.log("error");
      }
    }
  };
};

export const tagIdUpdate = (id, value, history) => {
  return async (dispatch) => {
    try {
      const response = await axios({
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + (localStorage.getItem("jwt")),
        },
        url: process.env.REACT_APP_BACKEND_API + `admin/tag/${id}`,
        data: value,

      });
      const { data } = response;
      if (data.status) {
        toasterMessage("success", data.result.message);
        history.push("/categories-management");
      } else {
        toasterMessage("error", data.result.message);
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response);
        toasterMessage("error", error.response.data.result.message);
      }
    }
  };
};

export const tagCreate = (value, history) => {
  return async (dispatch) => {
    try {
      const response = await axios({
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + (localStorage.getItem("jwt")),
        },
        url: process.env.REACT_APP_BACKEND_API + `admin/tag`,
        data: value,

      });
      const { data } = response;
      if (data.status) {
        toasterMessage("success", data.result.message);
        history.push("/categories-management");
      } else {
        toasterMessage("error", data.result.message);
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response);
        toasterMessage("error", error.response.data.result.message);
      }
    }
  };
};

export const deleatTag = (id) => {
  return async (dispatch) => {
    try {
      const response = await axios({
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + (localStorage.getItem("jwt")),
        },
        url: process.env.REACT_APP_BACKEND_API + `admin/tag/${id}`,

      });
      const { data } = response;
      if (data.status) {

        toasterMessage("success", data.result.message);
        await dispatch(getTagList());
      } else {
        toasterMessage("error", data.result.message);
      }
    } catch (error) {
      if (error.reponse) {
        console.log("error");

      }
      toasterMessage("error", error.response.data.result.message);
    }
  };
};

//Supplier Type
export const getSupplierType = (value) => {
  return async (dispatch) => {
    try {
      const response = await axios({
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + (localStorage.getItem("jwt")),
        },
        url: process.env.REACT_APP_BACKEND_API + `admin/supplier-type `,

      });
      const { data } = response;
      if (data.status) {
        await dispatch({
          type: "SUPPLIER_TYPE_LISTS",
          payload: data.result.data,
        });
      }
    } catch (error) {
      if (error.reponse) {
        console.log("error");
      }
    }
  };
};