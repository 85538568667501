import React from "react";
import { Form, InputGroup, Col, Button, FormControl } from "react-bootstrap";
import { useState, useRef, useEffect, useCallback } from "react";
import Multiselect from "multiselect-react-dropdown";
import { Editor } from "@tinymce/tinymce-react";
import ReactQuill from "react-quill";
import "../../../assets/styles/global.scss";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import ImageUploading from "react-images-uploading";
import { useDropzone } from "react-dropzone";
import { Link, useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { toasterMessage } from "../../../utils/toast-util";
import "react-quill/dist/quill.snow.css";
import CropEasy from "../../../crop/CropEasy";
import {
  createPage,
  UpdatePage,
  pageById,
} from "../../../Redux/action/pageDetail";
import { pageLayoutList } from "../../../Redux/action/cms";

function SpotLight({
  page_detail,
  subpageSelected,
  languageSetected,
  pageSelected,
}) {
  const DEFAULT_ORDER = 0;
  const [openCrop, setOpenCrop] = useState(false);
  const [quillText, setQuillText] = useState();
  const [image, setImage] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [description, setDescription] = useState("");
  const [videodescription, setVideodescription] = useState("");
  const [openCopyCrop, setOpenCopyCrop] = useState(false);
  const [validated, setValidated] = useState(false);
  const [publish, setPublish] = useState(false);
  const [publishVideo, setPublishvideo] = useState(false);
  const [postImageArray, setPostImageArray] = useState([]);
  const [postVideoArray, setPostVideoArray] = useState([]);
  const [videoLink, setVideoLink] = useState(null);
  const [deleatImage, setDeleatImage] = useState([]);
  const [deleatVideo, setDeleatVideo] = useState([]);

  const [postImageDetails, setPostImageDetails] = useState({});
  const [typeError, setTypeError] = useState(null);

  const dispatch = useDispatch();
  const history = useHistory();

  const pageListOptions = useSelector(
    (state) => state.CmsReducer.page_layout_list
  );
  const params = useParams();
  const language = [
    { value: "ar", label: "Arabic" },
    { value: "en", label: "English" },
  ];
  const Layout1 = [
    { value: "hero_image", label: "Hero" },
    { value: "banner", label: "Banner" },
    {
      value: "what_we_can_do",
      label: "What We can do",
    },
    {
      value: "what_makes_us_different",
      label: "What Makes Us Different",
    },
    { value: "our_work", label: "Our Work" },
  ];
  const Layout2 = [
    { value: "hero_image", label: "Hero" },
    { value: "banner", label: "Banner" },
    {
      value: "what_we_can_do",
      label: "What We can do",
    },
    {
      value: "what_makes_us_different",
      label: "What Makes Us Different",
    },
    { value: "our_work", label: "Our Work" },
  ];
  const Layout3 = [
    { value: "banner", label: "Banner" },
    {
      value: "event_tile",
      label: "Event Tile",
    },
    { value: "carousel", label: "Carousel" },
  ];

  const Layout4 = [
    { value: "a_venu", label: "A Venu" },
    { value: "hero_image", label: "Hero" },
    {
      value: "changing_tiles",
      label: "Changing Tiles",
    },
    {
      value: "Banner",
      label: "Banner",
    },
    { value: "gallery", label: "Gallery" },
  ];
  const Layout5 = [
    { value: "carousel", label: "Carousel" },
    { value: "hero_image", label: "Hero" },
    {
      value: "changing_tiles",
      label: "Changing Tiles",
    },
    {
      value: "Banner",
      label: "Banner",
    },
    { value: "spotlight", label: "Spotlight" },
  ];
  const Layout6 = [
    {
      value: "tiles",
      label: "Tiles",
    },
  ];
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (languageSetected) {
      // setTypeError(false);
    } else {
      toasterMessage("error", "Please select language");
    }
    if (pageSelected) {
    } else {
      toasterMessage("error", "Please select page");
    }
    console.log("subpageSelected", subpageSelected);
    if (subpageSelected) {
    } else {
      toasterMessage("error", "Please select Sub Section");
    }

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    // if (){

    // }
    event.preventDefault();
    setValidated(true);
    if (
      form.checkValidity() === true &&
      pageSelected &&
      languageSetected &&
      subpageSelected
    ) {
      let formData = new FormData();
      formData.append("lang", languageSetected?.value);
      formData.append("pageNameId", pageSelected.id);
      formData.append("order", event.target.order?.value);
      formData.append("subPage", subpageSelected?.value);
      formData.append("title", event.target.title?.value);
      formData.append("subTitle", "");
      formData.append("description", quillText ? quillText : "");
      formData.append("link", event.target.link?.value);
      formData.append("active_status", event.target.status.checked ? 1 : 0);
      if (deleatImage.length > 0) {
        for (var x = 0; x < deleatImage.length; x++) {
          formData.append("delete_images_array", deleatImage[x]);
        }
      }
      if (deleatVideo.length > 0) {
        for (var x = 0; x < deleatVideo.length; x++) {
          formData.append("delete_videos_array", deleatVideo[x]);
        }
      }
      console.log(postImageArray);
      if (postImageArray.length > 0) {
        for (var x = 0; x < postImageArray.length; x++) {
          if (postImageArray[x]?.isNew) {
            formData.append("page_images", postImageArray[x].file);
            formData.append("new_images_desc", postImageArray[x].media_desc);
            formData.append(
              "new_images_status",
              postImageArray[x].active_status ? 1 : 0
            );
            formData.append("new_banner_order", DEFAULT_ORDER);
          } else {
            formData.append("prev_images_id", postImageArray[x].id);
            formData.append(
              "prev_images_status",
              postImageArray[x].active_status ? 1 : 0
            );
            formData.append("prev_banner_order", DEFAULT_ORDER);
            // formData.append("page_images", null);
          }
        }
      }
      if (postVideoArray.length > 0) {
        for (var x = 0; x < postVideoArray.length; x++) {
          if (postVideoArray[x]?.isNew) {
            formData.append("new_videos_link", postVideoArray[x].media_link);
            formData.append("new_videos_desc", postVideoArray[x].media_desc);
            formData.append(
              "new_videos_status",
              postVideoArray[x].active_status ? 1 : 0
            );
            formData.append("new_video_order", DEFAULT_ORDER);
          } else {
            formData.append("prev_videos_id", postVideoArray[x].id);
            formData.append(
              "prev_videos_status",
              postVideoArray[x].active_status ? 1 : 0
            );
            formData.append("prev_video_order", DEFAULT_ORDER);
            // formData.append("new_videos_link ", null);
          }
        }
      } else {
        formData.append("new_videos_link ", null);
      }
      if (params.id) {
        dispatch(UpdatePage(formData, params.id, history));
      } else {
        dispatch(createPage(formData, history));
      }
    }
  };
  useEffect(() => {
    if (params.id) {
      dispatch(pageById(params.id));
    }
    dispatch(pageLayoutList());
  }, []);

  useEffect(() => {
    if (page_detail.images?.length > 0) {
      setPostImageArray(page_detail.images);
    }
    if (page_detail.videos?.length > 0) {
      setPostVideoArray(page_detail.videos);
    }
    setQuillText(page_detail.description);
  }, [page_detail]);

  function handleQuillChange(e) {
    setQuillText(e);
  }
  Editor.formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
  ];
  Editor.modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike"], // toggled buttons
      ["blockquote"],
      ["link"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "sub" }, { script: "super" }], // superscript/subscript
      [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
      [{ direction: "rtl" }], // text direction

      [{ size: ["small", false, "large", "huge"] }], // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ font: [] }],
      [{ align: [] }],

      ["clean"],
    ],
  };

 
  const styles = {
    menu: (base) => ({
      ...base,
      marginTop: "4px",
      fontSize: "0.875rem",

      passive: "true",
    }),
    control: (base) => ({
      ...base,
      fontSize: "0.875rem",
    }),
  };
  return (
    <>
      <Form
        className="forms-sample"
        noValidate
        validated={validated}
        onSubmit={(event) => handleSubmit(event)}
      >
        <>
          <div className="row">
            <h4 className="card-title col-9 d-flex align-items-center">
              General Information
            </h4>
          </div>
          <Form.Row>
            <Form.Group as={Col} md="6" className="row  mr-1">
              <Form.Label
                htmlFor="exampleInputUsername2"
                className=" col-sm-4  col-form-label"
              >
                Title*
              </Form.Label>
              <div className="col-sm-8">
                <Form.Control
                  type="text"
                  dir={languageSetected?.value === "ar" ? "rtl" : "ltr"}
                  className="form-control"
                  required
                  id="exampleInputUsername2"
                  placeholder="Title"
                  name="title"
                  defaultValue={page_detail.title}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide Title.
                </Form.Control.Feedback>
              </div>
            </Form.Group>
            <Form.Group as={Col} md="6" className="row">
              <Form.Label
                htmlFor="exampleInputUsername2"
                className="col-sm-4  col-form-label"
              >
                Link
              </Form.Label>
              <div className="col-sm-8">
                <Form.Control
                  type="text"
                  className="form-control"
                  id="exampleInputUsername2"
                  placeholder="'Reserve Your Sport' Link"
                  name="link"
                  defaultValue={page_detail.link}
                />
              </div>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} md="12" className="row">
              <Form.Label
                htmlFor="exampleInputUsername2"
                className=" col-sm-2  col-form-label"
              >
                Description
              </Form.Label>
              <div className="col-sm-8">
                <ReactQuill
                  value={quillText || ""}
                  modules={Editor.modules}
                  formats={Editor.formats}
                  onChange={(e) => handleQuillChange(e)}
                />
              </div>
            </Form.Group>
          </Form.Row>
        </>
        <Form.Row>
          {/* <Form.Group as={Col} md="10"></Form.Group> */}
          <Form.Group as={Col} md="12" className="row ml-2">
            <Form.Check
              className=""
              name="status"
              defaultChecked={page_detail.active_status}
              onClick={() => {}}
            />
            <Form.Label className="col-form-label ml-1">
              Publish This Section
            </Form.Label>
          </Form.Group>
        </Form.Row>
        <div className="row">
          <div className="col-sm-4">
            <Button type="submit" className="btn yellow-btn mr-2">
              {params.id ? "Update" : "Create"}
            </Button>
          </div>
        </div>
      </Form>
    </>
  );
}

export default SpotLight;
