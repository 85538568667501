import axios from "axios";
import { toasterMessage } from "../../utils/toast-util";

export const artistProfileDetail = () => {
  return async (dispatch) => {
    try {
      const response = await axios({
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + localStorage.getItem("jwt"),
        },
        url: process.env.REACT_APP_BACKEND_API + `artist/profile-details `,
      });
      const { data } = response;
      if (data.status) {
        await dispatch({
          type: "ARTIST_PROFILE_DETAIL",
          payload: data.result.data,
        });
      } else {
        toasterMessage("error", data.result.message);
      }
    } catch (error) {
      if (error.response) {
        console.log("error",error);
        toasterMessage("error", error.response.data.result.message);
      }
    }
  };
};
export const UpdateArtistProfileDetails = (value) => {
    return async (dispatch) => {
      try {
        const response = await axios({
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + (localStorage.getItem("jwt")),
          },
          url: process.env.REACT_APP_BACKEND_API+`artist/update-profile`,
          data: value,
          
        });
        const { data } = response;
        if (data.status) {
          toasterMessage("success",data.result.message);
        }else {
          toasterMessage("error", data.result.message);
        }
      } catch (error) {
        if (error.response) {
          console.log("error",error);
          toasterMessage("error", error.response.data.result.message);
        }
      }
    };
  };
  export const getArtistPostDetail =()=>{
    return async (dispatch) => {
      try {
        const response = await axios({
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + (localStorage.getItem("jwt")),
          },
          url: process.env.REACT_APP_BACKEND_API+`artist/post-details`,
          
        });
        const { data } = response;
        if (data.status) {
          await dispatch({
            type: "ARTIST_POST_DETAILS",
            payload: data.result.data,
          });
        } else {
          toasterMessage("error", data.result.message);
        }
      } catch (error) {
        if (error.response) {
          console.log("error",error);
          toasterMessage("error", error.response.data.result.message);
        }
      }
    };
  }
  export const UpdateArtistPostDetail =(value)=>{
    return async (dispatch) => {
      try {
        const response = await axios({
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + (localStorage.getItem("jwt")),
          },
          url: process.env.REACT_APP_BACKEND_API+`artist/update-posts`,
          data: value,
          
        });
        const { data } = response;
        if (data.status) {
          toasterMessage("success",data.result.message);
        }else {
          toasterMessage("error", data.result.message);
        }
      } catch (error) {
        if (error.response) {
          console.log("error",error);
          toasterMessage("error", error.response.data.result.message);
        }
      }
    };
  }
  export const getArtistOtherDetail =()=>{
    return async (dispatch) => {
      try {
        const response = await axios({
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + (localStorage.getItem("jwt")),
          },
          url: process.env.REACT_APP_BACKEND_API+`artist/other-details`,
          
        });
        const { data } = response;
        if (data.status) {
          await dispatch({
            type: "ARTIST_OTHER_DETAILS",
            payload: data.result.data,
          });
        } else {
          toasterMessage("error", data.result.message);
        }
      } catch (error) {
        if (error.response) {
          console.log("error",error);
          toasterMessage("error", error.response.data.result.message);
        }
      }
    };
  }
  export const UpdateArtistOtherDetails = (value) => {
    return async (dispatch) => {
      try {
        const response = await axios({
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + (localStorage.getItem("jwt")),
          },
          url: process.env.REACT_APP_BACKEND_API+`artist/update-additional`,
          data: value,
          
        });
        const { data } = response;
        if (data.status) {
          toasterMessage("success",data.result.message);
        }else {
          toasterMessage("error", data.result.message);
        }
      } catch (error) {
        if (error.response) {
          console.log("error",error);
          toasterMessage("error", error.response.data.result.message);
        }
      }
    };
  };
  export const getArtistNotifications =()=>{
    return async (dispatch) => {
      try {
        const response = await axios({
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + (localStorage.getItem("jwt")),
          },
          url: process.env.REACT_APP_BACKEND_API+`artist/notifications`,
          
        });
        const { data } = response;
        if (data.status) {
          await dispatch({
            type: "ARTIST_NOTIFICATION",
            payload: data.result.data,
          });
        } else {
          toasterMessage("error", data.result.message);
        }
      } catch (error) {
        if (error.response) {
          console.log("error",error);
          toasterMessage("error", error.response.data.result.message);
        }
      }
    };
  }

  export const getArtistDashboardDetails =()=>{
    return async (dispatch) => {
      try {
        const response = await axios({
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + (localStorage.getItem("jwt")),
          },
          url: process.env.REACT_APP_BACKEND_API+`artist/dashboard`,
          
        });
        const { data } = response;
        if (data.status) {
          await dispatch({
            type: "ARTIST_DASHBOARD",
            payload: data.result.data,
          });
        } else {
          toasterMessage("error", data.result.message);
        }
      } catch (error) {
        if (error.response) {
          console.log("error",error);
          toasterMessage("error", error.response.data.result.message);
        }
      }
    };
  }