import { SET_SUPPLIER_TYPE, SUPPLIER_LIST, SUPPLIER_DETAIL } from "../action/actiontypes";
const initialState = {
    supplier_list: [],
    supplier_detail: {},
    get_supplier_type: []
};
const SupplierManagementReducer = (state = initialState, action) => {
    switch (action.type) {
        case SUPPLIER_LIST:
            return {
                ...state,
                supplier_list: action.payload,
                supplier_detail: {},
                artist_post: {},
                specialty_options: [],
                tagList: [],
                genres_options: [],
            };
        case SUPPLIER_DETAIL:
            return {
                ...state,
                supplier_detail: action.payload,
            };
        case SET_SUPPLIER_TYPE:
            return {
                ...state,
                get_supplier_type: action.payload
            }
        default: return state
    }
};
export default SupplierManagementReducer 