import React from "react";
import { Form, InputGroup, Col, Button, FormControl } from "react-bootstrap";
import { useState, useRef, useEffect, useCallback } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { enquiryData } from "../../Redux/action/enquiryManagement";
function Enquiries() {
  const dispatch = useDispatch();
  const params = useParams();
  useEffect(() => {
    dispatch(enquiryData(params.id));
  }, []);
  const data = useSelector((state) => state.EnquiryReducer.data);
  return (
    <>
      {" "}
      <div className="row">
        <h4 className="card-title col-8 d-flex align-items-center">
          Enquiry Detail
        </h4>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/enquiry-management">Enquiry Management</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Enquiry Detail
            </li>
          </ol>
        </nav>
      </div>
      <div className="row">
        <div className="col-md-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <Form
                className="forms-sample "
                noValidate
              // onSubmit={(event) => handleSubmit(event)}
              >
                <>
                  <div className="row">
                    <h4 className="card-title col-9 d-flex align-items-center">
                      Enquiry Information
                    </h4>
                  </div>
                  <Form.Row>
                    <Form.Group as={Col} md="6" className="row  mr-1">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className=" col-sm-4  col-form-label"
                      >
                        Name
                      </Form.Label>
                      <div className="col-sm-8">
                        <Form.Control
                          type="text"
                          className="form-control"
                          required
                          id="exampleInputUsername2"
                          placeholder="Name"
                          name="Name"
                          defaultValue={data.name}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide name.
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>
                    <Form.Group as={Col} md="6" className="row">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className="col-sm-4  col-form-label"
                      >
                        Email
                      </Form.Label>
                      <div className="col-sm-8 ">
                        <Form.Control
                          type="text"
                          className="form-control"
                          id="exampleInputUsername2"
                          placeholder="Email"
                          name="email"
                          defaultValue={data.email}
                        />
                      </div>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md="6" className="row  mr-1">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className=" col-sm-4  col-form-label"
                      >
                        Contact No
                      </Form.Label>
                      <div className="col-sm-8">
                        <Form.Control
                          type="text"
                          className="form-control"
                          id="exampleInputUsername2"
                          placeholder="Contact No"
                          name="contact_no"
                          defaultValue={data.contact_no}
                        />
                      </div>
                    </Form.Group>
                    <Form.Group as={Col} md="6" className="row">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className="col-sm-4  col-form-label"
                      >
                        Company Name
                      </Form.Label>
                      <div className="col-sm-8">
                        <Form.Control
                          type="text"
                          className="form-control"
                          id="exampleInputUsername2"
                          placeholder="Company Name"
                          name="companyName"
                          defaultValue={data.companyName}
                        />
                      </div>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md="6" className="row  mr-1">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className=" col-sm-4  col-form-label"
                      >
                        Location
                      </Form.Label>
                      <div className="col-sm-8">
                        <Form.Control
                          type="text"
                          className="form-control"
                          id="exampleInputUsername2"
                          placeholder="Location"
                          name="location"
                          defaultValue={data.location}
                        />
                      </div>
                    </Form.Group>
                    <Form.Group as={Col} md="6" className="row">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className="col-sm-4  col-form-label"
                      >
                        Guests
                      </Form.Label>
                      <div className="col-sm-8">
                        <Form.Control
                          type="text"
                          className="form-control"
                          id="exampleInputUsername2"
                          placeholder="Guests"
                          name="guests"
                          defaultValue={data.guests}
                        />
                      </div>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md="6" className="row  mr-1">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className=" col-sm-4  col-form-label"
                      >
                        Extras
                      </Form.Label>
                      <div className="col-sm-8">
                        <Form.Control
                          type="text"
                          className="form-control"
                          id="exampleInputUsername2"
                          placeholder="Extras"
                          name="extras"
                          defaultValue={data.extras}
                        />
                      </div>
                    </Form.Group>
                    <Form.Group as={Col} md="6" className="row">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className="col-sm-4  col-form-label"
                      >
                        Event Time
                      </Form.Label>
                      <div className="col-sm-8">
                        <Form.Control
                          type="text"
                          className="form-control"
                          id="exampleInputUsername2"
                          placeholder="Event Time"
                          name="eventTime"
                          defaultValue={data.eventTime}
                        />
                      </div>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md="6" className="row  mr-1">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className=" col-sm-4  col-form-label"
                      >
                        Event Date*
                      </Form.Label>
                      <div className="col-sm-8">
                        <Form.Control
                          type="date"
                          className="form-control"
                          required
                          id="exampleInputUsername2"
                          placeholder="Date"
                          name="date"
                          defaultValue={data.eventDate}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide Date.
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>
                    <Form.Group as={Col} md="6" className="row">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className="col-sm-4  col-form-label"
                      >
                        Enquiry Type
                      </Form.Label>
                      <div className="col-sm-8">
                        <Form.Control
                          type="text"
                          className="form-control"
                          id="exampleInputUsername2"
                          placeholder="Enquiry Type"
                          name="enquiryType"
                          defaultValue={data.enquiryType}
                        />
                      </div>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md="6" className="row  mr-1">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className=" col-sm-4  col-form-label"
                      >
                        Description
                      </Form.Label>
                      <div className="col-sm-8">
                        <Form.Control
                          as="textarea"
                          className="form-control"
                          rows="8"
                          id="exampleInputUsername2"
                          placeholder="Description"
                          name="description"
                          defaultValue={data.description}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide description.
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>
                  </Form.Row>
                </>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Enquiries;
