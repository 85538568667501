import axios from "axios";
import {toasterMessage} from "../../utils/toast-util"
export const getJwt = (value) => {
    return async (dispatch) => {
      try {
        const response = await axios({
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            // Authorization: "Bearer " + (localStorage.getItem("token")),
          },
          url: process.env.REACT_APP_BACKEND_API+"auth/admin-login",
          data: JSON.stringify(value),
        });
        const { data } = response;
        if (data.status) {
          localStorage.setItem("jwt", data.result.accessToken);
          localStorage.setItem("userImage", data.result.data[0].admin.profileImg_url);
          localStorage.setItem("firstname", data.result.data[0].admin.firstname);
          localStorage.setItem("loginType", "2");
          await dispatch({
            type: "LOGIN_DETAILS",
            payload: data,
          });
          toasterMessage("success", data.result.message);
        }else if (data){
          toasterMessage("error", data.result.message);
        }
      } catch (error) {
        if (error.reponse) {
          console.log("error");
        }
      }
    };
  };
  export const resetPassword = (value) => {
    return async (dispatch) => {
      try {
        const response = await axios({
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          url: process.env.REACT_APP_BACKEND_API + "/auth/admin-reset",
          data: JSON.stringify(value),
        });
        const { data } = response;
        if (data.status) {
          toasterMessage("success", data.result.message);
        }else {
          toasterMessage("error", data.result.message);
        }
      } catch (error) {
        if (error.reponse) {
          console.log("error");
          toasterMessage("error", "Something went wrong!");
        }
      }
    };
  };
  export const forgotPassword = (value,history) => {
    return async (dispatch) => {
      try {
        const response = await axios({
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            // Authorization: "Bearer " + (localStorage.getItem("token")),
          },
          url: process.env.REACT_APP_BACKEND_API+"auth/admin-forget",
          data: JSON.stringify(value),
        });
        const { data } = response;
        if(data.status){
          await dispatch({
            type: "FORGOT_EMAIL",
            payload: data,
          });
          toasterMessage(
            "success",
            data.result.message          );
          history.push("/login");
        }else {
          toasterMessage("error", data.result.message);
        }

      } catch (error) {
        if (error.reponse) {
          console.log("error");
        }
      }
    };
  };