import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import '../../assets/styles/global.scss';
import { useState, useRef, useEffect, useCallback } from "react";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import {
  getArtistType,
  getSpecialities,
} from "../../Redux/action/categoriesManagement";
import {
  subAdminList,
  subAdminFilter,
  deleatSubAdmin,
} from "../../Redux/action/subAdminManagement";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import Select from "react-select";
import { Button } from "react-bootstrap";
const { SearchBar } = Search;

const defaultSorted = [
  {
    dataField: "id",
    order: "desc",
  },
];
const status = [
  { value: 0, label: "Inactive" },
  { value: 1, label: "Active" },
];


const styles = {
  menu: (base) => ({
    ...base,
    marginTop: "4px",
    "font-size": "0.875rem",

    passive: "true",
  }),
  control: (base) => ({
    ...base,
    "font-size": "0.875rem",
  }),
};
function SubAdminList() {
  const [statusSelected, setStatusSelected] = useState();
  const [mdShow, setMdShow] = useState(false);
  const [deleteId, setdeleteId] = useState();
  const dispatch = useDispatch();
  const subAdminOptions = useSelector(
    (state) => state.SubAdminManagementReducer.subAdmin_list
  );
  useEffect(() => {
    dispatch( subAdminList());
  }, [])
  

  const columns = [
    {
      dataField: "firstname",
      text: "First Name",
      sort: true,
      style: { padding: "0px 25px  0px 0.9375rem" },
    },
    {
      dataField: "lastname",
      text: "Last Name",
      sort: true,
      style: { padding: "0px 25px  0px 0.9375rem" },
    },
    {
      dataField: "email",
      text: "Email Address",
      sort: true,
      style: { padding: "0px 25px  0px 0.9375rem" },
    },
    {
      dataField: "phone",
      text: "Phone Number",
      sort: true,
      style: { padding: "0px 25px  0px 0.9375rem" },
    },
    {
      dataField: "createdAt",
      text: "Create Date",
      sort: true,
      style: { padding: "0px 25px  0px 0.9375rem" },
      formatter: (cellContent) => {
        return <Moment format="DD/MM/YYYY">{cellContent}</Moment>;
      },
      csvFormatter: (cellContent) => {
        const today = new Date(cellContent);
        return new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }).format(today);
      },
    },

    {
      dataField: "updatedAt",
      text: "Update Date",
      sort: false,
      style: { padding: "0px 25px  0px 0.9375rem" },
      formatter: (cellContent) => {
        return <Moment format="DD/MM/YYYY">{cellContent}</Moment>;
      },
      csvFormatter: (cellContent) => {
        const today = new Date(cellContent);
        return new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }).format(today);
      },
    },
    {
      dataField: "active_status",
      text: "Status",
      sort: true,
      style: { padding: "0px 25px  0px 0.9375rem" },
      formatter: (cellContent, row) => {
        if (cellContent === 1) {
          return <label className="badge badge-warning">Active</label>;
        } else {
          return <label className="badge badge-success">Inactive</label>;
        }
      },
      csvFormatter: (cellContent) => {
        if (cellContent === 1) {
          return "Active";
        } else {
          return "Inactive";
        }
      },
    },
    {
      dataField: "action",
      text: "Action",
      sort: false,
      style: { padding: "5px 25px 5px 0.9375rem", width: "16em" },
      formatter: (cellContent, row) => {
        return (
          <div style={{ display: "flex" }}>
            <div>
              <Link
                className="btn edit-icons mr-2"
                to={`/update-subAdmin/${row.id}`}
              >
                <i class="ti-pencil"></i>
              </Link>
            </div>
            <div>
              <button
                className="btn edit-icons"
                onClick={(e) => {
                  setdeleteId(row.id)
                  setMdShow(true)
                  
                }}
              >
                <i class="ti-trash"></i>
              </button>
            </div>
          </div>
        );
      },
    },
  ];
  const handleFilter = () => {
    var value = {
      status: statusSelected?.value === 1 ? true : statusSelected?.value != null ? false : null,
    };
    console.log("clicked",value);
    dispatch(subAdminFilter(value));
  };
  const resetFilter = () => {
    setStatusSelected("");
    dispatch(subAdminList());
  };
  const status = [
    { value: 0, label: "Inactive" },
    { value: 1, label: "Active" },
  ];

  return (
    <div>
      <div className="page-header">
        <h3 className="page-title">Sub Admin Management</h3>

        <div className="breadcrumb">
          <Link
            className="btn btn-block yellow-btn font-weight-medium auth-form-btn"
            to="/add-subAdmin"
          >
            + NEW
          </Link>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
        <div className="row justify-content-end mb-2 mr-1">
            
            <Button className=" yellow-btn" onClick={(e) => handleFilter()}>
              Apply
            </Button>
            <Button
              className="yellow-btn"
              onClick={(e) => resetFilter()}
            >
              Reset
            </Button>
          </div>
          <div className="row mb-2">
            <div className="col-sm-2">
              <Select
                styles={styles}
                maxMenuHeight={100}
                options={status}
                value={statusSelected}
                onChange={(e) => {
                  setStatusSelected(e);
                }}
                placeholder="Status"
                // ref={this.approvalRef}
              />
            </div>

          </div>
         
          <div className="card">
            <div className="card-body ">
              <div className="row ">
                <div className="col-12 ">
                  <ToolkitProvider
                    keyField="id"
                    bootstrap4
                    data={subAdminOptions || []}
                    columns={columns}
                    search
                    exportCSV={{
                      fileName: "Sub Admin List.csv",
                    }}
                  >
                    {(props) => (
                      <div>
                        <div className="page-header ">
                          <div className="d-flex align-items-center">
                            <p className="mb-2 mr-2">Search:</p>
                            <SearchBar {...props.searchProps} />{" "}
                          </div>
                        </div>
                        {/* <div className="container"> */}
                        <BootstrapTable
                          defaultSorted={defaultSorted}
                          pagination={paginationFactory()}
                          noDataIndication={() => <h4> No Records Found</h4>}
                          {...props.baseProps}
                          wrapperClasses="table-responsive "
                        />
                        {/* </div> */}
                      </div>
                    )}
                  </ToolkitProvider>
                  <Modal
                    show={mdShow}
                    onHide={() => {
                      setMdShow(false)
                    }}
                    aria-labelledby="example-modal-sizes-title-md"
                  >
                    <Modal.Header closeButton>
                      <Modal.Title
                        style={{ width: "100%", "text-align": "center" }}
                      >
                        Are You Sure, You Want To Delete ?
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Footer
                      className="fleex-wrap"
                      style={{ justifyContent: "center" }}
                    >
                      <Button
                        variant="success m-2 yellow-btn"
                        onClick={(e) => {
                          setMdShow(false)
                          dispatch(deleatSubAdmin(deleteId));
                        }}
                      >
                        Yes
                      </Button>
                      <Button
                        variant="light m-2 yellow-btn"
                        onClick={() => {
                          setMdShow(false)
                        }}
                      >
                        Cancel
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubAdminList;
