import React from "react";
import { Form, InputGroup, Col, Button, FormControl } from "react-bootstrap";
import { useState, useRef, useEffect, useCallback } from "react";
import Multiselect from "multiselect-react-dropdown";
import { Editor } from "@tinymce/tinymce-react";
import ReactQuill from "react-quill";
import "../../assets/styles/global.scss";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import ImageUploading from "react-images-uploading";
import { useDropzone } from "react-dropzone";
import { Link, useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { toasterMessage } from "../../utils/toast-util";
import "react-quill/dist/quill.snow.css";
import CropEasy from "../../crop/CropEasy";
import {
  createPage,
  UpdatePage,
  pageById,
} from "../../Redux/action/pageDetail";
import { pageLayoutList } from "../../Redux/action/cms";
import Gallery from "./forms/gallery";
import { getTagList } from "../../Redux/action/categoriesManagement";
import Carousel from "./forms/carousel";
import Banner from "./forms/banner";
import EventTile from "./forms/event-tile";
import HeroWarehouse from "./forms/hero-warehouse";
import Venu from "./forms/venu";
import SpotLight from "./forms/spotlight";
const DynamicForm = ({
  subpageSelected,
  page_detail,
  languageSetected,
  pageSelected,
}) => {
  switch (subpageSelected?.value) {
    case "carousel":
      return (
        <Carousel
          page_detail={page_detail}
          subpageSelected={subpageSelected}
          languageSetected={languageSetected}
          pageSelected={pageSelected}
        />
      );
    case "banner":
      return (
        <Banner
          page_detail={page_detail}
          subpageSelected={subpageSelected}
          languageSetected={languageSetected}
          pageSelected={pageSelected}
        />
      );
    case "event_tile":
      return (
        <EventTile
          page_detail={page_detail}
          subpageSelected={subpageSelected}
          languageSetected={languageSetected}
          pageSelected={pageSelected}
        />
      );
    case "gallery":
      return (
        <Gallery
          page_detail={page_detail}
          subpageSelected={subpageSelected}
          languageSetected={languageSetected}
          pageSelected={pageSelected}
        />
      );
    case "changing_tiles":
      return (
        <EventTile
          page_detail={page_detail}
          subpageSelected={subpageSelected}
          languageSetected={languageSetected}
          pageSelected={pageSelected}
        />
      );
    case "Banner":
      return (
        <Banner
          page_detail={page_detail}
          subpageSelected={subpageSelected}
          languageSetected={languageSetected}
          pageSelected={pageSelected}
        />
      );
    case "hero":
      return (
        <HeroWarehouse
          page_detail={page_detail}
          subpageSelected={subpageSelected}
          languageSetected={languageSetected}
          pageSelected={pageSelected}
          pageNameValue={pageSelected}
        />
      );
    case "about":
      return (
        <HeroWarehouse
          page_detail={page_detail}
          subpageSelected={subpageSelected}
          languageSetected={languageSetected}
          pageSelected={pageSelected}
          pageNameValue={pageSelected}
        />
      );
    case "team":
      return (
        <HeroWarehouse
          page_detail={page_detail}
          subpageSelected={subpageSelected}
          languageSetected={languageSetected}
          pageSelected={pageSelected}
          pageNameValue={pageSelected}
        />
      );
    case "a_venu":
      return (
        <Venu
          page_detail={page_detail}
          subpageSelected={subpageSelected}
          languageSetected={languageSetected}
          pageSelected={pageSelected}
        />
      );
    case "can_do":
      return (
        <Venu
          page_detail={page_detail}
          subpageSelected={subpageSelected}
          languageSetected={languageSetected}
          pageSelected={pageSelected}
        />
      );
    case "Us_Different":
      return (
        <Venu
          page_detail={page_detail}
          subpageSelected={subpageSelected}
          languageSetected={languageSetected}
          pageSelected={pageSelected}
        />
      );
    case "Our_Work":
      return (
        <EventTile
          page_detail={page_detail}
          subpageSelected={subpageSelected}
          languageSetected={languageSetected}
          pageSelected={pageSelected}
        />
      );
    case "hero_home":
      return (
        <HeroWarehouse
          page_detail={page_detail}
          subpageSelected={subpageSelected}
          languageSetected={languageSetected}
          pageSelected={pageSelected}
          pageNameValue={pageSelected}
        />
      );
    case "carousel_home":
      return (
        <EventTile
          page_detail={page_detail}
          subpageSelected={subpageSelected}
          languageSetected={languageSetected}
          pageSelected={pageSelected}
        />
      );
    case "spotlight":
      return (
        <SpotLight
          page_detail={page_detail}
          subpageSelected={subpageSelected}
          languageSetected={languageSetected}
          pageSelected={pageSelected}
        />
      );
    default:
      return null;
  }
};

function CreatePage() {
  const [openCrop, setOpenCrop] = useState(false);
  const [quillText, setQuillText] = useState();
  const [image, setImage] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [description, setDescription] = useState("");
  const [videodescription, setVideodescription] = useState("");
  const [openCopyCrop, setOpenCopyCrop] = useState(false);
  const [validated, setValidated] = useState(false);
  const [publish, setPublish] = useState(false);
  const [publishVideo, setPublishvideo] = useState(false);
  const [postImageArray, setPostImageArray] = useState([]);
  const [postVideoArray, setPostVideoArray] = useState([]);
  const [videoLink, setVideoLink] = useState(null);
  const [deleatImage, setDeleatImage] = useState([]);
  const [deleatVideo, setDeleatVideo] = useState([]);

  const [pageSelected, setPageSelected] = useState(null);
  const [subpageSelected, setSubpageSelected] = useState(null);
  const [languageSetected, setLanguageSetected] = useState("en");
  const [postImageDetails, setPostImageDetails] = useState({});
  const [typeError, setTypeError] = useState(null);

  const dispatch = useDispatch();
  const history = useHistory();

  const page_detail = useSelector(
    (state) => state.PageManagementReducer.page_detail
  );
  const pageListOptions = useSelector(
    (state) => state.CmsReducer.page_layout_list
  );
  const params = useParams();
  const language = [
    { value: "ar", label: "Arabic" },
    { value: "en", label: "English" },
  ];
  const Layout1 = [
    { value: "hero", label: "Hero" },
    { value: "Banner", label: "Banner" },
    {
      value: "can_do",
      label: "What We can do",
    },
    {
      value: "Us_Different",
      label: "What Makes Us Different",
    },
    { value: "Our_Work", label: "Our Work" },
  ];
  const Layout2 = [
    { value: "hero", label: "Hero" },
    { value: "Banner", label: "Banner" },
    {
      value: "can_do",
      label: "What We can do",
    },
    {
      value: "Us_Different",
      label: "What Makes Us Different",
    },
    { value: "Our_Work", label: "Our Work" },
  ];
  const Layout3 = [
    { value: "Banner", label: "Banner" },
    {
      value: "event_tile",
      label: "Event Tile",
    },
    { value: "carousel", label: "Carousel" },
  ];

  const Layout4 = [
    { value: "a_venu", label: "A Venu" },
    { value: "hero", label: "Hero" },
    {
      value: "changing_tiles",
      label: "Changing Tiles",
    },
    {
      value: "Banner",
      label: "Banner",
    },
    { value: "gallery", label: "Gallery" },
  ];
  const Layout5 = [
    { value: "carousel_home", label: "Carousel" },
    { value: "hero_home", label: "Hero" },
    {
      value: "changing_tiles",
      label: "Changing Tiles",
    },
    {
      value: "Banner",
      label: "Banner",
    },
    { value: "spotlight", label: "Spotlight" },
  ];
  const Layout6 = [
    {
      value: "tiles",
      label: "Tiles",
    },
  ];
  const Layout7 = [
    {
      value: "Banner",
      label: "Banner",
    },
    { value: "about", label: "About Us" },
    { value: "Our_Work", label: "Our Work" },
    { value: "team", label: "Team" },
  ];

  useEffect(() => {
    if (params.id) {
      dispatch(pageById(params.id));
    }
    dispatch(pageLayoutList());
  }, []);

  useEffect(() => {
    if (page_detail.images?.length > 0) {
      setPostImageArray(page_detail.images);
    }
    if (page_detail.videos?.length > 0) {
      setPostVideoArray(page_detail.videos);
    }
    setPageSelected(
      pageListOptions[
        pageListOptions.findIndex((x) => x.id === page_detail.pageNameId)
      ]
    );
    setQuillText(page_detail.description);
    switch (
      pageListOptions[
        pageListOptions.findIndex((x) => x.id === page_detail.pageNameId)
      ]?.layout
    ) {
      case "1":
        setSubpageSelected(
          Layout1[Layout1.findIndex((x) => x.value === page_detail.subPage)]
        );
        break;
      case "3":
        setSubpageSelected(
          Layout3[Layout3.findIndex((x) => x.value === page_detail.subPage)]
        );
        break;
      case "2":
        setSubpageSelected(
          Layout2[Layout2.findIndex((x) => x.value === page_detail.subPage)]
        );
        break;
      case "4":
        setSubpageSelected(
          Layout4[Layout4.findIndex((x) => x.value === page_detail.subPage)]
        );
        break;
      case "5":
        setSubpageSelected(
          Layout5[Layout5.findIndex((x) => x.value === page_detail.subPage)]
        );
        break;
      case "6":
        setSubpageSelected(
          Layout6[Layout6.findIndex((x) => x.value === page_detail.subPage)]
        );
        break;
      case "7":
        setSubpageSelected(
          Layout7[Layout7.findIndex((x) => x.value === page_detail.subPage)]
        );
        break;
      default:
        setSubpageSelected();
        break;
    }
    setLanguageSetected(
      language[language.findIndex((x) => x.value === page_detail.lang)]
    );
  }, [page_detail]);

  Editor.formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
  ];
  Editor.modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike"], // toggled buttons
      ["blockquote"],
      ["link"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "sub" }, { script: "super" }], // superscript/subscript
      [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
      [{ direction: "rtl" }], // text direction

      [{ size: ["small", false, "large", "huge"] }], // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ font: [] }],
      [{ align: [] }],

      ["clean"],
    ],
  };
  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImage(URL.createObjectURL(event.target.files[0]));
      setImageFile(event.target.files[0]);
    }
  };

  const addPost = () => {
    if (image) {
      setPostImageArray([
        ...postImageArray,
        {
          image: image,
          active_status: publish,
          file: imageFile,
          isNew: true,
          media_desc: description,
        },
      ]);
      setImageFile();
      setImage();
      setPublish(false);
      setDescription("");
    } else {
      toasterMessage("error", "Please add an image before adding Post.");
    }
  };

  const unPublish = (index, value) => {
    // let newState = postImageArray;
    const newState = postImageArray.map((obj, i) => {
      // 👇️ if id equals 2, update country property
      if (i === index) {
        console.log("found");
        return { ...obj, active_status: !value };
      }

      // 👇️ otherwise return object as is
      return obj;
    });
    setPostImageArray(newState);
  };
  const unPublishVideo = (index, value) => {
    const newState = postVideoArray.map((obj, i) => {
      // 👇️ if id equals 2, update country property
      if (i === index) {
        console.log("found");
        return { ...obj, active_status: !value };
      }

      // 👇️ otherwise return object as is
      return obj;
    });
    setPostVideoArray(newState);
  };
  const deleatPost = (index) => {
    if (postImageArray[index]?.isNew === false) {
      setDeleatImage([...deleatImage, postImageArray[index].id]);
    }
    setPostImageArray(postImageArray.filter((item, i) => i !== index));
  };
  const deleatVideoPost = (index) => {
    if (postVideoArray[index]?.isNew === false) {
      setDeleatVideo([...deleatVideo, postVideoArray[index].id]);
    }
    setPostVideoArray(postVideoArray.filter((item, i) => i !== index));
  };
  const handleSection = (pageSelected) => {
    switch (pageSelected?.layout) {
      case "1":
        return Layout1;
      case "2":
        return Layout2;
      case "3":
        return Layout3;
      case "4":
        return Layout4;
      case "5":
        return Layout5;
      case "6":
        return Layout6;
      case "7":
        return Layout7;
      default:
        return [];
    }
  };
  const styles = {
    menu: (base) => ({
      ...base,
      marginTop: "4px",
      fontSize: "0.875rem",

      passive: "true",
    }),
    control: (base) => ({
      ...base,
      fontSize: "0.875rem",
    }),
  };
  return (
    <>
      <div className="row">
        <h4 className="card-title col-9 d-flex align-items-center">
          {!params.id ? "Create Section" : "Update Section"}
        </h4>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/website-management/section-management">
                Section Management
              </Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {!params.id ? "Create Section" : "Update Section"}
            </li>
          </ol>
        </nav>
      </div>
      <div className="row">
        <div className="col-md-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <h4 className="card-title col-9 d-flex align-items-center">
                  Section Information
                </h4>
              </div>

              <Form
                className="forms-sample"
                noValidate
                validated={validated}
                // onSubmit={(event) => handleSubmit(event)}
              >
                <>
                  <Form.Row>
                    <Form.Group as={Col} md="6" className="row mr-1">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className=" col-sm-4  col-form-label"
                      >
                        Language*
                      </Form.Label>
                      <div className="col-sm-8">
                        <Select
                          required
                          options={language}
                          value={languageSetected}
                          styles={styles}
                          placeholder="Select Language"
                          isDisabled={params.id ? true : false}
                          maxMenuHeight={100}
                          onChange={(e) => {
                            setLanguageSetected(e);
                          }}
                        />

                        <span
                          style={{
                            width: "100%",
                            fontSize: "0.875em",
                            color: "#dc3545",
                          }}
                        >
                          {typeError ? "Please select" : null}
                        </span>
                      </div>
                    </Form.Group>
                    {/* <Form.Group as={Col} md="6" className="row">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className=" col-sm-4  col-form-label"
                      >
                        Order*
                      </Form.Label>
                      <div className="col-sm-8">
                        <Form.Control
                          type="number"
                          className="form-control"
                          required
                          placeholder="Order Number"
                          name="order"
                          defaultValue={page_detail.post_order}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide Order.
                        </Form.Control.Feedback>

                        <span
                          style={{
                            width: "100%",
                            fontSize: "0.875em",
                            color: "#dc3545",
                          }}
                        >
                          {typeError ? "Please select" : null}
                        </span>
                      </div>
                    </Form.Group> */}
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md="6" className="row  mr-1">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className="col-sm-4 col-form-label"
                      >
                        Page*
                      </Form.Label>
                      <div className="col-sm-8">
                        <Select
                          required
                          options={pageListOptions}
                          value={pageSelected}
                          styles={styles}
                          isDisabled={params.id ? true : false}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          placeholder="Select Page"
                          maxMenuHeight={100}
                          onChange={(e) => {
                            setPageSelected(e);
                            setSubpageSelected("");
                          }}
                        />

                        <span
                          style={{
                            width: "100%",
                            fontSize: "0.875em",
                            color: "#dc3545",
                          }}
                        >
                          {typeError ? "Please select" : null}
                        </span>
                      </div>
                    </Form.Group>
                    <Form.Group as={Col} md="6" className="row">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className="col-sm-4 col-form-label"
                      >
                        Sub Section
                      </Form.Label>
                      <div className="col-sm-8">
                        <Select
                          required
                          placeholder="Select Sub Section"
                          isDisabled={params.id ? true : false}
                          options={handleSection(pageSelected)}
                          value={subpageSelected}
                          styles={styles}
                          maxMenuHeight={100}
                          onChange={(e) => {
                            setSubpageSelected(e);
                          }}
                        />

                        <span
                          style={{
                            width: "100%",
                            fontSize: "0.875em",
                            color: "#dc3545",
                          }}
                        >
                          {typeError ? "Please select" : null}
                        </span>
                      </div>
                    </Form.Group>
                  </Form.Row>
                  {/* <div className="row">
                    <h4 className="card-title col-9 d-flex align-items-center">
                      General Information
                    </h4>
                  </div>
                  <>
                  <Form.Row>
                    <Form.Group as={Col} md="6" className="row  mr-1">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className=" col-sm-4  col-form-label"
                      >
                        Title*
                      </Form.Label>
                      <div className="col-sm-8">
                        <Form.Control
                          type="text"
                          dir={languageSetected?.value === "ar" ? "rtl" : "ltr"}
                          className="form-control"
                          required
                          id="exampleInputUsername2"
                          placeholder="Title"
                          name="title"
                          defaultValue={page_detail.title}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide Title.
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>
                    <Form.Group as={Col} md="6" className="row">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className="col-sm-4  col-form-label"
                      >
                        Sub Title
                      </Form.Label>
                      <div className="col-sm-8 ">
                        <Form.Control
                          type="text"
                          className="form-control"
                          id="exampleInputUsername2"
                          placeholder="Sub Title"
                          name="subtitle"
                          dir={languageSetected?.value === "ar" ? "rtl" : "ltr"}
                          defaultValue={page_detail.subTitle}
                        />
                      </div>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md="6" className="row  mr-1">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className=" col-sm-4  col-form-label"
                      >
                        General Event Info
                      </Form.Label>
                      <div className="col-sm-8">
                        <Form.Control
                          type="text"
                          dir={languageSetected?.value === "ar" ? "rtl" : "ltr"}
                          className="form-control"
                          id="exampleInputUsername2"
                          placeholder="General Event Info"
                          name="eventInfo"
                          defaultValue={page_detail.event_info}
                        />
                      </div>
                    </Form.Group>
                    <Form.Group as={Col} md="6" className="row">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className="col-sm-4  col-form-label"
                      >
                        Link
                      </Form.Label>
                      <div className="col-sm-8">
                        <Form.Control
                          type="text"
                          className="form-control"
                          id="exampleInputUsername2"
                          placeholder="'Reserve Your Sport' Link"
                          name="link"
                          defaultValue={page_detail.link}
                        />
                      </div>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md="12" className="row">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className=" col-sm-2  col-form-label"
                      >
                        Biography
                      </Form.Label>
                      <div className="col-sm-8">
                        <ReactQuill
                          value={quillText || ""}
                          modules={Editor.modules}
                          formats={Editor.formats}
                          onChange={(e) => handleQuillChange(e)}
                        />
                      </div>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md="6" className="d-flex">
                      <Form.Check
                        className="ml-2"
                        name="general_status"
                        defaultChecked={page_detail.active_status_gen}
                        onClick={() => {}}
                      />
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className="col-form-label ml-1"
                      >
                        Publish General Info
                      </Form.Label>
                    </Form.Group>
                  </Form.Row>
                  </> */}
                </>
                <DynamicForm
                  page_detail={page_detail}
                  subpageSelected={subpageSelected}
                  languageSetected={languageSetected}
                  pageSelected={pageSelected}
                />
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreatePage;
