import axios from "axios";
import { toasterMessage } from "../../utils/toast-util";

export const artistList = () => {
  return async (dispatch) => {
    try {
      const response = await axios({
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        url: process.env.REACT_APP_BACKEND_API + `admin/artist `,
      });
      const { data } = response;
      if (data.status) {
        await dispatch({
          type: "ARTIST_LIST",
          payload: data.result.data,
        });
      } else {
        toasterMessage("error", data.result.message);
      }
    } catch (error) {
      if (error.reponse) {
        toasterMessage("error", "Something went Wrong");
      }
    }
  };
};
export const artistListFilter = (value) => {
  return async (dispatch) => {
    try {
      var data_send = {};
      if (value.status != null) {
        if (value.status) {
          data_send["status"] = 1;
        } else {
          data_send["status"] = 0;
        }
      }

      if (value.approvedStatus) {
        data_send["approvalStatus"] = value.approvedStatus;
      }
      if (value.artistType) {
        data_send["artistType"] = value.artistType;
      }
      if (value.specialities) {
        data_send["specialities"] = value.specialities;
      }
      if (value.genres) {
        data_send["genres"] = value.genres;
      }
      if (value.tags) {
        data_send["tags"] = value.tags;
      }
      const response = await axios({
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + localStorage.getItem("jwt"),
        },
        url: `${process.env.REACT_APP_BACKEND_API}admin/artist`,
        params: data_send,

        //   ?myparam1=123&myparam2=abc&myparam2=xyz
      });
      const { data } = response;
      if (data.status) {
        await dispatch({
          type: "ARTIST_LIST",
          payload: data.result.data,
        });
      } else {
        toasterMessage("error", data.result.message);
      }
    } catch (error) {
      if (error.reponse) {
        toasterMessage("error", "Something went Wrong");
      }
    }
  };
};

export const deleatArtist = (id, value) => {
  return async (dispatch) => {
    try {
      const response = await axios({
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + localStorage.getItem("jwt"),
        },
        url: process.env.REACT_APP_BACKEND_API + `admin/artist/${id}`,
      });
      const { data } = response;
      if (data.status) {
        toasterMessage("success", data.result.message);
        await dispatch(artistListFilter(value));
      } else {
        toasterMessage("error", data.result.message);
      }
    } catch (error) {
      if (error.response) {
        console.log("error");
      }
      toasterMessage("error", error.response.data.result.message);
    }
  };
};

export const getCountry = (id) => {
  return async (dispatch) => {
    try {
      const response = await axios({
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          // Authorization: "Bearer " + (localStorage.getItem("jwt")),
        },
        url: process.env.REACT_APP_BACKEND_API + `admin/artist/${id}`,
      });
      const { data } = response;
      if (data.status) {
        toasterMessage("success", data.result.message);
        await dispatch(artistList());
      } else {
        toasterMessage("error", data.result.message);
      }
    } catch (error) {
      if (error.response) {
        console.log("error");
      }
      toasterMessage("error", error.response.data.result.message);
    }
  };
};
//Action to set Artist Type
export const actionSetArtistType = (value) => {
  return async (dispatch) => {
    await dispatch({
      type: "SET_ARTIST_TYPE",
      payload: value
    });
  }
}

//Action to set Speciality Type
export const actionSetSpecialityType = (value) => {
  return async (dispatch) => {
    await dispatch({
      type: "SET_SPECIALITY_TYPE",
      payload: value
    });
  }
}
//Action to set Tags Type
export const actionSetTagsType = (value) => {
  return async (dispatch) => {
    await dispatch({
      type: "SET_TAGS_TYPE",
      payload: value
    });
  }
}
//Action to set Genre Type
export const actionSetGenreType = (value) => {
  return async (dispatch) => {
    await dispatch({
      type: "SET_GENRE_TYPE",
      payload: value
    });
  }
}