import React from "react";
import { Form, InputGroup, Col, Button, FormControl } from "react-bootstrap";
import { useState, useRef, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Modal } from "react-bootstrap";
import "react-quill/dist/quill.snow.css";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
  SubmenuById,
  createSubMenu,
  UpdateSubMenu,
  subMenuList,
} from "../../Redux/action/cms";

function CreateSubMenu() {
  const [validated, setValidated] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();


  const menu_detail = useSelector(
    (state) => state.CmsReducer.menu_detail
  );
  const params = useParams();
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    event.preventDefault();
    setValidated(true);
    if (form.checkValidity() === true) {
      let obj = {
        name: event.target.submenuName?.value,
        name_arabic: event.target.submenuArabicName?.value,
        menuId: params.id,
        // active_status: event.target.status.checked ? 1 : 0,
      };
      if (params.Mainid) {
        dispatch(UpdateSubMenu(obj, params.Mainid, history));
      } else {
        dispatch(createSubMenu(obj, history));
        console.log(obj);
      }
    }
  };
  useEffect(() => {
    if (params.Mainid) {
      dispatch(SubmenuById(params.Mainid));
    }
  }, []);

  const subMenuDetails = useSelector(
    (state) => state.CmsReducer.sub_menu_detail
  );


  return (
    <>
      <div className="row">
        <h4 className="card-title col-8 d-flex align-items-center">
          {!params.Mainid ? "Create Sub Menu" : "Update Sub Menu"}
        </h4>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/website-management/menu-management">
                Menu Management
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link to={`/website-management/update-menu/${params.id}`}>
                Update Menu
              </Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {!params.Mainid ? "Create Sub Menu" : "Update Sub Menu"}
            </li>
          </ol>
        </nav>
      </div>
      <div className="row">
        <div className="col-md-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <h4 className="card-title col-9 d-flex align-items-center">
                  Sub Menu Information
                </h4>
              </div>

              <Form
                className="forms-sample"
                noValidate
                id="form"
                validated={validated}
                onSubmit={(event) => handleSubmit(event)}
              >
                <>
                  <Form.Row>
                    <Form.Group as={Col} md="6" className="row  mr-1">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className=" col-sm-4  col-form-label"
                      >
                        Sub Menu Name(English)*
                      </Form.Label>
                      <div className="col-sm-8">
                        <Form.Control
                          type="text"
                          className="form-control"
                          required
                          minLength={3}
                          id="exampleInputUsername2"
                          placeholder="Sub Menu Name"
                          name="submenuName"
                          defaultValue={subMenuDetails?.name}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide Sub Menu Name in English.
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>
                    <Form.Group as={Col} md="6" className="row  mr-1">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className=" col-sm-4  col-form-label"
                      >
                        Sub Menu Name(Arabic)*
                      </Form.Label>
                      <div className="col-sm-8">
                        <Form.Control
                          type="text"
                          className="form-control"
                          required
                          dir={"rtl"}
                          minLength={3}
                          id="exampleInputUsername2"
                          placeholder="Sub Menu Name"
                          name="submenuArabicName"
                          defaultValue={subMenuDetails?.name_arabic}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide Sub Menu Name in Arabic.
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>
                  </Form.Row>
                </>
                <div className="row">
                  <div className="col-sm-4">
                    <Button type="submit" className="btn yellow-btn mr-2">
                      {params.Mainid ? "Update" : "Create"}
                    </Button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateSubMenu;
