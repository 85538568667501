import axios from "axios";
import { toasterMessage } from "../../utils/toast-util";

export const artistLogin = (value, history) => {
  return async (dispatch) => {
    try {
      const response = await axios({
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        url: process.env.REACT_APP_BACKEND_API + `artist/login `,
        data: value,
      });
      const { data } = response;
      if (data.status) {
        toasterMessage("success", data.result.message);
        localStorage.setItem("email", value.email);
        localStorage.setItem("userId", data.result.id);
        localStorage.setItem("loginType", "1");
        history.push("/artist-otp");
      } else {
        toasterMessage("error", data.result.message);
      }
    } catch (error) {

      if (error.response) {
        console.log("error", error);
        toasterMessage("error", error.response.data.result.message);
      }
    }
  };
};

export const artistSignup = (value, handleResponse) => {
  return async (dispatch) => {
    try {
      const response = await axios({
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",

        },
        url: process.env.REACT_APP_BACKEND_API + `artist/sign-up `,
        data: value,
      });
      const { data } = response;
      if (data.status) {
        toasterMessage("success", data.result.message);
        //   history.push("/artist-otp");
      } else {
        toasterMessage("error", data.result.message);
      }
    } catch (error) {

      if (error.response) {
        console.log("error", error);
        toasterMessage("error", error.response.data.result.message);
      }
    }
  };
};
export const artistOtp = (value, history) => {
  return async (dispatch) => {
    try {
      const response = await axios({
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + localStorage.getItem("jwt"),
        },
        url: process.env.REACT_APP_BACKEND_API + `artist/otp-auth `,
        data: value,
      });
      const { data } = response;
      if (data.status) {
        toasterMessage("success", data.result.message);
        localStorage.setItem("userImage", data.result.data.profile_pic);
        localStorage.setItem("firstname", data.result.data.stageName);
        localStorage.setItem("jwt", data.result.accessToken);
        localStorage.removeItem("userId");
        localStorage.removeItem("email")
        history.push("/dashboard-artist")
      } else {
        console.log("tets");
        toasterMessage("error", data.result.message);
      }
    } catch (error) {

      if (error.response) {
        console.log("error", error);
        toasterMessage("error", error.response.data.result.message);
      }
    }
  };
};
export const artistForgot = (value) => {
  return async (dispatch) => {
    try {
      const response = await axios({
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          // Authorization: "Bearer " + localStorage.getItem("check"),
        },
        url: process.env.REACT_APP_BACKEND_API + `artist/forget-password`,
        data: value,
      });
      const { data } = response;
      console.log("tets", data);
      if (data.status) {
        toasterMessage("success", data.result.message);
        //   history.push("/artist-otp");
      } else {
        console.log("tets");
        toasterMessage("error", data.result.message);
      }
    } catch (error) {

      if (error.response) {
        console.log("error", error);
        toasterMessage("error", error.response.data.result.message);
      }
    }
  };
};
export const artistReset = (value) => {
  return async (dispatch) => {
    try {
      const response = await axios({
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          // Authorization: "Bearer " + localStorage.getItem("check"),
        },
        url: process.env.REACT_APP_BACKEND_API + `artist/reset-password`,
        data: value,
      });
      const { data } = response;
      console.log("tets", data);
      if (data.status) {
        toasterMessage("success", data.result.message);
        //   history.push("/artist-otp");
      } else {
        console.log("tets");
        toasterMessage("error", data.result.message);
      }
    } catch (error) {

      if (error.response) {
        console.log("error", error);
        toasterMessage("error", error.response.data.result.message);
      }
    }
  };
};

export const artistComp = (value,history) => {
  return async (dispatch) => {
    try {
      const response = await axios({
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
          // Authorization: "Bearer " + localStorage.getItem("check"),
        },
        url: process.env.REACT_APP_BACKEND_API + `artist/sign-up-completion`,
        data: value,
      });
      const { data } = response;
      console.log("tets", data);
      if (data.status) {
        toasterMessage("success", data.result.message);
          history.push("/artist-login");
      } else {
        console.log("tets");
        toasterMessage("error", data.result.message);
      }
    } catch (error) {

      if (error.response) {
        console.log("error", error);
        toasterMessage("error", error.response.data.result.message);
      }
    }
  };
};