import axios from "axios";
import { toasterMessage } from "../../utils/toast-util";

export const menuList = () => {
  return async (dispatch) => {
    try {
      const response = await axios({
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        url: process.env.REACT_APP_BACKEND_API + `admin/menus `,
      });
      const { data } = response;
      if (data.status) {
        await dispatch({
          type: "MENU_LIST",
          payload: data.result.data,
        });
      } else {
        toasterMessage("error", data.result.message);
      }
    } catch (error) {
      if (error.response) {
        console.log("error");
        toasterMessage("error", "Something went Wrong");
      }
    }
  };
};
export const pageListFilter = (value) => {
    console.log(value,"value");

  return async (dispatch) => {
    try {
        var data_send = {};
        console.log(value,"value");
        if (value.status) {
          data_send["status"] = 1;
      }else {
        data_send["status"] = 0;
      }
        if (value.language) {
            data_send["language"] = value.language;
        }
        if (value.subpage) {
            data_send["subPage"] = value.subpage;
        }
        if (value.page) {
            data_send["page"] = value.page;
        }
        console.log(data_send,"data_send");
      const response = await axios({
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + localStorage.getItem("jwt"),
        },
        url: `${process.env.REACT_APP_BACKEND_API}admin/page-content`,
        params: data_send,

        //   ?myparam1=123&myparam2=abc&myparam2=xyz
      });
      const { data } = response;
      if (data.status) {
        await dispatch({
          type: "PAGE_LIST",
          payload: data.result.data,
        });
      } else {
        toasterMessage("error", data.result.message);
      }
    } catch (error) {
      if (error.response) {
        console.log("error");
        toasterMessage("error", "Something went Wrong");
      }
    }
  };
};

export const deleatMenu = (id) => {
  return async (dispatch) => {
    try {
      const response = await axios({
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + (localStorage.getItem("jwt")),
        },
        url: process.env.REACT_APP_BACKEND_API+`admin/menus/${id}`,
        
      });
      const { data } = response;
      if (data.status) {

        toasterMessage("success", data.result.message);
        await dispatch(menuList());
      }else {
        toasterMessage("error", data.result.message);
      }
    } catch (error) {
      if (error.response) {
        console.log("error");
       
      }
       toasterMessage("error", error.response.data.result.message);
    }
  };
};
export const createMenu = (value,history) => {
    return async (dispatch) => {
      try {
        const response = await axios({
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + (localStorage.getItem("jwt")),
          },
          url: process.env.REACT_APP_BACKEND_API+`admin/menus`,
          data: value,
          
        });
        const { data } = response;
        if (data.status) {
          toasterMessage("success",data.result.message);
          history.push('/website-management/menu-management')
        }else {
          toasterMessage("error", data.result.message);
        }
      } catch (error) {
        if (error.response) {
          console.log("error");
          toasterMessage("error", error.response.data.result.message);
        }
      }
    };
  };


export const UpdateMenu = (value,id,history) => {
    return async (dispatch) => {
      try {
        const response = await axios({
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + (localStorage.getItem("jwt")),
          },
          url: process.env.REACT_APP_BACKEND_API+`admin/menus/${id}`,
          data: value,
          
        });
        const { data } = response;
        if (data.status) {
          toasterMessage("success",data.result.message);
          history.push('/website-management/menu-management')
        }else {
          toasterMessage("error", data.result.message);
        }
      } catch (error) {
        if (error.response) {
          console.log("error");
          toasterMessage("error", error.response.data.result.message);
        }
      }
    };
  };

  export const menuById = (id) => {
    return async (dispatch) => {
      try {
        const response = await axios({
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + localStorage.getItem("jwt"),
          },
          url: process.env.REACT_APP_BACKEND_API + `admin/menus/${id}`,
        });
        const { data } = response;
        if (data.status) {
          await dispatch({
            type: "MENU_DETAILS",
            payload: data.result.data,
          });
        } else {
          toasterMessage("error", data.result.message);
        }
      } catch (error) {
        if (error.response) {
          console.log("error");
          toasterMessage("error", error.response.data.result.message);
        }
      }
    };
  };

  export const subMenuList = (id) => {
    return async (dispatch) => {
      try {
        const response = await axios({
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + localStorage.getItem("jwt"),
          },
          url: process.env.REACT_APP_BACKEND_API + `admin/sub-menus/${id}`,
        });
        const { data } = response;
        if (data.status) {
          await dispatch({
            type: "SUB_MENU_LISTS",
            payload: data.result.data,
          });
        } else {
          toasterMessage("error", data.result.message);
        }
      } catch (error) {
        if (error.response) {
          console.log("error");
          toasterMessage("error", error.response.data.result.message);
        }
      }
    };
  };
  export const createSubMenu = (value,history) => {
    return async (dispatch) => {
      try {
        const response = await axios({
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + (localStorage.getItem("jwt")),
          },
          url: process.env.REACT_APP_BACKEND_API+`admin/sub-menus`,
          data: value,
          
        });
        const { data } = response;
        if (data.status) {
          toasterMessage("success",data.result.message);
          history.push(`/website-management/update-menu/${value.menuId}`)
        }else {
          toasterMessage("error", data.result.message);
        }
      } catch (error) {
        if (error.response) {
          console.log("error");
          toasterMessage("error", error.response.data.result.message);
        }
      }
    };
  };
  export const UpdateSubMenu = (value,id,history) => {
    return async (dispatch) => {
      try {
        const response = await axios({
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + (localStorage.getItem("jwt")),
          },
          url: process.env.REACT_APP_BACKEND_API+`admin/sub-menus/${id}`,
          data: value,
          
        });
        const { data } = response;
        if (data.status) {
          toasterMessage("success",data.result.message);
          history.push(`/website-management/update-menu/${value.menuId}`)
        }else {
          toasterMessage("error", data.result.message);
        }
      } catch (error) {
        if (error.response) {
          console.log("error", error.response.data.result.message);
          toasterMessage("error", error.response.data.result.message);
        }
      }
    };
  };
  export const SubmenuById = (id) => {
    return async (dispatch) => {
      try {
        const response = await axios({
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + localStorage.getItem("jwt"),
          },
          url: process.env.REACT_APP_BACKEND_API + `admin/sub-menu/${id}`,
        });
        const { data } = response;
        if (data.status) {
          await dispatch({
            type: "SUB_MENU_DETAILS",
            payload: data.result.data,
          });
        } else {
          toasterMessage("error", data.result.message);
        }
      } catch (error) {
        if (error.response) {
          console.log("error");
          toasterMessage("error", error.response.data.result.message);
        }
      }
    };
  };
  export const deleatSubMenu = (id,menuid) => {
    return async (dispatch) => {
      try {
        var obj = {
          menuId:menuid,
          id:id,
        }
        const response = await axios({
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + (localStorage.getItem("jwt")),
          },
          url: process.env.REACT_APP_BACKEND_API+`admin/sub-menus/delete`,
          data:obj,
        });
        const { data } = response;
        if (data.status) {
  
          toasterMessage("success", data.result.message);
          await dispatch(subMenuList(menuid));
        }else {
          toasterMessage("error", data.result.message);
        }
      } catch (error) {
        if (error.response) {
          console.log("error");
         
        }
         toasterMessage("error", error.response.data.result.message);
      }
    };
  };

  export const ReoderMenu = (value) => {
    return async (dispatch) => {
      try {
        const response = await axios({
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          url: process.env.REACT_APP_BACKEND_API + `admin/menus/change-order `,
          data: value,
        });
        const { data } = response;
        if (data.status) {
          await dispatch(menuList());
        } else {
          toasterMessage("error", data.result.message);
        }
      } catch (error) {
        if (error.response) {
          console.log("error");
          toasterMessage("error", error.response.data.result.message);
        }
      }
    };
  };
  export const ReoderSubMenu = (value,id) => {
    return async (dispatch) => {
      try {
        const response = await axios({
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          url: process.env.REACT_APP_BACKEND_API + `admin/sub-menus/change-order `,
          data: value,
        });
        const { data } = response;
        if (data.status) {
          await dispatch(subMenuList(id));
        } else {
          toasterMessage("error", data.result.message);
        }
      } catch (error) {
        if (error.response) {
          console.log("error");
          toasterMessage("error", error.response.data.result.message);
        }
      }
    };
  };

  export const deleatPageLayout = (id) => {
    return async (dispatch) => {
      try {

        const response = await axios({
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + (localStorage.getItem("jwt")),
          },
          url: process.env.REACT_APP_BACKEND_API+`admin/page-layout/${id}`,
        });
        const { data } = response;
        if (data.status) {
  
          toasterMessage("success", data.result.message);
          await dispatch(pageLayoutList());
        }else {
          toasterMessage("error", data.result.message);
        }
      } catch (error) {
        if (error.response) {
          console.log("error");
         
        }
         toasterMessage("error", error.response.data.result.message);
      }
    };
  };
  export const layoutDeatilById = (id) => {
    return async (dispatch) => {
      try {
        const response = await axios({
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + localStorage.getItem("jwt"),
          },
          url: process.env.REACT_APP_BACKEND_API + `admin/page-layout/${id}`,
        });
        const { data } = response;
        if (data.status) {
          await dispatch({
            type: "PAGE_LAYOUT_DETAILS",
            payload: data.result.data,
          });
        } else {
          toasterMessage("error", data.result.message);
        }
      } catch (error) {
        if (error.response) {
          console.log("error");
          toasterMessage("error", error.response.data.result.message);
        }
      }
    };
  };
  export const UpdatePageLayout = (value,id,history) => {
    return async (dispatch) => {
      try {
        const response = await axios({
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + (localStorage.getItem("jwt")),
          },
          url: process.env.REACT_APP_BACKEND_API+`admin/page-layout/${id}`,
          data: value,
          
        });
        const { data } = response;
        if (data.status) {
          toasterMessage("success",data.result.message);
          history.push(`/website-management/page-layout-management`)
        }else {
          toasterMessage("error", data.result.message);
        }
      } catch (error) {
        if (error.response) {
          console.log("error", error.response.data.result.message);
          toasterMessage("error", error.response.data.result.message);
        }
      }
    };
  };
  export const createPageLayout = (value,history) => {
    return async (dispatch) => {
      try {
        const response = await axios({
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + (localStorage.getItem("jwt")),
          },
          url: process.env.REACT_APP_BACKEND_API+`admin/page-layout`,
          data: value,
          
        });
        const { data } = response;
        if (data.status) {
          toasterMessage("success",data.result.message);
          history.push(`/website-management/page-layout-management`)
        }else {
          toasterMessage("error", data.result.message);
        }
      } catch (error) {
        if (error.response) {
          console.log("error");
          toasterMessage("error", error.response.data.result.message);
        }
      }
    };
  };
  export const pageLayoutList = () => {
    return async (dispatch) => {
      try {
        const response = await axios({
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + localStorage.getItem("jwt"),
          },
          url: process.env.REACT_APP_BACKEND_API + `admin/page-list`,
        });
        const { data } = response;
        if (data.status) {
          await dispatch({
            type: "PAGE_LAYOUT_LISTS",
            payload: data.result.data,
          });
        } else {
          toasterMessage("error", data.result.message);
        }
      } catch (error) {
        if (error.response) {
          console.log("error");
          toasterMessage("error", error.response.data.result.message);
        }
      }
    };
  };
  export const pageLayoutMenuList = () => {
    return async (dispatch) => {
      try {
        const response = await axios({
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + localStorage.getItem("jwt"),
          },
          url: process.env.REACT_APP_BACKEND_API + `admin/page-layout/menus`,
        });
        const { data } = response;
        if (data.status) {
          await dispatch({
            type: "PAGE_LAYOUT_MENUS_LISTS",
            payload: data.result.data,
          });
        } else {
          toasterMessage("error", data.result.message);
        }
      } catch (error) {
        if (error.response) {
          console.log("error");
          toasterMessage("error", error.response.data.result.message);
        }
      }
    };
  };