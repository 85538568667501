import React from "react";
import { Form, InputGroup, Col, Button } from "react-bootstrap";
import { useState, useRef, useEffect, useCallback } from "react";
import { getArtistNotifications } from "../../Redux/action/artistPortal";
import { useDispatch, useSelector } from "react-redux";
import IconN from "../../assets/images/notification.png";
import moment from "moment";
const Tile = ({ notification }) => {
  let td = [];
  notification.map((i) => {
    console.log();
    td.push(
      <>
        <div class="text-white search-talent-cards mb-5 notification_card">
          <div class="card-main-img col-1">
            <img src={IconN} alt="Product" class="img-responsive" />
          </div>
          <div class="col-11" style={{ color: "black" }}>
            <h3>{i.title}</h3>
            <div className="meta-information">
              <div>{i.body}</div>
              <div> {moment(i.updatedAt).format("DD MMM YYYY")} at {moment(i.updatedAt).format("hh:mm A")}</div>
            </div>
          </div>
        </div>
      </>
    );
  });
  return td;
};
function Notifications() {
  const [page, setPage] = useState("Profile Information");

  const dispatch = useDispatch();
  const notification = useSelector(
    (state) => state.ArtistPortalReducer.notifications
  );
  useEffect(() => {
    dispatch(getArtistNotifications());
  }, []);

  return (
    <>
      <h1 className="mb-0">My Notifications</h1>
      <div className="row mt-3">
        <div className="col-md-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  <Tile notification={notification} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Notifications;
