import { METATAG_DETAILS , METATAG_LISTS } from "../action/actiontypes";
const initialState = {
    tagList: [],
    tagdetails:{}
  };
  const MetaTagManagementReducer = (state = initialState, action) => {
    switch (action.type) {
        case METATAG_LISTS:
          return {
            ...state,
            tagList: action.payload,
            tagdetails: {},
          };
        case METATAG_DETAILS:
          return {
            ...state,
            tagdetails: action.payload,
        };
      default: return state
    }
  };
  export default MetaTagManagementReducer 