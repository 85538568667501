import React, { Component, useState } from "react";
import { Button } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  supplierOtp,
} from "../../Redux/action/supplierLogin";
function SupplierOtp() {
  const [mdShow, setMdShow] = useState(false);
  const [otp, setOtp] = useState(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const handleSubmit = (e) => {
    var obj = {
      otp: Number(otp),
      id: localStorage.userId,
      email: localStorage.email
    }
    if (otp) {
      dispatch(supplierOtp(obj, history))
    }
  }
  const handleOtp = (e) => {
    setOtp(e.target.value)
  }
  return (
    <div>
      <div className="d-flex align-items-stretch auth auth-img-bg h-100">
        <div className="row flex-grow">
          <div className="col-lg-5 register-half-bg">

            <div className="auth-form-transparent text-left d-flex side_area ">
              <div className="">
                <div className="brand-logo-forget">
                  <img
                    src={require("../../assets/images/logo.svg")}
                    alt="logo"
                  />
                </div>
                {/* <h2>Lorem ipsum dolor sit amet consectetur</h2>
                <h5 className="font-weight-light">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
                  vulputate libero et velit interdum, ac aliquet odio mattis.
                </h5> */}
              </div>

            </div>
            <div className="bottom_text">
              <h6>Terms & Conditions Privacy Policy</h6>
              <h6>copyrigtht@ 2022. All rights reserved</h6>
            </div>
          </div>
          <div className="col-lg-6 d-flex align-items-center justify-content-center">
            <div className="auth-form-transparent text-left ">
              {/* <div className="brand-logo">
                <img src={require("../../assets/images/logo.svg")} alt="logo" />
              </div> */}
              <h2 className="font-weight-bold">Login</h2>
              <h4>
                Enter the OTP received over your registered email address to
                access the profile.
              </h4>
              <form className="pt-3">
                <div className="form-group">
                  <h5>OTP</h5>
                  <div className="input-group">
                    <input
                      type="number"
                      className="form-control form-control-lg "
                      placeholder="Enter One Time Password"
                      onChange={(e) => { handleOtp(e) }}
                      value={otp}
                    />

                  </div>
                </div>
                {/* <div className="text-left mt-4 font-weight-light">
                  <Link to="/artist-forgot" className="text-gray">
                    Resend
                  </Link>
                </div> */}
                <div className="mt-3">
                  <Button
                    className="btn btn-block btn-lg font-weight-medium yellow-btn btn-icon-tex"
                    onClick={(e) => {
                      handleSubmit();
                    }}
                  >
                    Login
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SupplierOtp;
