import React, { Component, useState } from "react";
import { useRef, useEffect, useCallback } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import { useParams } from "react-router-dom";
import ImageUploading from "react-images-uploading";
import { Form, InputGroup, Col, Row } from "react-bootstrap";
import Multiselect from "multiselect-react-dropdown";
import { useDispatch, useSelector } from "react-redux";
import { getSupplierType } from "../../Redux/action/categoriesManagement";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import ArtistDemo from "../../assets/images/artist.png";
import { toasterMessage } from "../../utils/toast-util";
import { supplierComp } from "../../Redux/action/supplierLogin";
import { useHistory } from "react-router-dom";
import { countryDeatils } from "../../assets/country";

function SupplierRegister() {
  const [mdShow, setMdShow] = useState(false);
  const [validated, setValidated] = useState(false);
  const [country, setCountry] = useState(null);
  const [mobileNumber1, setmobileNumber1] = useState(null);
  const [landlineNumber, setLandlineNumber] = useState(null);
  const [imagePreview, setImagePreview] = useState(ArtistDemo);
  const [imageFile1, setImageFile] = useState(null);
  const dispatch = useDispatch();
  const CameraIcon = useRef(null);
  const supplierRef = useRef(null);
  const locationCompanyHeadquarterRef = useRef(null);
  const companyServiceAreaRef = useRef(null);
  const params = useParams();
  const history = useHistory();

  const supplierList = useSelector(
    (state) => state.CategoriesManagementReducer.supplierTypeList
  );

  const countryOptions = countryDeatils.map(country => ({
    label: country.country,
    value: country.country,
  }));

  useEffect(() => {
    dispatch(getSupplierType());
  }, []);

  const handleLocationSelection = (selectedItems) => {
    // Assuming the selectedItems is an array of selected values
    console.log('selectedItems[0]--->>>>', selectedItems[0].value);
    setCountry(selectedItems[0].value); // Assuming you only want to store one selected item
  };

  const handleSubmit = (event) => {
    if (mobileNumber1 === null || mobileNumber1.length < 11) {
      // event.preventDefault();
      toasterMessage("error", "Please enter mobile number");
    }

    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    event.preventDefault();
    setValidated(true);
    if (!event.target.terms?.checked) {
      toasterMessage("error", "Please agree Terms");
    }

    var localSupplier = [];
    supplierRef.current.getSelectedItems().forEach((item) => {
      localSupplier.push(item.id);
    });

    var localCompanyServiceAreas = [];
    companyServiceAreaRef.current.getSelectedItems().forEach((item) => {
      localCompanyServiceAreas.push(item.value);
    });

    var hasError = false;
    hasError = false;

    if (supplierList.length > 0) {
      if (localSupplier.length <= 0) {
        hasError = true;
        toasterMessage("error", "Please select Supplier");
      }
    }

    if (countryDeatils.length > 0) {
      if (localCompanyServiceAreas.length <= 0) {
        hasError = true;
        toasterMessage("error", "Please select Company Service Areas");
      }
    }

    if (
      form.checkValidity() === true &&
      mobileNumber1 &&
      event.target.terms?.checked &&
      !hasError
    ) {
      let formData = new FormData();
      formData.append("companyName", event.target.companyName?.value);
      formData.append("supplierTypes", localSupplier);
      formData.append("legalTradeName", event.target.legalTradeName?.value);
      formData.append("tradeLicenseNumber", event.target.tradeLicenseNumber?.value);
      formData.append("vatNumber", event.target.vatNumber?.value);
      formData.append("countryHeadquarters", country);
      formData.append("cityHeadquarters", event.target.city?.value);
      formData.append("companyServiceArea", localCompanyServiceAreas);
      formData.append("companyDescription", event.target.companyDescription?.value);
      formData.append("website", event.target.website?.value);
      formData.append("firstName", event.target.firstName?.value);
      formData.append("lastName", event.target.lastName?.value);
      formData.append("mobileNumber", mobileNumber1);
      formData.append("companyLandline", landlineNumber);
      formData.append("link", params.id);

      if (event.target.facebook.value) {
        formData.append("fbURL", event.target.facebook.value);
      }
      if (event.target.youtube.value) {
        formData.append("ytURL", event.target.youtube.value);
      }
      if (event.target.instagram.value) {
        formData.append("instaURL", event.target.instagram.value);
      }
      if (event.target.soundCloud.value) {
        formData.append("soundcloudURL", event.target.soundCloud.value);
      }
      if (event.target.twitter.value) {
        formData.append("twitterURL", event.target.twitter.value);
      }

      if (event.target.title.value) {
        formData.append("title", event.target.title.value);
      }

      if (!imageFile1) {
        event.preventDefault();
        toasterMessage(
          "error",
          "Please upload your profile image."
        );
        return;
      }
      if (imageFile1) {
        formData.append("supplier_profile_pic", imageFile1);
      } else {
        formData.append("supplier_profile_pic", null);
      }
      // setMdShow(true);
      dispatch(supplierComp(formData, history));
    }
  };

  const styles = {
    menu: (base) => ({
      ...base,
      marginTop: "4px",
      fontSize: "0.875rem",

      passive: "true",
    }),
    control: (base) => ({
      ...base,
      fontSize: "0.875rem",
    }),
  };
  const handleProfileImage = (e) => {
    let image_as_base64 = URL.createObjectURL(e.target.files[0]);
    let imageFile = e.target.files[0];
    if (!imageFile.name.match(/\.(jpg|jpeg|png|gif)$/)) {
      alert("Please select a valid image.");
    } else {
      setImagePreview(image_as_base64);
      setImageFile(imageFile);
    }
  };

  return (
    <div>
      <div className="d-flex align-items-stretch auth auth-img-bg h-100">
        <div className="row flex-grow">
          <div className="col-lg-5 register-half-bg">

            <div className="auth-form-transparent text-left d-flex side_area ">
              <div className="">
                <div className="brand-logo">
                  <img
                    src={require("../../assets/images/logo.svg")}
                    alt="logo"
                  />
                </div>
                <h2>Join our artist and supplier roster</h2>
                <h5 className="font-weight-light">
                  Register your details to become part of The Fridge roster of artists and event suppliers. Our team will contact you for opportunities at our events.
                </h5>
                <div className="brand-logo mt-5">
                  <img
                    src={require("../../assets/images/image2.png")}
                    alt="logo"
                  />
                </div>
              </div>

            </div>
            <div className="bottom_text">
              <h6>Terms & Conditions Privacy Policy</h6>
              <h6>copyrigtht@ 2022. All rights reserved</h6>
            </div>
          </div>
          <div
            className="col-lg-7 d-flex align-items-center justify-content-center"
            style={{ backgroundColor: "#FDFDFD" }}
          >
            <div className="text-left " style={{ margin: "5%" }}>
              {/* <div className="brand-logo">
<img src={require("../../assets/images/logo.svg")} alt="logo" />
</div> */}
              {/* <h4>Welcome Back </h4> */}
              <h2>Complete Your Profile</h2>
              <h5 className="font-weight-light">
                Fill in your information below to complete your profile.
              </h5>
              <div className="artist-register-image mb-2 mt-1">
                <img
                  src={imagePreview}
                  alt=""
                  width="100"
                  className="rounded-circle artist-image"
                />
                <div
                  className="artist-register-icon"
                  onClick={(e) => CameraIcon.current.click()}
                >
                  <i className="fa fa-camera"></i>
                </div>
                <input
                  required
                  className="d-none"
                  ref={CameraIcon}
                  onChange={(e) => handleProfileImage(e)}
                  type="file"
                />
              </div>
              <Form
                className="forms-sample"
                noValidate
                validated={validated}
                onSubmit={(event) => handleSubmit(event)}
              >
                <>
                  <br />

                  <div className="row">
                    <h4 className="card-title col-9 d-flex align-items-center">
                      General Information
                    </h4>
                  </div>
                  {/* Company Name and Supplier Type  */}
                  <Form.Row>
                    <Form.Group as={Col} md="6" className="row">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className="col-sm-12 col-form-label artist-form"
                      >
                        Company Name*
                      </Form.Label>
                      <div className="col-sm-12">
                        <Form.Control
                          type="text"
                          className="form-control"
                          required
                          placeholder="Enter your Company name"
                          name="companyName"
                        // defaultValue={artist_detail.passportName}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide Your Company Name.
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>

                    <Form.Group as={Col} md="6" className="row ">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className=" col-sm-12 col-form-label"
                      >
                        Supplier Type*
                      </Form.Label>
                      <div className="col-sm-12">
                        <Multiselect
                          options={supplierList}
                          customCloseIcon={
                            <i className="typcn typcn-delete ml-1"></i>
                          }
                          displayValue={"name" || null} // Property name to display in the dropdown options
                          hidePlaceholder={true}
                          showArrow
                          style={{
                            searchBox: { // To change search box element look
                              background: 'white',
                              height: "46px",
                              overflow: 'auto',
                              width: 'auto'
                            },
                            chips: {
                              background: '#F6F4ED',
                              color: 'black',
                              maxWidth: 'calc(100% - 32px)', // Reduce the max width of chips to fit within the search box
                              overflow: 'hidden', // Hide the overflow content
                              whiteSpace: 'nowrap', // Prevent wrapping of long names
                              textOverflow: 'ellipsis', // Show ellipsis for long names
                            },
                            options: {
                              "fontSize": "0.875rem",
                            },
                            "fontSize": "0.875rem",
                          }}
                          ref={supplierRef}
                          placeholder="Select Supplier Type"
                        />
                      </div>
                    </Form.Group>
                  </Form.Row>
                  {/* Trade Name and Trade License Number */}
                  <Form.Row>
                    <Form.Group as={Col} md="6" className="row ">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className="col-sm-12 col-form-label"
                      >
                        Legal Trade Name*
                      </Form.Label>
                      <div className="col-sm-12">
                        <Form.Control
                          type="text"
                          className="form-control"
                          required
                          placeholder="Legal Trade Name"
                          name="legalTradeName"
                        // defaultValue={artist_detail.stageName}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide a Legal Trade Name.
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>
                    <Form.Group as={Col} md="6" className="row mr-1">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className="col-sm-12 col-form-label"
                      >
                        Trade License Number*
                      </Form.Label>
                      <div className="col-sm-12">
                        <Form.Control
                          type="text"
                          className="form-control"
                          required
                          placeholder="Enter trade license number."
                          name="tradeLicenseNumber"
                        // defaultValue={artist_detail.passportName}
                        />
                        <Form.Control.Feedback type="invalid" id="#phoneId">
                          Please provide a Trade License Number.
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>
                  </Form.Row>
                  {/* VAT Number and Company Headquater */}
                  <Form.Row>
                    <Form.Group as={Col} md="6" className="row">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className="col-sm-12 col-form-label"
                      >
                        VAT Number*
                      </Form.Label>
                      <div className="col-sm-12">
                        <Form.Control
                          type="text"
                          className="form-control"
                          required
                          placeholder="Enter VAT number."
                          name="vatNumber"
                        // defaultValue={artist_detail.passportName}
                        />
                        <Form.Control.Feedback type="invalid" id="#phoneId">
                          Please provide a VAT Number.
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>

                    <Form.Group as={Col} md="6" className="row  mr-1">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className="col-sm-12 col-form-label"
                      >
                        Location of Company Headquarter*
                      </Form.Label>
                      <div className="col-sm-12">
                        <Multiselect
                          options={countryOptions}
                          // getOptionLabel={(option) => option.label}
                          // getOptionValue={(option) => option.value}
                          singleSelect={true}
                          customCloseIcon={
                            <i className="typcn typcn-delete ml-1"></i>
                          }
                          displayValue={"value" || null} // Property name to display in the dropdown options
                          hidePlaceholder={true}
                          showArrow
                          onSelect={handleLocationSelection}
                          style={{
                            searchBox: { // To change search box element look
                              background: 'white',
                              height: "46px",
                              overflow: 'auto',
                              width: 'auto'
                            },
                            chips: {
                              background: '#F6F4ED',
                              color: 'black',
                              maxWidth: 'calc(100% - 32px)', // Reduce the max width of chips to fit within the search box
                              overflow: 'hidden', // Hide the overflow content
                              whiteSpace: 'nowrap', // Prevent wrapping of long names
                              textOverflow: 'ellipsis', // Show ellipsis for long names
                            },
                            options: {
                              "fontSize": "0.875rem",
                            },
                            "fontSize": "0.875rem",
                          }}
                          ref={locationCompanyHeadquarterRef}
                          placeholder="Company Headquarter"
                        />
                        <span
                          style={{
                            width: "100%",
                            fontSize: "0.875em",
                            color: "#dc3545",
                          }}
                        ></span>
                      </div>
                    </Form.Group>
                  </Form.Row>

                  {/* City and Company's Service Area */}
                  <Form.Row>
                    <Form.Group as={Col} md="6" className="row ">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className="col-sm-12 col-form-label"
                      >
                        City*
                      </Form.Label>
                      <div className="col-sm-12">
                        <Form.Control
                          type="text"
                          className="form-control"
                          required
                          placeholder="City"
                          name="city"
                        // defaultValue={artist_detail.stageName}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide a City.
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>
                    <Form.Group as={Col} md="6" className="row mr-1">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className="col-sm-12 col-form-label"
                      >
                        Company's Service Areas*
                      </Form.Label>
                      <div className="col-sm-12">
                        <Multiselect
                          options={countryOptions}
                          // getOptionLabel={(option) => option.label}
                          // getOptionValue={(option) => option.value}
                          customCloseIcon={
                            <i className="typcn typcn-delete ml-1"></i>
                          }
                          displayValue={"value" || null} // Property name to display in the dropdown options
                          hidePlaceholder={true}
                          showArrow
                          style={{
                            searchBox: { // To change search box element look
                              background: 'white',
                              height: "46px",
                              overflow: 'auto',
                              width: 'auto'
                            },
                            chips: {
                              background: '#F6F4ED',
                              color: 'black',
                              maxWidth: 'calc(100% - 32px)', // Reduce the max width of chips to fit within the search box
                              overflow: 'hidden', // Hide the overflow content
                              whiteSpace: 'nowrap', // Prevent wrapping of long names
                              textOverflow: 'ellipsis', // Show ellipsis for long names
                            },
                            options: {
                              "fontSize": "0.875rem",
                            },
                            "fontSize": "0.875rem",
                          }}
                          ref={companyServiceAreaRef}
                          placeholder="Company Service Areas"
                        />
                      </div>
                    </Form.Group>
                  </Form.Row>

                  {/* Company Description and Website */}
                  <Form.Row>
                    <Form.Group as={Col} md="6" className="row ">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className="col-sm-12 col-form-label"
                      >
                        Company Description*
                      </Form.Label>
                      <div className="col-sm-12">
                        <Form.Control
                          type="text"
                          className="form-control"
                          required
                          placeholder="Company Description"
                          name="companyDescription"
                        // defaultValue={artist_detail.stageName}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide a Company Description.
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>
                    <Form.Group as={Col} md="6" className="row mr-1">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className="col-sm-6 col-form-label"
                      >
                        Website*
                      </Form.Label>
                      <div className="col-sm-12">
                        <Form.Control
                          type="text"
                          className="form-control"
                          placeholder="Enter website URL"
                          name="website"
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide a Website.
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>
                  </Form.Row>

                  <br />
                  <div className="row">
                    <h4 className="card-title col-9 d-flex align-items-center">
                      Social Media Links
                    </h4>
                  </div>
                  <Form.Row>
                    <Form.Group as={Col} md="4" className="row ">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className=" col-sm-12 col-form-label"
                      >
                        Facebook
                      </Form.Label>
                      <div className="col-sm-12">
                        <Form.Control
                          type="text"
                          className="form-control"
                          placeholder="Facebook"
                          name="facebook"
                        />
                      </div>
                    </Form.Group>
                    <Form.Group as={Col} md="4" className="row ">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className=" col-sm-12 col-form-label"
                      >
                        Instagram
                      </Form.Label>
                      <div className="col-sm-12">
                        <Form.Control
                          type="text"
                          className="form-control"
                          placeholder="Instagram"
                          name="instagram"
                        />
                      </div>
                    </Form.Group>
                    <Form.Group as={Col} md="4" className="row mr-1">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className="col-sm-12 col-form-label"
                      >
                        YouTube
                      </Form.Label>
                      <div className="col-sm-12">
                        <Form.Control
                          type="text"
                          className="form-control"
                          placeholder="YouTube"
                          name="youtube"
                        />
                      </div>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md="4" className="row ">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className=" col-sm-12 col-form-label"
                      >
                        SoundCloud
                      </Form.Label>
                      <div className="col-sm-12">
                        <Form.Control
                          type="text"
                          className="form-control"
                          placeholder="SoundCloud"
                          name="soundCloud"
                        />
                      </div>
                    </Form.Group>
                    <Form.Group as={Col} md="4" className="row ">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className=" col-sm-12 col-form-label"
                      >
                        Twitter
                      </Form.Label>
                      <div className="col-sm-12">
                        <Form.Control
                          type="text"
                          className="form-control"
                          placeholder="Twitter"
                          name="twitter"
                        />
                      </div>
                    </Form.Group>
                    <Form.Group as={Col} md="4" className="row mr-1">
                    </Form.Group>
                  </Form.Row>

                  <br />
                  <div className="row">
                    <h4 className="card-title col-9 d-flex align-items-center">
                      Contact Details
                    </h4>
                  </div>
                  <Form.Row>
                    <Form.Group as={Col} md="6" className="row ">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className="col-sm-12 col-form-label"
                      >
                        First Name*
                      </Form.Label>
                      <div className="col-sm-12">
                        <Form.Control
                          type="text"
                          className="form-control"
                          required
                          placeholder="Enter your first name"
                          name="firstName"
                        // defaultValue={artist_detail.stageName}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide your First Name.
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>
                    <Form.Group as={Col} md="6" className="row mr-1">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className="col-sm-6 col-form-label"
                      >
                        Last Name*
                      </Form.Label>
                      <div className="col-sm-12">
                        <Form.Control
                          type="text"
                          className="form-control"
                          placeholder="Enter your last name"
                          required
                          name="lastName"
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide your Last Name.
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>
                  </Form.Row>

                  <Form.Row>
                    <Form.Group as={Col} md="6" className="row ">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className="col-sm-12 col-form-label"
                      >
                        Title
                      </Form.Label>
                      <div className="col-sm-12">
                        <Form.Control
                          type="text"
                          className="form-control"
                          placeholder="Title"
                          name="title"
                        // defaultValue={artist_detail.stageName}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide a Title.
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>
                    <Form.Group as={Col} md="6" className="row mr-1">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className="col-sm-12 col-form-label"
                      >
                        Mobile Number*
                      </Form.Label>
                      <div className="col-sm-12">
                        <PhoneInput
                          id="phoneId"
                          inputProps={{
                            name: "phone",
                            required: true,
                          }}
                          country={"ae"}
                          minLength={12}
                          placeholder="Enter mobile number"
                          className="custom-input-fields"
                          value={mobileNumber1}
                          onChange={(phone) => setmobileNumber1(phone)}
                        />
                        <Form.Control.Feedback type="invalid" id="#phoneId">
                          Please provide your mobile number.
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>
                  </Form.Row>

                  <Form.Row>
                    <Form.Group as={Col} md="6" className="row mr-1">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className="col-sm-12 col-form-label"
                      >
                        Company Landline
                      </Form.Label>
                      <div className="col-sm-12">
                        <PhoneInput
                          id="phoneId"
                          inputProps={{
                            name: "phone",
                            required: true,
                          }}
                          country={"ae"}
                          minLength={12}
                          placeholder="Enter company landline number"
                          className="custom-input-fields"
                          value={landlineNumber}
                          onChange={(phone) => setLandlineNumber(phone)}
                        />
                        <Form.Control.Feedback type="invalid" id="#phoneId">
                          Please provide a company landline number.
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>
                  </Form.Row>
                  <>
                    <Form.Row>
                      <Form.Group as={Col} md="6" className="form-row">
                        {/* <div className="col-sm-6"> */}
                        <Form.Check
                          name="terms"
                          required
                          // defaultChecked={artist_detail.active_status}
                          onClick={() => { }}
                        />
                        <Form.Label
                          htmlFor="exampleInputUsername2"
                          className=" col-form-label"
                        >
                          Accept terms & condition*
                        </Form.Label>
                        <Form.Control.Feedback type="invalid">
                          Please agree the terms.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Form.Row>
                  </>

                </>
                <br />
                <br />

                <div className="row">
                  <div className="col-sm-12">
                    <Button type="submit" className="btn yellow-btn mr-2">
                      {"Create"}
                    </Button>
                  </div>
                </div>
              </Form>
              {/* <Modal
                size="md"
                show={mdShow}
                onHide={() => {
                  setMdShow(false);
                }}
                aria-labelledby="example-modal-sizes-title-md"
              >
                <Modal.Header closeButton>
                  <Modal.Title
                    style={{
                      width: "100%",
                      "text-align": "center",
                      "border-bottom": "1px solid #86878880",
                    }}
                  >
                    <div className="brand-logo p-4">
                      <img
                        src={require("../../assets/images/mail.png")}
                        alt="logo"
                      />
                    </div>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body
                  className="fleex-wrap"
                  style={{ "text-align": "center" }}
                >
                  <p className="font-weight-bold">
                    Your Details are send for review !
                  </p>
                </Modal.Body>
              </Modal> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SupplierRegister;