import axios from "axios";
import { toasterMessage } from "../../utils/toast-util";

export const contentList = () => {
  return async (dispatch) => {
    try {
      const response = await axios({
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer" + localStorage.getItem("jwt"),
        },
        url: process.env.REACT_APP_BACKEND_API + `admin/artist-content`,
      });
      const { data } = response;
      if (data.status) {
        await dispatch({
          type: "CONTENT_LIST",
          payload: data.result.data,
        });
      } else {
        toasterMessage("error", data.result.message);
      }
    } catch (error) {
      if (error.reponse) {
        console.log("error");
        toasterMessage("error", "Something went Wrong");
      }
    }
  };
};
export const contentListFilter = (value) => {
    console.log(value,"value");

  return async (dispatch) => {
    try {
        var data_send = {};
        console.log(value,"value");
        if (value.status !=2) {
            data_send["status"] = value.status;
        }
        if (value.language) {
            data_send["lang"] = value.language;
        }
        if (value.title) {
            data_send["title"] = value.title;
        }
        if (value.associatedArtist) {
            data_send["content_artist"] = value.associatedArtist;
        }
        if (value.leadArtist) {
            data_send["lead_artist"] = value.leadArtist;
        }
        console.log(data_send,"data_send");
      const response = await axios({
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer" + localStorage.getItem("jwt"),
        },
        url: `${process.env.REACT_APP_BACKEND_API}admin/artist-content`,
        params: data_send,

        //   ?myparam1=123&myparam2=abc&myparam2=xyz
      });
      const { data } = response;
      if (data.status) {
        await dispatch({
          type: "CONTENT_LIST",
          payload: data.result.data,
        });
      } else {
        toasterMessage("error", data.result.message);
      }
    } catch (error) {
      if (error.reponse) {
        console.log("error");
        toasterMessage("error", "Something went Wrong");
      }
    }
  };
};

export const deleatContent = (id) => {
  return async (dispatch) => {
    try {
      const response = await axios({
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + (localStorage.getItem("jwt")),
        },
        url: process.env.REACT_APP_BACKEND_API+`admin/artist-content/${id}`,
        
      });
      const { data } = response;
      if (data.status) {

        toasterMessage("success", data.result.message);
        await dispatch(contentList());
      }else {
        toasterMessage("error", data.result.message);
      }
    } catch (error) {
      if (error.response) {
        console.log("error");
       
      }
       toasterMessage("error", error.response.data.result.message);
    }
  };
};