import axios from "axios";
import {toasterMessage} from "../../utils/toast-util"

export const createPage = (value,history) => {
    return async (dispatch) => {
      try {
        const response = await axios({
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + (localStorage.getItem("jwt")),
          },
          url: process.env.REACT_APP_BACKEND_API+`admin/page-content`,
          data: value,
          
        });
        const { data } = response;
        if (data.status) {
          toasterMessage("success",data.result.message);
          history.push('/website-management/section-management')
        }else {
          console.log(data);
          toasterMessage("error", data.result);
        }
      } catch (error) {
        if (error.response) {
          console.log("error");
          if(error.response?.data?.result?.message){
            toasterMessage("error", error.response.data.result.message);
          }else{
            toasterMessage("error", error.response.data.result);
          }
          
        }
      }
    };
  };


export const UpdatePage = (value,id,history) => {
    return async (dispatch) => {
      try {
        const response = await axios({
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + (localStorage.getItem("jwt")),
          },
          url: process.env.REACT_APP_BACKEND_API+`admin/page-content/${id}`,
          data: value,
          
        });
        const { data } = response;
        if (data.status) {
          toasterMessage("success",data.result.message);
          history.push('/website-management/section-management')
        }else {
          toasterMessage("error", data.result);
        }
      } catch (error) {
        if (error.reponse) {
          console.log("error");
          toasterMessage("error",error.response.data.result);
        }
      }
    };
  };

  export const pageById = (id) => {
    return async (dispatch) => {
      try {
        const response = await axios({
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + localStorage.getItem("jwt"),
          },
          url: process.env.REACT_APP_BACKEND_API + `admin/page-content/${id}`,
        });
        const { data } = response;
        if (data.status) {
          await dispatch({
            type: "PAGE_DETAIL",
            payload: data.result.data,
          });
        } else {
          toasterMessage("error", data.result.message);
        }
      } catch (error) {
        if (error.reponse) {
          console.log("error");
          toasterMessage("error", "Something went Wrong");
        }
      }
    };
  };