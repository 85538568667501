import React, { Component } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState, useRef, useEffect, useCallback } from "react";
import { toasterMessage } from "../../utils/toast-util";
import { useHistory } from "react-router-dom";
import { Doughnut, Pie } from "react-chartjs-2";
import { getArtistDashboardDetails } from "../../Redux/action/artistPortal";
function DashboardArtist() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getArtistDashboardDetails());
  }, []);

  const data = useSelector((state) => state.ArtistPortalReducer.dashborad);
  const doughnutPieData = {
    datasets: [
      {
        data: [
          data.under_review_images,
          data.under_review_videos,
        ],
        backgroundColor: [
          "rgba(255, 99, 132, 0.5)",
          "rgba(54, 162, 235, 0.5)",
          "rgba(255, 206, 86, 0.5)",
          "rgba(75, 192, 192, 0.5)",
          "rgba(153, 102, 255, 0.5)",
          "rgba(255, 159, 64, 0.5)",
        ],
        borderColor: [
          "rgba(255,99,132,1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
      },
    ],

    // These labels appear in the legend and in the tooltips when hovering different arcs
    labels: ["Images", "Video"],
  };
  const artistData = {
    datasets: [
      {
        data: [data.approved_images, data.approved_videos],
        backgroundColor: [
          "rgba(255, 99, 132, 0.5)",
          "rgba(54, 162, 235, 0.5)",
          "rgba(255, 206, 86, 0.5)",
          "rgba(75, 192, 192, 0.5)",
          "rgba(153, 102, 255, 0.5)",
          "rgba(255, 159, 64, 0.5)",
        ],
        borderColor: [
          "rgba(255,99,132,1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
      },
    ],

    // These labels appear in the legend and in the tooltips when hovering different arcs
    labels: ["Images", "Video"],
  };
  const doughnutPieOptions = {
    responsive: true,
    animation: {
      animateScale: true,
      animateRotate: true,
    },
  };

  return (
    <div>
      <div className="page-header flex-wrap">
        <h1 className="mb-0">Dashboard</h1>
        {/* <div className="d-flex">
            <button type="button" className="btn btn-sm bg-white btn-icon-text border mr-0">
              <i className="mdi mdi-email btn-icon-prepend"></i> Email </button>
            <button type="button" className="btn btn-sm bg-white btn-icon-text border ml-3 mr-0">
              <i className="mdi mdi-printer btn-icon-prepend"></i> Print </button>
            <button type="button" className="btn btn-sm ml-3 btn-success mr-0"> Add User</button>
          </div> */}
      </div>
      {/* <h3 className="pl-0 h3  text-muted d-inline-block">Views</h3> */}
      <div className="row">
        <div className="col-xl-12 col-lg-12 stretch-card grid-margin">
          <div className="col-xl-4 col-md-4 stretch-card grid-margin grid-margin-sm-0 p-4">
            <div className="card bg-warning">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-start">
                  <div className="color-card">
                    <p className="mb-0 color-card-head">No of Views</p>
                    <h2 className="text-white">{data.views}</h2>
                  </div>
                  <i className="card-icon-indicator mdi mdi-account-circle bg-inverse-icon-warning"></i>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-4 stretch-card grid-margin grid-margin-sm-0 p-4">
            <div className="card bg-warning">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-start">
                  <div className="color-card">
                    <p className="mb-0 color-card-head"> Artist Media Status</p>
                    <h2 className="text-white">{data.media_status}</h2>
                  </div>
                  <i className="card-icon-indicator mdi mdi-cube-outline bg-inverse-icon-warning"></i>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-4 stretch-card grid-margin grid-margin-sm-0 p-4">
            <div className="card bg-warning">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-start">
                  <div className="color-card">
                    <p className="mb-0 color-card-head">Artist Profile Status</p>
                    <h2 className="text-white">{data.profile_status}</h2>
                  </div>
                  <i className="card-icon-indicator mdi mdi-cube-outline bg-inverse-icon-warning"></i>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="col-xl-12 col-md-6 stretch-card grid-margin grid-margin-sm-0 pb-sm-3">
                <div className="card bg-danger">
                  <div className="card-body px-3 py-4">
                    <div className="d-flex justify-content-between align-items-start">
                      <div className="color-card">
                        <p className="mb-0 color-card-head">Margin</p>
                        <h2 className="text-white">$5,300.<span className="h5">00</span></h2>
                      </div>
                      <i className="card-icon-indicator mdi mdi-cube-outline bg-inverse-icon-danger"></i>
                    </div>
                    <h6 className="text-white">13.21% Since last month</h6>
                  </div>
                </div>
              </div>
              <div className="col-xl-12 col-md-6 stretch-card grid-margin grid-margin-sm-0 pb-sm-3 pb-lg-0 pb-xl-3">
                <div className="card bg-primary">
                  <div className="card-body px-3 py-4">
                    <div className="d-flex justify-content-between align-items-start">
                      <div className="color-card">
                        <p className="mb-0 color-card-head">Orders</p>
                        <h2 className="text-white">$1,753.<span className="h5">00</span></h2>
                      </div>
                      <i className="card-icon-indicator mdi mdi-briefcase-outline bg-inverse-icon-primary"></i>
                    </div>
                    <h6 className="text-white">67.98% Since last month</h6>
                  </div>
                </div>
              </div>
              <div className="col-xl-12 col-md-6 stretch-card pb-sm-3 pb-lg-0">
                <div className="card bg-success">
                  <div className="card-body px-3 py-4">
                    <div className="d-flex justify-content-between align-items-start">
                      <div className="color-card">
                        <p className="mb-0 color-card-head">Affiliate</p>
                        <h2 className="text-white">2368</h2>
                      </div>
                      <i className="card-icon-indicator mdi mdi-account-circle bg-inverse-icon-success"></i>
                    </div>
                    <h6 className="text-white">20.32% Since last month</h6>
                  </div>
                </div>
              </div> */}
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Artist Media Approved</h4>
              <Pie data={artistData} options={doughnutPieOptions} />
            </div>
          </div>
        </div>
        <div className="col-md-6 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Artist Media To Be Approved</h4>
              <Doughnut data={doughnutPieData} options={doughnutPieOptions} />
            </div>
          </div>
        </div>
      </div>
   
    </div>
  );
}

// const ListItem = (props) => {
//   return (
//       <li className={(props.isCompleted ? 'completed' : null)}>
//           <div className="form-check m-0 align-items-start">
//               <label htmlFor="" className="form-check-label font-weight-medium">
//                   <input className="checkbox" type="checkbox"
//                       checked={props.isCompleted}
//                       onChange={props.changed}
//                       /> {props.children} <i className="input-helper"></i>
//               </label>
//           </div>
//           <i className="remove mdi mdi-close-circle-outline" onClick={props.remove}></i>
//       </li>
//   )
// };
export default DashboardArtist;
