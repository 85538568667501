import { LOGIN_DETAILS ,FORGOT_EMAIL} from "../action/actiontypes";
const initialState = {
  token: "",
  loggedIn: false,
  message:""
  };
  const loginReducer = (state = initialState, action) => {
    switch (action.type) {
      case LOGIN_DETAILS:
        return {
          token: action.payload.result.accessToken,
          message:action.payload.result.message,
          loggedIn: action.payload.status,
        };
        
      default: return state
    }
  };
  export default loginReducer 