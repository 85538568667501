import axios from "axios";
import { toasterMessage } from "../../utils/toast-util";

export const roleList = () => {
  return async (dispatch) => {
    try {
      const response = await axios({
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        url: process.env.REACT_APP_BACKEND_API + `admin/roles`,
      });
      const { data } = response;
      if (data.status) {
        await dispatch({
          type: "ROLES_LIST",
          payload: data.result.data,
        });
      } else {
        toasterMessage("error", data.result.message);
      }
    } catch (error) {
      if (error.reponse) {
        console.log("error");
        toasterMessage("error", error.response.data.result.message);
      }
    }
  };
};

export const deleatRole= (id) => {
  return async (dispatch) => {
    try {
      const response = await axios({
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + (localStorage.getItem("token")),
        },
        url: process.env.REACT_APP_BACKEND_API+`admin/roles/${id}`,
        
      });
      const { data } = response;
      if (data.status) {

        toasterMessage("success", data.result.message);
        await dispatch(roleList());
      }else {
        toasterMessage("error", data.result.message);
      }
    } catch (error) {
      if (error.response) {
        console.log("error");
       
      }
       toasterMessage("error", error.response.data.result.message);
    }
  };
};
export const createRole = (value,history) => {
  return async (dispatch) => {
    try {
      const response = await axios({
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + (localStorage.getItem("token")),
        },
        url: process.env.REACT_APP_BACKEND_API+`admin/roles`,
        data: value,
        
      });
      const { data } = response;
      if (data.status) {
        toasterMessage("success",data.result.message);
        history.push('/roles-management')
      }else {
        toasterMessage("error", data.result.message);
      }
    } catch (error) {
      if (error.response) {
        console.log("error");
        toasterMessage("error", error.response.data.result.message);
      }
    }
  };
};


export const UpdateRole = (value,id,history) => {
  return async (dispatch) => {
    try {
      const response = await axios({
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + (localStorage.getItem("token")),
        },
        url: process.env.REACT_APP_BACKEND_API+`admin/roles/${id}`,
        data: value,
        
      });
      const { data } = response;
      if (data.status) {
        toasterMessage("success",data.result.message);
        history.push('/roles-management')
      }else {
        toasterMessage("error", data.result.message);
      }
    } catch (error) {
      if (error.reponse) {
        console.log("error");
        toasterMessage("error", "Something went Wrong");
      }
    }
  };
};

export const RoleById = (id) => {
  return async (dispatch) => {
    try {
      const response = await axios({
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        url: process.env.REACT_APP_BACKEND_API + `admin/roles/${id}`,
      });
      const { data } = response;
      if (data.status) {
        await dispatch({
          type: "ROLE_DETAIL",
          payload: data.result.data,
        });
      } else {
        toasterMessage("error", data.result.message);
      }
    } catch (error) {
      if (error.reponse) {
        console.log("error");
        toasterMessage("error", "Something went Wrong");
      }
    }
  };
};