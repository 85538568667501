import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { connect } from "react-redux";
import { Modal, Tooltip, OverlayTrigger } from "react-bootstrap";
import {
  getArtistType,
  getSpecialities,
} from "../../Redux/action/categoriesManagement";
import { getTagList } from "../../Redux/action/categoriesManagement";
import {
  artistList,
  artistListFilter,
  deleatArtist,
  actionSetArtistType,
  actionSetSpecialityType,
  actionSetTagsType,
  actionSetGenreType
} from "../../Redux/action/artistList";
import { optionDetail } from "../../Redux/action/artistDetail";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import Select, { components } from "react-select";
import { Button } from "react-bootstrap";
import Multiselect from "multiselect-react-dropdown";
import dp from '../../assets/images/artist.png';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const { SearchBar } = Search;
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const defaultSorted = [
  {
    dataField: "id",
    order: "desc",
  },
];
const status = [
  { value: 0, label: "Inactive" },
  { value: 1, label: "Active" },
];
const approvedStatus = [
  { value: "Pending", label: "Pending" },
  { value: "Approved", label: "Approved" },
  { value: "Rejected", label: "Rejected" },
  { value: "Audition", label: "Audition" },
];
const MyExportCSV = (props) => {
  const handleClick = () => {
    props.onExport();
  };
  return (
    <div>
      <button className="btn yellow-btn btn-icon-tex" onClick={handleClick}>
        <i className="fa fa-download "></i> Export
      </button>
    </div>
  );
};
const styles = {
  menu: (base) => ({
    ...base,
    marginTop: "4px",
    "font-size": "0.875rem",
    width: "100% ",
    // maxHeight: "100px",
    // overflowY: "initial",
    passive: "true",
  }),
  control: (base) => ({
    ...base,
    "font-size": "0.875rem",
  }),
};
export class ArtistList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: false,
      valid: true,
      status: 1,
      approvedStatus: "Approved",
      artistType: this.props && this.props.get_artist_type,
      specialities: this.props && this.props.get_speciality_type,
      mdShow: false,
      tags: this.props && this.props.get_tags_type,
      genres: this.props && this.props.get_genre_type,
      deleateId: 0,
      managerName: [],
    };
    this.handleFilter = this.handleFilter.bind(this);
    this.resetFilter = this.resetFilter.bind(this);
    this.statusRef = React.createRef();
    this.tagRef = React.createRef();
    this.approvalRef = React.createRef();
    this.artistTypeRef = React.createRef();
    this.specialitiesRef = React.createRef();
    this.genreRef = React.createRef();
    var show = false;
  }

  componentDidMount() {
    var value = {
      status: this.state.status != null ? this.state.status : null,
      approvedStatus: this.state.approvedStatus,
      artistType: this.state.artistType,
      specialities: this.state.specialities,
      genres: this.state.genres,
      tags: this.state.tags
    };
    this.props.getSpecialities();
    this.props.getArtistType();
    this.props.artistListFilter(value);
    this.props.getTagList();
    this.state.artistType && this.props.optionDetail(this.state.artistType);
  }
  columns = [
    {
      dataField: "id",
      text: "Company ID",
      sort: true,
      style: { padding: "0px 25px 0px 0.9375rem" },
    },
    {
      dataField: "stageName",
      text: "Stage Name",
      sort: true,
      style: { padding: "0px 25px 0px 0.9375rem" },
    },
    {
      dataField: "passportName",
      text: "Name on Passport",
      sort: true,
      style: { padding: "0px 25px 0px 0.9375rem" },
    },
    {
      dataField: "email",
      text: "Email Address",
      sort: true,
      style: { padding: "0px 25px 0px 0.9375rem" },
    },
    {
      dataField: "nationality",
      text: "Nationality",
      sort: true,
      style: { padding: "0px 25px 0px 0.9375rem" },
    },
    {
      dataField: "country",
      text: "Country of residence",
      sort: true,
      style: { padding: "0px 25px 0px 0.9375rem" },
    },
    {
      dataField: "city",
      text: "City",
      sort: true,
      style: { padding: "0px 25px 0px 0.9375rem" },
    },
    {
      dataField: "tags",
      text: "Tags",
      sort: true,
      style: { padding: "0px 25px 0px 0.9375rem" },
    },
    {
      dataField: "artist_types",
      text: "Artist Type",
      sort: true,
      style: { padding: "0px 25px 0px 0.9375rem" },
    },
    {
      dataField: "specialities",
      text: "Artist Speciality",
      sort: true,
      style: { padding: "0px 25px 0px 0.9375rem" },
    },
    {
      dataField: "instuments",
      text: "Genres",
      sort: true,
      style: { padding: "0px 25px 0px 0.9375rem" },
    },

    {
      dataField: "approval_status",
      text: "Approved Status",
      sort: true,
      style: { padding: "0px 25px 0px 0.9375rem" },
    },
    {
      dataField: "approved_by",
      text: "Approved by",
      sort: true,
      style: { padding: "0px 25px 0px 0.9375rem" },
    },
    {
      dataField: "media_status",
      text: "Media Status",
      sort: true,
      style: { padding: "0px 25px 0px 0.9375rem" },
    },
    // {
    // dataField: "approved_date",
    // text: "Approved date",
    // sort: true,
    // style: { padding: "0px 0 0px 25px" },
    // formatter: (cellContent) => {
    // return <Moment format="DD/MM/YYYY">{cellContent}</Moment>;
    // },
    // csvFormatter: (cellContent) => {
    // const today = new Date(cellContent);
    // return new Intl.DateTimeFormat("en-US", {
    // year: "numeric",
    // month: "2-digit",
    // day: "2-digit",
    // }).format(today);
    // },
    // },
    {
      dataField: "approval_comment",
      text: "Comments",
      sort: true,
      style: { padding: "0px 25px 0px 0.9375rem" },
    },
    {
      dataField: "createdAt",
      text: "Created Date",
      sort: true,
      style: { padding: "0px 25px 0px 0.9375rem" },
      formatter: (cellContent) => {
        return <Moment format="DD/MM/YYYY">{cellContent}</Moment>;
      },
      csvFormatter: (cellContent) => {
        const today = new Date(cellContent);
        return new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }).format(today);
      },
    },
    {
      dataField: "updatedAt",
      text: "Updated Date",
      sort: true,
      style: { padding: "0px 25px 0px 0.9375rem" },
      formatter: (cellContent) => {
        return <Moment format="DD/MM/YYYY">{cellContent}</Moment>;
      },
      csvFormatter: (cellContent) => {
        const today = new Date(cellContent);
        return new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }).format(today);
      },
    },
    {
      dataField: "active_status",
      text: "Status",
      sort: true,
      style: { padding: "0px 25px 0px 0.9375rem" },
      formatter: (cellContent, row) => {
        if (cellContent === 1) {
          return <label className="badge badge-warning">Active</label>;
        } else {
          return <label className="badge badge-success">Inactive</label>;
        }
      },
      csvFormatter: (cellContent) => {
        if (cellContent === 1) {
          return "Active";
        } else {
          return "Inactive";
        }
      },
    },
  ];
  columns_action = [
    {
      dataField: "id",
      text: "Company ID",
      sort: true,
      style: { padding: "0px 25px 0px 0.9375rem" },
      hidden: true
    },
    {
      dataField: "profile_pic",
      text: "Profile pic",
      sort: true,
      csvExport: false,
      style: { padding: "0px 25px 0px 0.9375rem" },
      formatter: (cellContent) => {
        // let profilePic = process.env.REACT_APP_IMAGE_LINK + cellContent;
        if (cellContent) {
          return (
            <img
              src={cellContent.includes("https") ? cellContent : dp}
              alt="User Avatar"
              style={{ width: "50px", height: "50px" }}
            />
          );
        } else {
          return (
            <img
              src={dp}
              alt="Default Avatar"
              style={{ width: "50px", height: "50px" }}
            />
          );
        }
      },
    },
    {
      dataField: "stageName",
      text: "Stage Name",
      sort: true,
      style: { padding: "10px 50px 10px 0.9375rem" },
      formatter: (cellContent, row) => {
        return (
          <Link to={{ pathname: `/view-artist/${row.id}`, state: { editFlag: false } }} style={{ color: 'black' }} >
            {cellContent}
          </Link>
        );
      },
    },
    {
      dataField: "artist_types",
      text: "Artist Type",
      sort: true,
      style: { padding: "10px 50px 10px 0.9375rem" },
    },
    {
      dataField: "specialities",
      text: "Artist Speciality",
      sort: true,
      style: { padding: "10px 50px 10px 0.9375rem" },
    },
    {
      dataField: "instuments",
      text: "Genres",
      sort: true,
      style: { padding: "10px 50px 10px 0.9375rem" },
      csvFormatter: (cellContent) => {
        return cellContent === null ? 'N/A' : cellContent;
      },
    },
    {
      dataField: "nationality",
      text: "Nationality",
      sort: true,
      style: { padding: "10px 50px 10px 0.9375rem" },
      csvFormatter: (cellContent) => {
        return cellContent === null ? 'N/A' : cellContent;
      },
    },
    {
      dataField: "country",
      text: "Country of residence",
      sort: true,
      style: { padding: "10px 50px 10px 0.9375rem" },
      csvFormatter: (cellContent) => {
        return cellContent === null ? 'N/A' : cellContent;
      },
    },
    {
      dataField: "approval_status",
      text: "Approved Status",
      sort: true,
      style: { padding: "10px 50px 10px 0.9375rem" },
      csvFormatter: (cellContent) => {
        return cellContent === null ? 'N/A' : cellContent;
      },
    },
    {
      dataField: "media_status",
      text: "Media Status",
      sort: true,
      style: { padding: "10px 50px 10px 0.9375rem" },
      csvFormatter: (cellContent) => {
        return cellContent === null ? 'N/A' : cellContent;
      },
    },
    {
      dataField: "passportName",
      text: "Name on Passport",
      sort: true,
      style: { padding: "0px 25px 0px 0.9375rem" },
      hidden: true,
      csvFormatter: (cellContent) => {
        return cellContent === null ? 'N/A' : cellContent;
      },
    },
    {
      dataField: "email",
      text: "Email Address",
      sort: true,
      style: { padding: "0px 25px 0px 0.9375rem" },
      hidden: true,
      csvFormatter: (cellContent) => {
        return cellContent === null ? 'N/A' : cellContent;
      },
    },
    {
      dataField: "city",
      text: "City",
      sort: true,
      style: { padding: "0px 25px 0px 0.9375rem" },
      hidden: true,
      csvFormatter: (cellContent) => {
        return cellContent === null ? 'N/A' : cellContent;
      },
    },
    {
      dataField: "tags",
      text: "Tags",
      sort: true,
      style: { padding: "0px 25px 0px 0.9375rem" },
      hidden: true,
      csvFormatter: (cellContent) => {
        return cellContent === null ? 'N/A' : cellContent;
      },
    },

    {
      dataField: "approved_by",
      text: "Approved by",
      sort: true,
      style: { padding: "0px 25px 0px 0.9375rem" },
      hidden: true,
      csvFormatter: (cellContent) => {
        return cellContent === null ? 'N/A' : cellContent;
      },
    },
    // {
    // dataField: "approved_date",
    // text: "Approved date",
    // sort: true,
    // style: { padding: "0px 0 0px 25px" },
    // formatter: (cellContent) => {
    // return <Moment format="DD/MM/YYYY">{cellContent}</Moment>;
    // },
    // csvFormatter: (cellContent) => {
    // const today = new Date(cellContent);
    // return new Intl.DateTimeFormat("en-US", {
    // year: "numeric",
    // month: "2-digit",
    // day: "2-digit",
    // }).format(today);
    // },
    // },
    {
      dataField: "approval_comment",
      text: "Comments",
      sort: true,
      style: { padding: "0px 25px 0px 0.9375rem" },
      hidden: true,
      csvFormatter: (cellContent) => {
        return cellContent === null ? 'N/A' : cellContent;
      },
    },
    {
      dataField: "createdAt",
      text: "Created Date",
      sort: true,
      hidden: true,
      style: { padding: "0px 25px 0px 0.9375rem" },
      formatter: (cellContent) => {
        return <Moment format="DD/MM/YYYY">{cellContent}</Moment>;
      },
      csvFormatter: (cellContent) => {
        const today = new Date(cellContent);
        return new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }).format(today);
      },
    },
    {
      dataField: "updatedAt",
      text: "Updated Date",
      sort: true,
      hidden: true,
      style: { padding: "0px 25px 0px 0.9375rem" },
      formatter: (cellContent) => {
        return <Moment format="DD/MM/YYYY">{cellContent}</Moment>;
      },
      csvFormatter: (cellContent) => {
        const today = new Date(cellContent);
        return new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }).format(today);
      },
    },
    {
      dataField: "active_status",
      text: "Status",
      sort: true,
      hidden: true,
      style: { padding: "0px 25px 0px 0.9375rem" },
      formatter: (cellContent, row) => {
        if (cellContent === 1) {
          return <label className="badge badge-warning">Active</label>;
        } else {
          return <label className="badge badge-success">Inactive</label>;
        }
      },
      csvFormatter: (cellContent) => {
        if (cellContent === 1) {
          return "Active";
        } else {
          return "Inactive";
        }
      },
    },
    {
      dataField: "action",
      text: "Action",
      sort: false,
      style: { padding: "0px 25px 0px 0.9375rem", width: "16em" },
      csvExport: false,
      formatter: (cellContent, row) => {
        return (
          <div style={{ display: "flex" }}>
            <div>
              {this.props.permissions.artistManagement.view ? (
                <Link className="btn edit-icons" to={{ pathname: `/view-artist/${row.id}`, state: { editFlag: false } }} >
                  <OverlayTrigger
                    key="top"
                    placement="top"
                    overlay={<Tooltip id="tooltip">View Details</Tooltip>}
                  >
                    <i class="ti-eye"></i>
                  </OverlayTrigger>
                </Link>
              ) : null}
            </div>
            <div>
              {this.props.permissions.artistManagement.edit ? (
                <Link className="btn edit-icons" to={{ pathname: `/update-artist/${row.id}`, state: { editFlag: true } }} >
                  <OverlayTrigger
                    key="top"
                    placement="top"
                    overlay={<Tooltip id="tooltip">Edit Details</Tooltip>}
                  >
                    <i class="ti-pencil"></i>
                  </OverlayTrigger>
                </Link>
              ) : null}
            </div>

            {
              this.props.permissions.artistManagement.delete ? (
                <div>
                  <button
                    className="btn edit-icons"
                    onClick={(e) => {
                      // this.props.deleatArtist(row.id);
                      this.show = true;
                      this.setState({ mdShow: true, deleateId: row.id });
                    }}
                  >
                    <OverlayTrigger
                      key="top"
                      placement="top"
                      overlay={<Tooltip id="tooltip">Delete Details</Tooltip>}
                    >
                      <i class="ti-trash"></i>
                    </OverlayTrigger>
                  </button>
                </div>
              ) : null
            }
            {
              this.props.permissions.artistManagement.edit ? (
                <div>
                  <Link className="btn edit-icons" to={`/manage-posts/${row.id}`}>
                    <OverlayTrigger
                      key="top"
                      placement="top"
                      overlay={<Tooltip id="tooltip">Edit Post Details</Tooltip>}
                    >
                      <i class="ti-pencil-alt"></i>
                    </OverlayTrigger>
                  </Link>
                </div>
              ) : null
            }
          </div >
        );
      },
    },
  ];

  handleFilter() {
    var value = {
      status: this.state.status != null ? this.state.status : null,
      approvedStatus: this.state.approvedStatus,
      artistType: this.state.artistType,
      specialities: this.state.specialities,
      genres: this.state.genres,
      tags: this.state.tags
    };
    if (
      this.state.status != null ||
      this.state.approvedStatus ||
      this.state.artistType ||
      this.state.specialities ||
      this.state.genres ||
      this.state.tags
    ) {
      this.props.artistListFilter(value);
    }
  }
  resetFilter() {
    this.statusRef && this.statusRef.current && this.statusRef.current.select && this.statusRef.current.select.setValue(null);
    this.tagRef && this.tagRef.current && this.tagRef.current.resetSelectedValues();
    this.approvalRef && this.approvalRef.current && this.approvalRef.current.select && this.approvalRef.current.select.setValue(null);
    this.artistTypeRef && this.artistTypeRef.current && this.artistTypeRef.current.select && this.artistTypeRef.current.select.setValue(null);
    this.specialitiesRef && this.specialitiesRef.current && this.specialitiesRef.current.resetSelectedValues();
    this.genreRef && this.genreRef.current && this.genreRef.current.resetSelectedValues();
    this.props.artistList();
    this.props.actionSetArtistType('');
    this.props.actionSetGenreType('');
    this.props.actionSetSpecialityType('');
    this.props.actionSetTagsType('');
    this.setState({
      status: null,
      approvedStatus: null,
      artistType: null,
      specialities: [],
      genres: [],
      tags: [],
    });
  }
  resetFilterSpecific() {
    this.specialitiesRef && this.specialitiesRef.current && this.specialitiesRef.current.resetSelectedValues();
    this.genreRef && this.genreRef.current && this.genreRef.current.resetSelectedValues();
    this.setState({
      specialities: [],
      genres: [],
    });
  }

  render() {
    return (
      <div>
        <div className="page-header">
          <h3 className="page-title">Artist Management</h3>

          <div className="breadcrumb">
            {this.props.permissions.artistManagement?.add ?
              <Link
                className="btn btn-block yellow-btn font-weight-medium auth-form-btn"
                to={{ pathname: "/add-artist", state: { editFlag: true } }}
              >
                + NEW
              </Link>
              : null}
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="row justify-content-end mb-2 mr-1">
              <Button
                className=" yellow-btn "
                onClick={(e) => this.handleFilter()}
              >
                Apply
              </Button>
              <Button
                className=" yellow-btn"
                onClick={(e) => this.resetFilter()}
              >
                Reset
              </Button>
            </div>
            <div className="row mb-2">
              <div className="col">
                <Select
                  styles={styles}
                  options={status}
                  onChange={(e) => {
                    e && this.setState({ status: e.value });
                  }}
                  defaultValue={{ value: 1, label: "Active" }}
                  placeholder="Status"
                  ref={this.statusRef}
                />
              </div>
              <div className="col">
                <Select
                  styles={styles}
                  options={approvedStatus}
                  onChange={(e) => {
                    e && this.setState({ approvedStatus: e.value });
                  }}
                  defaultValue={{ value: "Approved", label: "Approved" }}
                  placeholder="Approved Status"
                  ref={this.approvalRef}
                />
              </div>
              <div className="col">
                <Select
                  styles={styles}
                  maxMenuHeight={100}
                  value={this.props.artistTypeList.find((option) => option.id === this.state.artistType)} // Match the state value to an option
                  options={this.props.artistTypeList}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  onChange={(e) => {
                    e && this.setState({ artistType: e.id });
                    e && this.props.optionDetail(e.id);
                    e && this.resetFilterSpecific();
                    e && this.props.actionSetArtistType(e.id)
                  }}
                  placeholder="Artist Type"
                  ref={this.artistTypeRef}
                />
              </div>
              <div className="col">
                <Multiselect
                  options={this.props.specialityList}
                  customCloseIcon={
                    <i class="typcn typcn-delete ml-1"></i>
                  }
                  selectedValues={this.props.specialityList.filter((option) => this.state.specialities.includes(option.id))}
                  showCheckbox={true}
                  hidePlaceholder={true}
                  displayValue={"name" || null}
                  showArrow
                  style={{
                    searchBox: { // To change search box element look
                      background: 'white',
                      height: "40px",
                      overflow: 'auto'
                    },
                    chips: {
                      background: '#F6F4ED',
                      color: 'black',
                      maxWidth: 'calc(100% - 32px)', // Reduce the max width of chips to fit within the search box
                      overflow: 'hidden', // Hide the overflow content
                      whiteSpace: 'nowrap', // Prevent wrapping of long names
                      textOverflow: 'ellipsis', // Show ellipsis for long names
                    },
                    options: {
                      "fontSize": "0.875rem",
                    },
                    "fontSize": "0.875rem",
                  }}
                  ref={this.specialitiesRef}
                  onSelect={(s) => {
                    let arrSpeciality = [];
                    s.map((t, i) => {
                      arrSpeciality.push(t.id)
                    })
                    this.setState({ specialities: arrSpeciality })
                    this.props.actionSetSpecialityType(arrSpeciality)
                  }}
                  onRemove={(r) => {
                    let arrSpeciality = [];
                    if (r) {
                      r.map((t) => {
                        arrSpeciality.push(t.id)
                      })
                    }
                    this.setState({ specialities: arrSpeciality })
                    this.props.actionSetSpecialityType(arrSpeciality)
                  }}
                  placeholder="Specialities"
                />
              </div>
              <div className="col">
                <Multiselect
                  options={this.props.genres}
                  customCloseIcon={
                    <i class="typcn typcn-delete ml-1"></i>
                  }
                  selectedValues={this.props.genres.filter((option) => this.state.genres.includes(option.id))}
                  displayValue={"name" || null} // Property name to display in the dropdown options
                  showArrow
                  showCheckbox={true}
                  hidePlaceholder={true}
                  style={{
                    searchBox: { // To change search box element look
                      background: 'white',
                      height: "40px",
                      overflow: 'auto'
                    },
                    chips: {
                      background: '#F6F4ED',
                      color: 'black',
                      maxWidth: 'calc(100% - 32px)', // Reduce the max width of chips to fit within the search box
                      overflow: 'hidden', // Hide the overflow content
                      whiteSpace: 'nowrap', // Prevent wrapping of long names
                      textOverflow: 'ellipsis', // Show ellipsis for long names
                    },
                    options: {
                      "fontSize": "0.875rem",
                    },
                    "fontSize": "0.875rem",
                  }}
                  ref={this.genreRef}
                  onSelect={(g) => {
                    let arrGenre = [];
                    g.map((t, i) => {
                      arrGenre.push(t.id)
                    })
                    this.setState({ genres: arrGenre })
                    this.props.actionSetGenreType(arrGenre)
                  }}
                  onRemove={(r) => {
                    let arrGenre = [];
                    if (r) {
                      r.map((t) => {
                        arrGenre.push(t.id)
                      })
                    }
                    this.setState({ genres: arrGenre })
                    this.props.actionSetGenreType(arrGenre)
                  }}
                  placeholder="Genres"
                />
              </div>
              <div className="col" style={{ maxHeight: '40px' }}>
                <Multiselect
                  options={this.props.tagList}
                  customCloseIcon={
                    <i class="typcn typcn-delete ml-1"></i>
                  }
                  selectedValues={this.props.tagList.filter((option) => this.state.tags.includes(option.id))}
                  displayValue={"name" || null} // Property name to display in the dropdown options
                  showArrow
                  showCheckbox={true}
                  hidePlaceholder={true}
                  style={{
                    searchBox: { // To change search box element look
                      background: 'white',
                      height: "40px",
                      overflow: 'auto'
                    },
                    chips: {
                      background: '#F6F4ED',
                      color: 'black',
                      maxWidth: 'calc(100% - 32px)', // Reduce the max width of chips to fit within the search box
                      overflow: 'hidden', // Hide the overflow content
                      whiteSpace: 'nowrap', // Prevent wrapping of long names
                      textOverflow: 'ellipsis', // Show ellipsis for long names
                    },
                    options: {
                      "fontSize": "0.875rem",
                    },
                    "fontSize": "0.875rem",
                  }}
                  ref={this.tagRef}
                  onSelect={(e) => {
                    let arr = [];
                    e.map((t, i) => {
                      arr.push(t.id)
                    })
                    this.setState({ tags: arr })
                    this.props.actionSetTagsType(arr)
                  }}
                  onRemove={(r) => {
                    let arr = [];
                    if (r) {
                      r.map((t) => {
                        arr.push(t.id)
                      })
                    }
                    this.setState({ tags: arr })
                    this.props.actionSetTagsType(arr)
                  }}
                  placeholder="Tags"
                />
              </div>
            </div>
            <Modal
              show={this.show}
              onHide={() => {
                this.setState({ mdShow: false });
                this.show = false;
              }}
              aria-labelledby="example-modal-sizes-title-md"
            >
              <Modal.Header closeButton>
                <Modal.Title style={{ width: "100%", "text-align": "center" }}>
                  Are You Sure, You Want To Delete ?
                </Modal.Title>
              </Modal.Header>
              <Modal.Footer
                className="fleex-wrap"
                style={{ justifyContent: "center" }}
              >
                <Button
                  variant="success m-2 yellow-btn"
                  onClick={(e) => {
                    var value = {
                      status: this.state.status != null ? this.state.status : null,
                      approvedStatus: this.state.approvedStatus,
                      artistType: this.state.artistType,
                      specialities: this.state.specialities,
                      genres: this.state.genres,
                      tags: this.state.tags
                    };
                    this.props.deleatArtist(this.state.deleateId, value);
                    this.show = false;
                    this.setState({ mdShow: false });
                  }}
                >
                  Yes
                </Button>
                <Button
                  variant="light m-2 yellow-btn"
                  onClick={() => {
                    this.setState({ mdShow: false });
                    this.show = false;
                  }}
                >
                  Cancel
                </Button>
              </Modal.Footer>
            </Modal>
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-12">
                    <ToolkitProvider
                      keyField="id"
                      bootstrap4
                      data={this.props.artist_list || []}
                      columns={
                        this.props.permissions.artistManagement?.edit ||
                          this.props.permissions.artistManagement?.delete
                          ? this.columns_action
                          : this.columns
                      }
                      search
                      exportCSV={{
                        fileName: "Artist List.csv",
                      }}
                    >
                      {(props) => (
                        <div style={{ display: "grid" }}>
                          <div className="page-header">
                            <div className="d-flex align-items-center">
                              <p className="mb-2 mr-2">Search:</p>
                              <SearchBar {...props.searchProps} />{" "}
                            </div>
                            {this.props.permissions.artistManagement.export ?
                              <MyExportCSV {...props.csvProps} />
                              : null}
                          </div>
                          {/* <div className="container"> */}
                          <BootstrapTable
                            defaultSorted={defaultSorted}
                            pagination={paginationFactory()}
                            noDataIndication={() => (
                              <div className="row">
                                <div className="col-sm-12 text-center">
                                  <h4 className="text-center">
                                    {" "}
                                    No Records Found
                                  </h4>
                                </div>
                              </div>
                            )}
                            {...props.baseProps}
                            wrapperClasses="table-responsive"
                          />
                          {/* </div> */}
                        </div>
                      )}
                    </ToolkitProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    );
  }
}

function mapStateToProps(state) {
  return {
    usersDetails: state.UserManagementReducer.usersDetails,
    specialityList: state.ArtistManagementReducer.specialty_options,
    artistTypeList: state.CategoriesManagementReducer.artistTypeList,
    artist_list: state.ArtistManagementReducer.artist_list,
    genres: state.ArtistManagementReducer.genres_options,
    tagList: state.CategoriesManagementReducer.tagList,
    permissions: state.PermissionReducer.permissionDetails,
    get_artist_type: state.ArtistManagementReducer.get_artist_type,
    get_speciality_type: state.ArtistManagementReducer.get_speciality_type,
    get_tags_type: state.ArtistManagementReducer.get_tags_type,
    get_genre_type: state.ArtistManagementReducer.get_genre_type,
  };
}

export default connect(mapStateToProps, {
  artistListFilter,
  getSpecialities,
  deleatArtist,
  getArtistType,
  artistList,
  optionDetail,
  getTagList,
  actionSetArtistType,
  actionSetSpecialityType,
  actionSetTagsType,
  actionSetGenreType
})(ArtistList);
