import React, { Component, useState } from "react";
import { useRef, useEffect, useCallback } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import { useParams } from "react-router-dom";
import ImageUploading from "react-images-uploading";
import { Form, InputGroup, Col, Row } from "react-bootstrap";
import { countryDeatils, Currency } from "../../assets/country";
import Multiselect from "multiselect-react-dropdown";
import { useDispatch, useSelector } from "react-redux";
import { optionDetail } from "../../Redux/action/artistDetail";
import { getTagList } from "../../Redux/action/categoriesManagement";
import { getArtistType } from "../../Redux/action/categoriesManagement";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import ArtistDemo from "../../assets/images/artist.png";
import { toasterMessage } from "../../utils/toast-util";
import { artistComp } from "../../Redux/action/artistLogin";
import { useHistory } from "react-router-dom";

function ArtistRegister() {
  const [mdShow, setMdShow] = useState(false);
  const [validated, setValidated] = useState(false);
  const [typeSelected, setTypeSelected] = useState(null);
  const [typeSelectedSpeciality, setTypeSelectedSpeciality] = useState([]);
  const [typeSelectedGenre, setTypeSelectedGenre] = useState([]);
  const [country, setCountry] = useState(null);
  const [countryError, setCountryError] = useState(null);
  const [mobileNumber1, setmobileNumber1] = useState(null);
  const [checkMobileNumber, setCheckMobileNumber] = useState(false);
  const [mobileNumber2, setmobileNumber2] = useState(null);
  const [nationality, setNationality] = useState(null);
  const [imagePreview, setImagePreview] = useState(ArtistDemo);
  const [imageFile1, setImageFile] = useState(null);
  const [profileimageFile, setProfileImageFile] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const dispatch = useDispatch();
  const tagRef = useRef(null);
  const CameraIcon = useRef(null);
  const specialtyRef = useRef(null);
  const genreRef = useRef(null);
  const [videoLink, setVideoLink] = useState(null);
  const [publish, setPublish] = useState(false);
  const [image, setImage] = useState(null);
  const [description, setDescription] = useState("");
  const [videodescription, setVideodescription] = useState("");
  const [publishVideo, setPublishvideo] = useState(false);
  const [postImageArray, setPostImageArray] = useState([]);
  const [postVideoArray, setPostVideoArray] = useState([]);
  const params = useParams();
  const history = useHistory();
  const [deleatImage, setDeleatImage] = useState([]);
  const [deleatVideo, setDeleatVideo] = useState([]);
  const [submitted, setSubmitted] = useState(false);

  const artistList = useSelector(
    (state) => state.CategoriesManagementReducer.artistTypeList
  );
  const specialty = useSelector(
    (state) => state.ArtistManagementReducer.specialty_options
  );
  const genres = useSelector(
    (state) => state.ArtistManagementReducer.genres_options
  );
  const tags_list = useSelector(
    (state) => state.CategoriesManagementReducer.tagList
  );
  useEffect(() => {
    dispatch(getArtistType());
    dispatch(getTagList());
  }, []);

  const handleSubmit = (event) => {
    if (mobileNumber1 === null || mobileNumber1.length < 11) {
      toasterMessage("error", "Please enter mobile number");
      setCheckMobileNumber(true);
    }
    if (!event.target.instagram.value) {
      hasError = true;
      toasterMessage("error", "Please provide your instagram URL");
    }
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    event.preventDefault();
    setValidated(true);
    if (!event.target.terms?.checked) {
      toasterMessage("error", "Please agree Terms");
    }
    var localSpecialty = [];
    specialtyRef.current.getSelectedItems().forEach((item) => {
      localSpecialty.push(item.id);
    });
    var localGenres = [];
    genreRef.current.getSelectedItems().forEach((item) => {
      localGenres.push(item.id);
    });
    var hasError = false;
    hasError = false;
    if (specialty.length > 0) {
      console.log("test", localSpecialty.length);
      if (localSpecialty.length <= 0) {
        hasError = true;
        toasterMessage("error", "Please select Speciality");
      }
    }
    if (!imageFile1) {
      event.preventDefault();
      setIsSubmitted(true);
      toasterMessage(
        "error",
        "Please upload your profile image."
      );
    }

    if (genres.length > 0) {
      if (localGenres.length <= 0) {
        hasError = true;
        toasterMessage("error", "Please select Genres");
      }
    }
    if (typeSelected) {
      event.preventDefault();
    } else {
      // setCountryError(true);
      setSubmitted(true);
      toasterMessage(
        "error",
        "Please provide all required General Information."
      );
      event.preventDefault();
    }
    if (
      form.checkValidity() === true &&
      typeSelected &&
      mobileNumber1 &&
      event.target.terms?.checked &&
      !hasError
    ) {
      let formData = new FormData();
      formData.append("stageName", event.target.stagename?.value);

      formData.append("artistType", typeSelected?.id);
      formData.append("specialities", localSpecialty);
      formData.append("genres", localGenres);
      formData.append("bio", event.target.biography?.value);
      formData.append("country", country?.country);
      formData.append("city", event.target.city?.value);
      formData.append("soundcloudURL", event.target.audioLink.value);
      formData.append("fbURL", event.target.facebook.value);
      formData.append("ytURL", event.target.youtube.value);
      formData.append("instaURL", event.target.instagram.value);
      formData.append("firstName", event.target.firstName.value);
      formData.append("lastName", event.target.lastname.value);
      formData.append("link", params.id);

      formData.append("phone1", mobileNumber1);

      if (imageFile1) {
        formData.append("artist_profile_pic", imageFile1);
      } else {
        formData.append("artist_profile_pic", null);
      }
      if (deleatImage.length > 0) {
        for (var x = 0; x < deleatImage.length; x++) {
          formData.append("delete_images_array", deleatImage[x]);
        }
      }
      if (deleatVideo.length > 0) {
        for (var x = 0; x < deleatVideo.length; x++) {
          formData.append("delete_videos_array", deleatVideo[x]);
        }
      }
      console.log(postImageArray);
      if (postImageArray.length > 0) {
        for (var x = 0; x < postImageArray.length; x++) {
          if (postImageArray[x]?.isNew) {
            formData.append("artist_images", postImageArray[x].file);
            formData.append("new_images_desc", postImageArray[x].media_desc);
            formData.append(
              "new_images_status",
              postImageArray[x].active_status ? 1 : 0
            );
          } else {
            formData.append("prev_images_id", postImageArray[x].id);
            formData.append("prev_images_desc", postImageArray[x].media_desc);
            formData.append(
              "prev_images_status",
              postImageArray[x].active_status ? 1 : 0
            );
            // formData.append("page_images", null);
          }
        }
      } else {
        formData.append("artist_images", null);
      }
      if (postVideoArray.length > 0) {
        for (var x = 0; x < postVideoArray.length; x++) {
          if (postVideoArray[x]?.isNew) {
            formData.append("new_videos_link", postVideoArray[x].media_link);
            formData.append("new_videos_desc", postVideoArray[x].media_desc);
            formData.append(
              "new_videos_status",
              postVideoArray[x].active_status ? 1 : 0
            );
          } else {
            formData.append("prev_videos_id", postVideoArray[x].id);
            formData.append("prev_videos_desc", postVideoArray[x].media_desc);
            formData.append(
              "prev_videos_status",
              postVideoArray[x].active_status ? 1 : 0
            );
            // formData.append("new_videos_link ", null);
          }
        }
      } else {
        formData.append("new_videos_link ", null);
      }

      setMdShow(true);
      dispatch(artistComp(formData, history));
    }
  };

  const addPost = () => {
    if (image) {
      setPostImageArray([
        ...postImageArray,
        {
          image: image,
          active_status: publish,
          file: profileimageFile,
          isNew: true,
          media_desc: description,
        },
      ]);
      setProfileImageFile();
      setImage();
      setPublish(false);
      setDescription("");
    } else {
      toasterMessage("error", "Please add an image before adding Post.");
    }
  };
  const addVideoPost = () => {
    if (videoLink) {
      setPostVideoArray([
        ...postVideoArray,
        {
          media_link: videoLink,
          active_status: publishVideo,
          isNew: true,
          media_desc: videodescription,
        },
      ]);
      setVideoLink();
      setPublishvideo(false);
      setVideodescription("");
    } else {
      toasterMessage("error", "Please add a video link before adding Post");
    }
  };
  const editPosts = (index, valueRecived, change) => {
    // let newState = postImageArray;
    console.log(valueRecived, "value");
    const newState = postImageArray.map((obj, i) => {
      // 
      if (i === index) {
        if (change === 1) {
          return { ...obj, media_title: valueRecived };
        } else {
          return { ...obj, media_desc: valueRecived };
        }
      }

      // 
      return obj;
    });
    console.log(newState, "newState");
    setPostImageArray(newState);
  };
  const editVideoPosts = (index, valueRecived, change) => {
    // let newState = postImageArray;
    console.log(valueRecived, "value");
    const newState = postVideoArray.map((obj, i) => {
      // 
      if (i === index) {
        if (change === 1) {
          return { ...obj, media_title: valueRecived };
        } else {
          return { ...obj, media_desc: valueRecived };
        }
      }

      // 
      return obj;
    });
    console.log(newState, "newState");
    setPostVideoArray(newState);
  };
  const unPublish = (index, value) => {
    // let newState = postImageArray;
    const newState = postImageArray.map((obj, i) => {
      // 
      if (i === index) {
        console.log("found");
        return { ...obj, active_status: !value };
      }

      // 
      return obj;
    });
    setPostImageArray(newState);
  };
  const unPublishVideo = (index, value) => {
    const newState = postVideoArray.map((obj, i) => {
      // 
      if (i === index) {
        console.log("found");
        return { ...obj, active_status: !value };
      }

      // 
      return obj;
    });
    setPostVideoArray(newState);
  };
  const deleatPost = (index) => {
    if (postImageArray[index]?.isNew === false) {
      setDeleatImage([...deleatImage, postImageArray[index].id]);
    }
    setPostImageArray(postImageArray.filter((item, i) => i !== index));
  };
  const deleatVideoPost = (index) => {
    if (postVideoArray[index]?.isNew === false) {
      setDeleatVideo([...deleatVideo, postVideoArray[index].id]);
    }
    setPostVideoArray(postVideoArray.filter((item, i) => i !== index));
  };
  const styles = {
    menu: (base) => ({
      ...base,
      marginTop: "4px",
      fontSize: "0.875rem",

      passive: "true",
    }),
    control: (base) => ({
      ...base,
      fontSize: "0.875rem",
      borderColor: (isDropdownEmpty && submitted) || (isCountryDropdownEmpty && submitted) ? 'red' : '#D3D3D3'
    }),
  };
  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImage(URL.createObjectURL(event.target.files[0]));
      setProfileImageFile(event.target.files[0]);
    }
  };
  const handleProfileImage = (e) => {
    let image_as_base64 = URL.createObjectURL(e.target.files[0]);
    let imageFile = e.target.files[0];
    if (!imageFile.name.match(/\.(jpg|jpeg|png|gif)$/)) {
      alert("Please select a valid image.");
    } else {
      setImagePreview(image_as_base64);
      setImageFile(imageFile);
      setIsSubmitted(false);
    }
  };
  const isDropdownEmpty = !typeSelected;
  const isCountryDropdownEmpty = !country;
  const isMultiselectEmpty = typeSelectedSpeciality?.length == 0 ? true : false;
  const isMultiselectEmptyGenre = typeSelectedGenre?.length == 0 ? true : false;

  return (
    <div>
      <div className="d-flex align-items-stretch auth auth-img-bg h-100">
        <div className="row flex-grow">
          <div className="col-lg-5 register-half-bg">

            <div className="auth-form-transparent text-left d-flex side_area ">
              <div className="">
                <div className="brand-logo">
                  <img
                    src={require("../../assets/images/logo.svg")}
                    alt="logo"
                  />
                </div>
                <h2 className="font-weight-bold">Join our artist and supplier roster</h2>
                <h4>
                  Register your details to become part of The Fridge roster of artists and event suppliers. Our team will contact you for opportunities at our events.
                </h4>
                <div className="brand-logo mt-5">
                  <img
                    src={require("../../assets/images/image2.png")}
                    alt="logo"
                  />
                </div>
              </div>

            </div>
            <div className="bottom_text">
              <h6>Terms & Conditions Privacy Policy</h6>
              <h6>copyrigtht@ 2022. All rights reserved</h6>
            </div>
          </div>
          <div
            className="col-lg-7 d-flex align-items-center justify-content-center"
            style={{ backgroundColor: "#FDFDFD" }}
          >
            <div className="text-left " style={{ margin: "5%" }}>
              <h2 className="font-weight-bold">Complete Your Profile</h2>
              <h4>
                Fill in your information below to complete your profile.
              </h4>
              <div className="artist-register-image mb-4 mt-3">
                <h6>
                  Upload Profile Image*
                </h6>
                <img
                  src={imagePreview}
                  alt=""
                  width="100"
                  className={`rounded-circle artist-image ${isSubmitted ? 'red-border' : ''}`}
                />
                <div
                  className="artist-register-icon-new"
                  onClick={(e) => CameraIcon.current.click()}
                >
                  <i className="fa fa-camera"></i>
                </div>
                <input
                  required
                  className="d-none"
                  ref={CameraIcon}
                  onChange={(e) => handleProfileImage(e)}
                  type="file"
                />
              </div>
              <Form
                className="forms-sample"
                noValidate
                validated={validated}
                onSubmit={(event) => handleSubmit(event)}
              >
                <>
                  <Form.Row>
                    <Form.Group as={Col} md="6" className="row">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className="col-sm-12 col-form-label artist-form"
                      >
                        First Name*
                      </Form.Label>
                      <div className="col-sm-12">
                        <Form.Control
                          type="text"
                          className="form-control"
                          required
                          placeholder="Enter your first name"
                          name="firstName"
                        // defaultValue={artist_detail.passportName}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide Your First Name.
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>

                    <Form.Group as={Col} md="6" className="row mr-1">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className=" col-sm-12 col-form-label"
                      >
                        Last Name*
                      </Form.Label>
                      <div className="col-sm-12">
                        <Form.Control
                          type="text"
                          className="form-control"
                          placeholder="Enter your last name "
                          name="lastname"
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide Your Last Name.
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md="6" className="row ">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className="col-sm-12 col-form-label"
                      >
                        Stage Name*
                      </Form.Label>
                      <div className="col-sm-12">
                        <Form.Control
                          type="text"
                          className="form-control"
                          required
                          placeholder="Stage Name"
                          name="stagename"
                        // defaultValue={artist_detail.stageName}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide a Stage Name.
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>
                    <Form.Group as={Col} md="6" className="row mr-1">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className="col-sm-12 col-form-label"
                      >
                        Phone Number*
                      </Form.Label>
                      <div className="col-sm-12">
                        <PhoneInput
                          id="phoneId"
                          inputProps={{
                            name: "phone",
                            required: true,
                          }}
                          country={"ae"}
                          minLength={12}
                          placeholder="Enter phone no."
                          className={`custom-input-fields ${checkMobileNumber ? 'red-border' : ''}`}
                          value={mobileNumber1}
                          onChange={(phone) => { setmobileNumber1(phone); setCheckMobileNumber(false); }}
                        />
                        <Form.Control.Feedback type="invalid" id="#phoneId">
                          Please provide a mobile number.
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md="6" className="row">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className="col-sm-12 col-form-label artist-form"
                      >
                        Country of residence*
                      </Form.Label>
                      <div className="col-sm-12">
                        <Select
                          styles={styles}
                          maxMenuHeight={100}
                          options={countryDeatils}
                          placeholder="Select Country"
                          value={country}
                          getOptionLabel={(option) => option.country}
                          getOptionValue={(option) => option.country}
                          onChange={(e) => {
                            setCountry(e);
                            setCountryError(false);
                          }}
                          required
                        />
                        <span
                          style={{
                            width: "100%",
                            fontSize: "0.875em",
                            color: "#dc3545",
                          }}
                        >
                          {countryError ? "Please select" : null}
                        </span>
                      </div>
                    </Form.Group>
                    <Form.Group as={Col} md="6" className="row mr-1">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className="col-sm-12 col-form-label artist-form"
                      >
                        City*
                      </Form.Label>
                      <div className="col-sm-12">
                        <Form.Control
                          type="text"
                          className="form-control"
                          required
                          placeholder="City"
                          name="city"
                        // defaultValue={artist_detail.city}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide City.
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md="12" className="row mr-1">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className="col-sm-12 col-form-label"
                      >
                        Website
                      </Form.Label>
                      <div className="col-sm-11">
                        <Form.Control
                          type="text"
                          className="form-control"
                          placeholder="Enter website URL"
                          name="website"
                        />
                      </div>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md="4" className="row">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className="col-sm-12 col-form-label"
                      >
                        Artist Type*
                      </Form.Label>
                      <div className="col-sm-12">
                        <Select
                          required
                          options={artistList}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          value={typeSelected}
                          maxMenuHeight={100}
                          placeholder="Select Artist Type"
                          styles={styles}
                          onChange={(e) => {
                            setTypeSelected(e);
                            specialtyRef.current.resetSelectedValues();
                            genreRef.current.resetSelectedValues();
                            dispatch(optionDetail(e.id));
                          }}
                        />

                        <span
                          style={{
                            width: "100%",
                            fontSize: "0.875em",
                            color: "#dc3545",
                          }}
                        ></span>
                      </div>
                    </Form.Group>
                    <Form.Group as={Col} md="4" className="row ">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className=" col-sm-12 col-form-label"
                      >
                        Artist Speciality*
                      </Form.Label>
                      <div className="col-sm-12">
                        <Multiselect
                          options={specialty}
                          customCloseIcon={
                            <i className="typcn typcn-delete ml-1"></i>
                          }
                          // selectedValues={valuesToUseSpeciality()}
                          displayValue={"name" || null} // Property name to display in the dropdown options
                          showArrow
                          style={{
                            searchBox: { // To change search box element look
                              borderColor: submitted && isMultiselectEmpty ? 'red' : null
                            },
                            chips: {
                              background: "#F6F4ED",
                              color: "black",
                            },
                            fontSize: "0.875rem",
                          }}
                          ref={specialtyRef}
                          onSelect={(s) => {
                            let arrSpeciality = [];
                            s.map((t, i) => {
                              arrSpeciality.push(t.id)
                            })
                            setTypeSelectedSpeciality(arrSpeciality)
                          }}
                          placeholder={
                            typeSelected
                              ? "Select Speciality"
                              : "Select Artist type first"
                          }
                        />
                      </div>
                    </Form.Group>
                    <Form.Group as={Col} md="4" className="row mr-1">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className="col-sm-12 col-form-label"
                      >
                        Artist Genre*
                      </Form.Label>
                      <div className="col-sm-12">
                        <Multiselect
                          options={genres} // Options to display in the dropdown
                          // selectedValues={valuesToUseGenres()}
                          customCloseIcon={
                            <i className="typcn typcn-delete ml-1"></i>
                          }
                          displayValue="name" // Property name to display in the dropdown options
                          showArrow
                          ref={genreRef}
                          style={{
                            searchBox: { // To change search box element look
                              borderColor: submitted && isMultiselectEmptyGenre ? 'red' : null
                            },
                            chips: {
                              background: "#F6F4ED",
                              color: "black",
                            },
                            fontSize: "0.875rem",
                          }}
                          onSelect={(s) => {
                            let arrGenre = [];
                            s.map((t, i) => {
                              arrGenre.push(t.id)
                            })
                            setTypeSelectedGenre(arrGenre)
                          }}
                          placeholder={
                            typeSelected
                              ? "Select Genres"
                              : "Select Artist type first"
                          }
                        />
                      </div>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md="6" className="row ">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className=" col-sm-12 col-form-label"
                      >
                        Facebook
                      </Form.Label>
                      <div className="col-sm-12">
                        <Form.Control
                          type="text"
                          className="form-control"
                          placeholder="Facebook"
                          name="facebook"
                        />
                      </div>
                    </Form.Group>
                    <Form.Group as={Col} md="6" className="row mr-1">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className=" col-sm-12 col-form-label"
                      >
                        Instagram*
                      </Form.Label>
                      <div className="col-sm-12">
                        <Form.Control
                          type="text"
                          className="form-control"
                          placeholder="Instagram"
                          name="instagram"
                          required
                        />
                      </div>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md="6" className="row ">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className=" col-sm-12 col-form-label"
                      >
                        Audio Link
                      </Form.Label>
                      <div className="col-sm-12">
                        <Form.Control
                          type="text"
                          className="form-control"
                          placeholder="Audio Link"
                          name="audioLink"
                        />
                      </div>
                    </Form.Group>
                    <Form.Group as={Col} md="6" className="row mr-1">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className="col-sm-12 col-form-label"
                      >
                        YouTube
                      </Form.Label>
                      <div className="col-sm-12">
                        <Form.Control
                          type="text"
                          className="form-control"
                          placeholder="YouTube"
                          name="youtube"
                        />
                      </div>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md="12" className="row">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className=" col-sm-2 col-form-label"
                      >
                        Biography*
                      </Form.Label>
                      <div className="col-sm-8">
                        <Form.Control
                          as="textarea"
                          className="form-control"
                          rows="4"
                          required
                          id="exampleTextarea1"
                          placeholder="Biography"
                          name="biography"
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please provide Biography.
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>
                  </Form.Row>
                </>
                <div className="row">
                  <h4 className="card-title col-9 d-flex align-items-center">
                    Add Performance Images
                  </h4>
                </div>
                <>
                  <div className="row">
                    <div className="col-sm-8">
                      <div className="row">
                        <label className=" col-sm-3 col-form-label">
                          Image :
                        </label>
                        {image ? (
                          <>
                            <div className="image-item col-sm-9">
                              <img
                                src={image}
                                alt=""
                                width="100"
                                className="img-lg mb-3"
                              />
                              <div className="upload__image-wrapper ">
                                {/* <div className="row"> */}
                                <Button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setImage();
                                  }}
                                  className="btn yellow-btn"
                                >
                                  Remove
                                </Button>
                              </div>
                            </div>
                          </>
                        ) : (
                          <input
                            type="file"
                            accept="image/png, image/jpeg"
                            onChange={onImageChange}
                            className="filetype col-sm-9"
                          />
                        )}
                      </div>
                    </div>
                    {/* <div className="col-sm-4">
<div className="row">
<div className="col-sm-12">
<div className="form-check form-check-success">
<label className="form-check-label">
<input
type="checkbox"
className="form-check-input"
checked={publish}
onChange={() => setPublish(!publish)}
/>{" "}
Publish
<i className="input-helper"></i>
</label>
</div>
</div>
</div>
</div> */}
                  </div>
                  <div className="row mt-5">
                    <label className=" col-sm-2 col-form-label">
                      Description
                    </label>
                    <div className="image-item col-sm-8">
                      <textarea
                        id="w3review"
                        // dir={
                        // languageSetected?.value === "ar" ? "rtl" : "ltr"
                        // }
                        name="w3review"
                        rows="6"
                        value={description}
                        style={{ width: "100%" }}
                        onChange={(e) => setDescription(e.target.value)}
                      />
                      {/* {description}
</textarea> */}
                    </div>
                  </div>

                  <Button
                    onClick={() => addPost()}
                    className="mt-3 mb-3 yellow-btn"
                  >
                    {" "}
                    Add Image{" "}
                  </Button>
                </>
                <>
                  {postImageArray?.length > 0 ? (
                    <h4 className="card-title col-9 d-flex align-items-center mt-3">
                      Already Added Performance images
                    </h4>
                  ) : null}
                  {postImageArray.map((i, index) => (
                    <>
                      <div
                        key={index}
                        className="card"
                        style={{
                          padding: "10px",
                          marginTop: "20px",
                          marginBottom: "20px",
                        }}
                      >
                        <div className="row">
                          <div className="col-sm-5">
                            <div className="row">
                              <label className=" col-sm-5 col-form-label">
                                Image :
                              </label>
                              <>
                                <div className="image-item col-sm-7">
                                  <img
                                    // src={
                                    //   i.media_link?.includes('https')
                                    //     ? i.media_link : process.env.REACT_APP_IMAGE_LINK + i.media_link
                                    // }
                                    src={i?.image}
                                    alt=""
                                    width="100"
                                    className="img-lg mb-3"
                                  />
                                </div>
                              </>
                            </div>
                          </div>
                          <div className="col-sm-7">
                            <div className="row">
                              <div className="col-sm-4">
                                <div className="form-check form-check-success">
                                  <label className="form-check-label">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      checked={i.active_status}
                                      onChange={() =>
                                        unPublish(index, i.active_status)
                                      }
                                    />{" "}
                                    Publish
                                    <i className="input-helper"></i>
                                  </label>
                                </div>
                              </div>
                              <div className="col-sm-4 mt-2">
                                <h5>Image No : {index + 1}</h5>
                              </div>
                              <div className="col-sm-4">
                                <Button
                                  className="btn yellow-btn"
                                  onClick={() => {
                                    deleatPost(index);
                                  }}
                                >
                                  Remove
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row mt-5">
                          <label className=" col-sm-2 col-form-label">
                            Description
                          </label>
                          <div className="image-item col-sm-8">
                            <textarea
                              id="w3review"
                              name="w3review"
                              rows="6"
                              value={i.media_desc}
                              style={{ width: "100%" }}
                              onChange={(e) => {
                                editPosts(index, e.target.value, 2);
                              }}
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </>
                  ))}
                </>
                <div className="row">
                  <h4 className="card-title col-9 d-flex align-items-center">
                    Add Performance Video
                  </h4>
                </div>
                <>
                  <div className="row">
                    <div className="col-sm-8">
                      <Form.Row>
                        <Form.Label
                          htmlFor="exampleInputUsername2"
                          className=" col-sm-3 col-form-label"
                        >
                          Link
                        </Form.Label>
                        <div className="image-item col-sm-8">
                          <Form.Control
                            type="text"
                            // dir={languageSetected?.value === "ar" ? "rtl" : "ltr"}
                            className="form-control"
                            id="videoLink"
                            placeholder="Video Link"
                            value={videoLink || ""}
                            name="videoLink"
                            onChange={(e) => setVideoLink(e.target.value)}
                          // defaultValue={artist_detail.title}
                          />
                        </div>
                      </Form.Row>
                    </div>
                    {/* <div className="col-sm-4">
<div className="row">
<div className="col-sm-12">
<div className="form-check form-check-success">
<label className="form-check-label">
<input
type="checkbox"
className="form-check-input"
checked={publishVideo}
onChange={() => setPublishvideo(!publishVideo)}
/>{" "}
Publish
<i className="input-helper"></i>
</label>
</div>
</div>
</div>
</div> */}
                  </div>
                  <div className="row mt-5">
                    <label className=" col-sm-2 col-form-label">
                      Description
                    </label>
                    <div className="image-item col-sm-8">
                      <textarea
                        id="w3review"
                        name="w3review"
                        rows="6"
                        value={videodescription}
                        style={{ width: "100%" }}
                        onChange={(e) => setVideodescription(e.target.value)}
                      />
                      {/* {description}
</textarea> */}
                    </div>
                  </div>

                  <Button
                    onClick={() => addVideoPost()}
                    className="mt-3 mb-3 yellow-btn"
                  >
                    {" "}
                    Add Video{" "}
                  </Button>
                </>
                <>
                  {postVideoArray.length > 0 ? (
                    <h4 className="card-title col-9 d-flex align-items-center mt-3">
                      Already Added Performance Video
                    </h4>
                  ) : null}
                  {postVideoArray.map((i, index) => (
                    <>
                      <div
                        key={index}
                        className="card"
                        style={{
                          padding: "10px",
                          marginTop: "20px",
                          marginBottom: "20px",
                        }}
                      >
                        <div className="row">
                          <div className="col-sm-5">
                            <Form.Row>
                              <Form.Group as={Col} md="12" className="row">
                                <Form.Label
                                  htmlFor="exampleInputUsername2"
                                  className=" col-sm-3 col-form-label"
                                >
                                  Link
                                </Form.Label>
                                <div className="col-sm-9">
                                  <a href={
                                    i.media_link?.includes('https') ? i.media_link
                                      : process.env.REACT_APP_IMAGE_LINK + i.media_link
                                  } target="_blank">
                                    Click here{" "}
                                  </a>
                                </div>
                              </Form.Group>
                            </Form.Row>
                          </div>
                          <div className="col-sm-6">
                            <div className="row">
                              <div className="col-sm-4">
                                <div className="form-check form-check-success">
                                  <label className="form-check-label">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      checked={i.active_status}
                                      onChange={() =>
                                        unPublishVideo(index, i.active_status)
                                      }
                                    />{" "}
                                    Publish
                                    <i className="input-helper"></i>
                                  </label>
                                </div>
                              </div>
                              <div className="col-sm-4 mt-2">
                                <h5>Video No : {index + 1}</h5>
                              </div>
                              <div className="col-sm-4">
                                <Button
                                  className="btn yellow-btn"
                                  onClick={() => {
                                    deleatVideoPost(index);
                                  }}
                                >
                                  Remove
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row mt-5">
                          <label className=" col-sm-2 col-form-label">
                            Description
                          </label>
                          <div className="image-item col-sm-8">
                            <textarea
                              id="w3review"
                              name="w3review"
                              rows="6"
                              style={{ width: "100%" }}
                              value={i.media_desc}
                              onChange={(e) =>
                                editVideoPosts(index, e.target.value, 2)
                              }
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </>
                  ))}
                </>

                <>
                  <Form.Row>
                    <Form.Group as={Col} md="6" className="form-row">
                      {/* <div className="col-sm-6"> */}
                      <Form.Check
                        name="terms"
                        required
                        // defaultChecked={artist_detail.active_status}
                        onClick={() => { }}
                      />
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className=" col-form-label"
                      >
                        Accept terms & condition*
                      </Form.Label>
                      <Form.Control.Feedback type="invalid">
                        Please agree the terms.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Form.Row>
                </>

                <div className="row">
                  <div className="col-sm-12">
                    <Button type="submit" className="btn yellow-btn mr-2">
                      {"Create"}
                    </Button>
                  </div>
                </div>
              </Form>
              <Modal
                size="md"
                show={mdShow}
                onHide={() => {
                  setMdShow(false);
                }}
                aria-labelledby="example-modal-sizes-title-md"
              >
                <Modal.Header closeButton>
                  <Modal.Title
                    style={{
                      width: "100%",
                      "text-align": "center",
                      "border-bottom": "1px solid #86878880",
                    }}
                  >
                    <div className="brand-logo p-4">
                      <img
                        src={require("../../assets/images/mail.png")}
                        alt="logo"
                      />
                    </div>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body
                  className="fleex-wrap"
                  style={{ "text-align": "center" }}
                >
                  <p className="font-weight-bold">
                    Your details are sent for review.
                  </p>
                </Modal.Body>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ArtistRegister;