import React from "react";
import { Form, InputGroup, Col, Button, FormControl } from "react-bootstrap";
import { useState, useRef, useEffect, useCallback } from "react";
import Multiselect from "multiselect-react-dropdown";
import { Editor } from "@tinymce/tinymce-react";
import ReactQuill from "react-quill";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { Modal } from "react-bootstrap";
import ImageUploading from "react-images-uploading";
import { useDropzone } from "react-dropzone";
import { Link, useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { toasterMessage } from "../../utils/toast-util";
import "react-quill/dist/quill.snow.css";
import CropEasy from "../../crop/CropEasy";
import { getArtistType } from "../../Redux/action/categoriesManagement";
import { artistList } from "../../Redux/action/artistList";
import {
  createContent,
  UpdateContent,
  contentById,
} from "../../Redux/action/contentDetail";
import { optionDetail } from "../../Redux/action/artistDetail";
function CreateContent() {
  const [openCrop, setOpenCrop] = useState(false);
  const [quillText, setQuillText] = useState(null);
  const [image, setImage] = useState(null);
  const [mdShow, setMdShow] = useState(false);
  const [event, setEvent] = useState();
  const [approval, setApproval] = useState();
  const [rejectionReason, setRejectionReason] = useState(null);
  const [typeSelected, setTypeSelected] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [description, setDescription] = useState("");
  const [videodescription, setVideodescription] = useState("");
  const [openCopyCrop, setOpenCopyCrop] = useState(false);
  const [validated, setValidated] = useState(false);
  const [publish, setPublish] = useState(false);
  const [publishVideo, setPublishvideo] = useState(false);
  const [postImageArray, setPostImageArray] = useState([]);
  const [postVideoArray, setPostVideoArray] = useState([]);
  const [videoLink, setVideoLink] = useState(null);
  const [deleatImage, setDeleatImage] = useState([]);
  const [deleatVideo, setDeleatVideo] = useState([]);
  const [leadArtist, setLeadArtist] = useState();

  const [languageSetected, setLanguageSetected] = useState();
  const [postImageDetails, setPostImageDetails] = useState({});
  const [typeError, setTypeError] = useState(null);

  const dispatch = useDispatch();
  const history = useHistory();
  const specialtyRef = useRef(null);
  const genreRef = useRef(null);
  const contentRef = useRef(null);

  const specialty = useSelector(
    (state) => state.ArtistManagementReducer.specialty_options
  );
  const genres = useSelector(
    (state) => state.ArtistManagementReducer.genres_options
  );

  const page_detail = useSelector(
    (state) => state.PageManagementReducer.page_detail
  );
  const artistTypeId = useSelector(
    (state) => state.CategoriesManagementReducer.artistTypeList
  );
  const content_detail = useSelector(
    (state) => state.ContentReducer.content_detail
  );
  const artistListOption = useSelector(
    (state) => state.ArtistManagementReducer.artist_list
  );
  const params = useParams();
  const language = [
    { value: "ar", label: "Arabic" },
    { value: "en", label: "English" },
  ];
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (typeSelected) {
      setTypeError(false);
    } else {
      toasterMessage("error", "Please select Artist type");
    }
    if (leadArtist) {
      setTypeError(false);
    } else {
      toasterMessage("error", "Please select Lead Artist");
    }
    if (languageSetected) {
    } else {
      toasterMessage("error", "Please select language");
    }

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    var localSpecialty = [];
    specialtyRef.current.getSelectedItems().forEach((item) => {
      localSpecialty.push(item.id);
    });
    var localGenres = [];
    genreRef.current.getSelectedItems().forEach((item) => {
      localGenres.push(item.id);
    });
    var localContent = [];
    contentRef.current.getSelectedItems().forEach((item) => {
      localContent.push(item.id);
    });
    event.preventDefault();
    setValidated(true);
    if (
      form.checkValidity() === true &&
      typeSelected &&
      languageSetected &&
      leadArtist
    ) {
      let formData = new FormData();
      formData.append("lang", languageSetected?.value);
      formData.append("title", event.target.title?.value);
      formData.append("artistTypeId", typeSelected?.id);
      formData.append("specialities", localSpecialty);
      formData.append("genres", localGenres);
      formData.append("content_artist", localContent);
      formData.append("lead_artist", leadArtist?.id);
      formData.append("body", quillText ? quillText : "");
      formData.append("subHeader", event.target.subheader?.value);
      formData.append("active_status", event.target.status.checked ? 1 : 0);
      if (approval) {
        formData.append("approval_status", "Approved");
      } else {
        formData.append("approval_status", "Rejected");
        formData.append("comment", rejectionReason);
      }
      if (deleatImage.length > 0) {
        for (var x = 0; x < deleatImage.length; x++) {
          formData.append("delete_images_array", deleatImage[x]);
        }
      }
      if (deleatVideo.length > 0) {
        for (var x = 0; x < deleatVideo.length; x++) {
          formData.append("delete_videos_array", deleatVideo[x]);
        }
      }
      console.log(postImageArray);
      if (postImageArray.length > 0) {
        for (var x = 0; x < postImageArray.length; x++) {
          if (postImageArray[x]?.isNew) {
            formData.append("artist_post_images", postImageArray[x].file);
            formData.append("new_images_desc", postImageArray[x].media_desc);
            formData.append(
              "new_images_status",
              postImageArray[x].active_status ? 1 : 0
            );
          } else {
            formData.append("prev_images_id", postImageArray[x].id);
            formData.append(
              "prev_images_status",
              postImageArray[x].active_status ? 1 : 0
            );
            // formData.append("artist_post_images", null);
          }
        }
      } else {
        formData.append("artist_post_images", null);
      }
      if (postVideoArray.length > 0) {
        for (var x = 0; x < postVideoArray.length; x++) {
          if (postVideoArray[x]?.isNew) {
            formData.append("new_videos_link", postVideoArray[x].media_link);
            formData.append("new_videos_desc", postVideoArray[x].media_desc);
            formData.append(
              "new_videos_status",
              postVideoArray[x].active_status ? 1 : 0
            );
          } else {
            formData.append("prev_videos_id", postVideoArray[x].id);
            formData.append(
              "prev_videos_status",
              postVideoArray[x].active_status ? 1 : 0
            );
            // formData.append("new_videos_link ", null);
          }
        }
      } else {
        formData.append("new_videos_link ", null);
      }
      if (params.id) {
        dispatch(UpdateContent(formData, params.id, history));
      } else {
        dispatch(createContent(formData, history));
        console.log(formData);
      }
    }
  };
  useEffect(() => {
    dispatch(getArtistType());
    dispatch(artistList());
    if (params.id) {
      dispatch(contentById(params.id));
    }
  }, []);

  useEffect(() => {
    setLeadArtist(
      artistListOption[
      artistListOption.findIndex((x) => x.id === content_detail.lead_artist)
      ]
    );
    setTypeSelected(
      artistTypeId[
      artistTypeId.findIndex((x) => x.id === content_detail.artistTypeId)
      ]
    );
    dispatch(optionDetail(content_detail.artistTypeId));
    if (content_detail.images?.length > 0) {
      setPostImageArray(content_detail.images);
    }
    if (content_detail.videos?.length > 0) {
      setPostVideoArray(content_detail.videos);
    }
    setQuillText(content_detail.body);
    setLanguageSetected(
      language[language.findIndex((x) => x.value === content_detail.lang)]
    );
  }, [content_detail]);
  function valuesToUseGenres() {
    const arryused = [];
    if (typeSelected && content_detail?.genres) {
      content_detail.genres.map((id) => {
        arryused.push(genres[genres.findIndex((x) => x.id === id)]);
      });
      if (arryused[0] !== undefined && arryused.length > 0) {
        return arryused;
      }
    } else {
      return null;
    }
  }
  function valuesToUseArtist() {
    const arryused = [];
    if (leadArtist && content_detail?.content_artist) {
      content_detail.content_artist.map((id) => {
        arryused.push(
          artistListOption[artistListOption.findIndex((x) => x.id === id)]
        );
      });
      if (arryused[0] !== undefined && arryused.length > 0) {
        return arryused;
      }
    } else {
      return null;
    }
  }
  function valuesToUseSpeciality() {
    const arryused = [];
    if (typeSelected && content_detail?.specialities) {
      content_detail.specialities.map((id) => {
        arryused.push(specialty[specialty.findIndex((x) => x.id === id)]);
      });
      if (arryused[0] !== undefined && arryused.length > 0) {
        console.log("specialty", arryused);
        console.log("specialty", arryused.length);
        return arryused;
      }
    } else {
      return null;
    }
  }
  function handleQuillChange(e) {
    setQuillText(e);
  }
  Editor.formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
  ];
  Editor.modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike"], // toggled buttons
      ["blockquote"],
      ["link"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "sub" }, { script: "super" }], // superscript/subscript
      [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
      [{ direction: "rtl" }], // text direction

      [{ size: ["small", false, "large", "huge"] }], // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ font: [] }],
      [{ align: [] }],

      ["clean"],
    ],
  };
  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImage(URL.createObjectURL(event.target.files[0]));
      setImageFile(event.target.files[0]);
    }
  };

  const addPost = () => {
    if (image) {
      setPostImageArray([
        ...postImageArray,
        {
          image: image,
          active_status: publish,
          file: imageFile,
          isNew: true,
          media_desc: description,
        },
      ]);
      setImageFile();
      setImage();
      setPublish(false);
      setDescription("");
    } else {
      toasterMessage("error", "Please add an image before adding Post");
    }
  };
  const addVideoPost = () => {
    if (videoLink) {
      setPostVideoArray([
        ...postVideoArray,
        {
          media_link: videoLink,
          active_status: publishVideo,
          isNew: true,
          media_desc: videodescription,
        },
      ]);
      setVideoLink();
      setPublishvideo(false);
      setVideodescription("");
    } else {
      toasterMessage("error", "Please add a video link before adding Post");
    }
  };
  const unPublish = (index, value) => {
    // let newState = postImageArray;
    const newState = postImageArray.map((obj, i) => {
      // 👇️ if id equals 2, update country property
      if (i === index) {
        console.log("found");
        return { ...obj, active_status: !value };
      }

      // 👇️ otherwise return object as is
      return obj;
    });
    setPostImageArray(newState);
  };
  const unPublishVideo = (index, value) => {
    const newState = postVideoArray.map((obj, i) => {
      // 👇️ if id equals 2, update country property
      if (i === index) {
        console.log("found");
        return { ...obj, active_status: !value };
      }

      // 👇️ otherwise return object as is
      return obj;
    });
    setPostVideoArray(newState);
  };
  const deleatPost = (index) => {
    if (postImageArray[index]?.isNew === false) {
      setDeleatImage([...deleatImage, postImageArray[index].id]);
    }
    setPostImageArray(postImageArray.filter((item, i) => i !== index));
  };
  const deleatVideoPost = (index) => {
    if (postVideoArray[index]?.isNew === false) {
      setDeleatVideo([...deleatVideo, postVideoArray[index].id]);
    }
    setPostVideoArray(postVideoArray.filter((item, i) => i !== index));
  };

  const styles = {
    menu: (base) => ({
      ...base,
      marginTop: "4px",
      fontSize: "0.875rem",

      passive: "true",
    }),
    control: (base) => ({
      ...base,
      fontSize: "0.875rem",
    }),
  };
  return (
    <>
      <div className="row">
        <h4 className="card-title col-8 d-flex align-items-center">
          {!params.id ? "Create Content" : "Update Content"}
        </h4>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/content-management">Content Management</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {!params.id ? "Create Content" : "Update Content"}
            </li>
          </ol>
        </nav>
      </div>
      <div className="row">
        <div className="col-md-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <h4 className="card-title col-9 d-flex align-items-center">
                  Content Information
                </h4>
              </div>

              <Form
                className="forms-sample"
                noValidate
                id="form"
                validated={validated}
                onSubmit={(event) => handleSubmit(event)}
              >
                <>
                  <Form.Row>
                    <Form.Group as={Col} md="6" className="row">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className=" col-sm-4  col-form-label"
                      >
                        Language*
                      </Form.Label>
                      <div className="col-sm-8">
                        <Select
                          required
                          options={language}
                          value={languageSetected}
                          styles={styles}
                          isDisabled={params.id ? true : false}
                          maxMenuHeight={100}
                          onChange={(e) => {
                            setLanguageSetected(e);
                          }}
                        />

                        <span
                          style={{
                            width: "100%",
                            fontSize: "0.875em",
                            color: "#dc3545",
                          }}
                        >
                          {typeError ? "Please select" : null}
                        </span>
                      </div>
                    </Form.Group>
                    {/* <Form.Group as={Col} md="6" className="row">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className=" col-sm-3  col-form-label"
                      >
                        Order*
                      </Form.Label>
                      <div className="col-sm-8">
                        <Form.Control
                          type="number"
                          className="form-control"
                          required
                          placeholder="Order Number"
                          name="order"
                          defaultValue={content_detail.post_order}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide Order.
                        </Form.Control.Feedback>

                        <span
                          style={{
                            width: "100%",
                            fontSize: "0.875em",
                            color: "#dc3545",
                          }}
                        >
                          {typeError ? "Please select" : null}
                        </span>
                      </div>
                    </Form.Group> */}
                  </Form.Row>
                  <div className="row">
                    <h4 className="card-title col-9 d-flex align-items-center">
                      General Information
                    </h4>
                  </div>
                  <Form.Row>
                    <Form.Group as={Col} md="6" className="row  mr-1">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className=" col-sm-4  col-form-label"
                      >
                        Title*
                      </Form.Label>
                      <div className="col-sm-8">
                        <Form.Control
                          type="text"
                          dir={languageSetected?.value === "ar" ? "rtl" : "ltr"}
                          className="form-control"
                          required
                          id="exampleInputUsername2"
                          placeholder="Title"
                          name="title"
                          defaultValue={content_detail.title}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide Title.
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>
                    <Form.Group as={Col} md="6" className="row">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className=" col-sm-4  col-form-label"
                      >
                        Sub Header*
                      </Form.Label>
                      <div className="col-sm-8">
                        <Form.Control
                          type="text"
                          dir={languageSetected?.value === "ar" ? "rtl" : "ltr"}
                          className="form-control"
                          required
                          placeholder="Sub Header"
                          name="subheader"
                          defaultValue={content_detail.subHeader}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide Sub Header.
                        </Form.Control.Feedback>

                        <span
                          style={{
                            width: "100%",
                            fontSize: "0.875em",
                            color: "#dc3545",
                          }}
                        >
                          {typeError ? "Please select" : null}
                        </span>
                      </div>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md="6" className="row  mr-1">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className="col-sm-4   col-form-label"
                      >
                        Lead Artist*
                      </Form.Label>
                      <div className="col-sm-8">
                        <Select
                          styles={styles}
                          maxMenuHeight={100}
                          options={artistListOption}
                          getOptionLabel={(option) => option.stageName}
                          value={leadArtist}
                          onChange={(e) => {
                            setLeadArtist(e);
                          }}
                          placeholder="Select Lead Artist"
                        />

                        <span
                          style={{
                            width: "100%",
                            fontSize: "0.875em",
                            color: "#dc3545",
                          }}
                        >
                          {typeError ? "Please select" : null}
                        </span>
                      </div>
                    </Form.Group>
                    <Form.Group as={Col} md="6" className="row">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className="col-sm-4 col-form-label"
                      >
                        Content Artist
                      </Form.Label>
                      <div className="col-sm-8">
                        <Multiselect
                          options={artistListOption} // Options to display in the dropdown
                          selectedValues={valuesToUseArtist()}
                          displayValue={"stageName" || ""} // Property name to display in the dropdown options
                          showArrow
                          customCloseIcon={
                            <i class="typcn typcn-delete ml-1"></i>
                          }
                          ref={contentRef}
                          style={{
                            chips: {
                              background: "#F6F4ED",
                              color: "black",
                            },
                            options: {
                              "font-size": "0.875rem",
                            },
                            "font-size": "0.875rem",
                          }}
                          placeholder="Select Content Artist "
                        />
                      </div>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md="6" className="row  mr-1">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className="col-sm-4   col-form-label"
                      >
                        Artist Type*
                      </Form.Label>
                      <div className="col-sm-8">
                        <Select
                          required
                          options={artistTypeId}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          value={typeSelected}
                          maxMenuHeight={100}
                          styles={styles}
                          placeholder="Select Artist Type"
                          onChange={(e) => {
                            setTypeSelected(e);
                            setTypeError(false);
                            specialtyRef.current.resetSelectedValues();
                            genreRef.current.resetSelectedValues();
                            dispatch(optionDetail(e.id));
                          }}
                        />

                        <span
                          style={{
                            width: "100%",
                            fontSize: "0.875em",
                            color: "#dc3545",
                          }}
                        >
                          {typeError ? "Please select" : null}
                        </span>
                      </div>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md="6" className="row  mr-1">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className="col-sm-4 col-form-label"
                      >
                        Genre
                      </Form.Label>
                      <div className="col-sm-8">
                        <Multiselect
                          options={genres} // Options to display in the dropdown
                          selectedValues={valuesToUseGenres()}
                          displayValue="name" // Property name to display in the dropdown options
                          showArrow
                          ref={genreRef}
                          customCloseIcon={
                            <i class="typcn typcn-delete ml-1"></i>
                          }
                          style={{
                            chips: {
                              background: "#F6F4ED",
                              color: "black",
                            },
                            options: {
                              "font-size": "0.875rem",
                            },
                            "font-size": "0.875rem",
                          }}
                          placeholder={
                            typeSelected
                              ? "Select Instument"
                              : "Select Artist type first"
                          }
                        />
                      </div>
                    </Form.Group>
                    <Form.Group as={Col} md="6" className="row">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className=" col-sm-4  col-form-label"
                      >
                        Speciality
                      </Form.Label>
                      <div className="col-sm-8">
                        <Multiselect
                          options={specialty}
                          selectedValues={valuesToUseSpeciality()}
                          displayValue="name" // Property name to display in the dropdown options
                          showArrow
                          customCloseIcon={
                            <i class="typcn typcn-delete ml-1"></i>
                          }
                          ref={specialtyRef}
                          style={{
                            chips: {
                              background: "#F6F4ED",
                              color: "black",
                            },
                            options: {
                              "font-size": "0.875rem",
                            },
                            "font-size": "0.875rem",
                          }}
                          placeholder={
                            typeSelected
                              ? "Select Speciality"
                              : "Select Artist type first"
                          }
                        />
                      </div>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md="12" className="row">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className=" col-sm-2  col-form-label"
                      >
                        Body
                      </Form.Label>
                      <div className="col-sm-8">
                        <ReactQuill
                          value={quillText || ""}
                          modules={Editor.modules}
                          formats={Editor.formats}
                          onChange={(e) => handleQuillChange(e)}
                        />
                      </div>
                    </Form.Group>
                  </Form.Row>
                </>
                <div className="row">
                  <h4 className="card-title col-9 d-flex align-items-center">
                    Add Posts
                  </h4>
                </div>
                <>
                  <div className="row">
                    <div className="col-sm-8">
                      <div className="row">
                        <label className=" col-sm-3  col-form-label">
                          Image :
                        </label>
                        {image ? (
                          <>
                            <div className="image-item col-sm-9">
                              <img
                                src={image}
                                alt=""
                                width="100"
                                className="img-lg mb-3"
                              />
                              <div className="upload__image-wrapper ">
                                {/* <div className="row"> */}
                                <Button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setImage();
                                  }}
                                  className="yellow-btn"
                                >
                                  Remove
                                </Button>
                                {/* <Button
                                            onClick={(e) => {
                                              setOpenCrop(true);
                                            }}
                                            className="btn-dangercol-sm-5 mt-2"
                                          >
                                            Crop
                                          </Button> */}
                                {/* </div> */}
                              </div>
                            </div>
                          </>
                        ) : (
                          <input
                            type="file"
                            accept="image/png, image/jpeg"
                            onChange={onImageChange}
                            className="filetype col-sm-9"
                          />
                        )}
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="form-check form-check-success">
                            <label className="form-check-label">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                checked={publish}
                                onChange={() => setPublish(!publish)}
                              />{" "}
                              Publish
                              <i className="input-helper"></i>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-5">
                    <label className=" col-sm-2  col-form-label">
                      Description
                    </label>
                    <div className="image-item col-sm-8">
                      <textarea
                        id="w3review"
                        dir={languageSetected?.value === "ar" ? "rtl" : "ltr"}
                        name="w3review"
                        rows="6"
                        value={description}
                        style={{ width: "100%" }}
                        onChange={(e) => setDescription(e.target.value)}
                      />
                      {/* {description}
                          </textarea> */}
                    </div>
                  </div>

                  <Button
                    onClick={() => addPost()}
                    className="mt-3 mb-3 yellow-btn"
                  >
                    {" "}
                    Add Post{" "}
                  </Button>
                </>
                <>
                  {postImageArray?.length > 0 ? (
                    <h4 className="card-title col-9 d-flex align-items-center mt-3">
                      Already Added Posts
                    </h4>
                  ) : null}
                  {postImageArray.map((i, index) => (
                    <>
                      <div
                        key={index}
                        className="card"
                        style={{
                          padding: "10px",
                          marginTop: "20px",
                          marginBottom: "20px",
                        }}
                      >
                        <div className="row">
                          <div className="col-sm-5">
                            <div className="row">
                              <label className=" col-sm-5  col-form-label">
                                Image :
                              </label>
                              <>
                                <div className="image-item col-sm-7">
                                  <img
                                    src={
                                      i.media_link?.includes('https')
                                        ? i.media_link :
                                        process.env.REACT_APP_IMAGE_LINK + i.media_link
                                    }
                                    alt=""
                                    width="100"
                                    className="img-lg mb-3"
                                  />
                                </div>
                              </>
                            </div>
                          </div>
                          <div className="col-sm-7">
                            <div className="row">
                              <div className="col-sm-4">
                                <div className="form-check form-check-success">
                                  <label className="form-check-label">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      checked={i.active_status}
                                      onChange={() =>
                                        unPublish(index, i.active_status)
                                      }
                                    />{" "}
                                    Publish
                                    <i className="input-helper"></i>
                                  </label>
                                </div>
                              </div>
                              <div className="col-sm-4 mt-2">
                                <h5>Image No : {index + 1}</h5>
                              </div>
                              <div className="col-sm-4">
                                <Button
                                  className="yellow-btn"
                                  onClick={() => {
                                    deleatPost(index);
                                  }}
                                >
                                  Remove
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row mt-5">
                          <label className=" col-sm-2  col-form-label">
                            Description
                          </label>
                          <div className="image-item col-sm-6">
                            <textarea
                              dir={
                                languageSetected?.value === "ar" ? "rtl" : "ltr"
                              }
                              id="w3review"
                              name="w3review"
                              rows="6"
                              value={i.media_desc}
                              style={{ width: "100%" }}
                              disabled
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </>
                  ))}
                </>
                <div className="row">
                  <h4 className="card-title col-9 d-flex align-items-center">
                    Add Video Posts
                  </h4>
                </div>
                <>
                  <div className="row">
                    <div className="col-sm-8">
                      <Form.Row>
                        <Form.Label
                          htmlFor="exampleInputUsername2"
                          className=" col-sm-3  col-form-label"
                        >
                          Link
                        </Form.Label>
                        <div className="col-sm-8">
                          <Form.Control
                            type="text"
                            // dir={languageSetected?.value === "ar" ? "rtl" : "ltr"}
                            className="form-control"
                            id="exampleInputUsername2"
                            placeholder="Video Link"
                            value={videoLink || ""}
                            name="videoLink"
                            onChange={(e) => setVideoLink(e.target.value)}
                          // defaultValue={artist_detail.title}
                          />
                        </div>
                      </Form.Row>
                    </div>
                    <div className="col-sm-4">
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="form-check form-check-success">
                            <label className="form-check-label">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                checked={publishVideo}
                                onChange={() => setPublishvideo(!publishVideo)}
                              />{" "}
                              Publish
                              <i className="input-helper"></i>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-5">
                    <label className=" col-sm-2  col-form-label">
                      Description
                    </label>
                    <div className="image-item col-sm-8">
                      <textarea
                        id="w3review"
                        dir={languageSetected?.value === "ar" ? "rtl" : "ltr"}
                        name="w3review"
                        rows="6"
                        value={videodescription}
                        style={{ width: "100%" }}
                        onChange={(e) => setVideodescription(e.target.value)}
                      />
                      {/* {description}
                          </textarea> */}
                    </div>
                  </div>

                  <Button
                    onClick={() => addVideoPost()}
                    className="mt-3 mb-3 yellow-btn"
                  >
                    {" "}
                    Add Video{" "}
                  </Button>
                </>
                <>
                  {postVideoArray.length > 0 ? (
                    <h4 className="card-title col-9 d-flex align-items-center mt-3">
                      Already Added Video Posts
                    </h4>
                  ) : null}
                  {postVideoArray.map((i, index) => (
                    <>
                      <div
                        key={index}
                        className="card"
                        style={{
                          padding: "10px",
                          marginTop: "20px",
                          marginBottom: "20px",
                        }}
                      >
                        <div className="row">
                          <div className="col-sm-5">
                            <Form.Row>
                              <Form.Group as={Col} md="12" className="row">
                                <Form.Label
                                  htmlFor="exampleInputUsername2"
                                  className=" col-sm-5  col-form-label"
                                >
                                  Link
                                </Form.Label>
                                <div className="col-sm-7 mt-2">
                                  <a href={
                                    i.media_link?.includes('https') ? i.media_link : process.env.REACT_APP_IMAGE_LINK + i.media_link
                                  } target="_blank">
                                    Click here{" "}
                                  </a>
                                </div>
                              </Form.Group>
                            </Form.Row>
                          </div>
                          <div className="col-sm-6">
                            <div className="row">
                              <div className="col-sm-4">
                                <div className="form-check form-check-success">
                                  <label className="form-check-label">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      checked={i.active_status}
                                      onChange={() =>
                                        unPublishVideo(index, i.active_status)
                                      }
                                    />{" "}
                                    Publish
                                    <i className="input-helper"></i>
                                  </label>
                                </div>
                              </div>
                              <div className="col-sm-4 mt-2">
                                <h5>Video No : {index + 1}</h5>
                              </div>
                              <div className="col-sm-4">
                                <Button
                                  className="btn yellow-btn"
                                  onClick={() => {
                                    deleatVideoPost(index);
                                  }}
                                >
                                  Remove
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row mt-5">
                          <label className=" col-sm-2  col-form-label">
                            Description
                          </label>
                          <div className="image-item col-sm-8">
                            <textarea
                              dir={
                                languageSetected?.value === "ar" ? "rtl" : "ltr"
                              }
                              id="w3review"
                              name="w3review"
                              rows="6"
                              style={{ width: "100%" }}
                              value={i.media_desc}
                              disabled
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </>
                  ))}
                </>
                <Form.Row>
                  {/* <Form.Group as={Col} md="10"></Form.Group> */}
                  <Form.Group as={Col} md="12" className="row ml-2">
                    <Form.Check
                      className=""
                      name="status"
                      defaultChecked={content_detail.active_status}
                      onClick={() => { }}
                    />
                    <Form.Label className="col-form-label ml-1">
                      Publish This Page
                    </Form.Label>
                  </Form.Group>
                </Form.Row>
                <div className="row">
                  <div className="col-sm-4">
                    {content_detail.approval_status === "Pending" ? (
                      <>
                        <Button
                          type="submit"
                          onClick={() => {
                            setApproval(true);
                          }}
                          className="yellow-btn"
                        >
                          Approve
                        </Button>
                        <Button
                          onClick={(event) => {
                            setMdShow(true);
                            console.log(event.target.title?.value);
                          }}
                          className="btn-sm yellow-btn"
                        >
                          Reject
                        </Button>
                      </>
                    ) : (
                      <Button
                        type="submit"
                        className="btn yellow-btn mr-2"
                        onClick={() => {
                          setApproval(true);
                        }}
                      >
                        {params.id ? "Update" : "Create"}
                      </Button>
                    )}
                  </div>
                  <Modal
                    show={mdShow}
                    onHide={() => setMdShow(false)}
                    aria-labelledby="example-modal-sizes-title-md"
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Reason ?</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                      <textarea
                        className="form-control"
                        id="textAreaExample"
                        rows="4"
                        onChange={(e) => {
                          setRejectionReason(e.target.value);
                        }}
                        placeholder={"Reason for Rejection ?"}
                        style={{ background: "#fff" }}
                      ></textarea>
                    </Modal.Body>

                    <Modal.Footer className="fleex-wrap">
                      <Button
                        id="btnSend"
                        variant="success yellow-btn m-2"
                        type="submit"
                        form="form"
                        disabled={rejectionReason ? false : true}
                        onClick={(e) => {
                          setApproval(false);
                          setMdShow(false);
                        }}
                      >
                        Submit
                      </Button>
                      <Button
                        variant="light yellow-btn m-2"
                        onClick={() => setMdShow(false)}
                      >
                        Cancel
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateContent;
