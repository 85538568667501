import { PAGE_DETAIL , OPTION_LISTS,PAGE_LIST } from "../action/actiontypes";
const initialState = {
    tagList: [],
    tagdetails:{},
    specialty_options:[],
    genres_options:[],
    page_list:[],
    page_detail:{},
  };
  const PageManagementReducer = (state = initialState, action) => {
    switch (action.type) {
        case OPTION_LISTS:
          return {
            ...state,
            specialty_options: action.payload.specialities,
            genres_options: action.payload.genres,            
          };
        case PAGE_LIST:
          return {
            ...state,
            page_list: action.payload,
            page_detail: {},
        };
        case PAGE_DETAIL:
          return {
            ...state,
            page_detail: action.payload,
        };
      default: return state
    }
  };
  export default PageManagementReducer 