import { ENQUIRY_LIST,ENQUIRY_DATA } from "../action/actiontypes";
const initialState = {
    list:[],
  data: {},
  };
  const EnquiryReducer = (state = initialState, action) => {
    switch (action.type) {
      case ENQUIRY_LIST:
        return {
          list: action.payload,
          data: {},
        };
        case ENQUIRY_DATA:
        return {
            data: action.payload,
        };
        
      default: return state
    }
  };
  export default EnquiryReducer 