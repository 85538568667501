import { PERMISSION_DETAILS} from "../action/actiontypes";
const initialState = {
    permissionDetails: {},
  };
  const PermissionReducer = (state = initialState, action) => {
    switch (action.type) {
      case PERMISSION_DETAILS:
        return {
          ...state,
          permissionDetails: action.payload,
        };
      default: return state
    }
  };
  export default PermissionReducer 