import React from "react";
import { Form, Col, Button, } from "react-bootstrap";
import { useState, useEffect } from "react";

import "../../../assets/styles/global.scss";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { toasterMessage } from "../../../utils/toast-util";
import "react-quill/dist/quill.snow.css";
import CropEasy from "../../../crop/CropEasy";
import {
  createPage,
  UpdatePage,
  pageById,
} from "../../../Redux/action/pageDetail";
import { pageLayoutList } from "../../../Redux/action/cms";

function Venu({
  page_detail,
  subpageSelected,
  languageSetected,
  pageSelected,
}) {
  const [openCrop, setOpenCrop] = useState(false);
  const [image, setImage] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [description, setDescription] = useState("");
  const [videodescription, setVideodescription] = useState("");
  const [openCopyCrop, setOpenCopyCrop] = useState(false);
  const [validated, setValidated] = useState(false);
  const [publish, setPublish] = useState(false);
  const [publishVideo, setPublishvideo] = useState(false);
  const [postImageArray, setPostImageArray] = useState([]);
  const [postVideoArray, setPostVideoArray] = useState([]);
  const [videoLink, setVideoLink] = useState(null);
  const [deleatImage, setDeleatImage] = useState([]);
  const [deleatVideo, setDeleatVideo] = useState([]);
  const [imageTitle, setImageTitle] = useState("");
  const [imageOrder, setImageOrder] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();

  const params = useParams();

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (languageSetected) {
    } else {
      toasterMessage("error", "Please select language");
    }
    if (pageSelected) {
    } else {
      toasterMessage("error", "Please select page");
    }
    console.log("subpageSelected", subpageSelected);
    if (subpageSelected) {
    } else {
      toasterMessage("error", "Please select Sub Section");
    }

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    event.preventDefault();
    setValidated(true);
    if (
      form.checkValidity() === true &&
      pageSelected &&
      languageSetected &&
      subpageSelected
    ) {
      let formData = new FormData();
      formData.append("lang", languageSetected?.value);
      formData.append("pageNameId", pageSelected.id);
      formData.append("subPage", subpageSelected?.value);
      formData.append("title", event.target.title?.value);
      formData.append("active_status", event.target.status.checked ? 1 : 0);
      if (deleatImage.length > 0) {
        for (var x = 0; x < deleatImage.length; x++) {
          formData.append("delete_images_array", deleatImage[x]);
        }
      }
      if (postImageArray.length > 0) {
        for (var x = 0; x < postImageArray.length; x++) {
          if (postImageArray[x]?.isNew) {
            formData.append("page_images", postImageArray[x].file);
            formData.append("new_images_desc", postImageArray[x].media_desc);
            formData.append("new_images_title", postImageArray[x].media_title);
            formData.append(
              "new_images_status",
              postImageArray[x].active_status ? 1 : 0
            );
            formData.append(
              "new_banner_order",
              postImageArray[x].banner_order
            );
          } else {
            formData.append("prev_images_id", postImageArray[x].id);
            formData.append("prev_images_desc", postImageArray[x].media_desc);
            formData.append("prev_images_title", postImageArray[x].media_title);
            formData.append(
              "prev_images_status",
              postImageArray[x].active_status ? 1 : 0
            );
            formData.append(
              "prev_banner_order",
              postImageArray[x].banner_order
            );
            // formData.append("page_images", null);
          }
        }
      }
      if (params.id) {
        dispatch(UpdatePage(formData, params.id, history));
      } else {
        dispatch(createPage(formData, history));
      }
    }
  };
  useEffect(() => {
    if (params.id) {
      dispatch(pageById(params.id));
    }
    dispatch(pageLayoutList());
  }, []);

  useEffect(() => {
    if (page_detail.images?.length > 0) {
      setPostImageArray(page_detail.images);
    }
  }, [page_detail]);

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImage(URL.createObjectURL(event.target.files[0]));
      setImageFile(event.target.files[0]);
    }
  };

  const addPost = () => {
    if (image) {
      setPostImageArray([
        ...postImageArray,
        {
          image: image,
          active_status: publish,
          file: imageFile,
          isNew: true,
          media_desc: description,
          media_title: imageTitle,
          banner_order: imageOrder,
        },
      ]);
      setImageFile();
      setImage();
      setPublish(false);
      setDescription("");
      setImageTitle("");
      setImageOrder("");
    } else {
      toasterMessage("error", "Please add an image before adding Post.");
    }
  };
  const addVideoPost = () => {
    if (videoLink) {
      setPostVideoArray([
        ...postVideoArray,
        {
          media_link: videoLink,
          active_status: publishVideo,
          isNew: true,
          media_desc: videodescription,
        },
      ]);
      setVideoLink();
      setPublishvideo(false);
      setVideodescription("");
    } else {
      toasterMessage("error", "Please add a video link before adding Post");
    }
  };
  const unPublish = (index, value) => {
    // let newState = postImageArray;
    const newState = postImageArray.map((obj, i) => {
      // 👇️ if id equals 2, update country property
      if (i === index) {
        console.log("found");
        return { ...obj, active_status: !value };
      }

      // 👇️ otherwise return object as is
      return obj;
    });
    setPostImageArray(newState);
  };
  const editPosts = (index, valueRecived, change) => {
    // let newState = postImageArray;
    console.log(valueRecived, "value")
    const newState = postImageArray.map((obj, i) => {
      // 👇️ if id equals 2, update country property
      if (i === index) {
        if (change === 1) {
          // 1 is for media_title
          return { ...obj, media_title: valueRecived };
        } else if (change === 3) {
          // 3 is for bannerOrder 
          return { ...obj, banner_order: valueRecived };
        } else {
          // 2 is for media_desc
          return { ...obj, media_desc: valueRecived };
        }

      }

      // 👇️ otherwise return object as is
      return obj;
    });
    console.log(newState, "newState")
    setPostImageArray(newState);
  };
  const unPublishVideo = (index, value) => {
    const newState = postVideoArray.map((obj, i) => {
      // 👇️ if id equals 2, update country property
      if (i === index) {
        console.log("found");
        return { ...obj, active_status: !value };
      }

      // 👇️ otherwise return object as is
      return obj;
    });
    setPostVideoArray(newState);
  };
  const deleatPost = (index, id) => {

    if (postImageArray[index]?.isNew === false) {

      setDeleatImage([...deleatImage, postImageArray[index].id]);

    }
    let newPostArray = [...postImageArray]
    setPostImageArray(newPostArray.filter((item, i) => item.id !== id));


  };
  const deleatVideoPost = (index) => {
    if (postVideoArray[index]?.isNew === false) {
      setDeleatVideo([...deleatVideo, postVideoArray[index].id]);
    }
    setPostVideoArray(postVideoArray.filter((item, i) => i !== index));
  };
  return (
    <>
      <Form
        className="forms-sample"
        noValidate
        validated={validated}
        onSubmit={(event) => handleSubmit(event)}
      >
        <>
          <div className="row">
            <h4 className="card-title col-9 d-flex align-items-center">
              General Information
            </h4>
          </div>
          <Form.Row>
            <Form.Group as={Col} md="6" className="row  mr-1">
              <Form.Label
                htmlFor="exampleInputUsername2"
                className=" col-sm-4  col-form-label"
              >
                Title*
              </Form.Label>
              <div className="col-sm-8">
                <Form.Control
                  type="text"
                  dir={languageSetected?.value === "ar" ? "rtl" : "ltr"}
                  className="form-control"
                  required
                  id="exampleInputUsername2"
                  placeholder="Title"
                  name="title"
                  defaultValue={page_detail.title}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide Title.
                </Form.Control.Feedback>
              </div>
            </Form.Group>
          </Form.Row>
        </>
        <div className="row">
          <h4 className="card-title col-9 d-flex align-items-center">
            Add Posts
          </h4>
        </div>
        <>
          <div className="row mt-4">
            <div className="col-sm-8">
              <div className="row">
                <label className=" col-sm-3  col-form-label">Image :</label>
                {image ? (
                  <>
                    <div className="image-item col-sm-9">
                      <img
                        src={image}
                        alt=""
                        width="100"
                        className="img-lg mb-3"
                      />
                      <div className="upload__image-wrapper ">
                        {/* <div className="row"> */}
                        <Button
                          onClick={(e) => {
                            e.preventDefault();
                            setImage();
                          }}
                          className="btn yellow-btn mb-2"
                        >
                          Remove
                        </Button>
                        {/* <Button
                                            onClick={(e) => {
                                              setOpenCrop(true);
                                            }}
                                            className="btn-dangercol-sm-5 mt-2"
                                          >
                                            Crop
                                          </Button> */}
                        {/* </div> */}
                      </div>
                    </div>
                  </>
                ) : (
                  <input
                    type="file"
                    accept="image/png, image/jpeg"
                    onChange={onImageChange}
                    className="filetype col-sm-9"
                  />
                )}
              </div>
            </div>
            <div className="col-sm-4">
              <div className="row">
                <div className="col-sm-12">
                  <div className="form-check form-check-success">
                    <label className="form-check-label">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        checked={publish}
                        onChange={() => setPublish(!publish)}
                      />{" "}
                      Publish
                      <i className="input-helper"></i>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-8">
              <Form.Row>
                <Form.Label
                  htmlFor="exampleInputUsername2"
                  className=" col-sm-3  col-form-label"
                >
                  Heading
                </Form.Label>
                <div className="col-sm-8">
                  <Form.Control
                    type="text"
                    dir={languageSetected?.value === "ar" ? "rtl" : "ltr"}
                    className="form-control"
                    id="exampleInputUsername2"
                    placeholder="Heading"
                    value={imageTitle || ""}
                    name="heading"
                    onChange={(e) => setImageTitle(e.target.value)}
                  />
                </div>
              </Form.Row>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-sm-8">
              <Form.Row>
                <Form.Label
                  htmlFor="imageOrder"
                  className=" col-sm-3  col-form-label"
                >
                  Page Order
                </Form.Label>
                <div className="col-sm-8">
                  <Form.Control
                    type="text"
                    dir={languageSetected?.value === "ar" ? "rtl" : "ltr"}
                    className="form-control"
                    id="imageOrder"
                    placeholder="Order"
                    value={imageOrder || ""}
                    name="imageOrder"
                    onChange={(e) => setImageOrder(e.target.value)}
                  />
                </div>
              </Form.Row>
            </div>
          </div>
          <div className="row mt-5">
            <label className=" col-sm-2  col-form-label">Description</label>
            <div className="image-item col-sm-8">
              <textarea
                id="w3review"
                dir={languageSetected?.value === "ar" ? "rtl" : "ltr"}
                name="w3review"
                rows="6"
                value={description}
                // cols={70}
                style={{ width: "100%" }}
                onChange={(e) => setDescription(e.target.value)}
              />
              {/* {description}
                          </textarea> */}
            </div>
          </div>
          <Button onClick={() => addPost()} className="mt-3 mb-3 yellow-btn">
            {" "}
            Add Post{" "}
          </Button>
        </>
        <>
          {postImageArray?.length > 0 ? (
            <h4 className="card-title col-9 d-flex align-items-center mt-3">
              Already Added Posts
            </h4>
          ) : null}
          {postImageArray.map((i, index) => (
            <>
              <div
                key={i.id}
                className="card"
                style={{
                  padding: "10px",
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
              >
                <div className="row">
                  <div className="col-sm-5">
                    <div className="row">
                      <label className=" col-sm-5  col-form-label">
                        Image :
                      </label>
                      <>
                        <div className="image-item col-sm-7">
                          {i?.media_link ?
                            <img
                              src={
                                i.media_link?.includes('https')
                                  ? i.media_link : process.env.REACT_APP_IMAGE_LINK + i.media_link
                              }
                              alt=""
                              width="100"
                              className="img-lg mb-3"
                            /> :
                            <img
                              src={i?.image}
                              alt=""
                              width="100"
                              className="img-lg mb-3"
                            />}
                        </div>
                      </>
                    </div>
                  </div>
                  <div className="col-sm-7">
                    <div className="row">
                      <div className="col-sm-4">
                        <div className="form-check form-check-success">
                          <label className="form-check-label">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              checked={i.active_status}
                              onChange={() => unPublish(index, i.active_status)}
                            />{" "}
                            Publish
                            <i className="input-helper"></i>
                          </label>
                        </div>
                      </div>
                      <div className="col-sm-4 mt-2">
                        <h5>Image No : {index + 1}</h5>
                      </div>
                      <div className="col-sm-4">
                        <Button
                          className="btn yellow-btn"
                          onClick={() => {
                            deleatPost(index, i.id);
                          }}
                        >
                          Remove
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-8">
                    <Form.Row>
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className=" col-sm-3  col-form-label"
                      >
                        Heading
                      </Form.Label>
                      <div className="col-sm-8">
                        <Form.Control
                          type="text"
                          dir={languageSetected?.value === "ar" ? "rtl" : "ltr"}
                          className="form-control"
                          id="exampleInputUsername2"
                          placeholder="Heading"
                          defaultValue={i.media_title}
                          name="heading"
                          onChange={(e) => {
                            editPosts(index, e.target.value, 1)
                          }}
                        />
                      </div>
                    </Form.Row>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-sm-8">
                    <Form.Row>
                      <Form.Label
                        htmlFor="alreadyAddedImageOrder"
                        className=" col-sm-3  col-form-label"
                      >
                        Page Order
                      </Form.Label>
                      <div className="col-sm-8">
                        <Form.Control
                          type="text"
                          dir={languageSetected?.value === "ar" ? "rtl" : "ltr"}
                          className="form-control"
                          id="alreadyAddedImageOrder"
                          placeholder="Image Order"
                          defaultValue={i.banner_order}
                          name="alreadyAddedImageOrder"
                          onChange={(e) => {
                            editPosts(index, e.target.value, 3)
                          }}
                        />
                      </div>
                    </Form.Row>
                  </div>
                </div>
                <div className="row mt-5">
                  <label className=" col-sm-2  col-form-label">
                    Description
                  </label>
                  <div className="image-item col-sm-8">
                    <textarea
                      dir={languageSetected?.value === "ar" ? "rtl" : "ltr"}
                      id={`desc${i.id}`}
                      name="w3review"
                      rows="6"
                      defaultValue={i.media_desc}
                      style={{ width: "100%" }}
                      onChange={(e) => {
                        editPosts(index, e.target.value, 2)
                      }}
                    ></textarea>
                  </div>
                </div>
              </div>
            </>
          ))}
        </>

        <Form.Row>
          {/* <Form.Group as={Col} md="10"></Form.Group> */}
          <Form.Group as={Col} md="12" className="row ml-2">
            <Form.Check
              className=""
              name="status"
              defaultChecked={page_detail.active_status}
              onClick={() => { }}
            />
            <Form.Label className="col-form-label ml-1">
              Publish This Section
            </Form.Label>
          </Form.Group>
        </Form.Row>
        <div className="row">
          <div className="col-sm-4">
            <Button type="submit" className="btn yellow-btn mr-2">
              {params.id ? "Update" : "Create"}
            </Button>
          </div>
        </div>
      </Form>
    </>
  );
}

export default Venu;
