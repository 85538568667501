import React from "react";
import { Form, InputGroup, Col, Button, FormControl } from "react-bootstrap";
import { useState, useRef, useEffect, useCallback } from "react";
import Multiselect from "multiselect-react-dropdown";
import { Editor } from "@tinymce/tinymce-react";
import ReactQuill from "react-quill";
import "../../../assets/styles/global.scss";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import ImageUploading from "react-images-uploading";
import { useDropzone } from "react-dropzone";
import { Link, useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { toasterMessage } from "../../../utils/toast-util";
import "react-quill/dist/quill.snow.css";
import CropEasy from "../../../crop/CropEasy";
import {
  createPage,
  UpdatePage,
  pageById,
} from "../../../Redux/action/pageDetail";
import { pageLayoutList } from "../../../Redux/action/cms";
function Banner({page_detail,subpageSelected,languageSetected,pageSelected}) {
  const DEFAULT_ORDER = 0;
  const [openCrop, setOpenCrop] = useState(false);
  const [quillText, setQuillText] = useState();
  const [image, setImage] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [description, setDescription] = useState("");
  const [videodescription, setVideodescription] = useState("");
  const [openCopyCrop, setOpenCopyCrop] = useState(false);
  const [validated, setValidated] = useState(false);
  const [publish, setPublish] = useState(false);
  const [publishVideo, setPublishvideo] = useState(false);
  const [postImageArray, setPostImageArray] = useState([]);
  const [postVideoArray, setPostVideoArray] = useState([]);
  const [videoLink, setVideoLink] = useState(null);
  const [deleatImage, setDeleatImage] = useState([]);
  const [deleatVideo, setDeleatVideo] = useState([]);
  const [postImageDetails, setPostImageDetails] = useState({});
  const [typeError, setTypeError] = useState(null);

  const dispatch = useDispatch();
  const history = useHistory();
  const pageListOptions = useSelector(
    (state) => state.CmsReducer.page_layout_list
  );
  const params = useParams();
  const language = [
    { value: "ar", label: "Arabic" },
    { value: "en", label: "English" },
  ];
  const Layout1 = [
    { value: "hero_image", label: "Hero" },
    { value: "banner", label: "Banner" },
    {
      value: "what_we_can_do",
      label: "What We can do",
    },
    {
      value: "what_makes_us_different",
      label: "What Makes Us Different",
    },
    { value: "our_work", label: "Our Work" },
  ];
  const Layout2 = [
    { value: "hero_image", label: "Hero" },
    { value: "banner", label: "Banner" },
    {
      value: "what_we_can_do",
      label: "What We can do",
    },
    {
      value: "what_makes_us_different",
      label: "What Makes Us Different",
    },
    { value: "our_work", label: "Our Work" },
  ];
  const Layout3 = [
    { value: "banner", label: "Banner" },
    {
      value: "event_tile",
      label: "Event Tile",
    },
    { value: "carousel", label: "Carousel" },
  ];

  const Layout4 = [
    { value: "a_venu", label: "A Venu" },
    { value: "hero_image", label: "Hero" },
    {
      value: "changing_tiles",
      label: "Changing Tiles",
    },
    {
      value: "Banner",
      label: "Banner",
    },
    { value: "gallery", label: "Gallery" },
  ];
  const Layout5 = [
    { value: "carousel", label: "Carousel" },
    { value: "hero_image", label: "Hero" },
    {
      value: "changing_tiles",
      label: "Changing Tiles",
    },
    {
      value: "Banner",
      label: "Banner",
    },
    { value: "spotlight", label: "Spotlight" },
  ];
  const Layout6 = [
    {
      value: "tiles",
      label: "Tiles",
    },
  ];
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (languageSetected) {
      // setTypeError(false);
    } else {
      toasterMessage("error", "Please select language");
    }
    if (pageSelected) {
    } else {
      toasterMessage("error", "Please select page");
    }
    console.log("subpageSelected", subpageSelected);
    if (subpageSelected) {
    } else {
      toasterMessage("error", "Please select Sub Section");
    }

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    // if (){

    // }
    event.preventDefault();
    setValidated(true);
    if (form.checkValidity() === true && pageSelected && languageSetected && subpageSelected) {
      let formData = new FormData();
      formData.append("lang", languageSetected?.value);
      formData.append("pageNameId", pageSelected.id);
    //   formData.append("order", event.target.order?.value);
      formData.append("subPage", subpageSelected?.value);
      formData.append("title", event.target.title?.value);
      formData.append("subTitle", event.target.subtitle?.value);
      formData.append("active_status", event.target.status.checked ? 1 : 0);
      formData.append("prev_banner_order", DEFAULT_ORDER);
      formData.append("new_banner_order", DEFAULT_ORDER);
      if (params.id) {
        dispatch(UpdatePage(formData, params.id, history));
      } else {
        dispatch(createPage(formData, history));
      }
    }
  };
  useEffect(() => {
    if (params.id) {
      dispatch(pageById(params.id));
    }
    // dispatch(pageLayoutList());
  }, []);

  useEffect(() => {
    if (page_detail.images?.length > 0) {
      setPostImageArray(page_detail.images);
    }
    if (page_detail.videos?.length > 0) {
      setPostVideoArray(page_detail.videos);
    }
 
  }, [page_detail]);



  return (
    <>

              <Form
                className="forms-sample"
                noValidate
                validated={validated}
                onSubmit={(event) => handleSubmit(event)}
              >
                <>
                  <div className="row">
                    <h4 className="card-title col-9 d-flex align-items-center">
                      General Information
                    </h4>
                  </div>
                  <Form.Row>
                    <Form.Group as={Col} md="6" className="row  mr-1">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className=" col-sm-4  col-form-label"
                      >
                        Title*
                      </Form.Label>
                      <div className="col-sm-8">
                        <Form.Control
                          type="text"
                          dir={languageSetected?.value === "ar" ? "rtl" : "ltr"}
                          className="form-control"
                          required
                          id="exampleInputUsername2"
                          placeholder="Title"
                          name="title"
                          defaultValue={page_detail.title}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide Title.
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>
                    <Form.Group as={Col} md="6" className="row">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className="col-sm-4  col-form-label"
                      >
                        Sub Title
                      </Form.Label>
                      <div className="col-sm-8 ">
                        <Form.Control
                          type="text"
                          className="form-control"
                          id="exampleInputUsername2"
                          placeholder="Sub Title"
                          name="subtitle"
                          dir={languageSetected?.value === "ar" ? "rtl" : "ltr"}
                          defaultValue={page_detail.subTitle}
                        />
                      </div>
                    </Form.Group>
                  </Form.Row>
                </>
                <Form.Row>
                  {/* <Form.Group as={Col} md="10"></Form.Group> */}
                  <Form.Group as={Col} md="12" className="row ml-2">
                    <Form.Check
                      className=""
                      name="status"
                      defaultChecked={page_detail.active_status}
                      onClick={() => {}}
                    />
                    <Form.Label className="col-form-label ml-1">
                      Publish This Section
                    </Form.Label>
                  </Form.Group>
                </Form.Row>
                <div className="row">
                  <div className="col-sm-4">
                    <Button type="submit" className="btn yellow-btn mr-2">
                      {params.id ? "Update" : "Create"}
                    </Button>
                  </div>
                </div>
              </Form>
    </>
  );
}

export default Banner;
