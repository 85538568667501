import React from "react";
import { Form, InputGroup, Col, Button, FormControl, Spinner } from "react-bootstrap";
import { useState, useRef, useEffect, useCallback } from "react";
import Multiselect from "multiselect-react-dropdown";
import { Editor } from "@tinymce/tinymce-react";
import ReactQuill from "react-quill";
import '../../assets/styles/global.scss';
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import ImageUploading from "react-images-uploading";
import { useDropzone } from "react-dropzone";
import { Link, useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { toasterMessage } from "../../utils/toast-util";
import "react-quill/dist/quill.snow.css";
import CropEasy from "../../crop/CropEasy";
import {
  ArtistPost,
  getArtistPost,
  UpdateArtistPost,
  artistPostApprovalById,
} from "../../Redux/action/artistDetail";
import { getTagList } from "../../Redux/action/categoriesManagement";
function CreateArtistPost() {
  const [openCrop, setOpenCrop] = useState(false);
  const [mdShow, setMdShow] = useState(false);
  const [image, setImage] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [description, setDescription] = useState("");
  const [videodescription, setVideodescription] = useState("");
  const [openCopyCrop, setOpenCopyCrop] = useState(false);
  const [validated, setValidated] = useState(false);
  const [publish, setPublish] = useState(false);
  const [publishVideo, setPublishvideo] = useState(false);
  const [postImageArray, setPostImageArray] = useState([]);
  const [postVideoArray, setPostVideoArray] = useState([]);
  const [videoLink, setVideoLink] = useState(null);
  const [deleatImage, setDeleatImage] = useState([]);
  const [deleatVideo, setDeleatVideo] = useState([]);

  const [rejectionReason, setRejectionReason] = useState(null);
  const [subpageSelected, setSubpageSelected] = useState(null);
  const [languageSetected, setLanguageSetected] = useState("en");
  const [postImageDetails, setPostImageDetails] = useState({});
  const [loader, setLoader] = useState(false);
  const [typeError, setTypeError] = useState(null);

  const dispatch = useDispatch();
  const history = useHistory();

  const artist_post = useSelector(
    (state) => state.ArtistManagementReducer.artist_post
  );
  const params = useParams();

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    event.preventDefault();
    setValidated(true);
    if (form.checkValidity() === true) {
      let formData = new FormData();
      formData.append("artistId", params.id);
      if (deleatImage.length > 0) {
        for (var x = 0; x < deleatImage.length; x++) {
          formData.append("delete_images_array", deleatImage[x]);
        }
      }
      if (deleatVideo.length > 0) {
        for (var x = 0; x < deleatVideo.length; x++) {
          formData.append("delete_videos_array", deleatVideo[x]);
        }
      }
      console.log(postImageArray);
      if (postImageArray.length > 0) {
        for (var x = 0; x < postImageArray.length; x++) {
          if (postImageArray[x]?.isNew) {
            formData.append(
              "artist_images",
              postImageArray[x].file
            );
            formData.append("new_images_desc", postImageArray[x].media_desc);
            formData.append("new_images_status", postImageArray[x].active_status ? 1 : 0);
          } else {
            formData.append("prev_images_id", postImageArray[x].id);
            formData.append("prev_images_desc", postImageArray[x].media_desc);
            formData.append("prev_images_status", postImageArray[x].active_status ? 1 : 0);
            // formData.append("page_images", null);
          }
        }
      } else {
        formData.append("artist_images", null);
      }
      if (postVideoArray.length > 0) {
        for (var x = 0; x < postVideoArray.length; x++) {
          if (postVideoArray[x]?.isNew) {
            formData.append("new_videos_link", postVideoArray[x].media_link);
            formData.append("new_videos_desc", postVideoArray[x].media_desc);
            formData.append("new_videos_status", postVideoArray[x].active_status ? 1 : 0);
          } else {
            formData.append("prev_videos_id", postVideoArray[x].id);
            formData.append("prev_videos_desc", postVideoArray[x].media_desc);
            formData.append("prev_videos_status", postVideoArray[x].active_status ? 1 : 0);
            // formData.append("new_videos_link ", null); 
          }
        }
      } else {
        formData.append("new_videos_link ", null);
      }
      if (artist_post.images?.length > 0 || artist_post.videos?.length > 0) {
        dispatch(UpdateArtistPost(formData, history, loader, setLoader));
      } else {
        dispatch(ArtistPost(formData, history, loader, setLoader));
      }

    }
  };
  const editPosts = (index, valueRecived, change) => {
    // let newState = postImageArray;
    console.log(valueRecived, "value")
    const newState = postImageArray.map((obj, i) => {
      // 👇️ if id equals 2, update country property
      if (i === index) {
        if (change === 1) {
          return { ...obj, media_title: valueRecived };
        } else {
          return { ...obj, media_desc: valueRecived };
        }

      }

      // 👇️ otherwise return object as is
      return obj;
    });
    console.log(newState, "newState")
    setPostImageArray(newState);
  };
  const editVideoPosts = (index, valueRecived, change) => {
    // let newState = postImageArray;
    console.log(valueRecived, "value")
    const newState = postVideoArray.map((obj, i) => {
      // 👇️ if id equals 2, update country property
      if (i === index) {
        if (change === 1) {
          return { ...obj, media_title: valueRecived };
        } else {
          return { ...obj, media_desc: valueRecived };
        }

      }

      // 👇️ otherwise return object as is
      return obj;
    });
    console.log(newState, "newState")
    setPostVideoArray(newState);
  };
  useEffect(() => {
    if (params.id) {
      dispatch(getArtistPost(params.id));
    }
  }, []);

  useEffect(() => {
    if (artist_post.images?.length > 0) {
      setPostImageArray(artist_post.images);
    }
    if (artist_post.videos?.length > 0) {
      setPostVideoArray(artist_post.videos);
    }

  }, [artist_post]);

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImage(URL.createObjectURL(event.target.files[0]));
      setImageFile(event.target.files[0]);
    }
  };

  const addPost = () => {
    if (image) {
      setPostImageArray([
        ...postImageArray,
        {
          image: image,
          active_status: publish,
          file: imageFile,
          isNew: true,
          media_desc: description,
        },
      ]);
      setImageFile();
      setImage();
      setPublish(false);
      setDescription("");
    } else {
      toasterMessage("error", "Please add an image before adding Post.");
    }
  };
  const addVideoPost = () => {
    if (videoLink) {
      setPostVideoArray([
        ...postVideoArray,
        {
          media_link: videoLink,
          active_status: publishVideo,
          isNew: true,
          media_desc: videodescription,
        },
      ]);
      setVideoLink();
      setPublishvideo(false);
      setVideodescription("");
    } else {
      toasterMessage("error", "Please add a video link before adding Post");
    }
  };
  const unPublish = (index, value) => {
    // let newState = postImageArray;
    const newState = postImageArray.map((obj, i) => {
      // 👇️ if id equals 2, update country property
      if (i === index) {
        console.log("found");
        return { ...obj, active_status: !value };
      }

      // 👇️ otherwise return object as is
      return obj;
    });
    setPostImageArray(newState);
  };
  const unPublishVideo = (index, value) => {
    const newState = postVideoArray.map((obj, i) => {
      // 👇️ if id equals 2, update country property
      if (i === index) {
        console.log("found");
        return { ...obj, active_status: !value };
      }

      // 👇️ otherwise return object as is
      return obj;
    });
    setPostVideoArray(newState);
  };
  const deleatPost = (index) => {
    if (postImageArray[index]?.isNew === false) {
      setDeleatImage([...deleatImage, postImageArray[index].id])
    }
    setPostImageArray(postImageArray.filter((item, i) => i !== index));
  };
  const deleatVideoPost = (index) => {
    if (postVideoArray[index]?.isNew === false) {
      setDeleatVideo([...deleatVideo, postVideoArray[index].id])
    }
    setPostVideoArray(postVideoArray.filter((item, i) => i !== index));
  };

  const styles = {
    menu: (base) => ({
      ...base,
      marginTop: "4px",
      fontSize: "0.875rem",

      passive: "true",
    }),
    control: (base) => ({
      ...base,
      fontSize: "0.875rem",
    }),
  };
  return (
    <>
      <div className="row">
        <h4 className="card-title col-8 d-flex align-items-center">
          {!params.id ? "Create Artist Post" : "Update Artist Post"}
        </h4>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/artist-management">Artist Management</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {!params.id ? "Create Artist Post" : "Update Artist Post"}
            </li>
          </ol>
        </nav>
      </div>
      <div className="row">
        <div className="col-md-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="row">
              </div>

              <Form
                className="forms-sample"
                noValidate
                validated={validated}
                onSubmit={(event) => handleSubmit(event)}
              >
                <div className="row">
                  <h4 className="card-title col-9 d-flex align-items-center">
                    Add Posts
                  </h4>
                </div>
                <>
                  <div className="row">
                    <div className="col-sm-8">
                      <div className="row">
                        <label className=" col-sm-3  col-form-label">
                          Image :
                        </label>
                        {image ? (
                          <>
                            <div className="image-item col-sm-9">
                              <img
                                src={image}
                                alt=""
                                width="100"
                                className="img-lg mb-3"
                              />
                              <div className="upload__image-wrapper ">
                                {/* <div className="row"> */}
                                <Button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setImage();
                                  }}
                                  className="btn yellow-btn"
                                >
                                  Remove
                                </Button>
                                {/* <Button
                                            onClick={(e) => {
                                              setOpenCrop(true);
                                            }}
                                            className="btn-dangercol-sm-5 mt-2"
                                          >
                                            Crop
                                          </Button> */}
                                {/* </div> */}
                              </div>
                            </div>
                          </>
                        ) : (
                          <input
                            type="file"
                            accept="image/png, image/jpeg"
                            onChange={onImageChange}
                            className="filetype col-sm-9"
                          />
                        )}
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="form-check form-check-success">
                            <label className="form-check-label">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                checked={publish}
                                onChange={() => setPublish(!publish)}
                              />{" "}
                              Publish
                              <i className="input-helper"></i>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-5">

                    <label className=" col-sm-2  col-form-label">
                      Description
                    </label>
                    <div className="image-item col-sm-8">
                      <textarea
                        id="w3review"
                        dir={
                          languageSetected?.value === "ar" ? "rtl" : "ltr"
                        }
                        name="w3review"
                        rows="6"
                        value={description}
                        style={{ width: "100%" }}
                        onChange={(e) => setDescription(e.target.value)}
                      />
                      {/* {description}
                          </textarea> */}
                    </div>
                  </div>

                  <Button onClick={() => addPost()} className="mt-3 mb-3 yellow-btn">
                    {" "}
                    Add Post{" "}
                  </Button>
                </>
                <>
                  {postImageArray?.length > 0 ? (
                    <h4 className="card-title col-9 d-flex align-items-center mt-3">
                      Already Added Posts
                    </h4>
                  ) : null}
                  {postImageArray.map((i, index) => {
                    const dateObject = new Date(i.createdAt);
                    const year = dateObject.getFullYear();
                    const month = String(dateObject.getMonth() + 1).padStart(2, "0");
                    const day = String(dateObject.getDate()).padStart(2, "0");
                    const formattedDate = `${year}-${month}-${day}`;
                    const formattedTime = dateObject.toLocaleTimeString('en-US', {
                      hour: '2-digit',
                      minute: '2-digit',
                      // second: '2-digit',
                    });
                    return (
                      <>
                        <div
                          key={index}
                          className="card"
                          style={{
                            padding: "10px",
                            marginTop: "20px",
                            marginBottom: "20px",
                          }}
                        >
                          <div className="row">
                            <div className="col-sm-5">
                              <div className="row">
                                <label className=" col-sm-5  col-form-label">
                                  Image :
                                </label>
                                <>
                                  <div className="image-item col-sm-7">
                                    {i?.media_link ? <img
                                      src={
                                        i.media_link?.includes('https')
                                          ? i?.media_link : process.env.REACT_APP_IMAGE_LINK + i?.media_link
                                      }
                                      alt=""
                                      width="100"
                                      className="img-lg mb-3"
                                    /> : <img
                                      src={i?.image}
                                      alt=""
                                      width="100"
                                      className="img-lg mb-3"
                                    />}
                                  </div>
                                </>
                              </div>
                            </div>
                            <div className="col-sm-7">
                              <div className="row">
                                <div className="col-sm-3">
                                  <div className="form-check form-check-success">
                                    <label className="form-check-label">
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={i.active_status}
                                        onChange={() =>
                                          unPublish(index, i.active_status)
                                        }
                                      />{" "}
                                      Publish
                                      <i className="input-helper"></i>
                                    </label>
                                  </div>
                                </div>
                                <div className="col-sm-3 mt-2">
                                  <h5>Image No : {index + 1}</h5>
                                </div>
                                {i.createdAt ?
                                  <div className="col-sm-3 mt-2">
                                    <h5>{`${formattedDate}/${formattedTime}`}</h5>
                                  </div>
                                  : <div className="col-sm-3 mt-2"></div>}
                                <div className="col-sm-3">
                                  <Button
                                    className="btn yellow-btn"
                                    onClick={() => {
                                      deleatPost(index);
                                    }}
                                  >
                                    Remove
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row mt-5">

                            <label className=" col-sm-2  col-form-label">
                              Description
                            </label>
                            <div className="image-item col-sm-8">
                              <textarea
                                dir={
                                  languageSetected?.value === "ar"
                                    ? "rtl"
                                    : "ltr"
                                }
                                id="w3review"
                                name="w3review"
                                rows="6"
                                value={i.media_desc}
                                style={{ width: "100%" }}
                                onChange={(e) => {
                                  editPosts(index, e.target.value, 2)
                                }}
                              >

                              </textarea>
                            </div>
                          </div>
                        </div>

                      </>
                    )
                  })}
                </>
                <div className="row">
                  <h4 className="card-title col-9 d-flex align-items-center">
                    Add Video Posts
                  </h4>
                </div>
                <>
                  <div className="row">
                    <div className="col-sm-8">
                      <Form.Row>

                        <Form.Label
                          htmlFor="exampleInputUsername2"
                          className=" col-sm-3  col-form-label"
                        >
                          Link
                        </Form.Label>
                        <div className="col-sm-8">
                          <Form.Control
                            type="text"
                            // dir={languageSetected?.value === "ar" ? "rtl" : "ltr"}
                            className="form-control"
                            id="exampleInputUsername2"
                            placeholder="Video Link"
                            value={videoLink || ""}
                            name="videoLink"
                            onChange={(e) => setVideoLink(e.target.value)}
                          // defaultValue={artist_detail.title}
                          />
                        </div>

                      </Form.Row>
                    </div>
                    <div className="col-sm-4">
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="form-check form-check-success">
                            <label className="form-check-label">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                checked={publishVideo}
                                onChange={() => setPublishvideo(!publishVideo)}
                              />{" "}
                              Publish
                              <i className="input-helper"></i>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-5">

                    <label className=" col-sm-2  col-form-label">
                      Description
                    </label>
                    <div className="image-item col-sm-8">
                      <textarea
                        id="w3review"
                        dir={
                          languageSetected?.value === "ar" ? "rtl" : "ltr"
                        }
                        name="w3review"
                        rows="6"
                        value={videodescription}
                        style={{ width: "100%" }}
                        onChange={(e) =>
                          setVideodescription(e.target.value)
                        }
                      />
                      {/* {description}
                          </textarea> */}
                    </div>
                  </div>

                  <Button onClick={() => addVideoPost()} className="mt-3 mb-3 yellow-btn">
                    {" "}
                    Add Video{" "}
                  </Button>
                </>
                <>
                  {postVideoArray.length > 0 ? (
                    <h4 className="card-title col-9 d-flex align-items-center mt-3">
                      Already Added Video Posts
                    </h4>
                  ) : null}
                  {postVideoArray.map((i, index) => (
                    <>
                      <div
                        key={index}
                        className="card"
                        style={{
                          padding: "10px",
                          marginTop: "20px",
                          marginBottom: "20px",
                        }}
                      >
                        <div className="row">
                          <div className="col-sm-5">
                            <Form.Row>
                              <Form.Group as={Col} md="12" className="row">
                                <Form.Label
                                  htmlFor="exampleInputUsername2"
                                  className=" col-sm-3  col-form-label"
                                >
                                  Link
                                </Form.Label>
                                <div className="col-sm-9">
                                  <a href={
                                    i.media_link?.includes('https') ? i.media_link :
                                      process.env.REACT_APP_IMAGE_LINK + i.media_link
                                  } target="_blank" >Click here </a>
                                </div>
                              </Form.Group>
                            </Form.Row>
                          </div>
                          <div className="col-sm-6">
                            <div className="row">
                              <div className="col-sm-4">
                                <div className="form-check form-check-success">
                                  <label className="form-check-label">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      checked={i.active_status}
                                      onChange={() =>
                                        unPublishVideo(index, i.active_status)
                                      }
                                    />{" "}
                                    Publish
                                    <i className="input-helper"></i>
                                  </label>
                                </div>
                              </div>
                              <div className="col-sm-4 mt-2">
                                <h5>Video No : {index + 1}</h5>
                              </div>
                              <div className="col-sm-4">
                                <Button
                                  className="btn yellow-btn"
                                  onClick={() => {
                                    deleatVideoPost(index);
                                  }}
                                >
                                  Remove
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row mt-5">

                          <label className=" col-sm-2  col-form-label">
                            Description
                          </label>
                          <div className="image-item col-sm-8">
                            <textarea
                              dir={
                                languageSetected?.value === "ar"
                                  ? "rtl"
                                  : "ltr"
                              }
                              id="w3review"
                              name="w3review"
                              rows="6"
                              style={{ width: "100%" }}
                              value={i.media_desc}
                              onChange={(e) => editVideoPosts(index, e.target.value, 2)}
                            >
                            </textarea>
                          </div>
                        </div>
                      </div>

                    </>
                  ))}
                </>
                <div className="row">
                  <div className="col-sm-12">
                    {artist_post.media_status === "Pending" ?
                      <>
                        <Button
                          onClick={() => {
                            dispatch(artistPostApprovalById(params.id, true, null, history, artist_post.type, loader, setLoader));
                          }}
                          className="btn-sm  yellow-btn"
                        >
                          {loader ? <Spinner animation="border" variant="light" />
                            : 'Approve'}
                        </Button>
                        <Button
                          onClick={() => setMdShow(true)}
                          className="btn-sm  yellow-btn"
                        >
                          Rejected
                        </Button>
                      </>
                      :
                      <Button type="submit" className="btn yellow-btn mr-2">
                        {params.id ? (loader ? <Spinner animation="border" variant="light" />
                          : 'Update') : "Create"}
                      </Button>
                    }
                  </div>
                  <Modal
                    show={mdShow}
                    onHide={() => setMdShow(false)}
                    aria-labelledby="example-modal-sizes-title-md"
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Reason ?</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                      <textarea
                        className="form-control"
                        id="textAreaExample"
                        rows="4"
                        onChange={(e) => {
                          setRejectionReason(e.target.value);
                        }}
                        placeholder={"Reason for Rejection ?"}
                        style={{ background: "#fff" }}
                      ></textarea>
                    </Modal.Body>

                    <Modal.Footer className="fleex-wrap">
                      <Button
                        variant=" yellow-btn m-2 "
                        onClick={(e) => {
                          dispatch(
                            artistPostApprovalById(
                              params.id,
                              false,
                              rejectionReason,
                              history,
                              artist_post.type
                            )
                          );
                          setMdShow(false);
                        }}
                      >
                        {loader ? <Spinner animation="border" variant="light" />
                          : 'Submit'}
                      </Button>
                      <Button
                        variant=" yellow-btn m-2"
                        onClick={() => setMdShow(false)}
                      >
                        Cancel
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>
              </Form>
            </div>
          </div>
        </div >
      </div >
    </>
  );
}

export default CreateArtistPost;
