import { combineReducers } from "redux";
import loginReducer from "./loginReducer";
import UserManagementReducer from "./userManagementReducer";
import CategoriesManagementReducer from "./categoriesManagementReducer";
import myAccountReducer from "./myAccount";
import MetaTagManagementReducer from "./metaTagManagementReducer";
import ArtistManagementReducer from "./artistManagementReducer";
import PageManagementReducer from "./pageMangementReducer"
import ContentReducer from "./contentReducer"
import SubAdminManagementReducer from "./subAdminManagementReducer"
import RoleManagementReducer from "./roleManagementReducer"
import PermissionReducer from "./permissionReducer"
import CmsReducer from "./cmsReducer"
import DashboardReducer from "./dashboardReducer"
import NewsletterReducer from "./newsLetterReducer"
import EnquiryReducer from "./enquiryReducer"
import SupplierPortalReducer from "./supplierPortalReducer";
import ArtistPortalReducer from "./artistPortalReducer";
import SupplierManagementReducer from "./supplierManagementReducer";


export default combineReducers({
  loginReducer,
  UserManagementReducer,
  myAccountReducer,
  PageManagementReducer,
  CategoriesManagementReducer,
  MetaTagManagementReducer,
  ArtistManagementReducer,
  ContentReducer,
  SubAdminManagementReducer,
  RoleManagementReducer,
  PermissionReducer,
  CmsReducer,
  EnquiryReducer,
  NewsletterReducer,
  DashboardReducer,
  ArtistPortalReducer,
  SupplierPortalReducer,
  SupplierManagementReducer,
});
