import axios from "axios";
import { toasterMessage } from "../../utils/toast-util";

export function optionDetail(id) {
  return async (dispatch) => {
    try {
      const response = await axios({
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + localStorage.getItem("jwt"),
        },
        url:
          process.env.REACT_APP_BACKEND_API +
          `admin/speciality-genre-list?artistTypeId=${id}`,
      });
      const { data } = response;
      if (data.status) {
        await dispatch({
          type: "OPTION_LISTS",
          payload: data.result.data,
        });
      } else {
        toasterMessage("error", data.result.message);
      }
    } catch (error) {
      if (error.reponse) {
        console.log("error");
      }
    }
  };
}

export const createArtist = (value, history, loader, setLoader) => {
  return async (dispatch) => {
    try {
      setLoader(!loader);
      const response = await axios({
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + localStorage.getItem("jwt"),
        },
        url: process.env.REACT_APP_BACKEND_API + `admin/artist`,
        data: value,
      });
      const { data } = response;
      if (data.status) {
        setLoader(!loader);
        toasterMessage("success", data.result.message);
        history.push("/artist-management");
      } else {
        setLoader(!loader);
        toasterMessage("error", data.result.message);
      }
    } catch (error) {
      setLoader(!loader);
      if (error.response) {
        setLoader(!loader);
        console.log("error");
        toasterMessage("error", error.response.data.result.message);
      }
    }
  };
};

export const UpdateArtist = (value, id, history, loader, setLoader) => {
  return async (dispatch) => {
    try {
      setLoader(!loader);
      const response = await axios({
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + localStorage.getItem("jwt"),
        },
        url: process.env.REACT_APP_BACKEND_API + `admin/artist/${id}`,
        data: value,
      });
      const { data } = response;
      if (data.status) {
        setLoader(!loader);
        toasterMessage("success", data.result.message);
        history.push("/artist-management");
      } else {
        setLoader(!loader);
        toasterMessage("error", data.result.message);
      }
    } catch (error) {
      setLoader(!loader);
      if (error.reponse) {
        setLoader(!loader);
        console.log("error");
        toasterMessage("error", error.response.data.result.message);
      }
    }
  };
};
export const artistListById = (id) => {
  return async (dispatch) => {
    try {
      const response = await axios({
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        url: process.env.REACT_APP_BACKEND_API + `admin/artist/${id}`,
      });
      const { data } = response;
      if (data.status) {
        await dispatch({
          type: "ARTIST_DETAIL",
          payload: data.result.data,
        });
      } else {
        toasterMessage("error", data.result.message);
      }
    } catch (error) {
      if (error.reponse) {
        console.log("error");
        toasterMessage("error", error.response.data.result.message);
      }
    }
  };
};

export const artistApprovalById = (id, action, reason, type, history) => {
  return async (dispatch) => {
    let demoApprovalStatus = "";
    if (action == "Audition") {
      demoApprovalStatus = "Audition";
    }
    else if (action == "Approved") {
      demoApprovalStatus = "Approved";
    }
    else {
      demoApprovalStatus = "Rejected";
    }
    try {
      const payload = {
        approval_status: demoApprovalStatus,
        approval_comment: reason,
        type: type,
      };
      const response = await axios({
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + localStorage.getItem("jwt"),
        },
        url:
          process.env.REACT_APP_BACKEND_API +
          `admin/artist/approval-status/${id}`,
        data: payload,
      });
      const { data } = response;
      if (data.status) {
        await dispatch(artistListById(id));
        toasterMessage("success", data.result.message);
        history.push("/artist-management");
      } else {
        toasterMessage("error", data.result.message);
      }
    } catch (error) {
      if (error.reponse) {
        console.log("error");
        toasterMessage("error", error.response.data.result.message);
      }
    }
  };
};

export const ArtistPost = (value, history) => {
  return async (dispatch) => {
    try {
      const response = await axios({
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + localStorage.getItem("jwt"),
        },
        url: process.env.REACT_APP_BACKEND_API + `admin/artist-post`,
        data: value,
      });
      const { data } = response;
      if (data.status) {
        toasterMessage("success", data.result.message);
        history.push("/artist-management");
      } else {
        toasterMessage("error", data.result.message);
      }
    } catch (error) {
      if (error.response) {
        console.log("error");
        toasterMessage("error", error.response.data.result.message);
      }
    }
  };
};
export const getArtistPost = (id) => {
  return async (dispatch) => {
    try {
      const response = await axios({
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + localStorage.getItem("jwt"),
        },
        url: process.env.REACT_APP_BACKEND_API + `admin/artist-post/${id}`,
      });
      const { data } = response;
      if (data.status) {
        await dispatch({
          type: "ARTIST_POST",
          payload: data.result.data,
        });
      } else {
        toasterMessage("error", data.result.message);
      }
    } catch (error) {
      if (error.reponse) {
        console.log("error");
        toasterMessage("error", error.response.data.result.message);
      }
    }
  };
};

export const UpdateArtistPost = (value, history, loader, setLoader) => {
  return async (dispatch) => {
    try {
      setLoader(!loader);
      const response = await axios({
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + localStorage.getItem("jwt"),
        },
        url: process.env.REACT_APP_BACKEND_API + `admin/artist-post`,
        data: value,
      });
      const { data } = response;
      if (data.status) {
        setLoader(!loader);
        toasterMessage("success", data.result.message);
        history.push("/artist-management");
      } else {
        setLoader(!loader);
        toasterMessage("error", data.result.message);
      }
    } catch (error) {
      if (error.response) {
        setLoader(!loader);
        console.log("error");
        toasterMessage("error", error.response.data.result.message);
      }
    }
  };
};

export const artistPostApprovalById = (id, action, reason, history, type, loader, setLoader) => {
  return async (dispatch) => {
    try {
      const payload = {
        media_status: action ? "Approved" : "Rejected",
        media_comment: reason,
        type: type,
      };
      setLoader(!loader);
      const response = await axios({
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + localStorage.getItem("jwt"),
        },
        url:
          process.env.REACT_APP_BACKEND_API +
          `admin/artist-post/approval-status/${id}`,
        data: payload,
      });
      const { data } = response;
      if (data.status) {
        setLoader(!loader);
        toasterMessage("success", data.result.message);
        history.push("/artist-management");
      } else {
        setLoader(!loader);
        toasterMessage("error", data.result.message);
      }
    } catch (error) {
      if (error.reponse) {
        setLoader(!loader);
        console.log("error");
        toasterMessage("error", error.response.data.result.message);
      }
    }
  };
};
