import axios from "axios";
import {toasterMessage} from "../../utils/toast-util"
export function  getPermissions(){
    return async (dispatch) => {
      try {
        const response = await axios({
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + (localStorage.getItem("jwt")),
          },
          url: process.env.REACT_APP_BACKEND_API+`admin/admin-permissions`,
          
        });
        const { data } = response;
        if (data) {
          await dispatch({
            type: "PERMISSION_DETAILS",
            payload: data.result.data,
          });
        console.log(data);
        }
      } catch (error) {
        if (error.reponse) {
          toasterMessage("error", "Something went Wrong");
        }
      }
    };
  };
