import { SUB_ADMIN_DETAIL , SUB_ADMIN_LIST} from "../action/actiontypes";
const initialState = {
    subAdmin_list:[],
    subadmin_detail:{},
  };
  const SubAdminManagementReducer = (state = initialState, action) => {
    switch (action.type) {
        case SUB_ADMIN_LIST:
          return {
            ...state,
            subAdmin_list: action.payload,
            subadmin_detail: {},
        };
        case SUB_ADMIN_DETAIL:
          return {
            ...state,
            subadmin_detail: action.payload,
        };
      default: return state
    }
  };
  export default SubAdminManagementReducer 