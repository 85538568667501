import { DASHBOARD_DATA } from "../action/actiontypes";
const initialState = {
  data: {},
  };
  const DashboardReducer = (state = initialState, action) => {
    switch (action.type) {
      case DASHBOARD_DATA:
        return {
          data: action.payload,
        };
        
      default: return state
    }
  };
  export default DashboardReducer 