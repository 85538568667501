import { ARTIST_DETAIL , OPTION_LISTS,ARTIST_LIST,ARTIST_POST ,SET_ARTIST_TYPE,SET_SPECIALITY_TYPE,SET_TAGS_TYPE,SET_GENRE_TYPE} from "../action/actiontypes";
const initialState = {
    tagList: [],
    tagdetails:{},
    specialty_options:[],
    genres_options:[],
    artist_list:[],
    artist_detail:{},
    artist_post:{},
    get_artist_type:'',
    get_speciality_type:[],
    get_tags_type:[],
    get_genre_type:[]
  };
  const ArtistManagementReducer = (state = initialState, action) => {
    switch (action.type) {
        case OPTION_LISTS:
          return {
            ...state,
            specialty_options: action.payload.specialities,
            genres_options: action.payload.genres,            
          };
        case ARTIST_LIST:
          return {
            ...state,
            artist_list: action.payload,
            artist_detail: {},
            artist_post:{}, 
            // specialty_options:[],
            tagList: [],
            // genres_options:[],
        };
        case ARTIST_DETAIL:
          return {
            ...state,
            artist_detail: action.payload,
        };
        case ARTIST_POST:
          return {
            ...state,
            artist_post: action.payload,
        };
        case SET_ARTIST_TYPE:
        return {
          ...state,
          get_artist_type: action.payload
        }
        case SET_SPECIALITY_TYPE:
        return {
          ...state,
          get_speciality_type: action.payload
        }
        case SET_TAGS_TYPE:
        return {
          ...state,
          get_tags_type: action.payload
        }
        case SET_GENRE_TYPE:
        return {
          ...state,
          get_genre_type: action.payload
        }
      default: return state
    }
  };
  export default ArtistManagementReducer 