import React, { Component, useState } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { toasterMessage } from "../../utils/toast-util";
import { useDispatch, useSelector } from "react-redux";
import {
  artistForgot,
} from "../../Redux/action/artistLogin";
function ArtistForgot() {
  const [mdShow, setMdShow] = useState(false);
  const [email, setEmail] = useState();
  const dispatch = useDispatch();
  const handleChange = (e, type) => {
    if (type == 1) {
      setEmail(e.target.value);
    }
  };
  const handleSubmit = () => {
    var validated = false;
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      toasterMessage("error", "Please add valid email");
    } else {
      validated = true;
    }


    if (validated) {
      console.log("works");
      var obj = {
        email: email,
      }
      dispatch(artistForgot(obj));
      setMdShow(true);
    }
  };
  return (
    <div>
      <div className="d-flex align-items-stretch auth auth-img-bg h-100">
        <div className="row flex-grow">
          <div className="col-lg-5 register-half-bg">

            <div className="auth-form-transparent text-left d-flex side_area ">
              <div className="">
                <div className="brand-logo-forget">
                  <img
                    src={require("../../assets/images/logo.svg")}
                    alt="logo"
                  />
                </div>
                {/* <h2>Lorem ipsum dolor sit amet consectetur</h2>
              <h5 className="font-weight-light">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
                vulputate libero et velit interdum, ac aliquet odio mattis.
              </h5> */}
              </div>

            </div>
            <div className="bottom_text">
              <h6>Terms & Conditions Privacy Policy</h6>
              <h6>copyrigtht@ 2022. All rights reserved</h6>
            </div>
          </div>
          <div className="col-lg-6 d-flex align-items-center justify-content-center">
            <div className="auth-form-transparent text-left ">
              {/* <div className="brand-logo">
                <img src={require("../../assets/images/logo.svg")} alt="logo" />
              </div> */}
              <h2 className="font-weight-bold">Forgot your password?</h2>
              <h4>
                Start the process to reset your password
              </h4>
              <form className="pt-3">
                {/* <div className="form-group">
                    <label>Username</label>
                    <div className="input-group">
                      <div className="input-group-prepend bg-transparent">
                        <span className="input-group-text bg-transparent border-right-0">
                          <i className="mdi mdi-account-outline text-primary"></i>
                        </span>
                      </div>
                      <input type="text" className="form-control form-control-lg border-left-0" placeholder="Username" />
                    </div>
                  </div> */}
                <div className="form-group">
                  <h5>Registered email address</h5>
                  <div className="input-group">
                    <input
                      onChange={(e) => handleChange(e, 1)}
                      value={email}
                      type="email"
                      className="form-control form-control-lg "
                      placeholder="Enter your registered email address"
                    />
                  </div>
                </div>
                <div className="mt-3">
                  <Button
                    className="btn btn-block btn-lg font-weight-medium yellow-btn btn-icon-tex"
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    Continue
                  </Button>
                </div>
                <div className="text-center mt-4 font-weight-light">
                  <Link to="/artist-login" className="text-black">
                    <i className="fa fa-angle-left"></i> Back to login
                  </Link>
                </div>
              </form>
              <Modal
                size="md"
                show={mdShow}
                onHide={() => {
                  setMdShow(false);
                }}
                aria-labelledby="example-modal-sizes-title-md"
              >
                <Modal.Header closeButton>
                  <Modal.Title
                    style={{
                      width: "100%",
                      "text-align": "center",
                      "border-bottom": "1px solid  #86878880",
                    }}
                  >
                    <div className="brand-logo p-4">
                      <img
                        src={require("../../assets/images/mail.png")}
                        alt="logo"
                      />
                    </div>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body
                  className="fleex-wrap"
                  style={{ "text-align": "center" }}
                >
                  <p className="font-weight-bold">
                    A verification link will be sent to your registered email
                    address.
                  </p>
                </Modal.Body>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ArtistForgot;
