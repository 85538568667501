import { ACCOUNT_DETAILS} from "../action/actiontypes";
const initialState = {
    accountDetails: {},
  };
  const myAccountReducer = (state = initialState, action) => {
    switch (action.type) {
      case ACCOUNT_DETAILS:
        return {
          ...state,
          accountDetails: action.payload,
        };
      default: return state
    }
  };
  export default myAccountReducer 