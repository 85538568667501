import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "../../assets/styles/global.scss";
import { useState, useRef, useEffect, useCallback } from "react";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import {
  pageLayoutList,
  pageListFilter,
  deleatPageLayout,
} from "../../Redux/action/cms";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import Select from "react-select";
import { Button } from "react-bootstrap";
const { SearchBar } = Search;

const defaultSorted = [
  {
    dataField: "id",
    order: "desc",
  },
];

const styles = {
  menu: (base) => ({
    ...base,
    marginTop: "4px",
    "font-size": "0.875rem",

    passive: "true",
  }),
  control: (base) => ({
    ...base,
    "font-size": "0.875rem",
  }),
};
function PageLayoutManagement() {
  const [mdShow, setMdShow] = useState(false);
  const [deleteId, setdeleteId] = useState();
  const dispatch = useDispatch();
  const pageListOptions = useSelector(
    (state) => state.CmsReducer.page_layout_list
  );
  useEffect(() => {
    dispatch(pageLayoutList());
  }, []);

  const columns = [
    {
      dataField: "name",
      text: "Page",
      sort: true,
      style: { padding: "5px 25px 5px 0.9375rem" },
    },
    // {
    //   dataField: "menuName",
    //   text: "Menu",
    //   sort: true,
    //   style: { padding: "5px 25px 5px 0.9375rem"},
    // },
    {
      dataField: "active_status",
      text: "Status",
      sort: true,
      style: { padding: "5px 25px 5px 0.9375rem" },
      formatter: (cellContent, row) => {
        console.log(cellContent);
        if (cellContent === true) {
          return <label className="badge badge-warning">Published</label>;
        } else {
          return <label className="badge badge-success">Unpublished</label>;
        }
      },
      csvFormatter: (cellContent) => {
        if (cellContent === true) {
          return "Active";
        } else {
          return "Inactive";
        }
      },
    },
    {
      dataField: "action",
      text: "Action",
      sort: false,
      style: { padding: "5px 25px 5px 0.9375rem", width: "16em" },
      formatter: (cellContent, row) => {
        return (
          <div style={{ display: "flex" }}>
          
              <>
              {row.id !== 6 || row.id !== 7 ? ( <div>
                  <Link
                    className="btn edit-icons mr-2"
                    to={`/website-management/page-layout-management/update-page-layout/${row.id}`}
                  >
                    <i class="ti-pencil"></i>
                  </Link>
                  <div>
                  
                </div>
                </div> 
                ) : null}
                <div>
                    <a
                    className="btn edit-icons mr-2"
                    target="#"
                    href={process.env.REACT_APP_PREVIEW_LINK +`${row.slug}`}
                  >
                    <i class="ti-eye"></i>
                  </a>
                  </div>
                {row.id > 7 ? (
                  
                <div>
                  <button
                    className="btn edit-icons"
                    onClick={(e) => {
                      setdeleteId(row.id);
                      setMdShow(true);
                    }}
                  >
                    <i class="ti-trash"></i>
                  </button>
                </div>) : null}
            </>

          </div>
        );
      },
    },
  ];

  return (
    <div>
      <div className="page-header">
        <h3 className="page-title">Page Layout Management</h3>

        <div className="breadcrumb">
          <Link
            className="btn btn-block yellow-btn font-weight-medium auth-form-btn"
            to="/website-management/page-layout-management/create-page-layout"
          >
            + NEW
          </Link>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body ">
              <div className="row ">
                <div className="col-12 ">
                  <ToolkitProvider
                    keyField="id"
                    bootstrap4
                    data={pageListOptions}
                    columns={columns}
                    search
                    exportCSV={{
                      fileName: "User List.csv",
                    }}
                  >
                    {(props) => (
                      <div>
                        <div className="page-header ">
                          <div className="d-flex align-items-center">
                            <p className="mb-2 mr-2">Search:</p>
                            <SearchBar {...props.searchProps} />{" "}
                          </div>
                        </div>
                        {/* <div className="container"> */}
                        <BootstrapTable
                          defaultSorted={defaultSorted}
                          pagination={paginationFactory()}
                          noDataIndication={() => <h4> No Records Found</h4>}
                          {...props.baseProps}
                          wrapperClasses="table-responsive "
                        />
                        {/* </div> */}
                      </div>
                    )}
                  </ToolkitProvider>
                  <Modal
                    show={mdShow}
                    onHide={() => {
                      setMdShow(false);
                    }}
                    aria-labelledby="example-modal-sizes-title-md"
                  >
                    <Modal.Header closeButton>
                      <Modal.Title
                        style={{ width: "100%", "text-align": "center" }}
                      >
                        Are You Sure, You Want To Delete ?
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Footer
                      className="fleex-wrap"
                      style={{ justifyContent: "center" }}
                    >
                      <Button
                        variant="success m-2 yellow-btn"
                        onClick={(e) => {
                          setMdShow(false);
                          dispatch(deleatPageLayout(deleteId));
                        }}
                      >
                        Yes
                      </Button>
                      <Button
                        variant="light m-2 yellow-btn"
                        onClick={() => {
                          setMdShow(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PageLayoutManagement;
