import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "../../assets/styles/global.scss";
import { useState, useRef, useEffect, useCallback } from "react";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import {
  pageLayoutList,
} from "../../Redux/action/cms"
import {
  enquiryList,
  enquiryDelete,
} from "../../Redux/action/enquiryManagement";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import Select from "react-select";
import { Button } from "react-bootstrap";
const { SearchBar } = Search;

const defaultSorted = [
  {
    dataField: "id",
    order: "desc",
  },
];
const status = [
  { value: 0, label: "Inactive" },
  { value: 1, label: "Active" },
];
const approvedStatus = [
  { value: "Pending", label: "Pending" },
  { value: "Approved", label: "Approved" },
  { value: "Rejected", label: "Rejected" },
];

const styles = {
  menu: (base) => ({
    ...base,
    marginTop: "4px",
    "font-size": "0.875rem",

    passive: "true",
  }),
  control: (base) => ({
    ...base,
    "font-size": "0.875rem",
  }),
};
function EnquiryManagement() {
  const [pageSelected, setPageSelected] = useState();
  const [statusSelected, setStatusSelected] = useState(null);
  const [languageOption, setLanguageOption] = useState();
  const [subpageSelected, setSubPageSelected] = useState();
  const [mdShow, setMdShow] = useState(false);
  const [deleteId, setdeleteId] = useState();
  const dispatch = useDispatch();
  // const pageListOptions = useSelector(
  //   (state) => state.PageManagementReducer.page_list
  // );
  const data = useSelector(
    (state) => state.EnquiryReducer.list
  );
  useEffect(() => {
    dispatch(enquiryList());
  }, []);

  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
      style: { padding: "0px 25px  0px 0.9375rem" },
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      style: { padding: "0px 25px  0px 0.9375rem" },
    },
    {
      dataField: "contact_no",
      text: "Contact No",
      sort: true,
      style: { padding: "0px 25px  0px 0.9375rem" },
    },
    {
      dataField: "companyName",
      text: "Company Name",
      sort: true,
      style: { padding: "0px 25px  0px 0.9375rem" },
    },
    {
      dataField: "eventDate",
      text: "Event Date",
      sort: true,
      style: { padding: "0px 0 0px 25px" },
      formatter: (cellContent) => {
        return <Moment format="DD/MM/YYYY">{cellContent}</Moment>;
      },
      csvFormatter: (cellContent) => {
        const today = new Date(cellContent);
        return new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }).format(today);
      },
    },
    {
      dataField: "eventTime",
      text: "Event Time",
      sort: true,
      style: { padding: "0px 25px  0px 0.9375rem" },
    },
    {
      dataField: "location",
      text: "Location",
      sort: true,
      style: { padding: "0px 25px  0px 0.9375rem" },
    },
    {
      dataField: "guests",
      text: "Guests",
      sort: true,
      style: { padding: "0px 25px  0px 0.9375rem" },
    },
    {
      dataField: "createdAt",
      text: "Submitted On",
      sort: true,
      style: { padding: "0px 25px  0px 0.9375rem" },
      formatter: (cellContent) => {
        const date = new Date(cellContent);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const day = String(date.getDate()).padStart(2, '0');
        const formattedDate = `${day}/${month}/${year}`;
        return formattedDate;
      },
    },
    // {
    //   dataField: "companyName",
    //   text: "Company Name",
    //   sort: true,
    //   style: { padding: "0px 25px  0px 0.9375rem" },
    // },
    // {
    //   dataField: "active_status",
    //   text: "Status",
    //   sort: true,
    //   style: { padding: "0px 25px  0px 0.9375rem" },
    //   formatter: (cellContent, row) => {
    //     if (cellContent === 1) {
    //       return <label className="badge badge-warning">Published</label>;
    //     } else {
    //       return <label className="badge badge-success">Unpublished</label>;
    //     }
    //   },
    //   csvFormatter: (cellContent) => {
    //     if (cellContent === 1) {
    //       return "Active";
    //     } else {
    //       return "Inactive";
    //     }
    //   },
    // },
    {
      dataField: "action",
      text: "Action",
      sort: false,
      csvExport: false,
      style: { padding: "5px 25px 5px 0.9375rem", width: "16em" },
      formatter: (cellContent, row) => {
        return (
          <div style={{ display: "flex" }}>
            <div>
              <Link
                className="btn edit-icons mr-2"
                to={`/enquiry-management/${row.id}`}
              >
                <i class="ti-eye"></i>
              </Link>
            </div>
            <div>
              <button
                className="btn edit-icons"
                onClick={(e) => {
                  setdeleteId(row.id);
                  setMdShow(true);
                }}
              >
                <i class="ti-trash"></i>
              </button>
            </div>
          </div>
        );
      },
    },
  ];
  const MyExportCSV = (props) => {
    const handleClick = () => {
      props.onExport();
    };
    return (
      <div>
        <button className="btn yellow-btn btn-icon-tex" onClick={handleClick}>
          <i className="fa fa-download "></i> Export
        </button>
      </div>
    );
  };
  // const handleFilter = () => {
  //   var value = {
  //     status: statusSelected?.value,
  //     language: languageOption?.value,
  //     subpage: subpageSelected?.value,
  //     page: pageSelected?.id,
  //   };
  //   console.log("clicked", value);
  //   dispatch(pageListFilter(value));
  // };
  // const resetFilter = () => {
  //   setPageSelected("");
  //   setStatusSelected("");
  //   setLanguageOption("");
  //   setSubPageSelected("");
  //   dispatch(pageList());
  // };
  const language = [
    { value: "ar", label: "Arabic" },
    { value: "en", label: "English" },
  ];
  const status = [
    { value: 0, label: "Unpublish" },
    { value: 1, label: "Publish" },
  ];

  return (
    <div>

      <div className="page-header">
        <h3 className="page-title">Enquiry Management</h3>
      </div>
      <div className="row">
        <div className="col-12">

          {/* <div className="row mb-2">
            <div className="col">
              <Select
                styles={styles}
                options={language}
                maxMenuHeight={100}
                value={languageOption}
                onChange={(e) => {
                  setLanguageOption(e);
                }}
                placeholder="Language"
                // ref={this.artistTypeRef}
              />
            </div>
            <div className="col">
              <Select
                required
                options={page}
                value={pageSelected}
                styles={styles}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                placeholder="Select Page"
                maxMenuHeight={100}
                onChange={(e) => {
                  setPageSelected(e);
                }}
              />
            </div>
            <div className="col">
              <Select
                styles={styles}
                maxMenuHeight={100}
                options={status}
                value={statusSelected}
                onChange={(e) => {
                  setStatusSelected(e);
                }}
                placeholder="Status"
                // ref={this.approvalRef}
              />
            </div>
            <div className="row justify-content-end mb-2 mr-1 col">
            <Button className=" yellow-btn" onClick={(e) => handleFilter()}>
              Apply
            </Button>
            <Button className="yellow-btn" onClick={(e) => resetFilter()}>
              Reset
            </Button>
          </div>
          </div> */}

          <div className="card">
            <div className="card-body ">
              <div className="row ">
                <div className="col-12 ">
                  <ToolkitProvider
                    keyField="id"
                    bootstrap4
                    data={data || []}
                    columns={columns}
                    search
                    exportCSV={{
                      fileName: "Enquries.csv",
                    }}
                  >
                    {(props) => (
                      <div>
                        <div className="page-header ">
                          <div className="d-flex align-items-center">
                            <p className="mb-2 mr-2">Search:</p>
                            <SearchBar {...props.searchProps} />{" "}
                          </div>
                          <MyExportCSV {...props.csvProps} />
                        </div>
                        {/* <div className="container"> */}
                        <BootstrapTable
                          defaultSorted={defaultSorted}
                          pagination={paginationFactory()}
                          noDataIndication={() => <h4> No Records Found</h4>}
                          {...props.baseProps}
                          wrapperClasses="table-responsive "
                        />
                        {/* </div> */}
                      </div>
                    )}
                  </ToolkitProvider>
                  <Modal
                    show={mdShow}
                    onHide={() => {
                      setMdShow(false);
                    }}
                    aria-labelledby="example-modal-sizes-title-md"
                  >
                    <Modal.Header closeButton>
                      <Modal.Title
                        style={{ width: "100%", "text-align": "center" }}
                      >
                        Are You Sure, You Want To Delete ?
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Footer
                      className="fleex-wrap"
                      style={{ justifyContent: "center" }}
                    >
                      <Button
                        variant="success m-2 yellow-btn"
                        onClick={(e) => {
                          setMdShow(false);
                          dispatch(enquiryDelete(deleteId));
                        }}
                      >
                        Yes
                      </Button>
                      <Button
                        variant="light m-2 yellow-btn"
                        onClick={() => {
                          setMdShow(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EnquiryManagement;
