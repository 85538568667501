import axios from "axios";
import { toasterMessage } from "../../utils/toast-util";

export const supplierLogin = (value, history) => {
    return async (dispatch) => {
        try {
            const response = await axios({
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                },
                url: process.env.REACT_APP_BACKEND_API + `supplier/login `,
                data: value,
            });
            const { data } = response;
            if (data.status) {
                toasterMessage("success", data.result.message);
                localStorage.setItem("email", value.email);
                localStorage.setItem("userId", data.result.id);
                localStorage.setItem("loginType", "3");
                history.push("/supplier-otp");
            } else {
                toasterMessage("error", data.result.message);
            }
        } catch (error) {

            if (error.response) {
                console.log("error", error);
                toasterMessage("error", error.response.data.result.message);
            }
        }
    };
};

export const supplierSignup = (value, handleResponse, history) => {
    return async (dispatch) => {
        try {
            const response = await axios({
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                },
                url: process.env.REACT_APP_BACKEND_API + `supplier/sign-up `,
                data: value,
            });
            const { data } = response;
            if (data.status) {
                toasterMessage("success", data.result.message);
                history.push("/supplier-login");
            } else {
                toasterMessage("error", data.result.message);
            }
        } catch (error) {

            if (error.response) {
                console.log("error", error);
                toasterMessage("error", error.response.data.result.message);
            }
        }
    };
};
export const supplierOtp = (value, history) => {
    return async (dispatch) => {
        try {
            const response = await axios({
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    // Authorization: "Bearer " + localStorage.getItem("jwt"),
                },
                url: process.env.REACT_APP_BACKEND_API + `supplier/otp-auth `,
                data: value,
            });
            const { data } = response;
            if (data.status) {
                toasterMessage("success", data.result.message);
                localStorage.setItem("userImage", data.result.data.profile_pic);
                localStorage.setItem("firstname", data.result.data.firstName);
                localStorage.setItem("jwt", data.result.accessToken);
                localStorage.removeItem("userId");
                localStorage.removeItem("email")
                history.push("/supplier/my-profile")
            } else {
                console.log("tets");
                toasterMessage("error", data.result.message);
            }
        } catch (error) {

            if (error.response) {
                console.log("error", error);
                toasterMessage("error", error.response.data.result.message);
            }
        }
    };
};
export const supplierForgot = (value) => {
    return async (dispatch) => {
        try {
            const response = await axios({
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    // Authorization: "Bearer " + localStorage.getItem("check"),
                },
                url: process.env.REACT_APP_BACKEND_API + `supplier/forget-password`,
                data: value,
            });
            const { data } = response;
            console.log("tets", data);
            if (data.status) {
                toasterMessage("success", data.result.message);
                //   history.push("/supplier-otp");
            } else {
                console.log("tets");
                toasterMessage("error", data.result.message);
            }
        } catch (error) {

            if (error.response) {
                console.log("error", error);
                toasterMessage("error", error.response.data.result.message);
            }
        }
    };
};
export const supplierReset = (value) => {
    return async (dispatch) => {
        try {
            const response = await axios({
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    // Authorization: "Bearer " + localStorage.getItem("check"),
                },
                url: process.env.REACT_APP_BACKEND_API + `supplier/reset-password`,
                data: value,
            });
            const { data } = response;
            console.log("tets", data);
            if (data.status) {
                toasterMessage("success", data.result.message);
                //   history.push("/supplier-otp");
            } else {
                console.log("tets");
                toasterMessage("error", data.result.message);
            }
        } catch (error) {

            if (error.response) {
                console.log("error", error);
                toasterMessage("error", error.response.data.result.message);
            }
        }
    };
};

export const supplierComp = (value, history) => {
    return async (dispatch) => {
        try {
            const response = await axios({
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "multipart/form-data",
                    "Access-Control-Allow-Origin": "*",
                    // Authorization: "Bearer " + localStorage.getItem("check"),
                },
                url: process.env.REACT_APP_BACKEND_API + `supplier/sign-up-completion`,
                data: value,
            });
            const { data } = response;
            console.log("tets", data);
            if (data.status) {
                toasterMessage("success", data.result.message);
                history.push("/supplier-login");
            } else {
                console.log("tets");
                toasterMessage("error", data.result.message);
            }
        } catch (error) {
            if (error.response) {
                console.log("error", error);
                toasterMessage("error", error.response.data.result.message);
            }
        }
    };
};