import React from "react";
import { Form, InputGroup, Col, Button, FormControl, Spinner } from "react-bootstrap";
import { useState, useRef, useEffect, useCallback } from "react";
import Multiselect from "multiselect-react-dropdown";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import ImageUploading from "react-images-uploading";
import { useDropzone } from "react-dropzone";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import { toasterMessage } from "../../utils/toast-util";
import CropEasy from "../../crop/CropEasy";
import { getArtistType } from "../../Redux/action/categoriesManagement";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import ArtistDemo from "../../assets/images/artist.png";

import {
  optionDetail,
  createArtist,
  artistListById,
  artistApprovalById,
  UpdateArtist,
  getArtistPost
} from "../../Redux/action/artistDetail";
import { getTagList } from "../../Redux/action/categoriesManagement";
import { countryDeatils, Currency } from "../../assets/country";
function AddArtist(props) {
  const [openCrop, setOpenCrop] = useState(false);
  const [mdShow, setMdShow] = useState(false);
  const [openIdCrop, setOpenIdCrop] = useState(false);
  const [openPhotoCrop, setOpenPhotoCrop] = useState(false);
  const [removePassportImage, setRemovePassportImage] = useState(true);
  const [removePassportImageCopy, setRemovePassportImageCopy] = useState(true);
  const [removeEmirateIDImage, setRemoveEmirateIDImage] = useState(true);
  const [removeEmirateVisaImage, setRemoveEmirateVisaImage] = useState(true);
  const [removeTechnicalRider, setRemoveTechnicalRider] = useState(true);
  const [removeHospitalityRider, setRemoveHospitalityRider] = useState(true);
  const [openCopyCrop, setOpenCopyCrop] = useState(false);
  const [validated, setValidated] = useState(false);
  const [artistTypeId, setArtistTypeId] = useState(false);
  const [typeSelected, setTypeSelected] = useState(null);
  const [typeSelectedSpeciality, setTypeSelectedSpeciality] = useState([]);
  const [typeSelectedGenre, setTypeSelectedGenre] = useState([]);
  const [rejectionReason, setRejectionReason] = useState(null);
  const [typeError, setTypeError] = useState(null);
  const [passportImage, setPassportImage] = useState([]);
  const [passportPhoto, setPassportPhoto] = useState([]);
  const [emirateID, setEmirateID] = useState([]);
  const [emiratesVisa, setEmiratesVisa] = useState([]);
  const [nationality, setNationality] = useState(null);
  const [technicalRider, setTechnicalRider] = useState([]);
  const [hospitalityRider, setHospitalityRider] = useState([]);
  const [corporateSets, setCorporateSets] = useState(null);
  const [corporateCurrency, setCorporateCurrency] = useState(null);
  const [residencySets, setResidencySets] = useState(null);
  const [residencyCurrency, setResidencyCurrency] = useState(null);
  const [multipleSets, setMultipleSets] = useState(null);
  const [multipleCurrency, setMultipleCurrency] = useState(null);
  const [nationalityError, setNationalityError] = useState(null);
  const [country, setCountry] = useState(null);
  const [countryError, setCountryError] = useState(null);
  const [docError, setDocError] = useState(false);
  const [mobileNumber1, setmobileNumber1] = useState(null);
  const [checkMobileNumber, setCheckMobileNumber] = useState(false);
  const [mobileNumber2, setmobileNumber2] = useState(null);
  const [imagePreview, setImagePreview] = useState(ArtistDemo);
  const [postImageArray, setPostImageArray] = useState([]);
  const [postVideoArray, setPostVideoArray] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const tagRef = useRef(null);
  const specialtyRef = useRef(null);
  const genreRef = useRef(null);
  const history = useHistory();
  const location = useLocation();
  const { editFlag } = location && location.state;
  const CameraIcon = useRef(null);
  const [profileimageFile, setProfileImageFile] = useState(null);

  const artistList = useSelector(
    (state) => state.CategoriesManagementReducer.artistTypeList
  );
  const specialty = useSelector(
    (state) => state.ArtistManagementReducer.specialty_options
  );
  const genres = useSelector(
    (state) => state.ArtistManagementReducer.genres_options
  );
  const tags_list = useSelector(
    (state) => state.CategoriesManagementReducer.tagList
  );
  const artist_detail = useSelector(
    (state) => state.ArtistManagementReducer.artist_detail
  );
  const artist_post = useSelector(
    (state) => state.ArtistManagementReducer.artist_post
  );
  const params = useParams();
  useEffect(() => {
    dispatch(getArtistType());
    dispatch(getTagList());
    if (params && params.id) {
      dispatch(artistListById(params.id));
      dispatch(getArtistPost(params.id));
    }
  }, []);
  useEffect(() => {
    if (artist_post.images?.length > 0) {
      setPostImageArray(artist_post.images);
    }
    if (artist_post.videos?.length > 0) {
      setPostVideoArray(artist_post.videos);
    }

  }, [artist_post]);
  const handleSubmit = (event) => {
    if (mobileNumber1 === null || mobileNumber1.length < 11) {
      toasterMessage("error", "Please enter mobile number");
      setCheckMobileNumber(true);
    }

    const form = event.currentTarget;

    if (country && nationality && typeSelected) {
      setCountryError(false);
      event.preventDefault();
    } else {
      // setCountryError(true);
      setSubmitted(true);
      toasterMessage(
        "error",
        "Please provide all required General Information."
      );
      event.preventDefault();
    }
    // var docE;
    // if (
    //   (passportPhoto[0] || artist_detail.passportImgUrl) &&
    //   (passportImage[0] || artist_detail.passportUrl) &&
    //   (emiratesVisa[0] || artist_detail.emiratesVisa) &&
    //   (emirateID[0] || artist_detail.emiratesId)
    // ) {
    //   docE = false;
    // } else {
    //   docE = true;
    //   toasterMessage("error", "Please provide all required Identify Documents");
    // }
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    event.preventDefault();
    setValidated(true);

    var localTag = [];
    tagRef.current.getSelectedItems().forEach((item) => {
      localTag.push(item.id);
    });
    var localSpecialty = [];
    specialtyRef.current.getSelectedItems().forEach((item) => {
      localSpecialty.push(item.id);
    });
    var localGenres = [];
    genreRef.current.getSelectedItems().forEach((item) => {
      localGenres.push(item.id);
    });
    var hasError = false;
    hasError = false;
    if (specialty.length > 0) {
      if (localSpecialty.length <= 0) {
        setError(true);
        hasError = true;
        toasterMessage("error", "Please select Speciality");
      }
    }
    if (!event.target.instagram.value) {
      hasError = true;
      toasterMessage("error", "Please provide your instagram URL");
    }
    // if (tags_list.length > 0) {
    //   if (localTag.length <= 0) {
    //     setError(true);
    //     hasError = true;
    //     toasterMessage("error", "Please select Tag");
    //   }
    // }
    if (genres.length > 0) {
      if (localGenres.length <= 0) {
        setError(true);
        hasError = true;
        toasterMessage("error", "Please select Genres");
      }
    }
    if (
      form.checkValidity() === true &&
      typeSelected &&
      nationality &&
      country &&
      mobileNumber1 &&
      !hasError
      // !docE
    ) {
      let formData = new FormData();
      formData.append("stageName", event.target.stagename?.value);
      formData.append("passportName", event.target.passportName?.value);
      formData.append("tags", localTag);
      formData.append("artistType", typeSelected?.id);
      formData.append("specialities", localSpecialty);
      formData.append("genres", localGenres);
      formData.append("bio", event.target.biography?.value);
      formData.append("nationality", nationality?.country);
      formData.append("country", country?.country);
      formData.append("city", event.target.city?.value);
      formData.append("website", event.target.website?.value);
      formData.append("fbURL", event.target.facebook.value);
      formData.append("ytURL", event.target.youtube.value);
      formData.append("instaURL", event.target.instagram.value);
      formData.append("soundcloudURL", event.target.soundcloud.value);
      formData.append("twitterURL", event.target.twitter.value);
      formData.append("firstName", event.target.firstName.value);
      formData.append("lastName", event.target.lastname.value);
      formData.append("email", event.target.email.value);
      formData.append("phone1", mobileNumber1);
      formData.append("phone2", mobileNumber2);
      formData.append("why_book", event.target.whybook.value);
      if (profileimageFile) {
        formData.append("artist_profile_pic", profileimageFile);
      } else {
        formData.append("artist_profile_pic", null);
      }
      formData.append(
        "emiratesID",
        removeEmirateIDImage
          ? emirateID[0]
            ? emirateID[0]
            : null
          : artist_detail.emiratesId
      );
      formData.append(
        "emiratesVisaURL",
        removeEmirateVisaImage
          ? emiratesVisa[0]
            ? emiratesVisa[0]
            : null
          : artist_detail.emiratesVisa
      );
      formData.append(
        "tech_rider",
        removeTechnicalRider
          ? technicalRider[0]
            ? technicalRider[0]
            : null
          : artist_detail.technical_rider
      );
      formData.append(
        "hospitality_riderURL",
        removeHospitalityRider
          ? hospitalityRider[0]
            ? hospitalityRider[0]
            : null
          : artist_detail.hospitality_rider
      );
      formData.append(
        "passportPhoto",
        removePassportImageCopy
          ? passportPhoto[0]
            ? passportPhoto[0]
            : null
          : artist_detail.passportImgUrl
      );
      formData.append(
        "passport",
        removePassportImage
          ? passportImage[0]
            ? passportImage[0]
            : null
          : artist_detail.passportUrl
      );
      formData.append("active_status", event.target.status.checked ? 1 : 0);
      // formData.append("local_artist", event.target.localartist.checked ? 1 : 0);
      formData.append(
        "fridge_artist",
        event.target.OnTheFridgeWebsite.checked ? 1 : 0
      );
      // formData.append("new_artist", event.target.newartist.checked ? 1 : 0);
      // formData.append(
      //   "international_artist",
      //   event.target.internationalartist.checked ? 1 : 0
      // );
      formData.append("corporate_set", corporateSets?.value);
      formData.append("corporate_min", event.target.corporate_minutes?.value);
      formData.append("corporate_rate", event.target.corporate_rate?.value);
      formData.append(
        "corporate_desc",
        event.target.corporate_description.value
      );
      formData.append("corporate_currency", corporateCurrency?.cc);
      formData.append("multiple_set", multipleSets?.value);
      formData.append("multiple_min", event.target.multiple_minutes?.value);
      formData.append("multiple_rate", event.target.multiple_rate?.value);
      formData.append(
        "multiple_desc",
        event.target.multiple_description?.value
      );
      formData.append("multiple_currency", multipleCurrency?.cc);
      formData.append("residency_set", residencySets?.value);
      formData.append("residency_min", event.target.residency_minutes?.value);
      formData.append("residency_rate", event.target.residency_rate?.value);
      formData.append(
        "residency_desc",
        event.target.residency_description?.value
      );
      formData.append("residency_currency", residencyCurrency?.cc);
      if (params && params.id) {
        dispatch(UpdateArtist(formData, params.id, history, loader, setLoader));
      } else {
        dispatch(createArtist(formData, history, loader, setLoader));
      }
    }
  };
  const onDrop = useCallback(
    (acceptedFiles) => {
      setTechnicalRider([...technicalRider, ...acceptedFiles]);
    },
    [technicalRider]
  );
  function TechnicalRiderDropZone(props) {
    const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
      // Disable click and keydown behavior
      noClick: true,
      noKeyboard: true,
      onDrop,
      accept: ".doc,.docx,.pdf",
    });

    const remove = (file) => {
      setTechnicalRider([]);
    };
    const files = technicalRider.map((file, i) => (
      <li key={file.path}>
        {file.path} - {file.size} bytes
      </li>
    ));

    return (
      <div>
        {technicalRider.length > 0 ? null : (
          <div
            {...getRootProps({ className: "dropzone" })}
            style={{
              minHeight: "200px",
              border: "1px solid #ebedf2",
              padding: "15px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <input {...getInputProps()} />
            <p>Drag 'n' drop some files here</p>
            <button
              type="button"
              onClick={open}
              className="btn yellow-btn mt-auto"
            >
              Upload
            </button>
          </div>
        )}
        <aside>
          {files.length ? <h5 className="my-2"></h5> : null}
          <ul className="list-ticked">{files}</ul>
          {technicalRider.length > 0 ? (
            <Button
              type="button"
              className="yellow-btn"
              onClick={(i) => remove(i)}
            >
              {" "}
              Remove
            </Button>
          ) : null}
        </aside>
      </div>
    );
  }
  function HospitalityRiderDropZone(props) {
    const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
      // Disable click and keydown behavior
      noClick: true,
      noKeyboard: true,
      accept: ".doc,.docx,.pdf",
      onDrop: useCallback(
        (acceptedFiles) => {
          setHospitalityRider([...hospitalityRider, ...acceptedFiles]);
        },
        [hospitalityRider]
      ),
    });

    const remove = (file) => {
      setHospitalityRider([]);
    };
    const files = hospitalityRider.map((file) => (
      <li key={file.path}>
        {file.path} - {file.size} bytes
      </li>
    ));

    return (
      <div>
        {hospitalityRider.length > 0 ? null : (
          <div
            {...getRootProps({ className: "dropzone" })}
            style={{
              minHeight: "200px",
              border: "1px solid #ebedf2",
              padding: "15px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <input {...getInputProps()} />
            <p>Drag 'n' drop some files here</p>
            <button
              type="button"
              onClick={open}
              className="btn yellow-btn mt-auto"
            >
              Upload
            </button>
          </div>
        )}
        <aside>
          {files.length ? <h5 className="my-2"></h5> : null}
          <ul className="list-ticked">{files}</ul>
          {hospitalityRider.length > 0 ? (
            <Button
              type="button"
              className="yellow-btn"
              onClick={(i) => remove()}
            >
              {" "}
              Remove
            </Button>
          ) : null}
        </aside>
      </div>
    );
  }
  function PassportImageUpload(props) {
    const { getRootProps, getInputProps, open } = useDropzone({
      // Disable click and keydown behavior
      noClick: true,
      noKeyboard: true,
      onDrop: useCallback(
        (acceptedFiles) => {
          setPassportImage([...passportImage, ...acceptedFiles]);
        },
        [passportImage]
      ),
      accept: ".png,.jpg,.pdf",
    });

    const remove = (file) => {
      setPassportImage([]);
    };
    const files = passportImage.map((file) => {
      const filePath = URL.createObjectURL(file)
      // <li key={file.path}>
      //   {file.path} - {file.size} bytes
      // </li>
      return (
        <div key={file.path} style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
          {file.type && !file.type.includes('pdf') ?
            <img
              src={filePath}
              alt="Preview"
              style={{
                width: '50%',  // This ensures the image takes the full width of the container
                height: 'auto', // This maintains the aspect ratio
                maxWidth: '50%'
              }}
            /> : null}
          <div style={{ marginTop: '8px' }}>
            <p>{file.path} - {file.size} bytes</p>
          </div>
        </div>
      )
    });

    return (
      <div>
        {passportImage.length > 0 ? null : (
          <div
            {...getRootProps({ className: "dropzone" })}
            style={{
              minHeight: "200px",
              border: "1px solid #ebedf2",
              padding: "15px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <input {...getInputProps()} />
            <button
              type="button"
              onClick={open}
              className="btn yellow-btn mt-auto"
            >
              Upload
            </button>
          </div>
        )}
        <aside>
          {files.length ? <h5 className="my-2"></h5> : null}
          <ul className="list-ticked">{files}</ul>
          {passportImage.length > 0 ? (
            <Button
              type="button"
              className="yellow-btn"
              onClick={(i) => remove()}
            >
              {" "}
              Remove
            </Button>
          ) : null}
        </aside>
      </div>
    );
  }
  function PassportImageCopyUpload(props) {
    const { getRootProps, getInputProps, open } = useDropzone({
      // Disable click and keydown behavior
      noClick: true,
      noKeyboard: true,
      onDrop: useCallback(
        (acceptedFiles) => {
          setPassportPhoto([...passportPhoto, ...acceptedFiles]);
        },
        [passportPhoto]
      ),
      accept: ".png,.jpg,.pdf",
    });

    const remove = (file) => {
      setPassportPhoto([]);
    };
    const files = passportPhoto.map((file) => {
      // <li key={file.path}>
      //   {file.path} - {file.size} bytes
      // </li>
      const filePath = URL.createObjectURL(file)
      return (
        <div key={file.path} style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
          {file.type && !file.type.includes('pdf') ?
            <img
              src={filePath}
              alt="Preview"
              style={{
                width: '50%',  // This ensures the image takes the full width of the container
                height: 'auto', // This maintains the aspect ratio
                maxWidth: '50%'
              }}
            /> : null}
          <div style={{ marginTop: '8px' }}>
            <p>{file.path} - {file.size} bytes</p>
          </div>
        </div>
      )
    });

    return (
      <div>
        {passportPhoto.length > 0 ? null : (
          <div
            {...getRootProps({ className: "dropzone" })}
            style={{
              minHeight: "200px",
              border: "1px solid #ebedf2",
              padding: "15px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <input {...getInputProps()} />
            <button
              type="button"
              onClick={open}
              className="btn yellow-btn mt-auto"
            >
              Upload
            </button>
          </div>
        )}
        <aside>
          {files.length ? <h5 className="my-2"></h5> : null}
          <ul className="list-ticked">{files}</ul>
          {passportPhoto.length > 0 ? (
            <Button
              type="button"
              className="yellow-btn"
              onClick={(i) => remove()}
            >
              {" "}
              Remove
            </Button>
          ) : null}
        </aside>
      </div>
    );
  }

  function EmirateIDUpload(props) {
    const { getRootProps, getInputProps, open } = useDropzone({
      // Disable click and keydown behavior
      noClick: true,
      noKeyboard: true,
      onDrop: useCallback(
        (acceptedFiles) => {
          setEmirateID([...emirateID, ...acceptedFiles]);
        },
        [emirateID]
      ),
      accept: ".png,.jpg,.pdf",
    });

    const remove = (file) => {
      setEmirateID([]);
    };
    const files = emirateID.map((file) => {
      // <li key={file.path}>
      //   {file.path} - {file.size} bytes
      // </li>
      const filePath = URL.createObjectURL(file)
      return (
        <div key={file.path} style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
          {file.type && !file.type.includes('pdf') ?
            <img
              src={filePath}
              alt="Preview"
              style={{
                width: '50%',  // This ensures the image takes the full width of the container
                height: 'auto', // This maintains the aspect ratio
                maxWidth: '50%'
              }}
            /> : null}
          <div style={{ marginTop: '8px' }}>
            <p>{file.path} - {file.size} bytes</p>
          </div>
        </div>
      )
    });

    return (
      <div>
        {emirateID.length > 0 ? null : (
          <div
            {...getRootProps({ className: "dropzone" })}
            style={{
              minHeight: "200px",
              border: "1px solid #ebedf2",
              padding: "15px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <input {...getInputProps()} />
            <button
              type="button"
              onClick={open}
              className="btn yellow-btn mt-auto"
            >
              Upload
            </button>
          </div>
        )}
        <aside>
          {files.length ? <h5 className="my-2"></h5> : null}
          <ul className="list-ticked">{files}</ul>
          {emirateID.length > 0 ? (
            <Button
              type="button"
              className="yellow-btn"
              onClick={(i) => remove()}
            >
              {" "}
              Remove
            </Button>
          ) : null}
        </aside>
      </div>
    );
  }
  function EmiratesVisaUpload(props) {
    const { getRootProps, getInputProps, open } = useDropzone({
      // Disable click and keydown behavior
      noClick: true,
      noKeyboard: true,
      onDrop: useCallback(
        (acceptedFiles) => {
          setEmiratesVisa([...emiratesVisa, ...acceptedFiles]);
        },
        [emiratesVisa]
      ),
      accept: ".png,.jpg,.pdf",
    });

    const remove = (file) => {
      setEmiratesVisa([]);
    };
    const files = emiratesVisa.map((file) => {
      // <li key={file.path}>
      //   {file.path} - {file.size} bytes
      // </li>
      const filePath = URL.createObjectURL(file)
      return (
        <div key={file.path} style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
          {file.type && !file.type.includes('pdf') ?
            <img
              src={filePath}
              alt="Preview"
              style={{
                width: '50%',  // This ensures the image takes the full width of the container
                height: 'auto', // This maintains the aspect ratio
                maxWidth: '50%'
              }}
            /> : null}
          <div style={{ marginTop: '8px' }}>
            <p>{file.path} - {file.size} bytes</p>
          </div>
        </div>
      )
    });

    return (
      <div>
        {emiratesVisa.length > 0 ? null : (
          <div
            {...getRootProps({ className: "dropzone" })}
            style={{
              minHeight: "200px",
              border: "1px solid #ebedf2",
              padding: "15px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <input {...getInputProps()} />
            <button
              type="button"
              onClick={open}
              className="btn yellow-btn mt-auto"
            >
              Upload
            </button>
          </div>
        )}
        <aside>
          {files.length ? <h5 className="my-2"></h5> : null}
          <ul className="list-ticked">{files}</ul>
          {emiratesVisa.length > 0 ? (
            <Button
              type="button"
              className="yellow-btn"
              onClick={(i) => remove()}
            >
              {" "}
              Remove
            </Button>
          ) : null}
        </aside>
      </div>
    );
  }

  function valuesToUse() {
    const arryused = [];
    if (typeSelected && artist_detail?.tags) {
      artist_detail.tags.map((id) => {
        arryused.push(tags_list[tags_list.findIndex((x) => x.id === id)]);
      });
      if (arryused[0] !== undefined && arryused.length > 0) {
        return arryused;
      }
    } else {
      return null;
    }
  }
  function handleProfileImage(e) {
    let image_as_base64 = URL.createObjectURL(e.target.files[0]);
    let imageFile = e.target.files[0];
    if (!imageFile.name.match(/\.(jpg|jpeg|png|gif)$/)) {
      alert("Please select a valid image.");
    } else {
      setImagePreview(image_as_base64);
      setProfileImageFile(imageFile);
    }
  }

  function valuesToUseGenres() {
    const arryused = [];
    if (typeSelected && artist_detail?.genres) {
      let new_genres = genres?.filter((value) => artist_detail?.genres?.includes(value.id));

      artist_detail.genres.map((id) => {
        arryused.push(new_genres[new_genres?.findIndex((x) => x?.id === id)]);
      });
      if (arryused[0] !== undefined && arryused.length > 0) {
        let arryusedNew = arryused.filter((arr) => arr !== undefined && arr !== null);
        return arryusedNew;
      }
    } else {
      return null;
    }
  }
  function valuesToUseSpeciality() {
    const arryused = [];
    if (typeSelected && artist_detail?.specialities) {
      let new_speciality = specialty?.filter((value) => artist_detail?.specialities?.includes(value.id));

      artist_detail.specialities.map((id) => {
        arryused.push(new_speciality[new_speciality?.findIndex((x) => x?.id === id)]);
      });
      if (arryused[0] !== undefined && arryused.length > 0) {
        let arryusedNew = arryused.filter((arr) => arr !== undefined && arr !== null);
        return arryusedNew;
      }
    } else {
      return null;
    }
  }

  useEffect(() => {
    setTypeSelected(
      artistList[
      artistList.findIndex((x) => x.id == artist_detail.artistTypeId)
      ]
    );
    if (artist_detail?.artistTypeId) {
      dispatch(optionDetail(artist_detail.artistTypeId));
    }
    if (artist_detail.emiratesId && artist_detail.emiratesId !== 'null') {
      setRemoveEmirateIDImage(false);
    }
    if (artist_detail.passportUrl && artist_detail.passportUrl !== 'null') {
      setRemovePassportImage(false);
    }
    if (artist_detail.emiratesVisa && artist_detail.emiratesVisa !== 'null') {
      setRemoveEmirateVisaImage(false);
    }
    if (artist_detail.technical_rider && artist_detail.technical_rider !== 'null') {
      setRemoveTechnicalRider(false);
    }
    if (artist_detail.hospitality_rider && artist_detail.hospitality_rider !== 'null') {
      setRemoveHospitalityRider(false);
    }
    if (artist_detail.multiple_set) {
      setMultipleSets(
        Sets[Sets.findIndex((x) => x.value == artist_detail.multiple_set)]
      );
    }
    if (artist_detail.residency_set) {
      setResidencySets(
        Sets[Sets.findIndex((x) => x.value == artist_detail.residency_set)]
      );
    }
    if (artist_detail.corporate_set) {
      setCorporateSets(
        Sets[Sets.findIndex((x) => x.value == artist_detail.corporate_set)]
      );
    }
    if (artist_detail.phone1) {
      setmobileNumber1(artist_detail.phone1);
    }
    if (artist_detail.phone2) {
      setmobileNumber2(artist_detail.phone2);
    }
    if (artist_detail.corporate_currency) {
      setCorporateCurrency(
        Currency[
        Currency.findIndex((x) => x.cc == artist_detail.corporate_currency)
        ]
      );
    }
    if (artist_detail.multiple_currency) {
      setMultipleCurrency(
        Currency[
        Currency.findIndex((x) => x.cc == artist_detail.multiple_currency)
        ]
      );
    }
    if (artist_detail.residency_currency) {
      setResidencyCurrency(
        Currency[
        Currency.findIndex((x) => x.cc == artist_detail.residency_currency)
        ]
      );
    }
    setCountry(
      countryDeatils[
      countryDeatils.findIndex((x) => x.country == artist_detail.country)
      ]
    );

    setNationality(
      countryDeatils[
      countryDeatils.findIndex((x) => x.country == artist_detail.nationality)
      ]
    );

    if (artist_detail.passportImgUrl && artist_detail.passportImgUrl !== 'null') {
      setRemovePassportImageCopy(false);
    }
    if (artist_detail?.profile_pic) {
      // setImagePreview( artist_detail.profile_pic);
      setImagePreview(artist_detail.profile_pic.includes('https') ? artist_detail.profile_pic : ArtistDemo);
      setProfileImageFile(artist_detail.profile_pic.includes('https') ? artist_detail.profile_pic : ArtistDemo);
    }
  }, [artist_detail]);

  const Sets = [
    { value: "0", label: "0" },
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
    { value: "8", label: "8" },
    { value: "9", label: "9" },
    { value: "10", label: "10" },
  ];
  const styles = {
    menu: (base) => ({
      ...base,
      marginTop: "4px",
      "fontSize": "0.875rem",

      passive: "true",
    }),
    control: (base) => ({
      ...base,
      "fontSize": "0.875rem",
      borderColor: (isDropdownEmpty && submitted) || (isDropdownEmptyNationality && submitted) || (isDropdownEmptyCountry && submitted) ? 'red' : '#D3D3D3'
    }),
  };
  const isDropdownEmpty = !typeSelected;
  const isDropdownEmptyNationality = !nationality;
  const isDropdownEmptyCountry = !country;
  const isMultiselectEmpty = typeSelectedSpeciality?.length == 0 ? true : false;
  const isMultiselectEmptyGenre = typeSelectedGenre?.length == 0 ? true : false;

  return (
    <>
      <div className="row">
        <h4 className="card-title col-9 d-flex align-items-center">
          {!params.id ? "Create Artist" : (params.id && editFlag) ? "Update Artist" : "View Artist"}
        </h4>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/artist-management">Artist Management</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {!params.id ? "Create Artist" : "Update Artist"}
            </li>
          </ol>
        </nav>
      </div>
      <div className="row">
        <div className="col-md-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              {params.id ? (
                <div className="artist-register-image mb-2 mt-1">
                  <img
                    src={imagePreview}
                    alt=""
                    width="100"
                    className="rounded-circle artist-image"
                  />
                  {editFlag ?
                    <>
                      <div
                        className="artist-register-icon"
                        onClick={(e) => CameraIcon.current.click()}
                      >
                        <i className="fa fa-camera"></i>
                      </div>
                      <input
                        className="d-none"
                        ref={CameraIcon}
                        onChange={(e) => handleProfileImage(e)}
                        type="file"
                      /> </> : null}
                </div>) :
                <div className="artist-register-image mb-2 mt-1">
                  <img
                    src={imagePreview}
                    alt=""
                    width="100"
                    className="rounded-circle artist-image"
                  />
                  <div
                    className="artist-register-icon"
                    onClick={(e) => CameraIcon.current.click()}
                  >
                    <i className="fa fa-camera"></i>
                  </div>
                  <input
                    className="d-none"
                    ref={CameraIcon}
                    onChange={(e) => handleProfileImage(e)}
                    type="file"
                  />
                </div>}
              <div className="row">
                <h3 className="font-weight-bold">
                  General Information
                </h3>
              </div>

              <Form
                className="forms-sample"
                noValidate
                validated={validated}
                onSubmit={(event) => handleSubmit(event)}
              >
                <>
                  {params.id ? (
                    <Form.Row>
                      <Form.Group as={Col} md="6" className="row">
                        <Form.Label
                          htmlFor="exampleInputUsername2"
                          className=" col-sm-4  col-form-label"
                        >
                          Company Id
                        </Form.Label>
                        <div className="col-sm-8">
                          <Form.Control
                            type="text"
                            disabled
                            className="form-control"
                            placeholder="Company Code"
                            defaultValue={artist_detail.id}
                          />
                        </div>
                      </Form.Group>
                    </Form.Row>
                  ) : null}
                  <Form.Row>
                    <Form.Group as={Col} md="6" className="row mr-1">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className="col-sm-4  col-form-label"
                      >
                        Stage Name*
                      </Form.Label>
                      <div className="col-sm-8">
                        <Form.Control
                          type="text"
                          className="form-control"
                          required
                          placeholder="Stage Name"
                          name="stagename"
                          defaultValue={artist_detail.stageName}
                          disabled={editFlag ? false : true}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide a Stage Name.
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>
                    <Form.Group as={Col} md="6" className="row">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className="col-sm-4  col-form-label"
                      >
                        Name on Passport*
                      </Form.Label>
                      <div className="col-sm-8">
                        <Form.Control
                          type="text"
                          className="form-control"
                          required
                          placeholder="Name on Passport"
                          name="passportName"
                          defaultValue={artist_detail.passportName}
                          disabled={editFlag ? false : true}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide name on Passport.
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md="6" className="row">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className="col-sm-4   col-form-label"
                      >
                        Artist Type*
                      </Form.Label>
                      <div className="col-sm-8">
                        <Select
                          required
                          options={artistList}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          value={typeSelected}
                          maxMenuHeight={100}
                          placeholder="Select Artist Type"
                          styles={styles}
                          onChange={(e) => {
                            setTypeSelected(e);
                            specialtyRef.current.resetSelectedValues();
                            genreRef.current.resetSelectedValues();
                            dispatch(optionDetail(e.id));
                          }}
                          isDisabled={editFlag ? false : true}
                        />

                        <span
                          style={{
                            width: "100%",
                            fontSize: "0.875em",
                            color: "#dc3545",
                          }}
                        >
                          {typeError ? "Please select" : null}
                        </span>
                      </div>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md="6" className="row mr-1">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className=" col-sm-4  col-form-label"
                      >
                        Speciality
                      </Form.Label>
                      <div className="col-sm-8">
                        <Multiselect
                          options={specialty}
                          customCloseIcon={
                            <i class="typcn typcn-delete ml-1"></i>
                          }
                          selectedValues={valuesToUseSpeciality()}
                          displayValue={"name" || null} // Property name to display in the dropdown options
                          showArrow
                          style={{
                            searchBox: { // To change search box element look
                              borderColor: submitted && isMultiselectEmpty ? 'red' : null
                            },
                            chips: {
                              background: "#F6F4ED",
                              color: "black",
                            },
                            "fontSize": "0.875rem",
                          }}
                          ref={specialtyRef}
                          onSelect={(s) => {
                            let arrSpeciality = [];
                            s.map((t, i) => {
                              arrSpeciality.push(t.id)
                            })
                            setTypeSelectedSpeciality(arrSpeciality)
                          }}
                          placeholder={
                            typeSelected
                              ? "Select Speciality"
                              : "Select Artist type first"
                          }
                          disable={editFlag ? false : true}
                        />
                      </div>
                    </Form.Group>
                    <Form.Group as={Col} md="6" className="row">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className="col-sm-4  col-form-label"
                      >
                        Genre
                      </Form.Label>
                      <div className="col-sm-8">
                        <Multiselect
                          options={genres} // Options to display in the dropdown
                          selectedValues={valuesToUseGenres()}
                          customCloseIcon={
                            <i class="typcn typcn-delete ml-1"></i>
                          }
                          displayValue={"name" || null} // Property name to display in the dropdown options
                          showArrow
                          ref={genreRef}
                          style={{
                            searchBox: { // To change search box element look
                              borderColor: submitted && isMultiselectEmptyGenre ? 'red' : null
                            },
                            chips: {
                              background: "#F6F4ED",
                              color: "black",
                            },
                            "fontSize": "0.875rem",
                          }}
                          onSelect={(s) => {
                            let arrGenre = [];
                            s.map((t, i) => {
                              arrGenre.push(t.id)
                            })
                            setTypeSelectedGenre(arrGenre)
                          }}
                          placeholder={
                            typeSelected
                              ? "Select Genres"
                              : "Select Artist type first"
                          }
                          disable={editFlag ? false : true}
                        />
                      </div>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md="6" className="row mr-1">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className=" col-sm-4  col-form-label"
                      >
                        Nationality*
                      </Form.Label>
                      <div className="col-sm-8">
                        <Select
                          styles={styles}
                          maxMenuHeight={100}
                          options={countryDeatils}
                          value={nationality}
                          placeholder="Select Nationality"
                          getOptionLabel={(option) => option.country}
                          getOptionValue={(option) => option.country}
                          onChange={(e) => {
                            setNationality(e);
                            setNationalityError(false);
                          }}
                          required
                          isDisabled={editFlag ? false : true}
                        />
                        <span
                          style={{
                            width: "100%",
                            fontSize: "0.875em",
                            color: "#dc3545",
                          }}
                        >
                          {nationalityError ? "Please select" : null}
                        </span>
                      </div>
                    </Form.Group>
                    <Form.Group as={Col} md="6" className="row">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className="col-sm-4  col-form-label"
                      >
                        Country of residence*
                      </Form.Label>
                      <div className="col-sm-8">
                        <Select
                          styles={styles}
                          maxMenuHeight={100}
                          options={countryDeatils}
                          placeholder="Select Country of residence"
                          value={country}
                          getOptionLabel={(option) => option.country}
                          getOptionValue={(option) => option.country}
                          onChange={(e) => {
                            setCountry(e);
                            setCountryError(false);
                          }}
                          required
                          isDisabled={editFlag ? false : true}
                        />
                        <span
                          style={{
                            width: "100%",
                            fontSize: "0.875em",
                            color: "#dc3545",
                          }}
                        >
                          {countryError ? "Please select" : null}
                        </span>
                      </div>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md="6" className="row mr-1">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className=" col-sm-4  col-form-label"
                      >
                        City*
                      </Form.Label>
                      <div className="col-sm-8">
                        <Form.Control
                          type="text"
                          className="form-control"
                          required
                          placeholder="City"
                          name="city"
                          defaultValue={artist_detail.city}
                          disabled={editFlag ? false : true}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide City.
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>
                    <Form.Group as={Col} md="6" className="row mr-1">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className="col-sm-4  col-form-label"
                      >
                        Website
                      </Form.Label>
                      <div className="col-sm-8">
                        <Form.Control
                          type="text"
                          className="form-control"
                          placeholder="Website"
                          name="website"
                          defaultValue={artist_detail.website}
                          disabled={editFlag ? false : true}
                        />
                      </div>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md="12" className="row">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className=" col-sm-2  col-form-label"
                      >
                        Biography*
                      </Form.Label>
                      <div className="col-sm-8">
                        <Form.Control
                          as="textarea"
                          className="form-control"
                          rows="4"
                          required
                          id="exampleTextarea1"
                          placeholder="Biography"
                          name="biography"
                          defaultValue={artist_detail.bio}
                          disabled={editFlag ? false : true}
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please provide Biography.
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md="12" className="row">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className=" col-sm-2  col-form-label"
                      >
                        Why Book
                      </Form.Label>
                      <div className="col-sm-8">
                        <Form.Control
                          as="textarea"
                          className="form-control"
                          rows="4"
                          // required
                          name="whybook"
                          placeholder="Give us 3 reasons why a client should book you"
                          defaultValue={artist_detail.why_book}
                          disabled={editFlag ? false : true}
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please provide a reason.
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>
                  </Form.Row>
                </>
                <div className="row">
                  <h3 className="font-weight-bold">
                    Social Media Links
                  </h3>
                </div>
                <>
                  <Form.Row>
                    <Form.Group as={Col} md="6" className="row mr-1">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className=" col-sm-4  col-form-label"
                      >
                        Facebook
                      </Form.Label>
                      <div className="col-sm-8">
                        <Form.Control
                          type="text"
                          className="form-control"
                          placeholder="Facebook"
                          name="facebook"
                          defaultValue={artist_detail.fbURL}
                          disabled={editFlag ? false : true}
                        />
                      </div>
                    </Form.Group>
                    <Form.Group as={Col} md="6" className="row ">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className="col-sm-4  col-form-label"
                      >
                        YouTube
                      </Form.Label>
                      <div className="col-sm-8">
                        <Form.Control
                          type="text"
                          className="form-control"
                          placeholder="YouTube"
                          name="youtube"
                          defaultValue={artist_detail.ytURL}
                          disabled={editFlag ? false : true}
                        />
                      </div>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md="6" className="row mr-1">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className=" col-sm-4  col-form-label"
                      >
                        Instagram*
                      </Form.Label>
                      <div className="col-sm-8">
                        <Form.Control
                          type="text"
                          className="form-control"
                          placeholder="Instagram"
                          name="instagram"
                          required
                          defaultValue={artist_detail.instaURL}
                          disabled={editFlag ? false : true}
                        />
                      </div>
                    </Form.Group>
                    <Form.Group as={Col} md="6" className="row ">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className="col-sm-4  col-form-label"
                      >
                        Sound Cloud
                      </Form.Label>
                      <div className="col-sm-8">
                        <Form.Control
                          type="text"
                          className="form-control"
                          placeholder="Sound Cloud"
                          name="soundcloud"
                          defaultValue={artist_detail.soundcloudURL}
                          disabled={editFlag ? false : true}
                        />
                      </div>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md="6" className="row ">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className=" col-sm-4  col-form-label"
                      >
                        Twitter
                      </Form.Label>
                      <div className="col-sm-8">
                        <Form.Control
                          type="text"
                          className="form-control"
                          placeholder="Twitter"
                          name="twitter"
                          defaultValue={artist_detail.twitterURL}
                          disabled={editFlag ? false : true}
                        />
                      </div>
                    </Form.Group>
                  </Form.Row>
                </>
                <div className="row">
                  <h3 className="font-weight-bold">
                    Contact Details
                  </h3>
                </div>
                <>
                  <Form.Row>
                    <Form.Group as={Col} md="6" className="row mr-1">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className=" col-sm-4  col-form-label"
                      >
                        First Name*
                      </Form.Label>
                      <div className="col-sm-8">
                        <Form.Control
                          type="text"
                          className="form-control"
                          required
                          placeholder="First Name"
                          name="firstName"
                          defaultValue={artist_detail.firstName}
                          disabled={editFlag ? false : true}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide a first Name.
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>
                    <Form.Group as={Col} md="6" className="row">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className="col-sm-4  col-form-label"
                      >
                        Last Name*
                      </Form.Label>
                      <div className="col-sm-8">
                        <Form.Control
                          type="text"
                          className="form-control"
                          required
                          placeholder="Last Name"
                          name="lastname"
                          defaultValue={artist_detail.lastName}
                          disabled={editFlag ? false : true}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide a last name.
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md="6" className="row mr-1">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className=" col-sm-4  col-form-label"
                      >
                        Email*
                      </Form.Label>
                      <div className="col-sm-8">
                        <Form.Control
                          type="email"
                          className="form-control"
                          placeholder="Email"
                          name="email"
                          required
                          defaultValue={artist_detail.email}
                          disabled={editFlag ? false : true}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide Email.
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>
                    <Form.Group as={Col} md="6" className="row">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className="col-sm-4  col-form-label"
                      >
                        Mobile Number*
                      </Form.Label>
                      <div className="col-sm-8">
                        <PhoneInput
                          id="phoneId"
                          inputProps={{
                            name: "phone",
                            required: true,
                          }}
                          country={"ae"}
                          minLength={12}
                          className={`custom-input-fields ${checkMobileNumber ? 'red-border' : ''}`}
                          value={mobileNumber1}
                          onChange={(phone) => { setmobileNumber1(phone); setCheckMobileNumber(false); }}
                          disabled={editFlag ? false : true}
                        />
                        <Form.Control.Feedback type="invalid" id="#phoneId">
                          Please provide a mobile number.
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md="6" className="row mr-1">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className=" col-sm-4  col-form-label"
                      >
                        Phone / WhatsApp
                      </Form.Label>
                      <div className="col-sm-8">
                        <PhoneInput
                          country={"ae"}
                          className="custom-input-fields"
                          value={mobileNumber2}
                          onChange={(phone) => setmobileNumber2(phone)}
                          disabled={editFlag ? false : true}
                        />
                      </div>
                    </Form.Group>
                  </Form.Row>
                </>
                <div className="row">
                  <h3 className="font-weight-bold">
                    Rates
                  </h3>
                </div>
                <>
                  <div className="mb-3">Corporate Rate Type</div>
                  <Form.Row>
                    <Form.Group as={Col} md="6" className="row mr-2">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className=" col-sm-4  col-form-label"
                      >
                        Set(s)
                      </Form.Label>
                      <div className="col-sm-8">
                        <Select
                          styles={styles}
                          maxMenuHeight={100}
                          options={Sets}
                          placeholder="Select Set"
                          value={corporateSets}
                          onChange={(e) => {
                            setCorporateSets(e);
                          }}
                          isDisabled={editFlag ? false : true}
                        />
                      </div>
                    </Form.Group>
                    <Form.Group as={Col} md="6" className="row mr-2">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className="col-sm-4  col-form-label"
                      >
                        Minutes
                      </Form.Label>
                      <div className="col-sm-8">
                        <Form.Control
                          type="number"
                          className="form-control"
                          placeholder="Between 1-60"
                          name="corporate_minutes"
                          defaultValue={artist_detail.corporate_min}
                          disabled={editFlag ? false : true}
                        />
                      </div>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md="6" className="row mr-2">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className="col-sm-4  col-form-label"
                      >
                        Rate
                      </Form.Label>
                      <div className="col-sm-8">
                        <Form.Control
                          type="number"
                          className="form-control"
                          placeholder="Insert Amount"
                          name="corporate_rate"
                          defaultValue={artist_detail.corporate_rate}
                          disabled={editFlag ? false : true}
                        />
                      </div>
                    </Form.Group>
                    <Form.Group as={Col} md="6" className="row  mr-2">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className=" col-sm-4  col-form-label"
                      >
                        Description
                      </Form.Label>
                      <div className="col-sm-8">
                        <Form.Control
                          type="text"
                          className="form-control"
                          placeholder="Description"
                          name="corporate_description"
                          defaultValue={artist_detail.corporate_desc}
                          disabled={editFlag ? false : true}
                        />
                      </div>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md="6" className="row">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className="col-sm-4  col-form-label"
                      >
                        Currency
                      </Form.Label>
                      <div className="col-sm-8">
                        <Select
                          options={Currency}
                          getOptionLabel={(option) => option.cc}
                          getOptionValue={(option) => option.cc}
                          placeholder="Select Currency"
                          styles={styles}
                          maxMenuHeight={100}
                          value={corporateCurrency}
                          onChange={(e) => {
                            setCorporateCurrency(e);
                          }}
                          isDisabled={editFlag ? false : true}
                        />
                      </div>
                    </Form.Group>
                  </Form.Row>
                  <div className="mb-3">Residency Rate Type</div>
                  <Form.Row>
                    <Form.Group as={Col} md="6" className="row mr-2">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className=" col-sm-4  col-form-label"
                      >
                        Set(s)
                      </Form.Label>
                      <div className="col-sm-8">
                        <Select
                          styles={styles}
                          maxMenuHeight={100}
                          options={Sets}
                          value={residencySets}
                          placeholder="Select Set"
                          onChange={(e) => {
                            setResidencySets(e);
                          }}
                          isDisabled={editFlag ? false : true}
                        />
                      </div>
                    </Form.Group>
                    <Form.Group as={Col} md="6" className="row">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className="col-sm-4  col-form-label"
                      >
                        Minutes
                      </Form.Label>
                      <div className="col-sm-8">
                        <Form.Control
                          type="number"
                          className="form-control"
                          placeholder="Between 1-60"
                          name="residency_minutes"
                          defaultValue={artist_detail.residency_min}
                          disabled={editFlag ? false : true}
                        />
                      </div>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md="6" className="row mr-2">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className="col-sm-4  col-form-label"
                      >
                        Rate
                      </Form.Label>
                      <div className="col-sm-8">
                        <Form.Control
                          type="number"
                          className="form-control"
                          placeholder="Insert Amount"
                          name="residency_rate"
                          defaultValue={artist_detail.residency_rate}
                          disabled={editFlag ? false : true}
                        />
                      </div>
                    </Form.Group>{" "}
                    <Form.Group as={Col} md="6" className="row">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className=" col-sm-4  col-form-label"
                      >
                        Description
                      </Form.Label>
                      <div className="col-sm-8">
                        <Form.Control
                          type="text"
                          className="form-control"
                          placeholder="Description"
                          name="residency_description"
                          defaultValue={artist_detail.residency_desc}
                          disabled={editFlag ? false : true}
                        />
                      </div>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md="6" className="row">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className="col-sm-4  col-form-label"
                      >
                        Currency
                      </Form.Label>
                      <div className="col-sm-8">
                        <Select
                          styles={styles}
                          maxMenuHeight={100}
                          placeholder="Select Currency"
                          options={Currency}
                          getOptionLabel={(option) => option.cc}
                          getOptionValue={(option) => option.cc}
                          value={residencyCurrency}
                          onChange={(e) => {
                            setResidencyCurrency(e);
                          }}
                          isDisabled={editFlag ? false : true}
                        />
                      </div>
                    </Form.Group>
                  </Form.Row>
                  <div className="mb-3">Multiple Rate Type</div>
                  <Form.Row>
                    <Form.Group as={Col} md="6" className="row mr-2">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className=" col-sm-4  col-form-label"
                      >
                        Set(s)
                      </Form.Label>
                      <div className="col-sm-8">
                        <Select
                          options={Sets}
                          styles={styles}
                          maxMenuHeight={100}
                          value={multipleSets}
                          placeholder="Select Sets"
                          onChange={(e) => {
                            setMultipleSets(e);
                          }}
                          isDisabled={editFlag ? false : true}
                        />
                      </div>
                    </Form.Group>
                    <Form.Group as={Col} md="6" className="row">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className="col-sm-4  col-form-label"
                      >
                        Minutes
                      </Form.Label>
                      <div className="col-sm-8">
                        <Form.Control
                          type="number"
                          className="form-control"
                          placeholder="Between 1-60"
                          name="multiple_minutes"
                          defaultValue={artist_detail.multiple_min}
                          disabled={editFlag ? false : true}
                        />
                      </div>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md="6" className="row mr-2">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className="col-sm-4  col-form-label"
                      >
                        Rate
                      </Form.Label>
                      <div className="col-sm-8">
                        <Form.Control
                          type="number"
                          className="form-control"
                          placeholder="Insert Amount"
                          name="multiple_rate"
                          defaultValue={artist_detail.multiple_rate}
                          disabled={editFlag ? false : true}
                        />
                      </div>
                    </Form.Group>
                    <Form.Group as={Col} md="6" className="row mr-2">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className=" col-sm-4  col-form-label"
                      >
                        Description
                      </Form.Label>
                      <div className="col-sm-8">
                        <Form.Control
                          type="text"
                          className="form-control"
                          placeholder="Description"
                          name="multiple_description"
                          defaultValue={artist_detail.multiple_desc}
                          disabled={editFlag ? false : true}
                        />
                      </div>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md="6" className="row">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className="col-sm-4  col-form-label"
                      >
                        Currency
                      </Form.Label>
                      <div className="col-sm-8">
                        <Select
                          styles={styles}
                          maxMenuHeight={100}
                          options={Currency}
                          placeholder="Select Currency"
                          getOptionLabel={(option) => option.cc}
                          getOptionValue={(option) => option.cc}
                          value={multipleCurrency}
                          onChange={(e) => {
                            setMultipleCurrency(e);
                          }}
                          isDisabled={editFlag ? false : true}
                        />
                      </div>
                    </Form.Group>
                  </Form.Row>
                </>
                <div className="row">
                  <h3 className="font-weight-bold">
                    Admin Only
                  </h3>
                </div>
                <>
                  <Form.Row>
                    <Form.Group as={Col} md="6" className="row mr-1">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className=" col-sm-4  col-form-label"
                      >
                        Tags
                      </Form.Label>
                      <div className="col-sm-8">
                        <Multiselect
                          required
                          options={tags_list} // Options to display in the dropdown
                          selectedValues={valuesToUse()} // Preselected value to persist in dropdown
                          displayValue={"name" || null} // Property name to display in the dropdown options
                          ref={tagRef}
                          customCloseIcon={
                            <i class="typcn typcn-delete ml-1"></i>
                          }
                          placeholder="Select Tags"
                          style={{
                            chips: {
                              background: "#F6F4ED",
                              color: "black",
                            },
                            options: {
                              "fontSize": "0.875rem",
                            },
                            "fontSize": "0.875rem",
                          }}
                          showArrow
                          disable={editFlag ? false : true}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide name on Passport.
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>
                  </Form.Row>
                  {/* <Form.Row className="justify-content-center">

                    <Form.Group as={Col} md="4" className="row  mr-2">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className="col-form-label"
                      >
                        Local Artist
                      </Form.Label>
                      <Form.Check
                        className="ml-1"
                        name="localartist"
                        defaultChecked={artist_detail.local_artist}
                        onClick={() => { }}
                        disabled={editFlag ? false : true}
                      />
                    </Form.Group>
                    <Form.Group as={Col} md="4" className="row  mr-2">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className="col-form-label"
                      >
                        New Artist
                      </Form.Label>
                      <Form.Check
                        name="newartist"
                        className="ml-1"
                        defaultChecked={artist_detail.new_artist}
                        onClick={() => { }}
                        disabled={editFlag ? false : true}
                      />
                    </Form.Group>
                    <Form.Group as={Col} md="4" className="row">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className="col-form-label"
                      >
                        International Artist
                      </Form.Label>
                      <Form.Check
                        className="ml-1"
                        name="internationalartist"
                        defaultChecked={artist_detail.international_artist}
                        onClick={() => { }}
                        disabled={editFlag ? false : true}
                      />
                    </Form.Group>
                  </Form.Row> */}
                </>
                <div className="row">
                  <h3 className="font-weight-bold">
                    Identify Documents
                  </h3>
                </div>
                <>
                  <Form.Row>
                    <Form.Group as={Col} md="6" className="row">
                      {openCopyCrop ? (
                        <CropEasy
                          photoURL={passportImage[0].image_profile}
                          setOpenCrop={setOpenCopyCrop}
                          setPhotoURL={setPassportImage}
                        />
                      ) : (
                        <>
                          <Form.Label
                            htmlFor="exampleInputUsername2"
                            className=" col-sm-4  col-form-label"
                          >
                            Passport Copy
                          </Form.Label>
                          <div className="col-sm-8">
                            {editFlag ?
                              <div className="upload__image-wrapper col-sm-12">
                                {artist_detail.passportUrl &&
                                  artist_detail.passportUrl !== "undefined" &&
                                  !removePassportImage && artist_detail.passportUrl !== "null" ? (
                                  <aside>
                                    <ul className="list-ticked">
                                      <li>
                                        <a
                                          href={

                                            artist_detail.passportUrl
                                          }
                                          target="_blank"
                                        >
                                          {/* {(artist_detail.passportUrl).split("?")[0]} */}
                                          {/\.(jpg|png)$/i.test((artist_detail.passportUrl).split("?")[0]) ? (
                                            <img
                                              src={(artist_detail.passportUrl)}
                                              style={{ width: "200px", height: "200px" }}
                                            />) : (artist_detail.passportUrl).split("?")[0]
                                          }
                                        </a>
                                      </li>
                                    </ul>
                                    <Button
                                      type="button"
                                      className="yellow-btn"
                                      onClick={(i) =>
                                        setRemovePassportImage(true)
                                      }
                                    >
                                      Remove
                                    </Button>
                                  </aside>
                                ) : (
                                  <PassportImageUpload />
                                )}
                              </div>
                              :
                              <div className="col-sm-8">
                                <div style={{ marginTop: '12px' }}>
                                  {artist_detail.passportUrl && artist_detail.passportUrl !== "null" ?
                                    <a
                                      href={
                                        artist_detail.passportUrl
                                      }
                                      target="_blank"
                                    >
                                      {/\.(jpg|png)$/i.test((artist_detail.passportUrl).split("?")[0]) ? (
                                        <img
                                          src={(artist_detail.passportUrl)}
                                          style={{ width: "200px", height: "200px" }}
                                        />) : (artist_detail.passportUrl).split("?")[0]
                                      }
                                    </a> : 'No file found'
                                  }
                                </div>
                              </div>
                            }
                          </div>
                        </>
                      )}
                    </Form.Group>
                    <Form.Group as={Col} md="6" className="row">
                      {openPhotoCrop ? (
                        <CropEasy
                          photoURL={passportPhoto[0].image_profile}
                          setOpenCrop={setOpenPhotoCrop}
                          setPhotoURL={setPassportPhoto}
                        />
                      ) : (
                        <>
                          <Form.Label
                            htmlFor="exampleInputUsername2"
                            className="col-sm-4  col-form-label"
                          >
                            Passport Photo
                          </Form.Label>
                          <div className="col-sm-8">
                            {editFlag ?
                              <div className="upload__image-wrapper col-sm-12">
                                {artist_detail.passportImgUrl &&
                                  artist_detail.passportImgUrl !== "undefined" &&
                                  !removePassportImageCopy && artist_detail.passportImgUrl !== "null" ? (
                                  <aside>
                                    <ul className="list-ticked">
                                      <li>
                                        <a
                                          href={

                                            artist_detail.passportImgUrl
                                          }
                                          target="_blank"
                                        >
                                          {/* {(artist_detail.passportImgUrl).split("?")[0]} */}
                                          {/\.(jpg|png)$/i.test((artist_detail.passportImgUrl).split("?")[0]) ? (
                                            <img
                                              src={(artist_detail.passportImgUrl)}
                                              style={{ width: "200px", height: "200px" }}
                                            />) : (artist_detail.passportImgUrl).split("?")[0]
                                          }
                                        </a>
                                      </li>
                                    </ul>
                                    <Button
                                      type="button"
                                      className="yellow-btn"
                                      onClick={(i) =>
                                        setRemovePassportImageCopy(true)
                                      }
                                    >
                                      Remove
                                    </Button>
                                  </aside>
                                ) : (
                                  <PassportImageCopyUpload />
                                )}
                              </div> :
                              <div className="col-sm-8">
                                <div style={{ marginTop: '12px' }}>
                                  {artist_detail.passportImgUrl && artist_detail.passportImgUrl !== "null" ?
                                    <a
                                      href={

                                        artist_detail.passportImgUrl
                                      }
                                      target="_blank"
                                    >
                                      {/\.(jpg|png)$/i.test((artist_detail.passportImgUrl).split("?")[0]) ? (
                                        <img
                                          src={(artist_detail.passportImgUrl)}
                                          style={{ width: "200px", height: "200px" }}
                                        />) : (artist_detail.passportImgUrl).split("?")[0]
                                      }
                                      {/* {(artist_detail.passportImgUrl).split("?")[0]} */}
                                    </a> : 'No file found'
                                  }
                                </div>
                              </div>
                            }
                          </div>
                        </>
                      )}
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md="6" className="row">
                      {openIdCrop ? (
                        <CropEasy
                          photoURL={emirateID[0].image_profile}
                          setOpenCrop={setOpenIdCrop}
                          setPhotoURL={setEmirateID}
                        />
                      ) : (
                        <>
                          <Form.Label
                            htmlFor="exampleInputUsername2"
                            className=" col-sm-4  col-form-label"
                          >
                            Emirate ID
                          </Form.Label>
                          <div className="col-sm-8">
                            {editFlag ?
                              <div className="upload__image-wrapper col-sm-12">
                                {artist_detail.emiratesId &&
                                  artist_detail.emiratesId !== "undefined" &&
                                  !removeEmirateIDImage && artist_detail.emiratesId !== "null" ? (
                                  <aside>
                                    <ul className="list-ticked">
                                      <li>
                                        <a
                                          href={

                                            artist_detail.emiratesId
                                          }
                                          target="_blank"
                                        >
                                          {/* {(artist_detail.emiratesId).split("?")[0]} */}
                                          {/\.(jpg|png)$/i.test((artist_detail.emiratesId).split("?")[0]) ? (
                                            <img
                                              src={(artist_detail.emiratesId)}
                                              style={{ width: "200px", height: "200px" }}
                                            />) : (artist_detail.emiratesId).split("?")[0]
                                          }
                                        </a>
                                      </li>
                                    </ul>
                                    <Button
                                      type="button"
                                      className="yellow-btn"
                                      onClick={(i) =>
                                        setRemoveEmirateIDImage(true)
                                      }
                                    >
                                      Remove
                                    </Button>
                                  </aside>
                                ) : (
                                  <EmirateIDUpload />
                                )}
                              </div> :
                              <div className="col-sm-8">
                                <div style={{ marginTop: '12px' }}>
                                  {artist_detail.emiratesId && artist_detail.emiratesId !== "null" ?
                                    <a
                                      href={

                                        artist_detail.emiratesId
                                      }
                                      target="_blank"
                                    >
                                      {/* {(artist_detail.emiratesId).split("?")[0]} */}
                                      {/\.(jpg|png)$/i.test((artist_detail.emiratesId).split("?")[0]) ? (
                                        <img
                                          src={(artist_detail.emiratesId)}
                                          style={{ width: "200px", height: "200px" }}
                                        />) : (artist_detail.emiratesId).split("?")[0]
                                      }
                                    </a> : 'No file found'
                                  }
                                </div>
                              </div>
                            }
                          </div>
                        </>
                      )}
                    </Form.Group>

                    <Form.Group as={Col} md="6" className="row">
                      {openCrop ? (
                        (console.log("works"),
                          (
                            <CropEasy
                              photoURL={emiratesVisa[0].image_profile}
                              setOpenCrop={setOpenCrop}
                              setPhotoURL={setEmiratesVisa}
                            />
                          ))
                      ) : (
                        <>
                          <Form.Label
                            htmlFor="exampleInputUsername2"
                            className="col-sm-4  col-form-label"
                          >
                            Emirates Visa
                          </Form.Label>
                          <div className="col-sm-8">
                            {editFlag ?
                              <div className="upload__image-wrapper col-sm-12">
                                {artist_detail.emiratesVisa &&
                                  artist_detail.emiratesVisa !== "undefined" &&
                                  !removeEmirateVisaImage && artist_detail.emiratesVisa !== "null" ? (
                                  <aside>
                                    <ul className="list-ticked">
                                      <li>
                                        <a
                                          href={

                                            artist_detail.emiratesVisa
                                          }
                                          target="_blank"
                                        >
                                          {/* {(artist_detail.emiratesVisa).split("?")[0]} */}
                                          {/\.(jpg|png)$/i.test((artist_detail.emiratesVisa).split("?")[0]) ? (
                                            <img
                                              src={(artist_detail.emiratesVisa)}
                                              style={{ width: "200px", height: "200px" }}
                                            />) : (artist_detail.emiratesVisa).split("?")[0]
                                          }
                                        </a>
                                      </li>
                                    </ul>
                                    <Button
                                      type="button"
                                      className="yellow-btn"
                                      onClick={(i) =>
                                        setRemoveEmirateVisaImage(true)
                                      }
                                    >
                                      Remove
                                    </Button>
                                  </aside>
                                ) : (
                                  <EmiratesVisaUpload />
                                )}
                              </div> :
                              <div className="col-sm-8">
                                <div style={{ marginTop: '12px' }}>
                                  {artist_detail.emiratesVisa && artist_detail.emiratesVisa !== "null" ?
                                    <a
                                      href={

                                        artist_detail.emiratesVisa
                                      }
                                      target="_blank"
                                    >
                                      {/* {(artist_detail.emiratesVisa).split("?")[0]} */}
                                      {/\.(jpg|png)$/i.test((artist_detail.emiratesVisa).split("?")[0]) ? (
                                        <img
                                          src={(artist_detail.emiratesVisa)}
                                          style={{ width: "200px", height: "200px" }}
                                        />) : (artist_detail.emiratesVisa).split("?")[0]
                                      }
                                    </a> : 'No file found'
                                  }
                                </div>
                              </div>
                            }
                          </div>
                        </>
                      )}
                    </Form.Group>
                  </Form.Row>
                  <Form.Row></Form.Row>
                </>
                <div className="row">
                  <h3 className="font-weight-bold">
                    Technical & Hospitality Riders
                  </h3>
                </div>
                <>
                  <Form.Row>
                    <Form.Group as={Col} md="6" className="row">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className=" col-sm-4  col-form-label"
                      >
                        Hospitality Rider
                      </Form.Label>
                      {editFlag ?
                        <div className="col-sm-8">
                          {removeHospitalityRider ? (
                            <HospitalityRiderDropZone />
                          ) : (
                            <aside>
                              <ul className="list-ticked">
                                <a
                                  href={

                                    artist_detail.hospitality_rider
                                  }
                                  target="_blank"
                                >
                                  {(artist_detail.hospitality_rider).split("?")[0]}
                                </a>
                              </ul>
                              <Button
                                type="button"
                                className="yellow-btn"
                                onClick={(i) => setRemoveHospitalityRider(true)}
                              >
                                Remove
                              </Button>
                            </aside>
                          )}
                        </div>
                        :
                        <div className="col-sm-8">
                          <div style={{ marginTop: '12px' }}>
                            {artist_detail.hospitality_rider && artist_detail.hospitality_rider !== "null" ?
                              <a
                                href={

                                  artist_detail.hospitality_rider
                                }
                                target="_blank"
                                style={{ marginTop: 10 }}
                              >
                                {(artist_detail.hospitality_rider).split("?")[0]}
                              </a> : 'No file found'
                            }
                          </div>
                        </div>
                      }
                    </Form.Group>
                    <Form.Group as={Col} md="6" className="row">
                      <Form.Label
                        htmlFor="exampleInputUsername2"
                        className="col-sm-4  col-form-label"
                      >
                        Technical Rider
                      </Form.Label>
                      {editFlag ?
                        <div className="col-sm-8">
                          {removeTechnicalRider ? (
                            <TechnicalRiderDropZone />
                          ) : (
                            <aside>
                              <ul className="list-ticked">
                                <a
                                  href={

                                    artist_detail.technical_rider
                                  }
                                  target="_blank"
                                >
                                  {(artist_detail.technical_rider).split("?")[0]}
                                </a>
                              </ul>
                              <Button
                                type="button"
                                className="yellow-btn"
                                onClick={(i) => setRemoveTechnicalRider(true)}
                              >
                                Remove
                              </Button>
                            </aside>
                          )}
                        </div> :
                        <div className="col-sm-8">
                          <div style={{ marginTop: '12px' }}>
                            {artist_detail.technical_rider && artist_detail.technical_rider !== "null" ?
                              <a
                                href={

                                  artist_detail.technical_rider
                                }
                                target="_blank"
                              >
                                {(artist_detail.technical_rider).split("?")[0]}
                              </a> : 'No file found'
                            }
                          </div>
                        </div>
                      }
                    </Form.Group>
                  </Form.Row>

                </>
                {editFlag == false ?
                  <>
                    <div className="row">
                      <h4 className="card-title col-9 d-flex align-items-center">
                        Image Posts
                      </h4>
                    </div>
                    {postImageArray.length > 0 ?
                      <>
                        <Form.Row>
                          <Form.Group as={Col} md="12" className="row">
                            <>
                              {postImageArray.map((i, index) => (
                                <>
                                  <div
                                    key={index}
                                    className="card"
                                    style={{
                                      padding: "10px",
                                      // marginTop: "10px",
                                      // marginBottom: "10px",
                                      marginRight: '15px'
                                    }}
                                  >
                                    <>
                                      {/* <div className="image-item col-sm-7"> */}
                                      <a
                                        href={
                                          i.media_link?.includes('https')
                                            ?
                                            i.media_link : process.env.REACT_APP_IMAGE_LINK + i.media_link
                                        }
                                        target="_blank"
                                      >
                                        <img
                                          src={
                                            i.media_link?.includes('https')
                                              ?
                                              i.media_link : process.env.REACT_APP_IMAGE_LINK + i.media_link
                                          }
                                          alt=""
                                          width="100"
                                          className="img-lg mb-3"
                                        />
                                      </a>
                                      {/* </div> */}
                                    </>
                                  </div>
                                </>
                              ))}
                            </>
                          </Form.Group>
                        </Form.Row>
                      </> : 'No Image Post'
                    }
                  </> : null
                }

                {editFlag == false ?
                  <>
                    <div className="row">
                      <h4 className="card-title col-9 d-flex align-items-center">
                        Video Posts
                      </h4>
                    </div>
                    {postVideoArray.length > 0 ?
                      <>
                        <Form.Row>
                          <Form.Group as={Col} md="12" className="row">
                            <>
                              {postVideoArray.map((i, index) => (
                                <>
                                  <div
                                    key={index}
                                    className="card"
                                    style={{
                                      padding: "10px",
                                      // marginTop: "10px",
                                      // marginBottom: "10px",
                                      marginRight: '15px'
                                    }}
                                  >
                                    <>
                                      <a
                                        href={
                                          i.media_link?.includes('https') ? i.media_link : process.env.REACT_APP_IMAGE_LINK + i.media_link
                                        }
                                        target="_blank"
                                      >
                                        {i.media_link?.includes('https') ?
                                          i.media_link
                                          : process.env.REACT_APP_IMAGE_LINK + i.media_link
                                        }
                                      </a>
                                    </>
                                  </div>
                                </>
                              ))}
                            </>
                          </Form.Group>
                        </Form.Row>
                      </> : 'No Video Post'
                    }
                  </> : null
                }
                <Form.Row>
                  <Form.Group as={Col} md="4" className="row mr-2">
                    <Form.Label
                      htmlFor="exampleInputUsername2"
                      className="col-sm-4  col-form-label mt-2"
                      style={{
                        fontSize: '18px', // Replace with the desired font size
                      }}
                    >
                      Active
                    </Form.Label>
                    {/* <div className="col-sm-6"> */}
                    <Form.Check
                      name="status"
                      className="form-check-input"
                      defaultChecked={artist_detail.active_status}
                      onClick={() => { }}
                      disabled={editFlag ? false : true}
                    />
                    {/* </div> */}
                  </Form.Group>
                  <Form.Group as={Col} md="4" className="row mr-2">
                    <Form.Label
                      htmlFor="exampleInputUsername2"
                      className="col-sm-4  col-form-label mt-2"
                      style={{
                        fontSize: '18px', // Replace with the desired font size
                      }}
                    >
                      Publish
                    </Form.Label>
                    <Form.Check
                      className="ml-1 form-check-input"
                      name="OnTheFridgeWebsite"
                      defaultChecked={artist_detail.fridge_artist}
                      onClick={() => { }}
                      disabled={editFlag ? false : true}
                    />
                  </Form.Group>
                </Form.Row>

                <div className="row">
                  <div className="col-sm-12">
                    {artist_detail.approval_status === "Pending" && editFlag ? (
                      <>
                        <Button
                          onClick={() => {
                            dispatch(artistApprovalById(params.id, "Audition", null, artist_detail.type, history));
                          }}
                          className="btn-sm yellow-btn"
                        >
                          {/* Approve */}
                          Audition
                        </Button>
                        {/* <Button
                          onClick={() => setMdShow(true)}
                          className="btn-sm yellow-btn"
                        >
                          Reject
                        </Button> */}
                      </>
                    ) : null}
                    {artist_detail.approval_status === "Audition" && editFlag ? (
                      <>
                        <Button
                          onClick={() => {
                            dispatch(artistApprovalById(params.id, "Approved", null, artist_detail.type, history));
                          }}
                          className="btn-sm yellow-btn"
                        >
                          Approve
                        </Button>
                        <Button
                          onClick={() => setMdShow(true)}
                          className="btn-sm yellow-btn"
                        >
                          Reject
                        </Button>
                      </>
                    ) : null}
                    {!params.id ? <Button type="submit" className="btn yellow-btn mr-2">
                      {loader ? <Spinner animation="border" variant="light" />
                        : 'Create'}
                    </Button> : null}
                    {params.id && (artist_detail.approval_status === "Approved" || artist_detail.approval_status === "Rejected") && editFlag ?
                      <Button type="submit" className="btn yellow-btn mr-2">
                        {loader ? <Spinner animation="border" variant="light" />
                          : 'Update'}
                      </Button> : null}

                    {/* <Button type="submit" className="btn yellow-btn mr-2">
                      {params.id ? null : "Create"}
                    </Button> */}
                  </div>
                  <Modal
                    show={mdShow}
                    onHide={() => setMdShow(false)}
                    aria-labelledby="example-modal-sizes-title-md"
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Reason ?</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                      <textarea
                        className="form-control"
                        id="textAreaExample"
                        rows="4"
                        onChange={(e) => {
                          setRejectionReason(e.target.value);
                        }}
                        placeholder={"Reason for Rejection ?"}
                        style={{ background: "#fff" }}
                      ></textarea>
                    </Modal.Body>

                    <Modal.Footer className="fleex-wrap">
                      <Button
                        variant="yellow-btn m-2"
                        onClick={(e) => {
                          dispatch(
                            artistApprovalById(
                              params.id,
                              "Rejected",
                              rejectionReason,
                              artist_detail.type,
                              history
                            )
                          );
                          setMdShow(false);
                        }}
                      >
                        Submit
                      </Button>
                      <Button
                        variant="yellow-btn m-2"
                        onClick={() => setMdShow(false)}
                      >
                        Cancel
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div >
    </>
  );
}

export default AddArtist;
